import { Box, Divider, Typography } from "@material-ui/core";
import React, { useState } from "react";
import {
  toDateTime,
  toNumberFormat,
  toDuration,
  gtagEvent,
} from "../../utilities/helpers";
import { FlightObject } from "../../utilities/types";
import FlightToggle from "../FlightToggle/FlightToggle";
import BookSearchStyle from "./BookSearchStyle";

type Props = {
  flightDetails: FlightObject;
};
export default function BookSearch({ flightDetails }: Props) {
  const [show, setShow] = useState(false);
  const classes = BookSearchStyle();

  const toggleShow = () => {
    setShow((prev) => !prev);
    gtagEvent("view_item", {
      currency: flightDetails.FlightCombination.Price.CurrencyCode,
      value: flightDetails.FlightCombination.Price.Amount,
      items: [
        {
          item_name: `${flightDetails.FlightCombination.FlightModels[0].DepartureName} - ${flightDetails.FlightCombination.FlightModels[0].ArrivalName}`,
          item_brand:
            flightDetails.FlightCombination.FlightModels[0].AirlineName,
          currency: flightDetails.FlightCombination.Price.CurrencyCode,
          item_category: "Flight",
          price: flightDetails.FlightCombination.Price.Amount,
          item_list_id: "flight_search",
          item_list_name: "Flight Search",
          item_variant:
            flightDetails.FlightCombination.FlightModels.length > 1
              ? "Return"
              : "Oneway",
        },
      ],
    });
  };

  const flight = flightDetails.FlightCombination.FlightModels[0];

  return (
    <Box component={"div"} className={classes.con}>
      <Box className={classes.root} onClick={toggleShow}>
        <Box className={classes.boxOne}>
          <img src={flight.AirlineLogoUrl} alt="" className={classes.logo} />
          <Typography variant="caption" align="center">
            {flight.AirlineName}
          </Typography>
        </Box>

        <Box className={classes.blockBox}>
          {flightDetails.FlightCombination.FlightModels.map((model, index) => (
            <Box className={classes.boxTwo} key={index}>
              <Box className={classes.box}>
                <Typography variant="caption" align="center">
                  {toDateTime(model.DepartureTime, true)}
                </Typography>
                <Typography variant="caption" align="center">
                  {`${model.DepartureName} (${model.DepartureCode})`}
                </Typography>
              </Box>

              <Box className={classes.box}>
                <Typography variant="caption" align="center">
                  {model.Stops
                    ? model.Stops > 1
                      ? `${model.Stops} Stops`
                      : "1 Stop"
                    : "Non-Stop"}
                </Typography>
                <Box className={classes.divider}>
                  <Divider />
                </Box>
                <Typography variant="caption" align="center">
                  {toDuration(model.TripDuration)}
                </Typography>
              </Box>

              <Box className={classes.box}>
                <Typography variant="caption" align="center">
                  {toDateTime(model.ArrivalTime, true)}
                </Typography>
                <Typography variant="caption" align="center">
                  {`${model.ArrivalName} (${model.ArrivalCode})`}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>

        <Box className={classes.boxThree}>
          <Box className={classes.boxes}>
            <Box className={classes.boxed}>
              <Typography variant="caption" align="center">
                {flight.Stops === 0 ? "Non-Stop" : `${flight.Stops} Stop`}
              </Typography>
            </Box>
            <Divider />
            <Box className={classes.boxed}>
              <Typography variant="caption" align="center">
                {flight.FlightLegs[0].CabinClassName}
              </Typography>
            </Box>
          </Box>

          <Box className={classes.boxContainer}>
            <Typography
              className={classes.price}
              variant="caption"
              align="center"
            >
              {toNumberFormat(
                flightDetails.FlightCombination.Price.Amount,
                flightDetails.FlightCombination.Price.CurrencyCode
              )}{" "}
              <br /> View
            </Typography>
          </Box>
        </Box>
      </Box>
      {show ? <FlightToggle flight={flightDetails} /> : null}
    </Box>
  );
}

import { makeStyles, Theme } from '@material-ui/core';
export default makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  list: {
    width: '100%',
  },
  boldText: {
    fontWeight: 700,
    fontSize: "16px",
  },
  smallText: {
    fontSize: "12px",
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
}));

import { makeStyles, Theme } from "@material-ui/core";

export default makeStyles((theme: Theme) => ({
  root: {
    width: 'min(400px, 90%)',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    margin: `${theme.spacing(8)}px auto`,
  },
  logo: {
    width: "100px",
    height: "30px",
  },
  heading: {
    textAlign: "center",
  },
  paragraph: {
    fontWeight: 500,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    textAlign: "center",
  },
  span: {
    color: theme.palette.primary.main,
  },
  form: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  input: {
    marginBottom: theme.spacing(1),
  },
  btn: {
    width: "100%",
    marginTop: theme.spacing(2),
  },
  spinner: {
    color: "#ffffff",
  },
}));

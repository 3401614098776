import { ElementType } from "react";
import { genderListData } from "../../../utilities/helpers";
import FormSelect from "../FormSelect/FormSelect";

type Props = {
  name?: string;
  label?: string;
  value?: any;
  handleChange?: (value: any) => void;
  error?: boolean;
  helperText?: any;
  color?: "white";
  hidden?: boolean;
  icon?: ElementType<any>;
  type?: string;
  disable?: boolean;
};

export default function FormGenderList({
  name,
  label,
  value,
  handleChange,
  error,
  helperText,
  color,
  hidden,
  icon,
  type,
  disable,
}: Props) {
  return (
    <FormSelect
      name={name || "Gender"}
      id={name || "Gender"}
      placeholder={`Select ${(label || "gender").toLowerCase()}`}
      label={label || "Gender"}
      defaultValue={""}
      options={genderListData.list}
      value={value || ""}
      handleChange={handleChange}
      error={error}
      helperText={helperText}
      color={color}
      hidden={hidden}
      disable={disable}
      icon={icon}
      type={type}
    />
  );
}

import { Avatar, Box, Divider, ListItem, ListItemAvatar, Paper, Typography } from '@material-ui/core';
import React from 'react';
import { toDateTime, toDuration } from '../../utilities/helpers';
import { FlightModel, PriceDetail } from '../../utilities/types';
import Ticket from '../Ticket/Ticket';
import FlightSummaryStyle from './FlightSummaryStyle';

type Props = {
  flightModels: FlightModel[];
  totalPrice: number;
  prices: PriceDetail[];
  adults: number;
  children: number;
  infants: number;
};

export default function FlightSummary({ flightModels, totalPrice, prices, adults, children, infants }: Props) {
  const classes = FlightSummaryStyle();

  return flightModels ? (
    <Paper elevation={0} className={classes.root}>
      <Typography className={classes.firstText}>Flight summary</Typography>
      <ListItem disableGutters>
        <ListItemAvatar>
          <Avatar variant='square' alt={flightModels[0].AirlineName} src={flightModels[0].AirlineLogoUrl} />
        </ListItemAvatar>
        <Typography variant='caption'>
          {
            `${flightModels[0].AirlineName} (${flightModels[0].Airline}) ${flightModels[0].FlightLegs[0].CabinClassName}`
          }
        </Typography>
      </ListItem>

      <Box className={classes.blockBox}>
        {flightModels.map((model, index) => (
          <Box className={classes.modelCon} key={index}>
            <Typography className={classes.modelTitle} variant='caption'>
              {toDateTime(model.DepartureTime)} &nbsp; {model.DepartureCode} - {model.ArrivalCode}
            </Typography>
            <Box className={classes.boxTwo}>
              <Box className={classes.left}>
                <Typography variant='caption' align='left'>
                  {toDateTime(model.DepartureTime, true)}
                </Typography>
                <Typography variant='caption' align='left'>
                  {model.DepartureName}
                </Typography>
              </Box>

              <Box className={classes.center}>
                <Typography variant='caption' align='center'>
                  {model.Stops ? model.Stops > 1 ? `${model.Stops} Stops` : '1 Stop' : 'Non-Stop'}
                </Typography>
                <Box className={classes.divider}>
                  <Divider />
                </Box>
                <Typography variant='caption' align='center'>
                  {toDuration(model.TripDuration)}
                </Typography>
              </Box>

              <Box className={classes.right}>
                <Typography variant='caption' align='right'>
                  {toDateTime(model.ArrivalTime, true)}
                </Typography>
                <Typography variant='caption' align='right'>
                  {model.ArrivalName}
                </Typography>
              </Box>
            </Box>
            <Divider />
          </Box>
        ))}
      </Box>
      <Ticket
        prices={prices}
        adults={adults}
        children={children}
        infants={infants}
        totalPrice={totalPrice}
      />
    </Paper >
  ) : null;
}

import { Box, Button, CircularProgress, Grid, Typography } from "@material-ui/core";
import { useFormik } from 'formik';
import React from "react";
import { useDispatch } from "react-redux";
import swal from 'sweetalert';
import * as yup from 'yup';
import FormInput from "../../../components/Form/FormInput/FormInput";
import { Action_OpenAuthModal } from "../../../reduxStore/authModal/actions";
import { Action_SetUser, signUp } from "../../../reduxStore/user/actions";
import { gtagEvent } from '../../../utilities/helpers';
import { ModalForms } from "../../../utilities/types";
import SignUpFormStyle from "./SignUpFormStyle";

export default function SignUpForm() {
  const classes = SignUpFormStyle();
  const dispatch = useDispatch();

  const validationSchema = yup.object({
    first_name: yup
      .string()
      .required(),
    last_name: yup
      .string()
      .required(),
    phone: yup
      .number()
      .required(),
    email: yup
      .string()
      .email()
      .required(),
    password: yup
      .string()
      .min(6)
      .max(30)
      .required(),
    confirm_password: yup
      .string()
      .oneOf([yup.ref('password'), null], 'Passwords must match')
      .required(),
  });

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      phone: "",
      email: "",
      password: "",
      confirm_password: ""
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const body = {
        names: `${values.first_name} ${values.last_name}`,
        phone: values.phone.toString(),
        email: values.email,
        password: values.password,
      }

      try {
        const { status, message, data } = await signUp(body);
        if (status === true) {
          gtagEvent('sign_up', {method: 'Landing Page Modal'});
          swal("Success!", message, "success");
          formik.resetForm();
          dispatch(Action_SetUser({
            token: data,
            user: {
              id: 0,
              email: body.email,
              phone: body.phone,
              names: body.names
            },
          }));
          dispatch(Action_OpenAuthModal(ModalForms.EmailVerify, false));
        } else {
          swal("Oops!", message, "error");
        }
        formik.setSubmitting(false);
      } catch (err) {
        swal("Oops!", "Something went wrong, please try again", "error");
        formik.setSubmitting(false);
      }
    },
  });

  return (
    <Box className={classes.root}>
      <Typography className={classes.signText}>Sign Up</Typography>
      <Typography className={classes.smallText}>
        <span>If registered?</span>
        <Button size={"small"} className={classes.btn}
          onClick={() => {
            dispatch(Action_OpenAuthModal(ModalForms.SignIn));
          }}
        >
          Sign In
        </Button>
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Box className={classes.formInput}>
              <FormInput
                label="First Name*"
                placeholder="Eg. John"
                type="text"
                id="name"
                name="first_name"
                value={formik.values.first_name}
                handleChange={formik.handleChange('first_name')}
                error={formik.touched.first_name && Boolean(formik.errors.first_name)}
                helperText={formik.touched.first_name && formik.errors.first_name}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box className={classes.formInput}>
              <FormInput
                label="Last Name*"
                placeholder="Eg. Williams"
                type="text"
                id="last_name"
                name="last_name"
                value={formik.values.last_name}
                handleChange={formik.handleChange('last_name')}
                error={formik.touched.last_name && Boolean(formik.errors.last_name)}
                helperText={formik.touched.last_name && formik.errors.last_name}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box className={classes.formInput}>
              <FormInput
                label="Phone Number*"
                placeholder="Phone Number"
                type="number"
                id="number"
                name="number"
                value={formik.values.phone}
                handleChange={formik.handleChange('phone')}
                error={formik.touched.phone && Boolean(formik.errors.phone)}
                helperText={formik.touched.phone && formik.errors.phone}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box className={classes.formInput}>
              <FormInput
                label="Email*"
                placeholder="Eg. Johnwilliams@gmail.com"
                type="email"
                id="email"
                name="email"
                value={formik.values.email}
                handleChange={formik.handleChange('email')}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box className={classes.formInput}>
              <FormInput
                label="Password*"
                placeholder="Password"
                type="password"
                id="password"
                name="password"
                value={formik.values.password}
                handleChange={formik.handleChange('password')}
                error={formik.touched.password && Boolean(formik.errors.password)}
                helperText={formik.touched.password && formik.errors.password}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box className={classes.formInput}>
              <FormInput
                label="Confirm Password*"
                placeholder="Password"
                type="password"
                id="confirm_password"
                name="confirm_password"
                value={formik.values.confirm_password}
                handleChange={formik.handleChange('confirm_password')}
                error={formik.touched.confirm_password && Boolean(formik.errors.confirm_password)}
                helperText={formik.touched.confirm_password && formik.errors.confirm_password}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box className={classes.btnContainer}>
              <Button
                variant="contained"
                type="submit"
                color="primary"
                className={classes.btnPrimary}
              >
                {!formik.isSubmitting ? "Sign up" : <CircularProgress className={classes.spinner} />}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
}

import {ActionTypes, AuthModalType, ModalForms} from "../../utilities/types";
import { AuthModalActionType } from "./actions";

const defaultState: AuthModalType = {
    open: false,
    form: ModalForms.SignIn,
    dismissible: true,
};

export function AuthModalReducer(
    state: AuthModalType = defaultState,
    action: AuthModalActionType,
) {
    switch (action.type) {
        case ActionTypes.OPEN_AUTH_MODAL:
        case ActionTypes.CLOSE_AUTH_MODAL:
            return {...action.payload};
        default:
            return {...state};
    }
}

import { Box, Grid, Typography, RadioGroup, FormControlLabel } from "@material-ui/core";
import FormRadio from "../../Form/FormRadio/FormRadio";
import { Alert } from "@material-ui/lab";
import useVisaEmploymentStyles from "./VisaEmployment.style";
import { VisaEmployed, VisaSelfEmployed } from "./VisaEmploymentState";
import React from "react";
import { useFormikContext } from "formik";

type Props = {
    employmentStatus: string;

}

export enum EmploymentStatus {
    Employed = "Employed",
    SelfEmployed = "Self Employed",
    Unemployed = "Unemployed",
}
export const VisaEmployment = () => {
    const { values, handleChange } = useFormikContext<Props>();

    const getEmploymentSteps = (step: string): JSX.Element | null => {
        switch (step) {
            case EmploymentStatus.Employed:
                return <VisaEmployed />;
            case EmploymentStatus.SelfEmployed:
                return <VisaSelfEmployed />;
            case EmploymentStatus.Unemployed:
            default:
                return null;
        }
    }
    const classes = useVisaEmploymentStyles();
    return (
        <Box>
            <Grid container item className={classes.root}>
                <Grid item md={12} >
                    <Alert severity='warning' className={''}>
                        Use all given Information as they appear on your passport/ID to avoid complications.
                    </Alert>

                </Grid>
                <Grid item container component='div' className={classes.formDetails}>
                    <Typography className={classes.title}>Select employment status *</Typography>
                    <RadioGroup
                        className={classes.radioGroup}
                        onChange={handleChange('employmentStatus')}
                        defaultValue={EmploymentStatus.Unemployed}
                        name='employmentStatus'
                        value={values.employmentStatus}
                    >
                        <FormControlLabel
                            value={EmploymentStatus.Employed}
                            label='Employed'
                            control={<FormRadio />}
                        />
                        < FormControlLabel
                            value={EmploymentStatus.SelfEmployed}
                            label='Self Employed'
                            control={<FormRadio />}
                        />
                        < FormControlLabel
                            value= {EmploymentStatus.Unemployed}
                            label='Unemployed'
                            control={<FormRadio />}
                        />
                    </RadioGroup>


                </Grid>
                {getEmploymentSteps(values.employmentStatus as string)}



            </Grid>
            {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
        </Box>
    );
}
import { CssBaseline, MuiThemeProvider } from "@material-ui/core";
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter } from 'react-router-dom';
import theme from "./assets/theme/theme";
import MarkUp from './components/MarkUp';
import { Action_SetAirport } from './reduxStore/airports/actions';
import api from './utilities/api';
import { AppStore } from "./utilities/types";

export default function App() {
    const dispatch = useDispatch();
    const { mode } = useSelector((store: AppStore) => store.themeSwitch);

    useEffect(() => {
        api.fetch('/get-airports')
            .then(({ status, data }) => {
                if (status === true) {
                    dispatch(Action_SetAirport(data));
                }
            });
    }, [dispatch]);

    return (
        <MuiThemeProvider theme={theme(mode)}>
            <CssBaseline />
            <BrowserRouter>
                <MarkUp />
            </BrowserRouter>
            {/* <Cookie /> */}
        </MuiThemeProvider>
    );
}

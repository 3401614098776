import React from "react";
import { useVisaStyles } from './VisaAdditionalInfo.styles'
import { Grid, Box, RadioGroup, FormControlLabel } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import FormRadio from '../Form/FormRadio/FormRadio';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';

import SpouseForm from "./SubForms/SpouseForm";
import ChildForm from "./SubForms/ChildForm";
import StaffForm from "./SubForms/StaffForm";
import GroupForm from "./SubForms/GroupForm";
import { useFormikContext } from "formik";

type AdditionalProps = {
    whoAreYouTravellingWith: string;

}
type Props = {
    hideLabel?: boolean;
    textToggle?: boolean;
    // action: (value: FlightSearchProps) => void;
    loading?: boolean;
};

export enum TravellingWith {
    Spouse = "Spouse",
    Child = "Child",
    Staff = "Staff",
    Group = "Group"
}


const VisaAdditionalInfoBlock: React.FC<Props> = ( ) => {
    const { values, handleChange,errors,touched } = useFormikContext<AdditionalProps>();
    const classes = useVisaStyles();




    const handleView = (option: string): JSX.Element | null => {

        switch (option) {
            case TravellingWith.Spouse:
                return <SpouseForm />;
            case TravellingWith.Group:
                return <GroupForm />
            case TravellingWith.Child:
                return <ChildForm />
            case TravellingWith.Staff:
                return <StaffForm />;
            default:
                return null
            
        }

        // console.log(optionValue);
    };

    return (
        <Grid container className={classes.root} >

            <Grid container item className={classes.employment}>
                {/* Travel status and occupation */}
                <Grid item container component='div' className={classes.radioBtn}>
                    {/* <Typography variant="body2" component="p">Are you traveling with someone?</Typography> */}
                    <FormControl component="fieldset" >
                        <FormLabel id="">Who are you traveling with?</FormLabel>
                        <RadioGroup
                            className={classes.radios}
                            onChange={handleChange}
                            value={values.whoAreYouTravellingWith}
                            name="whoAreYouTravellingWith"
                    
                        >
                            <FormControlLabel value={TravellingWith.Spouse} label='Spouse' control={<FormRadio />} />
                            <FormControlLabel value={TravellingWith.Group} label='Group' control={<FormRadio />} />
                            <FormControlLabel value={TravellingWith.Child} label='Child' control={<FormRadio />} />
                            <FormControlLabel value={TravellingWith.Staff} label='Staff' control={<FormRadio />} />
                        </RadioGroup>

                    </FormControl>

                </Grid>
                {errors.whoAreYouTravellingWith && touched.whoAreYouTravellingWith && <Alert severity="error">{errors.whoAreYouTravellingWith}</Alert>}


            </Grid>


            <Grid item container >
                <Box mt={2}>
                   {handleView(values.whoAreYouTravellingWith as string)}
                </Box>
            </Grid>

        </Grid >
    );
}
export default VisaAdditionalInfoBlock
import { makeStyles, Theme } from "@material-ui/core";

export function MobileMenu() {
  return makeStyles((theme: Theme) => ({
    root: {
      backgroundColor: theme.palette.background.paper,
      width: "100%",
      position: "fixed",
      top: theme.spacing(9),
      left: 0,
      right: 0,
      boxShadow: `0 10px 10px rgba(0,0,0,0.1)`,
    },
  }));
}

export default function MainHeaderStyle(
  overlay: boolean | undefined,
  overScroll: boolean
) {
  if (overlay && !overScroll) {
    return makeStyles((theme: Theme) => ({
      root: {
        padding: `${theme.spacing(2)}px 0`,
        backgroundColor: "transparent",
        position: "sticky",
        top: 0,
        zIndex: 1000,
        height: "40px",
        marginBottom: "calc(-40px - 32px)",
      },
      paper: {
        position: "absolute",
        width: 400,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
      },
      innerCon: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
      },
      logo: {
        display: "block",
        height: "40px",
      },
      menuItem: {
        color: theme.palette.common.white,
        marginRight: theme.spacing(2),
      },
      navCon: {
        flexDirection: "row",
      },
      navContent: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        
      },
      avatar: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        fontSize: "16px",
        fontWeight: "bold",
        width: "40px",
        height: "40px",
      },
      but: {
        padding: theme.spacing(1),
      },
      menu: {
        zIndex: "11000 !important" as any,
        marginTop: theme.spacing(8),
      },
      navButton: {
        fontWeight: "normal",
        marginLeft: theme.spacing(2),
        paddingLeft: theme.spacing(2.5),
        paddingRight: theme.spacing(2.5),
      },
      signIn: {
        color: "#002C43",
      },
      toggleButton: {
        color: theme.palette.common.white,
      },
    }));
  } else if (overlay && overScroll) {
    return makeStyles((theme: Theme) => ({
      root: {
        padding: `${theme.spacing(2)}px 0`,
        backgroundColor: theme.palette.background.paper,
        position: "sticky",
        top: 0,
        zIndex: 10000,
        marginBottom: "calc(-40px - 32px)",
      },
      innerCon: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
      },
      logo: {
        display: "block",
        height: "40px",
      },
      menuItem: {
        color: "inherit",
        marginRight: theme.spacing(2),
      },
      paper: {
        position: "absolute",
        width: 400,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
      },
      navCon: {
        flexDirection: "row",
      },
      navContent: {
        display: "flex",
        flexDirection: "row",
      },
      avatar: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        fontSize: "16px",
        fontWeight: "bold",
        width: "40px",
        height: "40px",
      },
      but: {
        padding: theme.spacing(1),
      },
      menu: {
        zIndex: "11000 !important" as any,
        marginTop: theme.spacing(8),
      },
      navButton: {
        fontWeight: "normal",
        marginLeft: theme.spacing(2),
        paddingLeft: theme.spacing(2.5),
        paddingRight: theme.spacing(2.5),
      },
      signIn: {
        color: "#002C43",
      },
      toggleButton: {
        color: "inherit",
      },
    }));
  } else {
    return makeStyles((theme: Theme) => ({
      root: {
        padding: `${theme.spacing(2)}px 0`,
        backgroundColor: theme.palette.background.paper,
        position: "sticky",
        top: 0,
        zIndex: 10000,
        marginBottom: 0,
      },
      innerCon: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
      },
      logo: {
        display: "block",
        height: "40px",
      },
      menuItem: {
        color: "inherit",
        marginRight: theme.spacing(2),
      },
      navCon: {
        flexDirection: "row",
      },
      navContent: {
        display: "flex",
        flexDirection: "row",
      },
      avatar: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        fontSize: "16px",
        fontWeight: "bold",
        width: "40px",
        height: "40px",
      },
      but: {
        padding: theme.spacing(1),
      },
      menu: {
        zIndex: "11000 !important" as any,
        marginTop: theme.spacing(8),
      },
      paper: {
        position: "absolute",
        width: 400,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
      },
      navButton: {
        fontWeight: "normal",
        marginLeft: theme.spacing(2),
        paddingLeft: theme.spacing(2.5),
        paddingRight: theme.spacing(2.5),
      },
      signIn: {
        color: "#002C43",
      },
      toggleButton: {
        color: "inherit",
      },
    }));
  }
}

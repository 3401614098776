import { makeStyles, Theme } from "@material-ui/core";

export default makeStyles((theme: Theme) => ({
  root: {
    width: "60%",
    paddingTop: theme.spacing(5),
    paddingLeft: 0,
    paddingRight: 0,
    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  text: {
    fontSize: "12px",
  },
}));

import { ActionTypes, FlightSearchProps } from "../../utilities/types";

export type FlightPropType = {
  type: ActionTypes.SET_FLIGHT_PROPS | ActionTypes.CLEAR_FLIGHT_PROPS;
  payload: FlightSearchProps;
};

export function Action_SetFlightProps(payload: FlightSearchProps): FlightPropType {
  return {
    type: ActionTypes.SET_FLIGHT_PROPS,
    payload: {...payload},
  };
}

export function Action_ClearFlightProps() {
  return {
    type: ActionTypes.CLEAR_FLIGHT_PROPS,
    payload: {},
  }
}
import React from "react";
import { Box, } from "@material-ui/core";

import InnerContainer from "../../components/InnerContainer/InnerContainer";
import Layout from "../../components/Layout/Layout";
import VisaStyle from "./VisaStyle";
import Seo from "../../components/Seo/Seo";
import { VisaApplicantOne, VisaApplicantTwo, VisaPassportInfo } from "../../components/visaApplicationsForms";
import VisaImg from '../../assets/images/visaBannerPage.jpg';
import { CustomStepper } from '../../components/CustomStepper';

import * as Yup from "yup";
import VisaAdditionalInfo, { TravelingWithSomeone } from "../../components/VisaAdditionalInfo/VisaAdditionalInfo";
import { EmploymentStatus, VisaEmployment } from "../../components/visaApplicationsForms/VisaEmpoyment/VisaEmployment";
import { VisaTravelInfo } from "../../components/visaApplicationsForms/VisaTravelInfo/VisaTravelInfo";
import { TravelHistory, VisaTravelHistory } from "../../components/visaApplicationsForms/VisaSideBar/VisaTravelHistory";
import { FormikConfig, FormikValues } from "formik";
import { Gender, Title } from "../../utilities/types";
import { TravellingWith } from "../../components/VisaAdditionalInfo/VisaAdditionalInfoBlock";



interface FormikStepProps extends Pick<FormikConfig<FormikValues>, "children" | "validationSchema"> {
  label: string;

}
const FormikStep = ({ children, ...props }: FormikStepProps) => {
  return <>{children}</>
}

export default function Visa() {
  const classes = VisaStyle(VisaImg)();


  return (
    <Layout>
      <Seo title={"Visa Application"} />
      <Box className={classes.background} />
      <InnerContainer>
        <Box className={classes.root}>

          {/* <Typography variant='h5'>Visa Application</Typography>
            <Grid className={classes.visaFormContainer} container > */}
          <CustomStepper
            initialValues={{
              firstName: '',
              lastName: '',
              middleName: '',
              title: '',
              gender: '',
              maritalStatus: '',
              nationality: '',
              dateOfBirth: '',
              passportPhoto: null,
              phoneNumber: '',
              email: '',
              address: '',
              city: '',
              state: '',
              employmentStatus: '',
              employersName: '',
              employersEmail: '',
              employersPhoneNumber: '',
              employedOccupation: '',
              selfEmployedOccupation: '',
              companysName: '',
              companysPhoneNumber: '',
              companysEmail: '',
              arrivalDate: '',
              departureDate: '',
              destinationContact: '',
              bookingId: '',
              passportNumber: '',
              passportExpiryDate: '',
              scannedCopyOfDataPage: null,
              travelHistory: TravelHistory.None,
              travelHistoryScannedDataPage: null,
              termsAndConditions: false,
              travelingWithSomeone: '' || TravelingWithSomeone.No,
              whoAreYouTravellingWith: null,
              groupTravels: {
                visaDataPage: null,
                passportPhotograph: null,
              },
              spouseTravels: {
                visaDataPage: null,
                passportPhotograph: null,
                marriageCertificate: null,
              },
              childTravels: {
                visaDataPage: null,
                passportPhotograph: null,
                birthCertificate: null,
              },
              staffTravels: {
                visaDataPage: null,
                passportPhotograph: null,
                swornAffidavit: null,
              }


            }}
            onSubmit={(values) => {
              const formData = new FormData();
              Object.keys(values).forEach((key) => {
                if (values[key] !== null && values[key] !== undefined){
                  formData.append(key, values[key])
                
                }
              })
            }
            }
          >

            {/* 1 :: PERSONAL INFO */}
            <FormikStep label="Personal Information"
              validationSchema={
                Yup.object().shape({
                  firstName: Yup.string().required('First name is required'),
                  lastName: Yup.string().required('Last name is required'),
                  middleName: Yup.string().required('Middle name is required'),
                  title: Yup.string().required('Select an option').oneOf([Title.Mr, Title.Mrs, Title.Ms, Title.Dr, Title.Hon,]),
                  gender: Yup.string().required('Select an option').oneOf([Gender.MALE, Gender.FEMALE]),
                  maritalStatus: Yup.string().required('Select an option'),
                  nationality: Yup.string().required('Please select an option.'),
                  dateOfBirth: Yup.date().required('required').nullable(),
                  passportPhoto: Yup.mixed().required('Upload a passport photograph.').nullable(),
                })
              }

            >
              <VisaApplicantOne />

            </FormikStep>

            {/* 2 :: CONTACT INFO FORM */}
            <FormikStep
              label="Contact Information"
              validationSchema={
                Yup.object().shape({
                  email: Yup.string().required('An email address is required.').email('Invalid email address!'),
                  phoneNumber: Yup.string().required('Mobile number is required'),
                  address: Yup.string().required('Your residential address is required'),
                  city: Yup.string().required('Please select an optiion.'),
                  state: Yup.string().required('Please select an optiion.'),
                })
              }
            >
              <VisaApplicantTwo />
            </FormikStep>

            {/* 3 :: EMPLOYMENT INFO FORM */}
            <FormikStep
              label="Employment Information"
              validationSchema={
                Yup.object().shape({
                  employmentStatus: Yup.mixed().oneOf([EmploymentStatus.Employed, EmploymentStatus.SelfEmployed, EmploymentStatus.Unemployed]).required('Select an option'),
                  employedOccupation: Yup.string().when('employmentStatus', { is: EmploymentStatus.Employed, then: Yup.string().required('') }),
                  employersName: Yup.string().when('employmentStatus', { is: EmploymentStatus.Employed, then: Yup.string().required('Required') }),
                  employersPhoneNumber: Yup.string().when('employmentStatus', { is: EmploymentStatus.Employed, then: Yup.string().required('Required') }),
                  employersEmail: Yup.string().when('employmentStatus', { is: EmploymentStatus.Employed, then: Yup.string().required('Required') }),
                  selfEmployedOccupation: Yup.string().when('employmentStatus', { is: EmploymentStatus.SelfEmployed, then: Yup.string().required('Required') }),
                  companysName: Yup.string().when('employmentStatus', { is: EmploymentStatus.SelfEmployed, then: Yup.string().required('Required') }),
                  companysPhoneNumber: Yup.string().when('employmentStatus', { is: EmploymentStatus.SelfEmployed, then: Yup.string().required('Required') }),
                  companysEmail: Yup.string().when('employmentStatus', { is: EmploymentStatus.SelfEmployed, then: Yup.string().required('Required') }),
                })}
            >
              <VisaEmployment />
            </FormikStep>

            {/* 4 :: TRAVEL INFO FORM */}
            <FormikStep
              label="Travel Information"
              validationSchema={
                Yup.object().shape({
                  arrivalDate: Yup.date().optional().nullable(),
                  departureDate: Yup.date().required('Select a date').nullable(),
                  destinationContact: Yup.string().required('Please fill in this field'),
                  bookingId: Yup.string().required('Please enter your booking ID'),
                })}
            >
              <VisaTravelInfo />
            </FormikStep>

            {/* 5 :: PASSPORT DETAILS */}
            <FormikStep
              label="Passport Details"
              validationSchema={
                Yup.object().shape({
                  passportNumber: Yup.string().required('Fill in number on passport document'),
                  passportExpiryDate: Yup.date().required('Select a date').nullable(),
                  scannedCopyOfDataPage: Yup.mixed().required('Upload scanned passport page').nullable(),
                })
              }
            >
              <VisaPassportInfo />
            </FormikStep>

            {/* 6 :: TRAVEL HISTORY */}
            <FormikStep
              label="Travel History"
              validationSchema={
                Yup.object().shape({
                  travelHistory: Yup.string().oneOf([TravelHistory.Asia, TravelHistory.Schengen, TravelHistory.UK, TravelHistory.USA, TravelHistory.Other, TravelHistory.None]).required('Required'),
                  travelHistoryScannedDataPage: Yup.mixed().when('travelHistory',
                    {
                      is: (val: TravelHistory) => val !== TravelHistory.None,
                      then: Yup.mixed().required('Required').nullable()
                    }),
                })
              }
            >
              <VisaTravelHistory />
            </FormikStep>

            {/* 7 :: ADDITIONAL INFO */}
            <FormikStep
              label="Additional Information"
              validationSchema={
                Yup.object().shape({
                  termsAndConditions: Yup.boolean().oneOf([true], 'You must accept the terms and conditions').required('Required'),
                  travelingWithSomeone: Yup.string().required('Required').oneOf([TravelingWithSomeone.Yes, TravelingWithSomeone.No]),
                  whoAreYouTravellingWith: Yup.string().when('travelingWithSomeone', {
                    is: TravelingWithSomeone.Yes, then: Yup.string().oneOf([TravellingWith.Spouse, TravellingWith.Child, TravellingWith.Staff, TravellingWith.Group]).required('Please specify who you will be accompanied with.')
                  }),
                  spouseTravels: Yup.object().when('whoAreYouTravellingWith', {
                    is: TravellingWith.Spouse, then: Yup.object().shape({
                      visaDataPage: Yup.mixed().required('Required').nullable(),
                      passportPhotograph: Yup.mixed().required('Required').nullable(),
                      marriageCertificate: Yup.mixed().required('Required').nullable(),
                    })
                  }),
                  groupTravels: Yup.object().when('whoAreYouTravellingWith', {
                    is: TravellingWith.Group, then: Yup.object().shape({
                      visaDataPage: Yup.mixed().required('Required').nullable(),
                      passportPhotograph: Yup.mixed().required('Required').nullable(),
                    })
                  }),
                  childTravels: Yup.object().when('whoAreYouTravellingWith', {
                    is: TravellingWith.Child, then: Yup.object().shape({
                      visaDataPage: Yup.mixed().required('Required').nullable(),
                      passportPhotograph: Yup.mixed().required('Required').nullable(),
                      birthCertificate: Yup.mixed().required('Required').nullable(),
                    })
                  }),
                  staffTravels: Yup.object().when('whoAreYouTravellingWith', {
                    is: TravellingWith.Staff, then: Yup.object().shape({
                      visaDataPage: Yup.mixed().required('Required').nullable(),
                      passportPhotograph: Yup.mixed().required('Required').nullable(),
                      swornAffidavit: Yup.mixed().required('Required').nullable(),
                    })
                  })
                })
              }
            >
              <VisaAdditionalInfo />
            </FormikStep>

          </CustomStepper>
        </Box>
      </InnerContainer>
    </Layout >
  );
}
import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { gtagEvent } from "../../utilities/helpers";
import { AppStore } from "../../utilities/types";
import PaymentDetail from "../PaymentDetail/PaymentDetail";
import BankPayment from "./BankPayment/BankPayment";
import CardPayment from "./CardPayment/CardPayment";
import FlutterPayment from "./FlutterPayment/FlutterPayment";
import WalletPayment from "./WalletPayment/WalletPayment";
import CredPalPayment from "./CredPalPayment/CredPalPayment";
import KalabashPayment from "./KalabashPayment/KalabashPayment";
import BinancePayment from "./BinancePayment/BinancePayment";

type Props = {
  bookingId: string;
  date?: Date;
};

export default function MakePayment({ bookingId, date }: Props) {
  const [paymentMethod, setPaymentMethod] = useState<string>("");
  const { bookingData }: AppStore = useSelector((store: AppStore) => store);

  const handleChange =
    (panel: string) => (_: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setPaymentMethod(isExpanded ? panel : "");
    };

  useEffect(() => {
    gtagEvent("begin_checkout", {
      currency: bookingData.currency,
      value: bookingData.amount,
      items: [{
        item_id: bookingData.bookingId,
        item_category: bookingData.type,
        price: bookingData.amount,
      }],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        {bookingData.currency === "NGN" ? (
          <WalletPayment
            active={paymentMethod === "wallet"}
            onClick={handleChange("wallet")}
            bookingId={bookingId}
            amount={bookingData.amount}
          />
        ) : null}
        <CardPayment
          active={paymentMethod === "added-cards"}
          onClick={handleChange("added-cards")}
          bookingId={bookingId}
        />
        <FlutterPayment
          active={paymentMethod === "flutter-wave"}
          onClick={handleChange("flutter-wave")}
          bookingId={bookingId}
        />
        <CredPalPayment
          active={paymentMethod === "cred-pal"}
          onClick={handleChange("cred-pal")}
          bookingId={bookingId}
        />
        <KalabashPayment
          active={paymentMethod === "kalabash"}
          onClick={handleChange("kalabash")}
          bookingId={bookingId}
        />
        <BinancePayment
          active={paymentMethod === "binance"}
          onClick={handleChange("binance")}
          bookingId={bookingId}
        />

        {/* <SwipePayment
          active={paymentMethod === 'swipe-pay'}
          onClick={handleChange('swipe-pay')}
          bookingId={bookingId}
        /> */}
        {/* {bookingData.currency === "NGN" ? (
          <BankPayment
            active={paymentMethod === "bank-transfer"}
            onClick={handleChange("bank-transfer")}
            bookingId={bookingId}
          />
        ) : null} */}
      </Grid>
      {date ? (
        <Grid item xs={12}>
          <PaymentDetail date={date} />
        </Grid>
      ) : null}
    </Grid>
  );
}

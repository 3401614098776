import { makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) => ({
  root: {},
  firstBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  title: {
    fontWeight: 400,
    fontSize: '12px',
    color: theme.palette.primary.main,
    marginLeft: theme.spacing(2),
  },
  text: {
    fontWeight: 700,
    fontSize: '24px',
    paddingBottom: theme.spacing(3),
  },
}));

export const PolicyData = [
    {
        title: 'marketing',
        data: 'We strive to provide you with choices regarding certain personal data uses, particularly around marketing and advertising.'

    },
    {
        title: 'promotional offers from us',
        data: {

        }
    }
]



export const rows = [
    {
        name: ['To register you as a new customer'], DataType: ['(a) Identity', '(b) Contact'], lawfulData: ['Performance of a contract with you']
    },
    {
        name: ['To process and deliver your order including:', '(a) Manage payments, fees and charges', '(b) Collect and recover money owed to us'], DataType: ['(a) Identity', '(b) Contact', '(c) Financial', ' (d) Transaction', '(e) Marketing and Communications'], lawfulData: ['(a) Performance of a contract with', '(b) Necessary for our legitimate interests (to recover debts due to us)']
    },
    {
        name: ['To manage our relationship with you which will include:', '(a) Notifying you about changes to our terms or privacy policy', '(b) Asking you to leave a review or take a survey'], DataType: ['(a) Identity', '(b) Contact', '(c) Profile', '(d) Marketing and Communications', '(e) Usage'], lawfulData: ['(a) Performance of a contract with you', '(b) Necessary to comply with a legal obligation', '(c) Necessary for our legitimate interests (to keep our records updated and to study how customers use our products/services)']
    },
    {
        name:['To enable you to partake in a prize draw, competition or complete a survey'],
        DataType:['(a) Identity', '(b) Contact', '(c) Profile', '(d) Usage', '(e) Marketing and Communications'],
        lawfulData:['(a) Performance of a contract with you', '(b) Necessary for our legitimate interests (to study how customers use our products/services, to develop them and grow our business)']

    },
    {
        name: ['To administer and protect our business and this website (including troubleshooting, data analysis, testing, system maintenance, support, reporting and hosting of data)'], DataType: ['(a) Identity', '(b) Contact', '(c) Technical'], lawfulData: ['(a) Necessary for our legitimate interests (for running our business, provision of administration and IT services, network security, to prevent fraud and in the context of a business reorganisation or group restructuring exercise)']
    },
    {
        name:['To deliver relevant website content and advertisements to you and measure or understand the effectiveness of the advertising we serve to you'],
        DataType:['(a) Identity', '(b) Contact', '(c) Profile', '(d) Usage', '(e) Marketing and Communications','(f) Technical'],
        lawfulData:['(a) Necessary for our legitimate interests (to study how customers use our products/services, to develop them, to grow our business and to inform our marketing strategy)', '(b) Necessary to comply with a legal obligation']

    },
    {
        name: ['To use data analytics to improve our website, products/services, marketing, customer relationships and experiences'], DataType: ['(a) Technical', '(b) Usage'], lawfulData: ['(a) Necessary for our legitimate interests (to define types of customers for our products and services, to keep our website updated and relevant, to develop our business and to inform our marketing strategy)']
    },
    {
        name: ['To make suggestions and recommendations to you about goods or services that may be of interest to you'], DataType: ['(a) Identity', '(b) Contact', '(c) Technical', '(d) Usage', '(e) Profile', '(f) Marketing and Communications'], lawfulData: ['(a) Necessary for our legitimate interests (to develop our products/services and grow our business)']
    }


]


export const sharePersonalData = [
    `With third parties assisting Travoofy in providing our Customers with the Service(s).
    Our Sub-Processors are given access to Customers’ Account and Service Data only
    as reasonably necessary to provide the Service(s) and will be subject to
    confidentiality obligations in their service agreements;`,
    `With third party service providers providing us with services, such as research and
    analytics, anti-spamming and anti-phishing services, marketing and data enrichment
    or for them to reach out to you on our behalf;`,
    `For Travoofy users: with third parties to permit such third parties to provide services
    that help us to provide our business activities, which may include assisting us with
    marketing, advertising our product/service offerings, or providing, maintaining and
    improving the features and functionality of the Services`,
    `With third party payment processors who process your credit card and other payment
    information for Travoofy but who are otherwise not permitted to store, retain or use
    such information;`,
    `With third party partners for the purpose of assisting us in onboarding you and
    providing any further support needed to use our Services;`,
    `With sponsors of contests for which you register;`,
    `With third-party service providers that are assisting us with the operation and
    administration of events. If we are running an event in partnership with other
    organizations, we will share your Personal Data with such organizations for use in
    relation to the event;
    `,
    `With third-party social media networks, advertising networks and websites;`,
    `With external recruiters, and related organizations such as third-party providers that
    undertake employee background checks on our behalf and on behalf of other entities
    within our Group of Companies;
    `,
    `With auditors, lawyers, accountants and other professional advisers who advise and
    assist us in relation to the lawful and effective management of our organization and in
    relation to any disputes we may become involved in;`,
    `Law enforcement or other government and regulatory agencies including but not
    limited to National Information Technology Development Agency (NITDA) or other
    third parties as required by, and in accordance with, applicable law or regulation;`,
    `With Affiliates within Travoofy and companies that we will acquire in the future when
    they are made part of the Gmart Alliance Tours and Travels Limited – this may be for
    customer support, marketing, technical operations, account management or
    organizational purposes and to provide, enhance and improve the Services;`,
    `If we are involved in a merger, reorganization or other fundamental corporate change
    with a third party, or sell/buy a business unit to/from a third party, or if all or a portion
    of our business, assets or stock are acquired by a third party, with such third party
    including at the due diligence stage;`,
    `Other third parties - Occasionally, we may receive requests from third parties with
    authority to obtain disclosure of Personal Data, such as to check that we are
    complying with applicable law and regulation, to investigate an alleged crime, or to
    establish, exercise or defend legal rights. We will only fulfil requests for Personal
    Data where we are permitted to do so in accordance with applicable law or regulation.`,
    `When you connect your Gmail mailbox with your Travoofy Account.`

]

export const dataRetention = [
    ` We will only retain your personal data for as long as reasonably necessary to fulfil the
purposes we collected it for, including for the purposes of satisfying any legal, regulatory, tax,
accounting or reporting requirements. We may retain your personal data for a longer period
in the event of a complaint or if we reasonably believe there is a prospect of litigation in
respect to our relationship with you.`,
    `To determine the appropriate retention period for personal data, we consider the amount,
nature and sensitivity of the personal data, the potential risk of harm from unauthorised use
or disclosure of your personal data, the purposes for which we process your personal data
and whether we can achieve those purposes through other means, and the applicable legal,
regulatory, tax, accounting or other requirements.`,
    `By law we have to keep basic information about our customers (including Contact, Identity,
Financial and Transaction Data) for up to six years after they cease being customers for tax
and other financial purposes.`,
    `In some circumstances you can ask us to delete your data.`,
    `In some circumstances we will anonymise your personal data (so that it can no longer be
associated with you) for research or statistical purposes, in which case we may use this
information indefinitely without further notice to you.`
]

export const legalRights = [
    `Request access to your personal data`,
    `Request correction of your personal data`,
    `Request erasure of your personal data.`,
    ` Object to processing of your personal data`,
    `Request restriction of processing your personal data.`,
    `Request transfer of your personal data.`,
    `Right to withdraw consent.`

]
export const legalrightData = [
    {
        title: 'No fee usually required',
        data: [
            `You will not have to pay a fee to access your personal data (or to exercise any of the other
                rights). However, we may charge a reasonable fee if your request is clearly unfounded,
                repetitive or excessive. Alternatively, we could refuse to comply with your request in these
                circumstances.`

        ]
    },
    {
        title: 'What we may need from you',
        data: [
            `We may need to request specific information from you to help us confirm your identity and
            ensure your right to access your personal data (or to exercise any of your other rights). This is a security measure to ensure that personal data is not disclosed to any person who has no right to receive it. We may also contact you to ask you for further information in relation to
            your request to speed up our response.
            `
        ]
    }, {
        title: 'Time limit to respond',
        data: [
            `We try to respond to all legitimate requests within one month. Occasionally it could take us  longer than a month if your request is particularly complex or you have made a number of requests. In this case, we will notify you and keep you updated.`
        ]
    }, {
        title: 'Amendments',
        data: [
            `Amendments to this Notice will be posted to this URL and will be effective when posted. If
            we make any material changes, we will notify you by means of a conspicuous notice on this
            Website or via e-mail or via in-product notification, but we encourage you to review this
            Notice periodically to keep up to date on how we use your Personal Data. You should
            frequently visit this Notice to stay fully informed. Your continued use of our Websites or the Service(s) following the posting of any amendment, modification, or change to this Notice shall constitute your acceptance of the amendments to this Notice. You can choose to  discontinue use of the Websites or Service(s), if you do not accept the terms of this Notice, or any modified version of this Notice.`
        ]
    },
    {
        title: 'Children\'s personal data',
        data: [
            `This website is not intended for persons under the age of 13(Thirteen) years old in
            accordance to the Nigeria Data Protection Regulation 2019 (NDPR). We do not knowingly
            collect data relating to children. Wherein a child intends to use the services of Travoofy, suchchild must have the consent of a guardian and must have given clear consent to use the data of such child after carefully reading this policy. We encourage parents and legal guardians to monitor their children’s Internet usage and to help enforce this Notice by
            instructing their children never to provide Personal Data through our Services or Websites
            without their permission. If you have reason to believe that a child under the age of 13
            (Thirteen) has provided Personal Data to us through our Websites or Services, please
            contact us and we will endeavor to delete that information and terminate the child's account
            from our databases.`
        ]
    },
    {
        title: 'Sale of Personal Information',
        data: [
            `Freshworks does not sell your Personal Information, as we do not have the right to do so
            under Nigerian
            including any requests to exercise your legal rights, please contact the DPO using the
            details set out below.
            `

        ]
    },
    {
        title: 'Contact details',
        data: [
            `If you have any questions about this privacy policy or our privacy practices, please contact
            our DPO data privacy manager in the following ways:`,
            `Your full name of legal entity:`,
            `Your postal address:
            `,
            `Your Telephone number:`

        ]
    },
    {
        title: 'Changes to the privacy policy and your duty to inform us of changes',
        data: [
            `We keep our privacy policy under regular review.`,
            `It is important that the personal data we hold about you is accurate and current. Please keep us informed if your personal data changes during your relationship with us.`

        ]
    },

]
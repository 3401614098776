import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CircularProgress,
  Grid,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Radio,
} from "@material-ui/core";
import { Payment, PaymentRounded } from "@material-ui/icons";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import { Action_SetBookingData } from "../../../reduxStore/bookingData/actions";
import api from "../../../utilities/api";
import { gtagEvent, toNumberFormat } from "../../../utilities/helpers";
import { AppStore, UserCard } from "../../../utilities/types";
import CardPaymentStyle from "./CardPaymentStyle";

type Props = {
  active: boolean;
  onClick: any;
  bookingId: string;
};

export default function CardPayment({ active, onClick, bookingId }: Props) {
  const classes = CardPaymentStyle();
  const [cards, setCards] = useState<UserCard[]>([]);
  const [selectedCard, setSelectedCard] = useState<number>();
  const [loading, setLoading] = useState<boolean>(false);
  const { bookingData }: AppStore = useSelector((store: AppStore) => store);
  const dispatch = useDispatch();
  const mounted = useRef(true);

  const getBooking = () => {
    api
      .fetch(`/booking/${bookingId}`, undefined, true)
      .then(({ status, data }) => {
        if (status) {
          dispatch(Action_SetBookingData(data));
        }
      });
  };

  const pay = () => {
    setLoading(true);
    api
      .post(
        "/payment/card",
        {
          bookingId,
          cardId: selectedCard,
          currency: bookingData.currency,
        },
        true
      )
      .then(({ status, message }) => {
        if (status) {
          gtagEvent("purchase", {
            currency: bookingData.currency,
            transaction_id: bookingId,
            value: bookingData.amount,
            items: [
              {
                item_id: bookingId,
                item_category: bookingData.type,
                price: bookingData.amount,
                item_variant: "Card Payment",
              },
            ],
          });
          swal("Congratulation!", message, "success");
          getBooking();
        } else {
          swal("Warning", message, "warning");
          getBooking();
        }
      })
      .finally(() => setLoading(false));
  };

  const filterCurrency = (card: UserCard) =>
    (card.currency || "NGN") === bookingData.currency;

  useEffect(() => {
    mounted.current = true;
    api
      .fetch("/payments/user/cards", undefined, true)
      .then(({ status, data }) => {
        if (status && mounted.current) {
          setCards(data.filter(filterCurrency));
        }
      });

    return () => {
      mounted.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Accordion
      elevation={0}
      expanded={active}
      onChange={onClick}
      disabled={cards.length === 0}
    >
      <AccordionSummary expandIcon={<Radio color="primary" checked={active} />}>
        <ListItem>
          <ListItemAvatar>
            <PaymentRounded fontSize="large" />
          </ListItemAvatar>
          <ListItemText
            primaryTypographyProps={{ className: classes.boldText }}
            primary="Added Card"
            secondary="Pay with your already added debit/credit card"
          />
        </ListItem>
      </AccordionSummary>
      <AccordionDetails>
        <Grid
          container
          spacing={2}
          justifyContent={cards.length < 2 ? "center" : "flex-start"}
        >
          {cards.map((card, index) => (
            <Grid item xs={12} sm={6} key={index}>
              <ListItem
                onClick={() => setSelectedCard(card.id)}
                className={classes.root}
                button
                dense
                selected={card.id === selectedCard}
              >
                <ListItemIcon>
                  <Payment fontSize="large" />
                </ListItemIcon>
                <ListItemText
                  primary={`${card.type} ••••${card.last4}`}
                  secondary={card.bank}
                  primaryTypographyProps={{ className: classes.primary }}
                />
                <Radio edge="end" checked={card.id === selectedCard} />
              </ListItem>
            </Grid>
          ))}
          <Grid item xs={12} className={classes.button}>
            <br />
            <Button
              onClick={pay}
              variant="contained"
              color="primary"
              size="large"
              disabled={loading || !selectedCard}
              startIcon={
                loading ? (
                  <CircularProgress />
                ) : (
                  <PaymentRounded fontSize="large" />
                )
              }
            >
              Pay Now {toNumberFormat(bookingData.amount, bookingData.currency)}
            </Button>
            <br />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

import React from "react";
import { Grid, Box } from "@material-ui/core";
import FormInput from "../../Form/FormInput/FormInput";
import useVisaTravelStyle from "./VisaTravel.style";
import FormDate from "../../Form/FormDate/FormDate";
import { CalendarTodayOutlined } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import { useFormikContext } from "formik";

type Props = {
    arrivalDate: Date;
    departureDate: Date;
    destinationContact: string;
    bookingId: string;
}
export const VisaTravelInfo = () => {
    const { values, handleChange, touched, errors } = useFormikContext<Props>();
    const classes = useVisaTravelStyle();
    return (
        <Box >
            <Grid container className={classes.root} >
                <Grid item md={12} >
                    <Alert severity='warning' className={''}>Use all given Information as they appear on your passport/ID to avoid complications.</Alert>

                </Grid>
                <Grid item container className={classes.inputSpace} >
                    <Grid item xs={12} sm={5} md={5} >
                        <FormDate
                            label="Intended Departure Date *"
                            icon={CalendarTodayOutlined}
                            placeholder="Departure date"
                            name="departureDate"
                            handleChange={handleChange('departureDate')}
                             error={touched.departureDate && Boolean(errors.departureDate)}
                            helperText={touched.departureDate && errors.departureDate}

                        />
                    </Grid>
                    <Grid item xs={12} sm={5} md={5}>
                        <FormDate
                            label="Intended Arrival Date *"
                            icon={CalendarTodayOutlined}
                            placeholder="Arrival date"
                            name="arrivalDate"
                            handleChange={handleChange}
                            error={touched.arrivalDate && Boolean(errors.arrivalDate)}
                            helperText={touched.arrivalDate && errors.arrivalDate}

                        />
                    </Grid>
                </Grid>
                <Grid item container className={classes.inputSpace} >
                    <Grid item xs={12} sm={5} md={5} >
                        <FormInput label="Destination Contact"
                            placeholder="Destination Contact "
                            name="destinationContact "
                            type='text'
                            handleChange={handleChange('destinationContact')}
                            value={values.destinationContact}
                            error={touched.destinationContact && Boolean(errors.destinationContact)}
                            helperText={touched.destinationContact && errors.destinationContact}

                        />

                    </Grid>
                    <Grid item xs={12} sm={5} md={5}>
                        <FormInput
                            label="Booking ID"
                            placeholder="Booking ID"
                            name="bookingId"
                            type='text'
                            handleChange={handleChange}
                            value={values.bookingId}
                            error={touched.bookingId && Boolean(errors.bookingId)}
                            helperText={touched.bookingId && errors.bookingId}

                        />
                    </Grid>
                </Grid>
        </Grid>
        </Box>
    );
}


export type ThemeMode = "light" | "dark";

export enum ModalForms {
  SignIn = "SIGN_IN",
  SignUp = "SIGN_UP",
  EmailVerify = "EMAIL_VERIFY",
}

export type AuthModalType = {
  open: boolean;
  form: ModalForms;
  dismissible: boolean;
};

export type APIResponse = {
  status: boolean;
  message: string;
  data: any;
};

export type UserObject = {
  token: string;
  user: {
    id: number;
    names: string;
    phone: string;
    email: string;
  };
};

export type AirportObject = {
  AirportCode: string;
  AirportName: string;
  CityCountry: string;
  City: string;
  Country: string;
};

export enum PassengerType {
  ADULT = "Adult",
  CHILD = "Child",
  INFANT = "Infant",
}

export enum Gender {
  MALE = "Male",
  FEMALE = "Female",
}

export enum Title {
  Mr = "Mr",
  Ms = "Ms",
  Mrs = "Mrs",
  Sir = "Sir",
  Dr = "Dr",
  HRH = "HRH",
  Hon = "Hon",
  Pst = "Pst",
  Sen = "Sen",
}

export type TravelerDetail = {
  PassengerType: "Adult" | "Child" | "Infant";
  FirstName: string;
  MiddleName: string;
  LastName: string;
  DateOfBirth: Date;
  Age: null;
  PhoneNumber: string;
  PassportNumber: string;
  ExpiryDate: Date;
  PassportIssuingAuthority: string;
  PassportIssueCountryCode: null;
  Gender: Gender;
  Title: Title;
  Email: string;
  Address: string;
  Country: string;
  CountryCode: string;
  City: string;
  PostalCode: string;
  TicketNumber: null;
  RoomNumber: null;
  SelectedSeats: null;
  PassengerReferenceNumber: string;
  SelectedBaggages: null;
  WakaPointId: null;
  IsWakapointRegister: boolean;
};

export type UserCard = {
  bank: string;
  id: number;
  last4: string;
  type: string;
  currency?: string;
};

export type WalletObject = {
  accountNumber: string;
  accountNames: string;
  bankName: string;
  balance: number;
};

export type PassengerObject = {
  Country: string;
  CountryCode: string;
  DateOfBirth: string;
  Email: string;
  FirstName: string;
  Gender: string;
  LastName: string;
  MiddleName: string;
  PassengerType: string;
  PhoneNumber: string;
  Title: string;
  isPassportRequired: boolean;
  PassportNumber: string;
  ExpiryDate: string;
  PassportIssuingAuthority: string;
  ticket: {
    flightClass: string;
    flight: string;
    departureTime: string;
    arivalTime: string;
    boardingTime: string;
    bookingRef: string;
    seatNumber: string;
    terminal: string;
  }[][];
};

export type BookingData = {
  id: number;
  bookingId: string;
  type: string;
  amount: number;
  currency: string;
  bookingStatus: string;
  passengers: PassengerObject[];
  ticketStatus: string;
  meta: {
    BookingId: string;
    BillingAddress: null;
    CustomerId: string;
    ProductType: string;
    TargetCurrency: string;
    ProductTermsAndConditions: {
      TermsAndConditions: string[];
      TermsAndConditionImportantNotice: string;
    };
    FlightBookingResult: {
      FlightBookingSummaryModel: {
        PnrReferenceNumber: string;
        PnrDate: Date;
        FlightSummaryModel: {
          FlightCombination: FlightCombination;
          PriceBreakups: null;
        };
        TravellerDetails: TravelerDetail[];
        PnrStatus: null;
        TicketStatus: null;
      };
      IsFareLow: boolean;
      IsFareHigh: boolean;
      HasResult: boolean;
    };
    SelectedPaySmallSmallPlan: null;
  };
  status: string;
  createdAt: Date;
  updatedAt: Date;
  userId: number;
};

export type PaidBookingData = {
  id: number;
  bookingId: string;
  type: string;
  amount: number;
  currency: string;
  bookingStatus: string;
  passengers: PassengerObject[];
  ticketStatus: string;
  meta: {
    BookingId: string;
    CustomerId: string;
    ProductType: string;
    BillingAddress: null;
    BookingStatusDetails: {
      Message: string;
      PnrStatus: string;
      BookingStatus: string;
      GeographyCode: string;
      PaymentStatus: string;
      PaymentRemarks: string;
      TicketingStatus: string;
      CovidAlertMessage: null;
      AvailabilityMessage: string;
    };
    FlightBookingResult: {
      FlightBookingSummaryModel: {
        PnrDate: Date;
        PnrStatus: string;
        TicketStatus: string;
        TravellerDetails: TravelerDetail[];
        FlightSummaryModel: {
          PriceBreakups: null;
          FlightCombination: FlightCombination;
        };
        PnrReferenceNumber: string;
      };
    };
    BookingPaymentDetails: {
      Discount: {
        Amount: number;
        CurrencyCode: string;
      };
      TotalPrice: {
        Amount: number;
        CurrencyCode: string;
      };
      PaymentLink: null;
      PaymentStatus: string;
      PayBankDetails: string;
      TotalTripPrice: {
        Amount: number;
        CurrencyCode: string;
      };
      FranchiseMarkup: {
        Amount: number;
        CurrencyCode: null;
      };
      PaymentMethodId: number;
      PaymentOptionId: number;
      SendForApproval: boolean;
      TotalAddonAmount: {
        Amount: number;
        CurrencyCode: string;
      };
      PaymentMethodName: string;
      PaymentOptionName: string;
      PaymentReferenceCode: null;
    };
    ProductTermsAndConditions: {
      TermsAndConditions: string[];
      TermsAndConditionImportantNotice: string;
    };
    SelectedPaySmallSmallPlan: null;
  };
  status: string;
  createdAt: Date;
  updatedAt: Date;
  userId: number;
};

export type PackageBookingData = {
  id: number;
  bookingId: string;
  type: string;
  amount: number;
  currency: string;
  bookingStatus: string;
  passengers: PassengerObject[];
  ticketStatus: string;
  meta: {
    document: PackageDocumentObject;
    package: PackageObject;
  };
  status: string;
  createdAt: Date;
  updatedAt: Date;
  userId: number;
};

export type PassengerDetail = {
  PassengerType: PassengerType;
  FirstName: string;
  MiddleName: string;
  LastName: string;
  DateOfBirth: string;
  Gender: Gender;
  Email?: string;
  PhoneNumber?: string;
  Address?: string;
  isPassportRequired: boolean;
  PassportNumber?: string;
  ExpiryDate?: string;
  PassportIssuingAuthority?: string;
  Title: string;
  City?: string;
  Country?: string;
  CountryCode?: string;
  PostalCode?: string;
};

export type FlightSearchProps = {
  FlightSearchType: string;
  Adults: string;
  Children: string;
  Infants: string;
  Ticketclass: string;
  TargetCurrency: string;
  Itineraries: {
    Departure: string;
    Destination: string;
    DepartureDate: string;
  }[];
};

export type FlightLeg = {
  FlightLegNumber: string;
  DepartureCode: string;
  DepartureName: string;
  DestinationCode: string;
  DestinationName: string;
  StartTime: string;
  EndTime: string;
  Duration: string;
  IsStop: false;
  Layover: string;
  LayoverDuration: string;
  BookingClass: string;
  CabinClass: string;
  CabinClassName: string;
  OperatingCarrier: string;
  OperatingCarrierName: string;
  MarketingCarrier: string;
  FlightNumber: string;
  Aircraft: string;
  FareType: string;
  FarebasisCode: string;
  Status: any;
  CorporateCode: any;
  FlightSelectData: any;
  TechnicalStops: {
    AirportCode: string;
    AirportName: string;
    ArrivalDate: string;
    DepartureDate: string;
    Duration: string;
  }[];
  Seats: any;
};

export type FlightModel = {
  Name: string;
  Airline: string;
  AirlineName: string;
  DepartureCode: string;
  DepartureName: string;
  DepartureTime: string;
  ArrivalName: string;
  ArrivalCode: string;
  ArrivalTime: string;
  Stops: number;
  StopTime: string;
  TripDuration: string;
  StopCity: string;
  FlightLegs: FlightLeg[];
  AirlineLogoUrl: string;
  FreeBaggage: {
    BagCount: number;
    Weight: number;
    WeightUnit: number;
  };
};

export type PriceDetail = {
  BaseFare: {
    Amount: number;
    CurrencyCode: string;
  };
  Tax: {
    Amount: number;
    CurrencyCode: string;
  };
  PassengerType: "Adult" | "Child" | "Infant";
};

export type FlightCombination = {
  FlightModels: FlightModel[];
  Price: {
    Amount: number;
    CurrencyCode: string;
  };
  MarketingCarrier: string;
  Adults: number;
  Children: number;
  Infants: number;
  PriceDetails: PriceDetail[];
  FareRules: string[];
  PenaltyRules: string[];
  AirlineCode: string;
  IsRefundable: boolean;
  NonRefundableFreeText: string;
  IncludePaySmallSmall: boolean;
  DownPaymentDetailInPercentage: number;
  PaySmallSmallLockDownPrice: number;
  ConnectionCode: string;
};

export type FlightObject = {
  FlightCombination: FlightCombination;
  SelectData: string;
};

export type FlightData = {
  FlightObject?: FlightObject;
  FlightSummaryModel?: {
    FlightCombination: {
      FlightModels: [];
      Price: {
        Amount: number;
        CurrencyCode: string;
      };
      MarketingCarrier: string;
      Adults: number;
      Children: number;
      Infants: number;
      PriceDetails: PriceDetail[];
      FareRules: string[];
      PenaltyRules: string[];
      AirlineCode: string;
      IsRefundable: boolean;
      NonRefundableFreeText: string;
      IncludePaySmallSmall: boolean;
      DownPaymentDetailInPercentage: number;
      PaySmallSmallLockDownPrice: number;
      ConnectionCode: string;
    };
    PriceBreakups: null;
  };
  IsPriceMatched?: boolean;
  HasResult?: boolean;
  SelectData?: string;
  ProductTermsAndConditions?: {
    TermsAndConditions: string[];
    TermsAndConditionImportantNotice: string;
  };
  BookingId?: string;
  IsPassportRequired?: boolean;
  CustomMessages?: {
    Title: string;
    Message: string;
    SeverityLevel: "High" | "Medium" | "Low";
  }[];
};

export interface on {
  user: {
    auth: boolean;
    otp_token: string;
    tfa_token: string;
    user: any;
    client: string;
  };
}

export type AppStore = {
  themeSwitch: { mode: ThemeMode };
  user: UserObject;
  authModal: AuthModalType;
  airports: AirportObject[];
  flightData: FlightData;
  flightProps: FlightSearchProps;
  bookingData: BookingData;
};

export enum FlightStops {
  NONSTOP = "nonStop",
  ONESTOP = "oneStop",
  MORESTOP = "moreStop",
}

export type StopsObject = {
  label: string;
  value: boolean;
};

export type QuickBookingObject = {
  createdAt: Date;
  from: {
    city: string;
    airportCode: string;
  };
  id: number;
  to: {
    city: string;
    airportCode: string;
  };
  updatedAt: Date;
};

export type PackageObject = {
  id: number;
  name: string;
  description: string;
  featuredImage: string;
  images: string[];
  inclusions: string[];
  date: Date[];
  prices: {
    unit: string;
    currency: string;
    actualPrice: number;
    sellingPrice: number;
  };
  status: string;
  createdAt: Date;
  updatedAt: Date;
  categoryId: number;
  documentModelId: number;
};

export type DocumentField = {
  name: string;
  type: FormTypes;
  label: string;
  required: boolean;
  description: string;
  value?: any;
};

export type PackageDocumentObject = {
  createdAt: Date;
  data: DocumentField[];
  documentModelId: number;
  id: number;
  updatedAt: Date;
  userId: number;
};

export type DocumentModelObject = {
  id: number;
  name: string;
  description: string;
  fields: DocumentField[];
  createdAt: Date;
  updatedAt: Date;
};

export type PackageDetailObject = {
  id: number;
  name: string;
  description: string;
  featuredImage: string;
  images: string[];
  inclusions: string[];
  date: Date[];
  prices: {
    unit: string;
    currency: string;
    actualPrice: string;
    sellingPrice: string;
  };
  status: "open" | "closed" | "suspended";
  createdAt: Date;
  updatedAt: Date;
  categoryId: number;
  documentModelId: number;
  documentModel: DocumentModelObject;
  category: {
    id: number;
    name: string;
    createdAt: Date;
    updatedAt: Date;
  };
};

export type CategoryObject = {
  id: number;
  name: string;
  createdAt: Date;
  updatedAt: Date;
};

export type CategoryDetailObject = {
  id: number;
  name: string;
  createdAt: Date;
  updatedAt: Date;
  packages: PackageObject[];
};

export type AnyObject<T> = {
  [key: string]: T;
};

export enum FormTypes {
  Text = "text",
  Number = "number",
  Email = "email",
  Phone_Number = "phone",
  Date = "date",
  File = "file",
  Gender_Select = "gender-select",
  County_Select = "country-select",
  Yes_No = "toggle",
  Address = "address",
  Long_Text = "long-text",
}

export enum ActionTypes {
  SET_THEME = "SET_THEME",
  SET_USER = "SET_USER",
  REMOVE_USER = "REMOVE_USER",
  OPEN_AUTH_MODAL = "OPEN_AUTH_MODAL",
  CLOSE_AUTH_MODAL = "CLOSE_AUTH_MODAL",
  SET_AIRPORT = "SET_AIRPORT",
  SET_FLIGHT_DATA = "SET_FLIGHT_DATA",
  SET_BOOKING_DATA = "SET_BOOKING_DATA",
  SET_FLIGHT_PROPS = "SET_FLIGHT_PROPS",
  CLEAR_FLIGHT_DATA = "CLEAR_FLIGHT_DATA",
  CLEAR_FLIGHT_PROPS = "CLEAR_FLIGHT_PROPS",
  CLEAR_BOOKING_DATA = "CLEAR_BOOKING_DATA",
}

export enum StorageKeys {
  Theme = "theme",
  User = "user",
  Airports = "airports",
  Concent = "cookie-concent",
}

export enum FlightSort {
  Cheapest = "Cheapest",
  Fastest = "Fastest",
  Stops = "Stops",
  Airlines = "Airlines",
}

export enum DayTimes {
  MORNING = "Morning",
  AFTERNOON = "Afternoon",
  EVENING = "Evening",
  NIGHT = "Night",
}

export enum TicketClass {
  Economy = "Y",
  PremiumEconomy = "W",
  BusinessClass = "C",
  FirstClass = "F",
}

export type VisaObject = {
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
  country?: string;
  city?: string;
  address?: string;
  dateOfBirth?: Date;
  gender?: Gender;
  title?: Title;
  passportNumber?: string;
  passportExpiryDate?: Date;
  passportIssueDate?: Date;
  state?: string;
  maritalStatus?: MaritalStatus;
  nationality?: string;
  passportPhoto?: FormTypes.File;
  middleName?: string;
  scannedDataPage?: FormTypes.File;
  expiryDate?: Date;
  employedData?: EmployedData;
  companysName?: string;
  companysPhoneNumber?: string;
  companysEmail?: string;
  employed?: boolean;
  selfEmployed?: boolean;
  unemployed?: boolean;
  employmentStatus?: EmploymentStatus;
  selfEmployedData?: SelfEmployedData;
  departureDate?: Date;
  arrivalDate?: Date;
  bookingId?: string;
  destinationContact?: string;
  travelHistory?: string;
  travelWithSomeone?: string;
  termsAndConditions?: boolean;

}
// type TravelWithSomeOne = 'Yes' | 'No'

type EmployedData = {
  employersName?: string;
  employersEmail?: string;
  employersPhoneNumber?: string;
  occupation?: string;
}
type SelfEmployedData = {
  selfOccupation?: string;
  companyName?: string;
  companyPhoneNumber?: string;
  companyEmail?: string;
}
export type EmploymentStatus = "employed" | "self Employed" | "unemployed";
export enum MaritalStatus {
  SINGLE = "Single",
  MARRIED = "Married",
  DIVORCED = "Divorced",
}
import { Grid, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import BookingDetails from "../../../components/BookingDetails/BookingDetails";
import FlightSummary from "../../../components/FlightSummary/FlightSummary";
import InnerContainer from "../../../components/InnerContainer/InnerContainer";
import Layout from "../../../components/Layout/Layout";
import Seo from "../../../components/Seo/Seo";
import { Action_SetBookingData } from "../../../reduxStore/bookingData/actions";
import api from "../../../utilities/api";
import pageRoutes from "../../../utilities/pageRoutes";
import { AppStore, PaidBookingData } from "../../../utilities/types";
import PackageBooking from "../PackageBooking/PackageBooking";
import ViewBookingStyle from "./ViewBookingStyle";

export default function ViewBooking() {
  const classes = ViewBookingStyle();
  const { bookingId }: any = useParams();
  const dispatch = useDispatch();
  const { bookingData, user }: AppStore = useSelector(
    (store: AppStore) => store
  );
  const [loading, setLoading] = useState<boolean>(true);
  const history = useHistory();
  const paidBooking: PaidBookingData = bookingData as any;

  const getBooking = (id: string) => {
    setLoading(true);
    api.fetch(`/booking/${id}`, undefined, true).then(({ status, data }) => {
      setLoading(false);
      if (status) {
        dispatch(Action_SetBookingData(data));
      } else {
        history.push(pageRoutes.Flight);
      }
    });
  };

  useEffect(() => {
    if (bookingId && user.token) {
      getBooking(bookingId);
    }
    if (!user.token) {
      history.goBack();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingId]);

  return bookingData.type === "package" ? (
    <PackageBooking />
  ) : (
    <Layout>
      <Seo title={"Booking"} />
      <InnerContainer className={classes.root}>
        {!loading ? (
          <Typography className={classes.bookingText}>
            Booking Ref: <small>{bookingData.bookingId}</small>
          </Typography>
        ) : (
          <Typography className={classes.bookingText}>
            <Skeleton animation="wave" variant="text" width={300} />
          </Typography>
        )}

        <Grid container spacing={3}>
          <Grid item md={8} sm={12} xs={12}>
            {!loading && bookingData.meta ? (
              bookingData.status === "paid" &&
              ["success", "failed"].includes(bookingData.bookingStatus) ? (
                <BookingDetails
                  booking={bookingData}
                  combination={
                    paidBooking.meta.FlightBookingResult.FlightBookingSummaryModel.FlightSummaryModel
                      .FlightCombination
                  }
                />
              ) : (
                <BookingDetails
                  booking={bookingData}
                  combination={
                    bookingData.meta.FlightBookingResult
                      .FlightBookingSummaryModel.FlightSummaryModel
                      .FlightCombination
                  }
                />
              )
            ) : (
              <Skeleton animation="wave" variant="rect" height={600} />
            )}
          </Grid>
          <Grid item md={4} sm={12} xs={12}>
            {!loading && bookingData && bookingData.amount ? (
              bookingData.status === "paid" ? (
                <FlightSummary
                  totalPrice={paidBooking.amount}
                  adults={
                    paidBooking.meta.FlightBookingResult.FlightBookingSummaryModel.FlightSummaryModel
                      .FlightCombination.Adults
                  }
                  children={
                    paidBooking.meta.FlightBookingResult.FlightBookingSummaryModel.FlightSummaryModel
                      .FlightCombination.Children
                  }
                  infants={
                    paidBooking.meta.FlightBookingResult.FlightBookingSummaryModel.FlightSummaryModel
                      .FlightCombination.Infants
                  }
                  prices={
                    paidBooking.meta.FlightBookingResult.FlightBookingSummaryModel.FlightSummaryModel
                      .FlightCombination.PriceDetails
                  }
                  flightModels={
                    paidBooking.meta.FlightBookingResult.FlightBookingSummaryModel.FlightSummaryModel
                      .FlightCombination.FlightModels || []
                  }
                />
              ) : (
                <FlightSummary
                  totalPrice={bookingData.amount}
                  adults={
                    bookingData.meta.FlightBookingResult
                      .FlightBookingSummaryModel.FlightSummaryModel
                      .FlightCombination.Adults
                  }
                  children={
                    bookingData.meta.FlightBookingResult
                      .FlightBookingSummaryModel.FlightSummaryModel
                      .FlightCombination.Children
                  }
                  infants={
                    bookingData.meta.FlightBookingResult
                      .FlightBookingSummaryModel.FlightSummaryModel
                      .FlightCombination.Infants
                  }
                  prices={
                    bookingData.meta.FlightBookingResult
                      .FlightBookingSummaryModel.FlightSummaryModel
                      .FlightCombination.PriceDetails
                  }
                  flightModels={
                    bookingData.meta.FlightBookingResult
                      .FlightBookingSummaryModel.FlightSummaryModel
                      .FlightCombination.FlightModels || []
                  }
                />
              )
            ) : (
              <Skeleton animation="wave" variant="rect" height={600} />
            )}
          </Grid>
        </Grid>
      </InnerContainer>
    </Layout>
  );
}

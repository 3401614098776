import { Box, Button, Grid } from "@material-ui/core";
import { CalendarTodayOutlined } from "@material-ui/icons";
import { sub } from "date-fns";
import { useFormik } from "formik";
import * as Yup from "yup";
import { countryListData } from "../../utilities/helpers";
import {
  Gender,
  PassengerDetail,
  PassengerType,
  Title,
} from "../../utilities/types";
import FormCountryList from "../Form/FormCountryList/FormCountryList";
import FormDate from "../Form/FormDate/FormDate";
import FormGenderList from "../Form/FormGenderList/FormGenderList";
import FormInput from "../Form/FormInput/FormInput";
import FormSelect from "../Form/FormSelect/FormSelect";
import PassengerFormStyle from "./PassengerFormStyle";

type Props = {
  activeStep: number;
  handleBack: () => void;
  handleNext: () => void;
  data: PassengerDetail;
  passport: boolean;
  saveData: (param: (prev: PassengerDetail[]) => PassengerDetail[]) => void;
  date: string;
};

const validation = (passport: boolean, min?: Date, max?: Date) =>
  Yup.object({
    PassengerType: Yup.string().required(),
    FirstName: Yup.string().required(),
    MiddleName: Yup.string().notRequired(),
    LastName: Yup.string().required(),
    DateOfBirth: min
      ? Yup.date().min(min).max(max).required()
      : Yup.date().max(max).required(),
    Title: Yup.string().required(),
    Gender: Yup.string().required().oneOf([Gender.MALE, Gender.FEMALE]),
    Email: Yup.string().email().required(),
    PhoneNumber: Yup.string().required(),
    CountryCode: Yup.string().label("Country").required(),
    PassportNumber: passport
      ? Yup.string().required()
      : Yup.string().notRequired(),
    ExpiryDate: passport ? Yup.string().required() : Yup.string().notRequired(),
    PassportIssuingAuthority: passport
      ? Yup.string().required()
      : Yup.string().notRequired(),
  });

const getMinMaxDate = (
  type: PassengerType,
  date: string
): { min: Date | undefined; max: Date | undefined } => {
  const now = new Date(date);
  switch (type) {
    case PassengerType.ADULT:
      return { min: undefined, max: sub(now, { years: 12 }) };
    case PassengerType.CHILD:
      return { min: sub(now, { years: 12 }), max: sub(now, { years: 2 }) };
    case PassengerType.INFANT:
    default:
      return { min: sub(now, { years: 2 }), max: now };
  }
};

export default function PassengerForm({
  saveData,
  activeStep,
  handleBack,
  handleNext,
  data,
  passport,
  date,
}: Props) {
  const classes = PassengerFormStyle();
  const { min, max } = getMinMaxDate(data.PassengerType, date);

  const formik = useFormik({
    initialValues: {
      ...data,
    },
    validationSchema: validation(passport, min, max),
    onSubmit: (value) => {
      const pData: PassengerDetail = {
        PassengerType: value.PassengerType,
        FirstName: value.FirstName,
        MiddleName: value.MiddleName,
        LastName: value.LastName,
        DateOfBirth: value.DateOfBirth,
        Gender: value.Gender,
        isPassportRequired: passport,
        Title: value.Title,
        CountryCode: value.CountryCode,
        Email: value.Email,
        PhoneNumber: value.PhoneNumber,
        Country: countryListData.data[value.CountryCode as string],
      };
      if (passport) {
        pData.PassportNumber = value.PassportNumber;
        pData.ExpiryDate = value.ExpiryDate;
        pData.PassportIssuingAuthority = value.PassportIssuingAuthority;
      }
      saveData((prev: PassengerDetail[]) =>
        prev.map((p, i) => {
          if (i === activeStep) {
            return pData;
          }
          return p;
        })
      );
      handleNext();
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={4} sm={2}>
            <FormSelect
              name="Title"
              id="Title"
              placeholder="Select title"
              label="Title"
              options={Object.keys(Title).map((key) => ({
                label: key,
                value: key,
              }))}
              value={formik.values.Title || ""}
              handleChange={formik.handleChange("Title")}
              error={formik.touched.Title && Boolean(formik.errors.Title)}
              helperText={formik.touched.Title && formik.errors.Title}
            />
          </Grid>
          <Grid item xs={8} sm={5}>
            <FormInput
              name="FirstName"
              type="text"
              id="FirstName"
              placeholder="First Name"
              label="First Name"
              value={formik.values.FirstName}
              handleChange={formik.handleChange("FirstName")}
              error={
                formik.touched.FirstName && Boolean(formik.errors.FirstName)
              }
              helperText={formik.touched.FirstName && formik.errors.FirstName}
            />
          </Grid>
          <Grid item xs={12} sm={5}>
            <FormInput
              name="LastName"
              type="text"
              id="LastName"
              placeholder="Last Name"
              label="Last Name"
              value={formik.values.LastName}
              handleChange={formik.handleChange("LastName")}
              error={formik.touched.LastName && Boolean(formik.errors.LastName)}
              helperText={formik.touched.LastName && formik.errors.LastName}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormInput
              name="MiddleName"
              type="text"
              id="MiddleName"
              placeholder="Middle Name"
              label="Middle Name"
              value={formik.values.MiddleName}
              handleChange={formik.handleChange("MiddleName")}
              error={
                formik.touched.MiddleName && Boolean(formik.errors.MiddleName)
              }
              helperText={formik.touched.MiddleName && formik.errors.MiddleName}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormGenderList
              name="Gender"
              label="Gender"
              value={formik.values.Gender}
              handleChange={formik.handleChange("Gender")}
              error={formik.touched.Gender && Boolean(formik.errors.Gender)}
              helperText={formik.touched.Gender && formik.errors.Gender}
            />
            {/* <FormSelect
              name="Gender"
              id="Gender"
              placeholder="Select gender"
              label="Gender"
              defaultValue={""}
              options={genderListData.list}
              value={formik.values.Gender || ""}
              handleChange={formik.handleChange("Gender")}
              error={formik.touched.Gender && Boolean(formik.errors.Gender)}
              helperText={formik.touched.Gender && formik.errors.Gender}
            /> */}
          </Grid>
          <Grid item xs={12} sm={5}>
            <FormDate
              label="Date Birth"
              placeholder="Date Of Birth"
              id="DateOfBirth"
              name="DateOfBirth"
              showToolbar
              icon={CalendarTodayOutlined}
              value={formik.values.DateOfBirth}
              handleChange={formik.handleChange("DateOfBirth")}
              minDate={min}
              maxDate={max}
              error={
                formik.touched.DateOfBirth && Boolean(formik.errors.DateOfBirth)
              }
              helperText={
                formik.touched.DateOfBirth && formik.errors.DateOfBirth
              }
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormInput
              name="Email"
              type="email"
              id="Email"
              placeholder="Email"
              label="Email"
              value={formik.values.Email}
              handleChange={formik.handleChange("Email")}
              error={formik.touched.Email && Boolean(formik.errors.Email)}
              helperText={formik.touched.Email && formik.errors.Email}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormInput
              name="PhoneNumber"
              type="tel"
              id="PhoneNumber"
              placeholder="Phone Number"
              label="Phone Number"
              value={formik.values.PhoneNumber}
              handleChange={formik.handleChange("PhoneNumber")}
              error={
                formik.touched.PhoneNumber && Boolean(formik.errors.PhoneNumber)
              }
              helperText={
                formik.touched.PhoneNumber && formik.errors.PhoneNumber
              }
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormCountryList
              name="CountryCode"
              label="Nationality"
              value={formik.values.CountryCode}
              handleChange={formik.handleChange("CountryCode")}
              error={
                formik.touched.CountryCode && Boolean(formik.errors.CountryCode)
              }
              helperText={
                formik.touched.CountryCode && formik.errors.CountryCode
              }
            />
            {/* <FormSelect
              name="CountryCode"
              id="CountryCode"
              placeholder="Select country"
              label="Country"
              options={countryListData.list}
              defaultValue={""}
              value={formik.values.CountryCode || ""}
              handleChange={formik.handleChange("CountryCode")}
              error={
                formik.touched.CountryCode && Boolean(formik.errors.CountryCode)
              }
              helperText={
                formik.touched.CountryCode && formik.errors.CountryCode
              }
            /> */}
          </Grid>
          {passport ? (
            <>
              <Grid item xs={12} sm={4}>
                <FormInput
                  name="PassportNumber"
                  type="text"
                  id="PassportNumber"
                  placeholder="Passport Number"
                  label="Passport Number"
                  value={formik.values.PassportNumber}
                  handleChange={formik.handleChange("PassportNumber")}
                  error={
                    formik.touched.PassportNumber &&
                    Boolean(formik.errors.PassportNumber)
                  }
                  helperText={
                    formik.touched.PassportNumber &&
                    formik.errors.PassportNumber
                  }
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormDate
                  label="Expiry Date"
                  placeholder="Expiry Date"
                  id="ExpiryDate"
                  name="ExpiryDate"
                  icon={CalendarTodayOutlined}
                  value={formik.values.ExpiryDate}
                  handleChange={formik.handleChange("ExpiryDate")}
                  error={
                    formik.touched.ExpiryDate &&
                    Boolean(formik.errors.ExpiryDate)
                  }
                  helperText={
                    formik.touched.ExpiryDate && formik.errors.ExpiryDate
                  }
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormInput
                  name="PassportIssuingAuthority"
                  type="text"
                  id="PassportIssuingAuthority"
                  placeholder="Passport Issuing Authority"
                  label="Passport Issuing Authority"
                  value={formik.values.PassportIssuingAuthority}
                  handleChange={formik.handleChange("PassportIssuingAuthority")}
                  error={
                    formik.touched.PassportIssuingAuthority &&
                    Boolean(formik.errors.PassportIssuingAuthority)
                  }
                  helperText={
                    formik.touched.PassportIssuingAuthority &&
                    formik.errors.PassportIssuingAuthority
                  }
                />
              </Grid>
            </>
          ) : null}
        </Grid>
      </Box>
      <Box className={classes.stepperControl}>
        <Button
          onClick={handleBack}
          disabled={activeStep === 0}
          variant={"text"}
          color={"default"}
        >
          Previous
        </Button>
        &nbsp;&nbsp;
        <Button
          type="submit"
          disabled={!formik.isValid}
          variant={"contained"}
          color={"primary"}
        >
          Next
        </Button>
      </Box>
    </form>
  );
}

import { Box, makeStyles } from '@material-ui/core';
import React, { ReactNode } from 'react';
import {Link} from 'react-router-dom';

type Props = {
  to: string;
  className?: string;
  sx?: object;
  children: ReactNode;
  onClick?: () => void;
};

export default function AppLink({children, className, onClick, ...rest}: Props) {
  const classes = styles();

  return (
    <Box onClick={onClick} className={`${classes.root} ${className}`} component={Link} {...rest}>{children}</Box>
  );
};

const styles = makeStyles({
  root: {
    textDecoration: 'none !important',
  }
});

import { ModalForms } from './../../utilities/types';
import {ActionTypes, AuthModalType} from "../../utilities/types";

export type AuthModalActionType = {
    type: ActionTypes.OPEN_AUTH_MODAL | ActionTypes.CLOSE_AUTH_MODAL,
    payload: AuthModalType,
};

export function Action_OpenAuthModal(modalForm: ModalForms, dismissible: boolean = true): AuthModalActionType {
    return (
        {
            type: ActionTypes.OPEN_AUTH_MODAL,
            payload: {
                open: true,
                form: modalForm,
                dismissible,
            },
        }
    );
}

export function Action_CloseAuthModal(): AuthModalActionType {
    return (
        {
            type: ActionTypes.CLOSE_AUTH_MODAL,
            payload: {
                open: false,
                form: ModalForms.SignIn,
                dismissible: true,
            },
        }
    );
}
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Typography,
} from "@material-ui/core";
import { CalendarToday, Flight } from "@material-ui/icons";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import travoofy from "../../assets/images/logo.png";
import { Action_SetFlightProps } from "../../reduxStore/flightProps/actions";
import api from "../../utilities/api";
import { sorters, toDateTime, toNumberFormat } from "../../utilities/helpers";
import pageRoutes from "../../utilities/pageRoutes";
import {
  FlightObject,
  FlightSearchProps,
  FlightSort,
  QuickBookingObject,
  TicketClass,
} from "../../utilities/types";
import BookingCardStyle from "./BookingCardStyle";

type Props = {
  item: QuickBookingObject;
  update: (val: any) => void;
  send: (val: any) => void;
};

function BookingCard({ item, update, send }: Props) {
  const classes = BookingCardStyle();
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const mounted = useRef(true);
  const [flightResult, setFlightResult] = useState<FlightObject>();
  const [flightData, setFlightData] = useState<FlightSearchProps>();
  const [showText, setShowText] = useState<boolean>(false);
  const [gtagData, setGtagData] = useState<any>();

  const getFlight = (data: QuickBookingObject) => {
    setLoading(true);
    const date = new Date();
    const body: FlightSearchProps = {
      FlightSearchType: "Oneway",
      Adults: "1",
      Children: "0",
      Infants: "0",
      Ticketclass: TicketClass.Economy,
      TargetCurrency: "NGN",
      Itineraries: [
        {
          Departure: data.from.airportCode,
          Destination: data.to.airportCode,
          DepartureDate: `${
            date.getMonth() + 1
          }/${date.getDate()}/${date.getFullYear()}`,
        },
      ],
    };
    api
      .post("/flight/search", body)
      .then(({ status, data }) => {
        if (mounted.current && status) {
          const fObj: FlightObject = [
            ...data.sort(sorters[FlightSort.Cheapest]),
          ][0];
          setFlightResult(fObj);
          const gd = {
            item_id: item.id,
            item_name: `${item.from.city} - ${item.to.city}`,
            item_brand: fObj.FlightCombination.FlightModels[0].AirlineName,
            currency: fObj.FlightCombination.Price.CurrencyCode,
            item_category: "Flight",
            price: fObj.FlightCombination.Price.Amount,
            item_variant:
              fObj.FlightCombination.FlightModels.length > 1
                ? "Return"
                : "Oneway",
          };
          setGtagData(gd);
          update(gd);
        }
      })
      .finally(() => (mounted.current ? setLoading(false) : null));
    if (mounted.current) {
      setFlightData(body);
    }
  };

  const bookNow = (data: FlightSearchProps) => {
    send(gtagData);
    dispatch(Action_SetFlightProps(data));
    history.push(pageRoutes.SearchFlight);
  };

  useEffect(() => {
    mounted.current = true;
    getFlight(item);
    return () => {
      mounted.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card className={classes.root} elevation={0}>
      <CardContent className={classes.content}>
        <Typography variant="h6" align="center" className={classes.title}>
          {!flightResult
            ? item.from.city
            : flightResult.FlightCombination.FlightModels[0].DepartureName}{" "}
          to{" "}
          {!flightResult
            ? item.to.city
            : flightResult.FlightCombination.FlightModels[0].ArrivalName}
        </Typography>
        <Typography
          className={classes.subtitle}
          variant="caption"
          component={"p"}
          align="center"
        >
          Round / One way trip
        </Typography>
        <Box className={classes.imageCon}>
          {loading || !flightResult ? (
            <img src={travoofy} alt="Travoofy" height={"100%"} />
          ) : !showText ? (
            <>
              <img
                src={
                  flightResult.FlightCombination.FlightModels[0].AirlineLogoUrl
                }
                alt={flightResult.FlightCombination.FlightModels[0].AirlineName}
                height={"100%"}
                onError={() => setShowText(true)}
              />
              <Typography
                style={{ fontWeight: "bold" }}
                component={"p"}
                variant="caption"
                align="center"
              >
                {flightResult.FlightCombination.FlightModels[0].AirlineName}
              </Typography>
            </>
          ) : (
            <Chip
              color="primary"
              avatar={
                <Avatar>
                  <Flight fontSize={"small"} />
                </Avatar>
              }
              label={flightResult.FlightCombination.FlightModels[0].AirlineName}
            />
          )}
        </Box>
        <Typography
          className={classes.price}
          variant="subtitle2"
          align="center"
          color="primary"
        >
          {loading
            ? null
            : flightResult
            ? `From ${toNumberFormat(
                flightResult.FlightCombination.Price.Amount,
                flightResult.FlightCombination.Price.CurrencyCode
              )}`
            : null}
        </Typography>
        <Typography className={classes.date} variant="subtitle2" align="center">
          <CalendarToday fontSize={"small"} className={classes.icon} />
          {!flightResult
            ? toDateTime(new Date())
            : toDateTime(
                flightResult.FlightCombination.FlightModels[0].DepartureTime
              )}
        </Typography>
      </CardContent>
      <Button
        className={classes.button}
        fullWidth
        color="primary"
        variant="contained"
        size="large"
        onClick={() => bookNow(flightData as any)}
      >
        Book Now
      </Button>
    </Card>
  );
}

export default React.memo(BookingCard, (prev, next) => {
  const a = prev.item.from.airportCode === next.item.from.airportCode;
  const b = prev.item.to.airportCode === next.item.to.airportCode;
  return a === b;
});

import { Box, Container } from "@material-ui/core";
import React, { ReactNode } from "react";

type Props = {
  children: ReactNode;
  className?: string;
  sx?: object;
};

export default function InnerContainer({ children, className, sx }: Props) {
  return (
    <Container component={'div'} maxWidth={'lg'}>
      <Box className={className} component={'div'} sx={sx}>{children}</Box>
    </Container>
  );
}

import {
  Box,
  Checkbox,
  Icon,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
} from "@material-ui/core";
import React, { ElementType } from "react";
import FormToggleStyle from "./FormToggleStyle";

type Props = {
  label?: string;
  icon?: ElementType<any>;
  color?: "white";
  value?: boolean;
  handleChange?: (value: any) => void;
  error?: any;
  helperText?: any;
  id?: string;
  name?: string;
  hidden?: boolean;
  disable?: boolean;
};

export default function FormToggle({
  label,
  icon,
  color,
  value,
  handleChange,
  error,
  helperText,
  id,
  name,
  hidden,
  disable,
}: Props) {
  const classes = FormToggleStyle(color)();
  const [checked, setChecked] = React.useState(!!value);

  return (
    <Box className={classes.root}>
      <List>
        <ListItem dense disabled={disable}>
          {icon ? (
            <ListItemIcon>
              <Icon component={icon} color="disabled" />
            </ListItemIcon>
          ) : null}
          <ListItemText
            id={name}
            primary={label}
            primaryTypographyProps={{
              style: { textTransform: "capitalize" },
            }}
            secondary={helperText}
            secondaryTypographyProps={{
              className: classes.helperText,
              style: { color: error ? "red" : undefined },
            }}
            disableTypography={disable}
          />
          <ListItemSecondaryAction>
            <Checkbox
              edge="end"
              id={id}
              color={"primary"}
              disabled={disable}
              hidden={hidden}
              onChange={(event) => {
                const data = event.target.checked;
                if (handleChange) {
                  handleChange(event);
                }
                setChecked(data);
              }}
              checked={checked}
            />
          </ListItemSecondaryAction>
        </ListItem>
      </List>
    </Box>
  );
}

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React, { useState } from "react";
import { toNumberFormat } from "../../utilities/helpers";
import { PriceDetail } from "../../utilities/types";
import TicketStyle from "./TicketStyle";

type Props = {
  totalPrice: number;
  prices: PriceDetail[];
  adults: number;
  children: number;
  infants: number;
};

export default function Ticket({
  totalPrice,
  prices,
  adults,
  children,
  infants,
}: Props) {
  const classes = TicketStyle();
  const [showPrice, setShowPrice] = useState<boolean>(true);

  const getPassengerCount = (price: PriceDetail) => {
    switch (price.PassengerType) {
      case "Child":
        return `x${children} Children`;
      case "Infant":
        return `x${infants} Infants`;
      case "Adult":
      default:
        return `x${adults} Adults`;
    }
  };

  return (
    <Box>
      <Accordion
        elevation={0}
        expanded={showPrice}
        onChange={(_, val) => setShowPrice(val)}
      >
        <AccordionSummary
          className={classes.title}
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography variant="inherit" component={"strong"}>
            Ticket Price
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.root}>
          {prices.map((price, index) => (
            <Box key={index}>
              <Box className={classes.container}>
                <Typography className={classes.smallText}>
                  {getPassengerCount(price)}
                </Typography>
                <Typography className={classes.text}>
                  {toNumberFormat(
                    price.BaseFare.Amount,
                    price.BaseFare.CurrencyCode
                  )}
                </Typography>
              </Box>
            </Box>
          ))}
        </AccordionDetails>
      </Accordion>
      <Divider />
      <Box className={classes.contain}>
        <Typography className={classes.textTotal}>Total</Typography>
        <Typography className={classes.sumTotal}>
          {toNumberFormat(totalPrice, prices[0].BaseFare.CurrencyCode)}
        </Typography>
      </Box>
    </Box>
  );
}

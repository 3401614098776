import { makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(2),
  },
  boxOne: {
    display: 'flex',
    fontWeight: 700,
    justifyContent: 'space-between',
    color: theme.palette.secondary.dark,
  },
  boxThree: {
    display: 'flex',
    fontWeight: 700,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    justifyContent: 'space-between',
    color: theme.palette.secondary.dark,
  },
  contain: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  avatar: {
    backgroundColor: theme.palette.background.paper,
    marginLeft: theme.spacing(-2),
  },
  secondText: {
    fontSize: '12px',
    width: '100%'
  },
  btn: {
    width: '100%'
  },
  baggage: {
    marginBottom: theme.spacing(0),
  },
  baggageText: {
    fontWeight: 'bold',
  },
}));

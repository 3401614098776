import { makeStyles, Theme } from '@material-ui/core';
export default makeStyles((theme: Theme) => ({
  root: {
    border: `1px solid ${theme.palette.divider}`,
  },
  boldText: {
    fontWeight: 700,
    fontSize: "16px",
  },
  primary: {
    fontWeight: 700,
    fontSize: '14px',
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
}));

import { ActionTypes, BookingData } from "../../utilities/types";
import { BookingDataActionType } from "./actions";

const defaultState: BookingData | null = null;

export function BookingDataReducer(
  state = defaultState,
  action: BookingDataActionType
) {
  switch (action.type) {
    case ActionTypes.SET_BOOKING_DATA:
      return { ...action.payload };
    case ActionTypes.CLEAR_BOOKING_DATA:
      return null;
    default:
      return { ...state };
  }
}

import {
  Button,
  CircularProgress,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
import { InfoOutlined } from "@material-ui/icons";
import { getFormInput } from "../../../utilities/form";
import { DocumentField, FormTypes } from "../../../utilities/types";
import DynamicFormStyle from "./DynamicFormStyle";

type Props = {
  actionLabel?: string;
  fields: DocumentField[];
  note?: string;
  form: any;
};

export default function DynamicForm({
  actionLabel,
  fields,
  note,
  form,
}: Props) {
  const classes = DynamicFormStyle();

  const items = fields.sort((x, y) => {
    const order = Object.values(FormTypes);
    if (order.indexOf(x.type) < order.indexOf(y.type)) return -1;
    if (order.indexOf(x.type) > order.indexOf(y.type)) return 1;
    return 0;
  });

  return (
    <Paper elevation={0} className={classes.root}>
      <Typography variant="subtitle2">
        Please complete the form below to begin
      </Typography>
      {note ? (
        <Typography className={classes.info} variant="caption" component={"em"}>
          <InfoOutlined fontSize="small" color="primary" /> &nbsp; Note: {note}
        </Typography>
      ) : null}
      <br />
      <br />
      <form onSubmit={form.handleSubmit}>
        <Grid container spacing={2}>
          {items.map((item) =>
            getFormInput(
              item,
              form.values,
              form.handleChange,
              form.errors
            )
          )}
          <Grid item xs={12}>
            <Button
              disabled={!form.isValid || form.isSubmitting}
              size="large"
              variant="contained"
              color="primary"
              type="submit"
              startIcon={
                form.isSubmitting ? (
                  <CircularProgress color="inherit" size={30} />
                ) : undefined
              }
            >
              {actionLabel || "Submit"}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
}

import { makeStyles, Theme } from '@material-ui/core';

const styles = (photo: any) => makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    height: '100%',
    backgroundImage: `url(${photo})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  headerText: {
    textAlign: 'center',
    color: '#ffffff',
    padding: `${theme.spacing(5)}px 0`,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  largeText: {
    paddingTop: theme.spacing(5),
  },
  smallText: {
    fontWeight: 700,
    lineHeight: '40.41px',
  },
  subtitle: {
    fontWeight: 400,
    fontSize: '25px',
    lineHeight: '40.41px',
  },
}));

export default styles;

import { makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) => ({
  root: {
    width: 'min(600px, 90%)',
    height: 'auto',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    backgroundColor: theme.palette.background.paper,
    margin: `${theme.spacing(8)}px auto`,
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: theme.spacing(11),
  },
  signText: {
    fontSize: '24px',
    fontWeight: 700,
    marginBottom: theme.spacing(1),
  },
  smallText: {
    fontSize: '12px',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  spanText: {
    color: '#21AFF6',
    marginLeft: theme.spacing(1),
  },
  formInput: {
    marginBottom: theme.spacing(2),
  },
  btnContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  btnPrimary: {
    width: "full-width",
    backgroundColor: '#21AFF6',
    marginBottom: theme.spacing(2),
  },
  btn: {
    color: theme.palette.primary.main,
  },
  spinner: {
    color: "#ffffff"
  },
}));

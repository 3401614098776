import React from 'react'
import { useVisaStyles } from '../VisaAdditionalInfo.styles'
import { Grid } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import InfoIcon from '@material-ui/icons/Info';
import { useFormikContext } from 'formik';
import FormUpload from '../../Form/FormUpload/FormUpload';

type Props = {
    hideLabel?: boolean;
    textToggle?: boolean;
    // action: (value: FlightSearchProps) => void;
    loading?: boolean;
};

type SpouseFormProps = {
    spouseTravels: {
        visaDataPage: File,
        passportPhotograph: File,
        marriageCertificate: File,
    }

}

export default function SpouseForm({ hideLabel }: Props) {
    const classes = useVisaStyles();
    const { handleChange, errors, touched } = useFormikContext<SpouseFormProps>();

    return (
        <Grid container>
            {/* passport upload and warning icon */}
            <Grid item container className={classes.titleFour} spacing={3}>
                <Grid item xs={12} sm={6} md={6} className={`${classes.paper} `} >
                    <FormUpload
                        label="Visa Data page *"
                        name="spouseTravels.visaDataPage"
                        handleChange={handleChange('spouseTravels.visaDataPage')}
                        helperText={touched?.spouseTravels?.visaDataPage && errors?.spouseTravels?.visaDataPage}
                        error={touched?.spouseTravels?.visaDataPage && Boolean(errors?.spouseTravels?.visaDataPage)}

                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6} className={`${classes.paper} `} >
                    <FormUpload
                        label="Passport photograph photo *"
                        name="spouseTravels.passportPhotograph"
                        handleChange={handleChange('spouseTravels.passportPhotograph')}
                        helperText={touched?.spouseTravels?.passportPhotograph && errors?.spouseTravels?.passportPhotograph}
                        error={touched?.spouseTravels?.passportPhotograph && Boolean(errors?.spouseTravels?.passportPhotograph)}

                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6} className={`${classes.paper} `} >
                    <FormUpload
                        label="Marriage Certificate (for spouse) *"
                        name="spouseTravels.marriageCertificate"
                        handleChange={handleChange('spouseTravels.marriageCertificate')}
                        helperText={touched?.spouseTravels?.marriageCertificate && errors?.spouseTravels?.marriageCertificate}
                        error={touched?.spouseTravels?.marriageCertificate && Boolean(errors?.spouseTravels?.marriageCertificate)}

                    />
                </Grid>

                <Grid item xs={12} sm={6} md={6} className={classes.fileUpload} >
                    <Alert icon={<InfoIcon />} severity='warning' color='info'>All file uploads must be in .pdf formats</Alert>
                </Grid>

            </Grid>
        </Grid>

    )
}

import slide1 from "../../assets/images/slide1.jpg";
import slide2 from "../../assets/images/slide2.png";
import slide3 from "../../assets/images/slide3.jpg";
import Slide from "../Slide/Slide";
import Slider from "../Slider/Slider";

const slides = [
  {
    image: slide1,
    title: "Travel Package",
    caption: "TRY OUR AMAZING OFFERS",
    subtitle: "Make that dream come true",
  },
  {
    image: slide2,
    title: "Travel Package",
    caption: "TRY OUR AMAZING OFFERS",
    subtitle: "Make that dream come true",
  },
  {
    image: slide3,
    title: "Travel Package",
    caption: "TRY OUR AMAZING OFFERS",
    subtitle: "Make that dream come true",
  },
];

export default function VacationCarousel() {
  return (
    <Slider>
      {slides.map((slide, index) => (
        <Slide
          key={index}
          image={slide.image}
          title={slide.title}
          caption={slide.caption}
          subtitle={slide.subtitle}
        />
      ))}
    </Slider>
  );
}

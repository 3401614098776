import { makeStyles, Theme } from "@material-ui/core";
import planeBg from "../../assets/images/plane-fade-bg.svg";

export default makeStyles((theme: Theme) => ({
  root: {
    height: "100%",
    backgroundImage: `url(${planeBg})`,
    backgroundRepeat: "no-repeat",
    backgroundPositionX: theme.spacing(3),
    backgroundPositionY: theme.spacing(3),
    backgroundSize: `calc(100% - ${theme.spacing(6)}px) auto`,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  content: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(3),
  },
  title: {
    marginBottom: theme.spacing(1),
  },
  subtitle: {
    marginBottom: theme.spacing(3),
  },
  imageCon: {
    height: "40px",
    margin: "auto",
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
    "& img": {
      display: "block",
      margin: "auto",
    },
    textAlign: "center",
    fontWeight: "bold",
  },
  price: {
    fontWeight: 700,
    marginBottom: theme.spacing(3),
  },
  date: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: theme.spacing(3),
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  button: {
    borderRadius: 0,
  },
}));

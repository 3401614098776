import React, { useState } from "react";
import StepperStyle from "./CustomStepper.style";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";
import { Box,  Grid, Typography, Modal } from "@material-ui/core";
import { StepLabel, Stepper, Step, Button, useMediaQuery, Theme, MobileStepper, useTheme } from "@material-ui/core";
import { FormikConfig, FormikValues, Formik } from "formik";
import { Form } from 'formik'
 

type Props = {
    action?: () => void;
    hideLabel?: boolean;
    loading?: boolean;
    textToggle?: boolean;
    setDisplay?: any;
    display? : any;
     content?: any;
    }


const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 350,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  
  export default function BasicModal( {setDisplay, display, content}:Props) {
    // const [open, setOpen] = useState(false);
    // const handleOpen = () => setOpen(true);
    const handleClose = () => setDisplay(false);
     console.log(display)
    return (

      <div>

        
        <Modal
          open={display}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          
          <Box sx={style}>
          <div onClick={handleClose} style={{cursor:"pointer",}}>
            X
            <br></br>
          </div>
        
            <Typography id="modal-modal-title" variant="h6" 
            
            style ={{
              color : '#6096CC'
            }} 
            
            component="h2"
            >
              Details Successfully Submitted.

            </Typography>
            <br></br>
            <Typography id="modal-modal-description" >
                We are glad to receive your Travoofy Visa Application Support, we'll review your application and revert within 24 hours.
            </Typography>
            <br></br>
            <br></br>
            <Typography id="modal-modal-description" >
                 For further assistance and enquiries, please contact <a href="mailto: mail@travoofy.com">mail@travoofy.com</a>
            </Typography>
          </Box>
        </Modal>
      </div>
    );
  }


interface FormikStepProps extends Pick<FormikConfig<FormikValues>, "children" | "validationSchema"> {

    label: string;

}
export const CustomStepper = ({ children, ...props }: FormikConfig<FormikValues>) => {

    const [steps, setSteps] = useState(0);
    const stepsArray = React.Children.toArray(children) as React.ReactElement<FormikStepProps>[]
    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));
    const currentStep = stepsArray[steps]
    const classes = StepperStyle();
    const theme = useTheme();

    const [display,setDisplay]= useState(false);
    


    const lastStep = () => steps === stepsArray.length - 1;
    // const handleNext = () => {
    //     setSteps(s => s + 1)
    // };

    const handleBack = () => {
        setSteps(s => s - 1)
    };

    return (

        <Formik {...props}
            validationSchema={currentStep.props.validationSchema}
            onSubmit={async (values, helpers) => {
                if (lastStep()) {
                    await props.onSubmit(values, helpers)
                   
                }
                else {
                    setSteps(s => s + 1)
                }


            }}>
            <Form className={classes.root}>
              <BasicModal display={display} setDisplay={setDisplay} />
                {isSmall ?

                    <MobileStepper
                        variant="dots"
                        steps={stepsArray.length}
                        position="static"
                        activeStep={steps}
                        className={classes.mobileStepper}
                        nextButton={
                            <Button size="small" type='submit'>
                                {lastStep() ? 'Submit' : 'Next'}
                                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                            </Button>
                        }
                        backButton={
                          <Button size="small" onClick={handleBack} disabled={steps === 0}>
                                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                                Back
                            </Button>
                        }
                    />

                    :
                    <Stepper alternativeLabel activeStep={steps}>
                        {stepsArray.map((child) => (
                            <Step key={child.props.label}>
                                <StepLabel>{child.props.label}</StepLabel>

                            </Step>

                        ))}
                    </Stepper>}
                {currentStep}
                <div className={classes.rootButton}>
                    {steps == 0 ? <p></p>
                        
                  : steps > 0 ? <Button 
                  variant="outlined"
                  onClick={handleBack}
                >
                  Back
              </Button > : null}

                    < Button
                       style ={{
                        backgroundColor : 'rgb(164,236,248)'
                      }} 
                      variant='contained' type='submit'
                      onClick={()=> lastStep()? setDisplay(true) : null}
                      >
                        {lastStep() ? 'Submit' : 'Next'}
                       
                    </Button>

                </div>

            </Form >
        </Formik>
    );
}

import { Box, Grid, Typography } from "@material-ui/core";
import { CheckOutlined } from "@material-ui/icons";
import React from "react";
import InclusionStyle from "./InclusionStyle";

type Props = {
  items: string[];
};

export default function Inclusion({items}: Props) {
  const classes = InclusionStyle();

  return (
    <Box className={classes.root}>
      <Typography className={classes.text}>Inclusions</Typography>

      {items.map((title, index) => (
        <Grid key={index} container spacing={1}>
          <Grid item sm={12} className={classes.firstBox}>
            <CheckOutlined color="primary" />
            <Typography className={classes.title}>{title}</Typography>
          </Grid>
        </Grid>
      ))}
    </Box>
  );
}

import { makeStyles, Theme } from "@material-ui/core";
export default makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    minHeight: "200px",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  image: {
    width: "42%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    [theme.breakpoints.down("xs")]: {
      flex: 1,
      width: "100%",
      minHeight: "200px",
    },
  },
  content: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("xs")]: {
      flex: "none",
    },
  },
  title: {
    fontWeight: 700,
    lineHeight: "25.3px",
    marginBottom: theme.spacing(1),
  },
  duration: {
    fontWeight: 700,
    textTransform: "uppercase",
    fontSize: "14px",
    marginBottom: theme.spacing(1),
  },
  price: {
    fontSize: "18px",
    lineHeight: "27.6px",
    marginTop: theme.spacing(4),
    display: "inline-block",
    marginRight: theme.spacing(1),
  },
  oldPrice: {
    fontSize: "12px",
    lineHeight: "15.6px",
    textDecoration: "line-through",
  },
  reviewCount: {
    fontSize: "10px",
  },
}));

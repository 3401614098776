import { makeStyles, Theme } from "@material-ui/core";

export default makeStyles((theme: Theme) => ({
    root: {
        marginTop: "calc(min(50vw / 5, 50px) + 10px)",
        marginBottom: "calc(min(50vw / 5, 50px) + 10px)",
        display: "flex",
        flexDirection: "column",
        gap: "40px",
        background: "white",
        // padding:'30px 40px',
        [theme.breakpoints.down('sm')]: {
            padding: '10px 10px',
        },
        [theme.breakpoints.up('sm')]: {
            padding: '30px 50px'
        },
        ' & a': {
            textDecoration: 'none'
        },
        '& a:hover': {
            color: 'blue'
        },
        '& li': {
            color: 'black',
            marginLeft: '-14px',
            fontWeight: 'bold',

        },
        '& li::marker': {
            color: 'black',

        },
        '& li ul li ': {
            color: 'black',
            fontWeight: 'bold',
            listStyleType: 'lower-alpha',
        },
        '& li ul li::marker': {
            color: 'black',
        },

    },
    childOne: {
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
    },
    headerTitle: {
        marginLeft: '12px'
    },
    spacingEle: {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',

    }



}));
import { makeStyles, Theme } from "@material-ui/core";
import headerBg from "../../assets/images/flight-banner.png";
import { dark } from "../../utilities/helpers";
import { ThemeMode } from "../../utilities/types";

const styles = (mode: ThemeMode) =>
  makeStyles((theme: Theme) => ({
    root: {
      width: "100%",
    },
    background: {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(5),
      backgroundImage: `url(${headerBg})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "70% auto",
      backgroundPositionX: "right",
      backgroundPositionY: "center",
      backgroundColor: dark(mode)
        ? theme.palette.background.default
        : "#057FC3",
    },
    innerContainer: {
      flexDirection: "row",
      marginBottom: theme.spacing(7),
      [theme.breakpoints.down("sm")]: {
        flexDirection: "row-reverse",
      },
    },
    tabs: {
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(3),
    },
    firstText: {
      fontSize: "14px",
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(3),
    },
    loadingText: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(3),
    },
    emptyCon: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      paddingTop: theme.spacing(10),
      paddingBottom: theme.spacing(10),
    },
    emptyIcon: {
      fontSize: "6em",
    },
    pagination: {
      marginTop: theme.spacing(2),
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  }));

export default styles;

import { Box, Card, CardContent, Typography } from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import { toDateTime, toNumberFormat } from "../../utilities/helpers";
import { PackageObject } from "../../utilities/types";
import PackageCardStyle from "./PackageCardStyle";

type Props = PackageObject;

export default function PackageCard(item: Props) {
  const classes = PackageCardStyle();

  return (
    <Card className={classes.root} elevation={0}>
      <Box
        className={classes.image}
        style={{ backgroundImage: `url(${item.featuredImage})` }}
      />
      <CardContent className={classes.content}>
        <Typography className={classes.title}>{item.name}</Typography>
        <Typography
          className={classes.duration}
          variant="subtitle2"
          color="primary"
        >
          {toDateTime(item.date[0])}{" "}
          {item.date[1] ? `to ${toDateTime(item.date[1])}` : null}
        </Typography>
        <Rating value={5} readOnly size="small" />
        {/* <Typography className={classes.reviewCount} variant="caption">
          (4964 Review)
        </Typography> */}
        <Box>
          <Typography className={classes.price} variant="h6" color="primary">
            {toNumberFormat(item.prices.sellingPrice, item.prices.currency)}
          </Typography>
          <Typography className={classes.reviewCount} variant="caption">
            {item.prices.unit}
          </Typography>
        </Box>
        <Typography
          className={classes.oldPrice}
          variant="h6"
          color="textSecondary"
        >
          {toNumberFormat(item.prices.actualPrice, item.prices.currency)}
        </Typography>
      </CardContent>
    </Card>
  );
}

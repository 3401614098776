import { makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) => ({
  root: {
    width: 'min(400px, 90%)',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    margin: `${theme.spacing(8)}px auto`,
  },
  signText: {
    fontSize: '24px',
    fontWeight: 700,
    marginBottom: theme.spacing(1),
  },
  smallText: {
    fontSize: '12px',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  formInput: {
    marginBottom: theme.spacing(2),
  },
  btnContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  btnPrimary: {
    width: "full-width",
    marginBottom: theme.spacing(2),
  },
  btn: {
    color: theme.palette.primary.main,
  },
  spinner: {
    color: "#ffffff"
  },
}));

import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';
import { Link } from 'react-router-dom';
import { Divider, ListItem, ListItemText, Typography, Button } from '@material-ui/core';
import { gtagEvent } from '../../utilities/helpers';

// IMPORT ICONS
import { WhatsApp, Phone, ContactSupport, Email } from '@material-ui/icons';

type Props = {
    type?: string;
    classStyle?: any;
};

export default function SupportMenu({ type, classStyle }: Props) {

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    // DETERMINE TYPE OF ELEMENT TO DISPLAY
    const handler = (type === 'navBtn') ?
        <Button
            className={classStyle}
            aria-controls="fade-menu"
            aria-haspopup="true" onClick={handleClick}

        >
            Support
        </Button>
        :
        <span key={4}>
            <ListItem
                button
                aria-controls="fade-menu" aria-haspopup="true" onClick={handleClick}
            >
                <ListItemText primary="Support" />
            </ListItem>
            <Divider />
        </span>;

    return (
        <div>
            {handler}
            <Menu
                id="fade-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}
                style={{ zIndex: 15000 }}
            >

                <MenuItem onClick={handleClose}>
                    <Link
                        to="#"
                        style={{ textDecoration: 'none' }}
                        onClick={() => {
                            gtagEvent("contact_us", { name: "Phone Call" });
                            window.location.assign("tel:018880305");
                        }}>
                        <Typography variant="body2" color="secondary">
                            <Phone fontSize="small" style={{ marginBottom: -4, marginRight: 12 }} />
                            01-8880305
                        </Typography>
                    </Link>
                </MenuItem>

                <MenuItem onClick={handleClose}>
                    <Link to="/contact-us" style={{ textDecoration: 'none' }}>
                        <Typography variant="body2" color="secondary">
                            <ContactSupport fontSize="small" style={{ marginBottom: -4, marginRight: 12 }} />
                            Contact Us
                        </Typography>
                    </Link>
                </MenuItem>

                <MenuItem onClick={handleClose}>
                    <Link
                        to="#"
                        style={{ textDecoration: 'none' }}
                        onClick={() => {
                            gtagEvent("contact_us", { name: "Email" });
                            window.location.assign("mailto: bookings@travoofy.com");
                        }}>
                        <Typography variant="body2" color="secondary">
                            <Email fontSize="small" style={{ marginBottom: -4, marginRight: 12 }} />
                            Customer Support
                        </Typography>
                    </Link>
                </MenuItem>

                <MenuItem onClick={handleClose}>
                    <Link
                        to="#"
                        style={{ textDecoration: 'none' }}
                        onClick={() => {
                            gtagEvent("contact_us", { name: "WhatsApp" });
                            window.location.assign("https://wa.me/2348120977653");
                        }}>
                        <Typography variant="body2" color="secondary">
                            <WhatsApp fontSize="small" style={{ marginBottom: -4, marginRight: 12 }} />
                            WhatsApp
                        </Typography>
                    </Link>
                </MenuItem>
            </Menu>
        </div >
    );
}

import { makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
  },
  slider: {
    "& > .MuiSlider-markLabel": {
      fontSize: '12px',

      "&[data-index='0']": {
        left: '10% !important',
      },
      "&[data-index='1']": {
        left: '90% !important',
      }
    },
  },
  checkbox: {
    "& > .MuiFormControlLabel-label": {
      fontSize: '14px',
    },
  },
  cabinText: {
    fontSize: '16px',
    fontWeight: 700,
  },
  miniText: {
    fontSize: '14px',
    marginTop: theme.spacing(2),
    color: theme.palette.text.primary,
  },
  text: {
    fontSize: '14px',
    color: theme.palette.secondary.dark,
  },
  flexItem: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  flexCheck: {
    display: 'flex',
  },
  slide: {
    padding: '20px',
  }
}));

import { Box, Grid, Button, Typography, Paper } from '@material-ui/core';
import { CalendarTodayOutlined, LocationOn, Search } from '@material-ui/icons';
import React from'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import FormDate from '../../../components/Form/FormDate/FormDate';
import FormInput from '../../../components/Form/FormInput/FormInput';
import HotelDetailCard from '../../../components/HotelDetailCard/HotelDetailCard';
import HotelService from '../../../components/HotelService/HotelService';
import HotelReview from '../../../components/HotelReview/HotelReview';
import InnerContainer from '../../../components/InnerContainer/InnerContainer';
import Layout from '../../../components/Layout/Layout';
import image1 from '../../../assets/images/boat.webp';
import image2 from '../../../assets/images/city.webp';
import image3 from '../../../assets/images/obudu.png';
import image4 from '../../../assets/images/tropicana.webp';
import image5 from '../../../assets/images/fabric-sales-8.png';
import image6 from '../../../assets/images/savannah.webp';
import image7 from '../../../assets/images/Rectangle-133.png';
import image8 from '../../../assets/images/slide1.jpg';
import image9 from '../../../assets/images/slide2.png';
import image10 from '../../../assets/images/slide3.jpg';
import { AppStore } from '../../../utilities/types';
import HotelDetailStyle from './HotelDetailStyle';
import Reviews from '../../../components/Reviews/Reviews';
import Gallery from '../../../components/Gallery/Gallery';
import HotelRoom from '../../../components/HotelRoom/HotelRoom';

export default function HotelDetail() {
    const {mode} = useSelector((store: AppStore) => store.themeSwitch);
    const classes = HotelDetailStyle(mode)();

    const images = [image1, image2, image3, image4, image5, image6, image7, image8, image9, image10];

    return (
        <Layout>
            <Box className={classes.root}>
                <InnerContainer>
                <Grid container spacing={3}>
                    <Grid item md={3} sm={10}> 
                        <FormInput placeholder='Dubai, UAE' icon={LocationOn}/>
                    </Grid>
                    <Grid item md={3} sm={10}>
                        <FormDate icon={CalendarTodayOutlined} disablePast />
                    </Grid>
                    <Grid item md={3} sm={10}>
                        <FormDate icon={CalendarTodayOutlined} disablePast />
                    </Grid>
                    <Grid item sm={8} md={2} className={classes.searchButtonCon}>
                        <Button variant='contained'
                            component={Link}
                            to={'/search-hotel'}
                            color='primary' size='large' 
                            endIcon={<Search />} fullWidth
                        >
                        Search
                    </Button>
                    </Grid>
                </Grid>
                </InnerContainer>
            </Box>
            <Box>
                <InnerContainer>
                    <Typography className={classes.texts}>Home  &gt; Dubai Hotels  &gt; Dubai, UAE &gt; Genova Hotels </Typography>
                    <Typography className={classes.text}>Genova Hotels</Typography>

                    <Grid container spacing={3}>
                        <Grid item xs={12} md={8} sm={12}>
                            <Box><Gallery images={images} spacing={3} autoPlay /></Box>
                            <Box>
                                <Paper elevation={0} className={classes.paper}>
                                    <Typography>Overview</Typography>
                                    <Typography>Facilities</Typography>
                                    <Typography>Rooms</Typography>
                                    <Typography>Reviews</Typography>
                                    <Typography>Policies</Typography>
                                </Paper>
                            </Box>

                            <Box><HotelService /></Box>
                            <Box><HotelRoom /></Box>
                            <Box><HotelReview /></Box>
                            <Box><Reviews /></Box>
                        </Grid>
                        <Grid item xs={12} md={4} sm={12}>
                            <HotelDetailCard />
                        </Grid>
                    </Grid>
                </InnerContainer>
            </Box>
        </Layout>
    )
}
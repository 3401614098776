import { Grid, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import swal from "sweetalert";
import FlightSummary from "../../components/FlightSummary/FlightSummary";
import InnerContainer from "../../components/InnerContainer/InnerContainer";
import Layout from "../../components/Layout/Layout";
import MakePayment from "../../components/MakePayment/MakePayment";
import PaymentSuccess from "../../components/PaymentSuccess/PaymentSuccess";
import Seo from "../../components/Seo/Seo";
import Steppers from "../../components/Stepper/Stepper";
import { Action_SetBookingData } from "../../reduxStore/bookingData/actions";
import api from "../../utilities/api";
import { AppStore, PaidBookingData } from "../../utilities/types";
import PaymentStyle from "./PaymentStyle";

export default function Payment() {
  const classes = PaymentStyle();
  const { bookingId }: any = useParams();
  const dispatch = useDispatch();
  const { bookingData, user }: AppStore = useSelector(
    (store: AppStore) => store
  );
  const [loading, setLoading] = useState<boolean>(true);
  const history = useHistory();
  const paidBooking: PaidBookingData = bookingData as any;

  const getBooking = (id: string) => {
    setLoading(true);
    api
      .fetch(`/booking/${id}`, undefined, true)
      .then(({ status, data, message }) => {
        if (status) {
          dispatch(Action_SetBookingData(data));
        } else {
          swal("Oops!", message, "error").then(() => {
            history.goBack();
          });
        }
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (bookingId && user.token) {
      getBooking(bookingId);
    }
    if (!user.token) {
      history.goBack();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingId]);

  return (
    <Layout>
      <Seo title={"Payment"} />
      <InnerContainer className={classes.root}>
        {!loading ? (
          <>
            {bookingData && bookingData.status === "pending" ? (
              <>
                <Steppers activeStep={2} />
                <Typography className={classes.paymentText}>Payment</Typography>
              </>
            ) : null}
            {bookingData && bookingData.status === "paid" ? (
              <>
                <br />
                <br />
                <Typography className={classes.paymentText}>
                  Thank you for Booking
                </Typography>
              </>
            ) : null}
          </>
        ) : (
          <>
            <Typography className={classes.paymentText}>
              <Skeleton animation="wave" variant="text" width={400} />
            </Typography>
            <Typography className={classes.paymentText}>
              <Skeleton animation="wave" variant="text" width={200} />
            </Typography>
          </>
        )}

        <Grid container spacing={3}>
          <Grid item md={8} sm={12} xs={12}>
            {!loading && bookingData ? (
              <>
                {bookingData.status === "pending" ? (
                  <MakePayment
                    bookingId={bookingId}
                    date={bookingData.createdAt}
                  />
                ) : null}
                {bookingData.status === "paid" ? (
                  <PaymentSuccess booking={paidBooking} />
                ) : null}
              </>
            ) : (
              <Skeleton animation="wave" variant="rect" height={600} />
            )}
          </Grid>

          <Grid item md={4} sm={12} xs={12}>
            {!loading && bookingData && bookingData.amount ? (
              bookingData.status === "paid" ? (
                <FlightSummary
                  totalPrice={paidBooking.amount}
                  adults={
                    paidBooking.meta.FlightBookingResult.FlightBookingSummaryModel.FlightSummaryModel
                      .FlightCombination.Adults
                  }
                  children={
                    paidBooking.meta.FlightBookingResult.FlightBookingSummaryModel.FlightSummaryModel
                      .FlightCombination.Children
                  }
                  infants={
                    paidBooking.meta.FlightBookingResult.FlightBookingSummaryModel.FlightSummaryModel
                      .FlightCombination.Infants
                  }
                  prices={
                    paidBooking.meta.FlightBookingResult.FlightBookingSummaryModel.FlightSummaryModel
                      .FlightCombination.PriceDetails
                  }
                  flightModels={
                    paidBooking.meta.FlightBookingResult.FlightBookingSummaryModel.FlightSummaryModel
                      .FlightCombination.FlightModels || []
                  }
                />
              ) : (
                <FlightSummary
                  totalPrice={bookingData.amount}
                  adults={
                    bookingData.meta.FlightBookingResult
                      .FlightBookingSummaryModel.FlightSummaryModel
                      .FlightCombination.Adults
                  }
                  children={
                    bookingData.meta.FlightBookingResult
                      .FlightBookingSummaryModel.FlightSummaryModel
                      .FlightCombination.Children
                  }
                  infants={
                    bookingData.meta.FlightBookingResult
                      .FlightBookingSummaryModel.FlightSummaryModel
                      .FlightCombination.Infants
                  }
                  prices={
                    bookingData.meta.FlightBookingResult
                      .FlightBookingSummaryModel.FlightSummaryModel
                      .FlightCombination.PriceDetails
                  }
                  flightModels={
                    bookingData.meta.FlightBookingResult
                      .FlightBookingSummaryModel.FlightSummaryModel
                      .FlightCombination.FlightModels || []
                  }
                />
              )
            ) : (
              <Skeleton animation="wave" variant="rect" height={600} />
            )}
          </Grid>
        </Grid>
      </InnerContainer>
    </Layout>
  );
}

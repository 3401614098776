import axios from "axios";
import { getUser } from "./cookie";
import { APIResponse } from "./types";

export const BASE_URL = process.env.REACT_APP_API_ENDPOINT;

const token = () => getUser().token;
function headers() {
  return {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
}

function authHeaders() {
  return {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Token ${token()}`,
  };
}

function parseResponse(response: any): APIResponse {
  return response?.data;
}

function parseError(err: Error): APIResponse {
  return {
    status: false,
    message: err.message,
    data: null,
  };
}

function queryString(params: any) {
  const query = Object.keys(params)
    .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
    .join("&");
  return `${query.length ? "?" : ""}${query}`;
}

const api = {
  fetch(url: any, params = {}, auth = false): Promise<APIResponse> {
    return axios
      .get<APIResponse>(`${BASE_URL}${url}${queryString(params)}`, {
        headers: auth ? authHeaders() : headers(),
        validateStatus: (status) => {
          return status >= 200 && status < 500; // default
        },
      })
      .then(parseResponse)
      .catch(parseError);
  },

  post(url: any, data: any, auth = false): Promise<APIResponse> {
    const body = data;
    return axios
      .post<APIResponse>(`${BASE_URL}${url}`, body, {
        headers: auth ? authHeaders() : headers(),
        validateStatus: (status) => {
          return status >= 200 && status < 500; // default
        },
      })
      .then(parseResponse)
      .catch(parseError);
  },

  put(url: any, data: any, auth = false): Promise<APIResponse> {
    const body = data;
    return axios
      .put<APIResponse>(`${BASE_URL}${url}`, body, {
        headers: auth ? authHeaders() : headers(),
        validateStatus: (status) => {
          return status >= 200 && status < 500; // default
        },
      })
      .then(parseResponse)
      .catch(parseError);
  },

  patch(url: any, data: any, auth = false): Promise<APIResponse> {
    const body = data;
    return axios
      .patch<APIResponse>(`${BASE_URL}${url}`, body, {
        headers: auth ? authHeaders() : headers(),
        validateStatus: (status) => {
          return status >= 200 && status < 500; // default
        },
      })
      .then(parseResponse)
      .catch(parseError);
  },

  delete(url: any, auth = false): Promise<APIResponse> {
    return axios
      .delete<APIResponse>(`${BASE_URL}${url}`, {
        headers: auth ? authHeaders() : headers(),
        validateStatus: (status) => {
          return status >= 200 && status < 500; // default
        },
      })
      .then(parseResponse)
      .catch(parseError);
  },
};

export default api;

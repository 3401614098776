import { Theme, makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) => ({
  root: {
    marginBottom: theme.spacing(3),
  },
  loading: {
    margin: theme.spacing(3, 'auto'),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    textAlign: "center",
  },
  link: {
    textDecoration: "underline",
    cursor: "pointer",
  },
}));

import {
  Box,
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
} from "@material-ui/core";
import {
  BusinessCenterOutlined,
  CalendarTodayOutlined,
  ChildCareOutlined,
  FlightLandOutlined,
  FlightTakeoffOutlined,
  PersonOutlineOutlined,
  Search,
} from "@material-ui/icons";
import { useFormik } from "formik";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { Action_SetFlightProps } from "../../reduxStore/flightProps/actions";
import {
  AppStore,
  FlightSearchProps,
  TicketClass,
} from "../../utilities/types";
import FormDate from "../Form/FormDate/FormDate";
import FormSearch from "../Form/FormSearch/FormSearch";
import FormSelect from "../Form/FormSelect/FormSelect";
import FlightSearchStyle from "./FlightSearchStyle";
import { gtagEvent } from "../../utilities/helpers";

type Props = {
  hideLabel?: boolean;
  textToggle?: boolean;
  action: (value: FlightSearchProps) => void;
  loading?: boolean;
};

const validationSchema = Yup.object({
  Departure: Yup.string().required(),
  Destination: Yup.string()
    .notOneOf(
      [Yup.ref("Departure")],
      "Departure and Destination can't be the same"
    )
    .required(),
  DepartureDate: Yup.date().required(),
  ArrivalDate: Yup.date(),
  Adults: Yup.number().min(1).max(6).required(),
  Children: Yup.number().max(4),
  Infants: Yup.number().when("Adults", {
    is: (val: number) => val <= 4,
    then: Yup.number().max(
      Yup.ref("Adults"),
      "Infant count must be less than or equal to Adult count"
    ),
    otherwise: Yup.number().max(4),
  }),
  Ticketclass: Yup.string().required(),
  Passengers: Yup.mixed().when(["Adults", "Children", "Infants"], {
    is: (a: number, c: number, i: number) => a + c + i <= 9,
    then: Yup.mixed().notRequired(),
    otherwise: Yup.mixed().required(
      "Total number of Passengers must not be more than 9"
    ),
  }),
});

const flightTypes = ["Return", "Oneway", "Multidestination"];

export default function FlightSearch({
  hideLabel,
  textToggle,
  action,
  loading,
}: Props) {
  const dispatch = useDispatch();
  const classes = FlightSearchStyle();
  const { flightProps }: AppStore = useSelector((store: AppStore) => store);
  const [flightType, setFlightType] = useState(
    flightTypes.indexOf(flightProps.FlightSearchType)
  );
  const getVariant = (value: number) =>
    value === flightType ? "contained" : "outlined";
  const getColor = (value: number) =>
    value === flightType ? "primary" : "default";
  const handleFlightType = (value: number) => {
    setFlightType(value);
  };
  const isOneWay = () => flightType === 1;
  const options = [
    { label: "Economy", value: TicketClass.Economy },
    { label: "Premium Economy", value: TicketClass.PremiumEconomy },
    { label: "Business Class", value: TicketClass.BusinessClass },
    { label: "First Class", value: TicketClass.FirstClass },
  ];
  const today = new Date();
  const nextDay = new Date(today.getTime() + 1000 * 60 * 60 * 24);

  const formik = useFormik({
    initialValues: {
      Departure:
        flightProps.Itineraries.length > 0
          ? flightProps.Itineraries[0].Departure
          : "",
      Destination:
        flightProps.Itineraries.length > 0
          ? flightProps.Itineraries[0].Destination
          : "",
      DepartureDate:
        flightProps.Itineraries.length > 0
          ? flightProps.Itineraries[0].DepartureDate
          : today.toDateString(),
      ArrivalDate:
        flightProps.Itineraries.length > 1
          ? flightProps.Itineraries[1].DepartureDate
          : nextDay.toDateString(),
      Adults: flightProps.Adults || "",
      Children: flightProps.Children || "",
      Infants: flightProps.Infants || "",
      Ticketclass: flightProps.Ticketclass,
      Passengers: null,
    },
    validationSchema: validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      const body: FlightSearchProps = {
        FlightSearchType: flightTypes[flightType],
        Adults: values.Adults,
        Children: values.Children,
        Infants: values.Infants,
        Ticketclass: values.Ticketclass,
        TargetCurrency: "NGN",
        Itineraries: [
          {
            Departure: values.Departure,
            Destination: values.Destination,
            DepartureDate: values.DepartureDate,
          },
        ],
      };

      if (flightType === 0) {
        body.Itineraries.push({
          Departure: values.Destination,
          Destination: values.Departure,
          DepartureDate: values.ArrivalDate,
        });
      }

      gtagEvent("search", {
        search_term: `${values.Departure} - ${values.Destination}`,
      });
      setSubmitting(false);
      dispatch(Action_SetFlightProps(body));
      action(body);
    },
  });

  const getCount = (total: number, label: string, plural: string) => {
    const output: { label: string; value: string }[] = [];
    for (let i = 1; i <= total; i++) {
      output.push({ label: `${i} ${i < 2 ? label : plural}`, value: `${i}` });
    }
    return output;
  };

  const getLabel = (text: string) => (hideLabel ? "" : text);

  const getClass = (type: number) =>
    flightType === type ? classes.buttonsActive : classes.buttons2;
  return (
    <Box className={classes.root}>
      {!textToggle ? (
        <Box className={classes.topRow}>
          <Button
            onClick={handleFlightType.bind(null, 0)}
            className={classes.buttons}
            variant={getVariant(0)}
            color={getColor(0)}
          >
            Round trip
          </Button>
          <Button
            onClick={handleFlightType.bind(null, 1)}
            className={classes.buttons}
            variant={getVariant(1)}
            color={getColor(1)}
          >
            One way
          </Button>
          {/* <Button onClick={handleFlightType.bind(null, 2)} className={classes.buttons} variant={getVariant(2)} color={getColor(2)}>Multi-City</Button> */}
        </Box>
      ) : (
        <Box className={classes.topRow}>
          <Button
            onClick={handleFlightType.bind(null, 0)}
            className={getClass(0)}
            variant={"text"}
            color={"default"}
          >
            Round trip
          </Button>
          <Button
            onClick={handleFlightType.bind(null, 1)}
            className={getClass(1)}
            variant={"text"}
            color={"default"}
          >
            One way
          </Button>
          {/* <Button onClick={handleFlightType.bind(null, 2)} className={classes.buttons} variant={getVariant(2)} color={getColor(2)}>Multi-City</Button> */}
        </Box>
      )}
      <Box className={classes.bottomRow}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={isOneWay() ? 4 : 3}>
              <FormSearch
                label={getLabel("Travel from")}
                placeholder="From"
                icon={FlightTakeoffOutlined}
                type="text"
                id="Departure"
                name="Departure"
                value={formik.values.Departure}
                handleChange={formik.handleChange("Departure")}
                error={formik.dirty && Boolean(formik.errors.Departure)}
                helperText={formik.dirty && formik.errors.Departure}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={isOneWay() ? 4 : 3}>
              <FormSearch
                label={getLabel("Travel to")}
                placeholder="To"
                icon={FlightLandOutlined}
                type="text"
                id="Destination"
                name="Destination"
                value={formik.values.Destination}
                handleChange={formik.handleChange("Destination")}
                error={formik.dirty && Boolean(formik.errors.Destination)}
                helperText={formik.dirty && formik.errors.Destination}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={isOneWay() ? 4 : 3}>
              <FormDate
                label={getLabel("Departure Date")}
                placeholder="Departure"
                icon={CalendarTodayOutlined}
                disablePast
                id="DepartureDate"
                name="DepartureDate"
                value={formik.values.DepartureDate}
                handleChange={formik.handleChange("DepartureDate")}
                error={formik.dirty && Boolean(formik.errors.DepartureDate)}
                helperText={formik.dirty && formik.errors.DepartureDate}
              />
            </Grid>
            {!isOneWay() ? (
              <Grid item xs={12} sm={6} md={isOneWay() ? 4 : 3}>
                <FormDate
                  label={getLabel("Arrival Date")}
                  placeholder="Arrival"
                  icon={CalendarTodayOutlined}
                  disablePast
                  id="ArrivalDate"
                  name="ArrivalDate"
                  value={formik.values.ArrivalDate}
                  handleChange={formik.handleChange("ArrivalDate")}
                  error={formik.dirty && Boolean(formik.errors.ArrivalDate)}
                  helperText={formik.dirty && formik.errors.ArrivalDate}
                />
              </Grid>
            ) : null}
          </Grid>
          <Box className={classes.gap} />
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={6}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={4}>
                  <FormSelect
                    options={getCount(6, "Adult", "Adults")}
                    label={getLabel("Adult")}
                    placeholder="No Adult"
                    icon={PersonOutlineOutlined}
                    type="text"
                    id="Adults"
                    name="Adults"
                    value={formik.values.Adults}
                    defaultValue={"0"}
                    handleChange={formik.handleChange("Adults")}
                    error={formik.dirty && Boolean(formik.errors.Adults)}
                    helperText={formik.dirty && formik.errors.Adults}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormSelect
                    options={getCount(4, "Child", "Children")}
                    label={getLabel("Child")}
                    placeholder="No Child"
                    icon={PersonOutlineOutlined}
                    type="text"
                    id="Children"
                    name="Children"
                    value={formik.values.Children}
                    defaultValue={"0"}
                    handleChange={formik.handleChange("Children")}
                    error={formik.dirty && Boolean(formik.errors.Children)}
                    helperText={formik.dirty && formik.errors.Children}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormSelect
                    options={getCount(4, "Infant", "Infants")}
                    label={getLabel("Infant")}
                    placeholder="No Infant"
                    icon={ChildCareOutlined}
                    type="text"
                    id="Infants"
                    name="Infants"
                    value={formik.values.Infants}
                    defaultValue={"0"}
                    handleChange={formik.handleChange("Infants")}
                    error={formik.dirty && Boolean(formik.errors.Infants)}
                    helperText={formik.dirty && formik.errors.Infants}
                  />
                </Grid>
                {formik.dirty && Boolean(formik.errors.Passengers) ? (
                  <FormHelperText error className={classes.errorText}>
                    {formik.errors.Passengers}
                  </FormHelperText>
                ) : null}
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormSelect
                options={options}
                label={getLabel("Select Class")}
                placeholder="Flight Class"
                icon={BusinessCenterOutlined}
                type="text"
                id="Ticketclass"
                name="Ticketclass"
                value={formik.values.Ticketclass}
                handleChange={formik.handleChange("Ticketclass")}
                error={
                  formik.touched.Ticketclass &&
                  Boolean(formik.errors.Ticketclass)
                }
                helperText={
                  formik.touched.Ticketclass && formik.errors.Ticketclass
                }
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              className={
                hideLabel
                  ? classes.searchButtonConNoLabel
                  : classes.searchButtonCon
              }
            >
              <Button
                variant="contained"
                type="submit"
                color="primary"
                size="large"
                startIcon={loading ? null : <Search />}
                fullWidth
                disabled={loading || formik.isSubmitting || !formik.isValid}
              >
                {!loading ? (
                  "Search"
                ) : (
                  <CircularProgress size={30} className={classes.spinner} />
                )}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Box>
  );
}

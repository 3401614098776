import { Box, Typography } from '@material-ui/core';
import React from 'react';
import InnerContainer from '../InnerContainer/InnerContainer';
import SlideStyle from './SlideStyle';

type Props = {
  image?: any;
  title?: string;
  caption?: string;
  subtitle?: string;
};

export default function Slide({ image, title, caption, subtitle }: Props) {
  const classes = SlideStyle(image)();

  return (
    <Box className={classes.root}>
      <Box className={classes.headerText} style={{background: 'rgba(0, 0, 0, 0.6)'}}>
        <InnerContainer>
          <Typography variant='h6' className={classes.smallText}>{title}</Typography>
          <Typography variant='h3' className={classes.largeText}>{caption}</Typography>
          <Typography variant='body1' className={classes.subtitle}>{subtitle}</Typography>
        </InnerContainer>
      </Box>
    </Box>
  );
}

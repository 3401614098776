import { Box, Button, CircularProgress, Typography } from "@material-ui/core";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import swal from "sweetalert";
import * as yup from "yup";
import FormInput from "../../../components/Form/FormInput/FormInput";
import {
  Action_CloseAuthModal,
  Action_OpenAuthModal,
} from "../../../reduxStore/authModal/actions";
import { Action_SetUser, login } from "../../../reduxStore/user/actions";
import { gtagEvent } from '../../../utilities/helpers';
import { ModalForms } from "../../../utilities/types";
import SignInFormStyle from "./SignInFormStyle";

export default function SignInForm() {
  const classes = SignInFormStyle();
  const dispatch = useDispatch();

  const validationSchema = yup.object({
    email: yup.string().email("Invalid email").required("Email is Required"),
    password: yup.string().min(6).max(30).required("Password is Required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        const { status, data, message } = await login(values);
        if (status === true) {
          gtagEvent('login', {method: 'Landing Page Modal'});
          formik.resetForm();
          dispatch(Action_SetUser(data));
          swal("Success!", message, "success");
          dispatch(Action_CloseAuthModal());
        } else {
          swal("Oops!", message, "error");
        }
        formik.setSubmitting(false);
      } catch (err) {
        swal("Oops!", "Something went wrong, please try again", "error");
        formik.setSubmitting(false);
        formik.resetForm();
      }
    },
  });

  return (
    <Box className={classes.root}>
      <Typography className={classes.signText}>Sign in</Typography>
      <Typography className={classes.smallText}>
        <span>Not registered?</span>
        <Button
          size={"small"}
          className={classes.btn}
          onClick={() => {
            dispatch(Action_OpenAuthModal(ModalForms.SignUp));
          }}
        >
          Sign Up
        </Button>
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <Box className={classes.formInput}>
          <FormInput
            label="Email*"
            id="email"
            type="email"
            name="email"
            value={formik.values.email}
            handleChange={formik.handleChange("email")}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            placeholder="Eg. Johnwilliams@gmail.com"
          />
        </Box>
        <Box className={classes.formInput}>
          <FormInput
            label="Password*"
            type="password"
            placeholder="Confirm Password*"
            id="password"
            name="password"
            value={formik.values.password}
            handleChange={formik.handleChange("password")}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
          />
        </Box>
        <Typography
          href={"https://app.travoofy.com/forgotpassword"}
          target={"_blank"}
          component={"a"}
          color="primary"
          className={classes.smallText}
        >
          Forgot Password?
        </Typography>
        <br />
        <br />
        <Box className={classes.btnContainer}>
          <Button
            variant="contained"
            color="primary"
            className={classes.btnPrimary}
            type="submit"
          >
            {!formik.isSubmitting ? (
              "Sign in"
            ) : (
              <CircularProgress className={classes.spinner} />
            )}
          </Button>
        </Box>
      </form>
    </Box>
  );
}

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Slider,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { Tune } from "@material-ui/icons";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React, { useEffect, useState } from "react";
import { toNumberFormat } from "../../utilities/helpers";
import { AnyObject } from "../../utilities/types";
import PackageSideBarStyle from "./PackageSideBarStyle";

type Props = {
  priceRange: number[];
  onPriceChange?: (value: { min: number; max: number }) => void;
  categories: string[];
  onCategoryChange?: (value: AnyObject<boolean>) => void;
  currency: string;
};

export default function PackageSideBar({
  priceRange,
  onPriceChange,
  categories,
  onCategoryChange,
  currency,
}: Props) {
  const classes = PackageSideBarStyle();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const [filter, setFilter] = useState<boolean>(true);
  // Price
  const [showPrice, setShowPrice] = useState<boolean>(true);
  const [price, setPrice] = useState<number[]>(priceRange);
  const priceMarks = ([a, b]: number[]) => [
    { label: toNumberFormat(a, currency), value: a },
    { label: toNumberFormat(b, currency), value: b },
  ];
  // Categories
  const [showCategories, setShowCategories] = useState<boolean>(true);
  const [categoryNames, setCategoryNames] = useState<AnyObject<boolean>>({});

  const handleCategoryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCategoryNames({
      ...categoryNames,
      [event.target.name]: event.target.checked,
    });
  };

  useEffect(() => {
    if (onPriceChange) {
      onPriceChange({ min: priceRange[0], max: priceRange[1] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [priceRange]);

  useEffect(() => {
    setFilter(!isSmall);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSmall]);

  useEffect(() => {
    if (onCategoryChange) {
      onCategoryChange(categoryNames);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryNames]);

  return (
    <Box className={classes.root}>
      <Accordion
        elevation={0}
        expanded={filter}
        onChange={(_, val) => setFilter(val)}
      >
        <AccordionSummary expandIcon={<Tune />}>
          <Typography variant="inherit" component={"strong"}>
            Filters
          </Typography>
        </AccordionSummary>
        <Divider />
        <AccordionDetails className={classes.body}>
          {/* Airlines Filter */}
          <Accordion
            elevation={0}
            expanded={showCategories}
            onChange={(_, val) => setShowCategories(val)}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="inherit" component={"strong"}>
                Packages
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <FormGroup>
                {categories.map((name, index) => (
                  <FormControlLabel
                    key={index}
                    className={classes.checkbox}
                    control={
                      <Checkbox
                        checked={!!categoryNames[name]}
                        onChange={handleCategoryChange}
                        name={name}
                        color="primary"
                      />
                    }
                    label={name}
                  />
                ))}
              </FormGroup>
            </AccordionDetails>
          </Accordion>
          <Divider />
          {/* Airlines Filter */}

          {/* Price Filter */}
          <Accordion
            elevation={0}
            expanded={showPrice}
            onChange={(_, val) => setShowPrice(val)}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="inherit" component={"strong"}>
                Price
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Slider
                defaultValue={priceRange}
                value={price}
                min={priceRange[0]}
                max={priceRange[1]}
                marks={priceMarks(price)}
                valueLabelDisplay="off"
                onChange={(_, val) => setPrice(val as any)}
                onChangeCommitted={(_, val) => {
                  const _v: number[] = val as number[];
                  if (onPriceChange) {
                    onPriceChange({ min: _v[0], max: _v[1] });
                  }
                }}
                className={classes.slider}
              />
            </AccordionDetails>
          </Accordion>
          {/* Price Filter */}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}

import VisaSearchStyle from "./VisaSearch.style";
import { Link } from "react-router-dom";
import { Box, Button, Grid, Typography, Modal } from "@material-ui/core";
import {useState} from 'react';
import FormSelect from "../Form/FormSelect/FormSelect";

import {
    BusinessCenterOutlined,
    CalendarTodayOutlined,
    ChildCareOutlined,
    FlightLandOutlined,
    FlightTakeoffOutlined,
    PersonOutlineOutlined,
    FlagSharp,
    AirlineSeatFlat,
    Book,
    
    Search,
  } from "@material-ui/icons";
  

type Props = {
    action?: () => void;
    hideLabel?: boolean;
    loading?: boolean;
    textToggle?: boolean;
    setDisplay?: any;
    display? : any;
     content?: any;
    }


const country = [
    { value: 'USA', label: 'USA' },
    { value: 'United Kngdom', label: 'United Kingdom' },
    { value: 'Canada', label: 'Canada' },
    { value: 'Ireland', label: 'Ireland' },
    { value: 'South Africa', label: 'South Africa' },
    { value: 'Egypt', label: 'Egypt' },
    { value: 'Schengen', label: 'Schengen' },
    { value: 'Turkey', label: 'Turkey' },
    { value: 'Belgium', label: 'Belgium' },
    { value: 'Brazil', label: 'Brazil' },
    { value: 'Denmark', label: 'Denmark' },
    { value: 'Estonia', label: 'Estonia' },
    { value: 'Finland', label: 'Finland' },
    { value: 'Germany', label: 'Germany' },
    { value: 'Greece', label: 'Greece' },
    { value: 'Hungary', label: 'Hungary' },
    { value: 'Iceland', label: 'Iceland' },
    { value: 'Italy', label: 'Italy' },
    { value: 'Latvia', label: 'Latvia' },
    { value: 'Lithuania', label: 'Lithuania' },
    { value: 'Luxembourg', label: 'Luxembourg' },
    { value: 'Malta', label: 'Malta' },
    { value: 'Norway', label: 'Norway' },
    { value: 'Poland', label: 'Poland' },
    { value: 'Spain', label: 'Spain' },
    { value: 'Sweden', label: 'Sweden' },
    { value: 'Switzerland', label: 'Switzerland' },
    { value: 'The Czech Republic', label: 'The Czech Republic' },
    { value: 'The Netherlands', label: 'The Netherlands' },
    { value: 'Turkey', label: 'Turkey' },
    
   

]

const visaType = [
    { value:{name: 'Tourist',requirements : [
        {
            header: " Visa application for Tourism purpose",
            content: [" International passport valid for at least 6 months",
                      " For non-Nigerian nationals residing in Nigeria: copy of Nigerian residence permit ",
                       "Two passport photographs",
                       " Travel health insurance covering the whole stay ", 
                       " Copy of the round-trip airline reservation with passenger reservation number (it is recommended not to buy the ticket until the visa is granted) ",
                       "Confirmed hotel reservation ",
                    "Original up to date personal bank statements of the last six months (only stamped and signed bank statements are accepted) – NO copies ",
                        "Complete and detailed travel itinerary ",
                        ]
        },
        {
            header: "If applicable: ",
            content: ["Proof of landed property",
                      "For non-Nigerian nationals residing in Nigeria: copy of Nigerian residence permit ",
                       "If traditionally married only: „affidavit of facts “plus wedding pictures",
                        ]
        },
        {
            header: "If applicable: ",
            content: ["Original letter of introduction by employer ",
                      "Leave letter from employer ",
                       "Employment contract and pay slips of the last six months ",
                       "Original up to date salary bank statements of the last six months (only stamped and signed bank statements are accepted) – NO copies",
                        ]
        },
        {
            header: "If applicant is freelancer / self-employed:  ",
            content: ["COI / COR / Certificate of Incorporation / Registration of the company in Nigeria ",
                      "MEMO / Memorandum and Articles of Association / Company and Allied Matters Act ",
                       "FIRS / Federal Inland Revenue Service Income Tax Clearance Certificate / Company Income Tax Clearance Certificate of the past year ",
                       "Original up to date company's bank statements of the last six months  (only stamped and signed bank statements are accepted) – NO copies ",
                        ]
        },
        {
            header: "If applicant is a minor: ",
            content: ["NPC Child´s Birth Certificate ",
                      " Letter of school confirming current enrolment and sanctioning leave of absence ",
                       "Consent letter signed from both parents regarding child´s travel ",
                       " Copy of both parents’ passports ", 
                       " If one parent is deceased: Death Certificate",
                       "If parents are divorced: Court Order regarding child´s custody  ",
                    
                        ]
        },



        ,
                   ]
    }, label: 'Tourist',   },

    { value:{name: 'Business',requirements : [
        {
            header: " Visa application for Business purpose",
            content: [" International passport valid for at least 6 months",
                      " For non-Nigerian nationals residing in Nigeria: copy of Nigerian residence permit ",
                       "Two passport photographs",
                       " Travel health insurance covering the whole stay ", 
                       " Copy of the round-trip airline reservation with passenger reservation number (it is recommended not to buy the ticket until the visa is granted) ",
                       "Confirmed hotel reservation ",
                    "Original up to date personal bank statements of the last six months (only stamped and signed bank statements are accepted) – NO copies ",
                        "Complete and detailed travel itinerary ",
                        ]
        
        },
        {
            header: " Visa application for For business travelers purpose",
            content: [" Original invitation letter: ",
                      " For business travellers: invitation from a company in destination country ",
                       
                        ]
        },
        
       



        ,
                   ]
    }, label: 'Business',   },

    { value:{name: 'conferences, Trainings and cultural events, Study',requirements : [
        {
            header: " Visa application for Tourism purpose",
            content: [" International passport valid for at least 6 months",
                      " For non-Nigerian nationals residing in Nigeria: copy of Nigerian residence permit ",
                       "Two passport photographs",
                       " Travel health insurance covering the whole stay ", 
                       " Copy of the round-trip airline reservation with passenger reservation number (it is recommended not to buy the ticket until the visa is granted) ",
                       "Confirmed hotel reservation ",
                    "Original up to date personal bank statements of the last six months (only stamped and signed bank statements are accepted) – NO copies ",
                        "Complete and detailed travel itinerary ",
                        ]
        },
        {
            header: "For conferences, Trainings and cultural events, Study ",
            content: ["For visitors of conferences, courses, trainings and cultural events: invitation for the respective purpose ",
                      "For trade fair visitors: invitation for the trade fair or entry ticket ",
                       "For study: admission confirmation letter from the country and receipt of tuition fee (if applicable)",
                        ]
        },
        {
            header: "If Visiting Family/Friends ",
            content: ["Invitation letter from the family member or friends",
                      "Copy of the inviting persons passport data page and or residence permit ",
                       "Up to date proof of residence of the inviting person",
                       "Proof of relationship: copy of birth certificate and resident permit of inviting relative the destination country and birth certificate of applicant (original and photocopy)",
                        "if you want to visit your children the birth certificate of your children must contain the names of both parents ",
                        ]
        },
        
       



        ,
                   ]
    }, label: 'conferences, Trainings and cultural events, Study',   },



    { value:{name: 'For Medical Visa',requirements : [
        {
            header: " Visa application for Tourism purpose",
            content: [" International passport valid for at least 6 months",
                      " For non-Nigerian nationals residing in Nigeria: copy of Nigerian residence permit ",
                       "Two passport photographs",
                       " Travel health insurance covering the whole stay ", 
                       " Copy of the round-trip airline reservation with passenger reservation number (it is recommended not to buy the ticket until the visa is granted) ",
                       "Confirmed hotel reservation ",
                    "Original up to date personal bank statements of the last six months (only stamped and signed bank statements are accepted) – NO copies ",
                        "Complete and detailed travel itinerary ",
                        ]
        },
        {
            header: "For Medical Visa ",
            content: ["Confirmation from a hospital / doctor in destination country the following information:",
                      "Date and place of appointment / treatment  ",
                       "Estimated duration of the treatment ",
                       "The expected costs",
                      "That the expected costs have been paid already",
                       "If you are not staying at the hospital your whole stay confirmed hotel reservation / booking or proof of any other accommodation in the destination country ",
                        ]
        },

        ,
                   ]
    }, label: 'For Medical Visa',   },

    { value:{name: 'For Transit Visa',requirements : [
        {
            header: " Visa application for Tourism purpose",
            content: [" International passport valid for at least 6 months",
                      " For non-Nigerian nationals residing in Nigeria: copy of Nigerian residence permit ",
                       "Two passport photographs",
                       " Travel health insurance covering the whole stay ", 
                       " Copy of the round-trip airline reservation with passenger reservation number (it is recommended not to buy the ticket until the visa is granted) ",
                       "Confirmed hotel reservation ",
                    "Original up to date personal bank statements of the last six months (only stamped and signed bank statements are accepted) – NO copies ",
                        "Complete and detailed travel itinerary ",
                        ]
        },
        {
            header: "For Transit Visa",
            content: ["visa for final and itinerary of the final country ",
                           ]
        },

        ,
                   ]
    }, label: 'For Transit Visa',   },
    
    { value:{name: 'E- VISAS',requirements : [
        {
            header: " Visa application for Tourism purpose",
            content: [" International passport valid for at least 6 months",
                      " For non-Nigerian nationals residing in Nigeria: copy of Nigerian residence permit ",
                       "Two passport photographs",
                       " Travel health insurance covering the whole stay ", 
                       " Copy of the round-trip airline reservation with passenger reservation number (it is recommended not to buy the ticket until the visa is granted) ",
                       "Confirmed hotel reservation ",
                    "Original up to date personal bank statements of the last six months (only stamped and signed bank statements are accepted) – NO copies ",
                        "Complete and detailed travel itinerary ",
                        ]
        },
        {
            header: " Visa application for Tourism purpose",
            content: [" International passport valid for at least 6 months",
                      " For non-Nigerian nationals residing in Nigeria: copy of Nigerian residence permit ",
                       "Two passport photographs",
                       " Travel health insurance covering the whole stay ", 
                       " Copy of the round-trip airline reservation with passenger reservation number (it is recommended not to buy the ticket until the visa is granted) ",
                       "Confirmed hotel reservation ",
                    "Original up to date personal bank statements of the last six months (only stamped and signed bank statements are accepted) – NO copies ",
                        "Complete and detailed travel itinerary ",
                        ]
        },
        {
            header: "E- VISAS COUNTRIES FOR NIGERANS AND THEIR GENERAL REQUIREMENTS",
            content: [" Countries include Kenya, UAE, Uganda, Turkey,Tanzania, Ethiopia, Malawi, Zambia, and Zimbabwe ",
                      
                        ]
        },
        {
            header: "REQUIREMENTS",
            content: ["International passport valid for more than 6 months",
                      "Passport photograph with a white background ",
                       "Full name of parents",
                       "Flight Itinerary and hotel reservation",
                        "Details of Next of Kin",
                        "Home address of Applicant",
                       "E mail address of applicant ",
                        "Phone number of applicant ",
                        ]
        },
        
       



        ,
                   ]
    }, label: 'E- VISAS',   },

]

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 350,
    height:550,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    overflowY: "scroll"
  };
const BasicModal = ({setDisplay, display, content}:Props) => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setDisplay(false);
   console.log(content)
    return (
      <div>
        
        
        <Modal
          open={display}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
            <Box sx={style}>

            <div onClick={handleClose} style={{cursor:"pointer",
             color: "black", fontWeight:"700",
             fontSize:"20px",borderRadius:"50px",
             border:"5px solid Red",
             width:"35%",textAlign:"center" }} >
                   Close
            </div>
             {
        
        content.map(function(el:any,){
             console.log(el.header);
            return  (

                <div>
                 
                    <h1>
                       {el.header}
                    </h1>
                    <div>
                        {el.content.map(function(contentList:any){
                          return <li>{contentList}</li>
                        })}
                    </div>
                
                </div>
               )
            
         
        })
    } </Box>
        </Modal>
      </div>
    );
  }
  




const VisaSearch = ({ action, hideLabel, loading, textToggle }: Props) => {
     const  [display, setDisplay] = useState(false)
     const  [content, setContent] = useState([{header:"vv", content: ["sss"]}])
    const classes = VisaSearchStyle();
    return (
        
        <Box >
                <BasicModal display={display} content = {content} setDisplay={setDisplay} />
                
            <Grid container spacing={1}  className={classes.root} >
                <Box px={3}>
                    <Typography>
                        Instantly apply for an immigrant or nonimigrant visa to any travel destination of your choice.
                        The registration process can be completed in a matter of minutes.
                        Please ensure you have a scanned copy of the necessary documents on your device ready to be uploaded.
                    </Typography>
                </Box>
            

                <Grid item sm={12} xs={12} lg={11} className={classes.visaButtons} container>
                    <Grid item xs={12} sm={7}className={classes.visaButtonsChild}>

                        <Box textAlign='left' width='185px'>
                            <FormSelect name='selectCountry'
                            
                            label={'Select Country of Travel *' }
                            
                            placeholder="Country"
                            icon={FlagSharp}
                            defaultValue={"0"}

                                options={country} />
                        </Box>
                        <Box textAlign='left' width='185px'>
                            <FormSelect name='visaType' label={'Select visaType *'}
                             handleChange= {function (requirement){
                                console.log(requirement);
                                setDisplay(true)
                                setContent(requirement.requirements)
                             }}
                              

                              icon={Book}
                              
                               options={visaType} />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={5}>

                        <Box textAlign='right' className={classes.mt}>
                            <Button component={Link} to='/search-visa' color='primary' variant='contained' size='large'>APPLY FOR VISA</Button>
                        </Box>
                    </Grid>
                </Grid>
                {/* <Grid item xs={10} sm={6} md={4}>
                    <Button component={Link} to='/search-visa' className={classes.otherBtn} variant='contained' style={{ width: '100%' }} > OTHER VISA</Button>
                </Grid> */}

                
            </Grid>

        </Box>

    )

}
export default VisaSearch;
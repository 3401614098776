import { makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) => ({
  root: {},
  inner: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  tabCon: {
    color: theme.palette.text.primary,
    backgroundColor: 'transparent',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  tab: {
    backgroundColor: 'transparent',
  },
  divider: {
    marginTop: '700px',
  },
  button: {
    marginTop: theme.spacing(3),
  },
}));

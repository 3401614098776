import { Box, Typography } from '@material-ui/core';
import React from 'react';
import BreadCrumbStyle from './BreadCrumbStyle';
import { useHistory } from 'react-router-dom';

type Props = {
  paths: string[];
};

export default function BreadCrumb({ paths }: Props) {
  const classes = BreadCrumbStyle();
  const history = useHistory();

  const isLast = (index: number) => index === paths.length - 1;

  const handleClick = (index: number) => {
    if (index === 0) {
      history.push('/');
    } else if (isLast(index)) {
      // Do Nothing
    } else {
      history.goBack();
    }
  };

  return (
    <Typography variant="subtitle1" className={classes.root}>
      {paths.map((path, index) => {
        const next = isLast(index) ? null : <>&nbsp;&gt;&nbsp;</>;
        return (
          <span key={index}>
            <Box
              onClick={() => handleClick(index)}
              className={isLast(index) ? '' : classes.link}
              component={'span'}
            >
              {path}
            </Box>
            {next}
          </span>
        );
      })}
    </Typography>
  );
}

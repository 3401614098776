
import { Radio } from '@material-ui/core';
import { useStyles } from './FormRadio.style';


const FormRadio = ({...props}) => {
    const classes = useStyles();
    return (
        <Radio
        className={classes.root}
        disableRipple
        color="primary"
        {...props}
      />
    );
}

export default FormRadio;
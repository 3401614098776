import { ActionTypes, FlightData } from "../../utilities/types";

export type FlightDataActionType = {
  type: ActionTypes.SET_FLIGHT_DATA | ActionTypes.CLEAR_FLIGHT_DATA;
  payload: FlightData | null;
};

export function Action_SetFlightData(payload: FlightData): FlightDataActionType {
  return {
    type: ActionTypes.SET_FLIGHT_DATA,
    payload: { ...payload },
  };
}

export function Action_ClearFlightData(): FlightDataActionType {
  return {
    type: ActionTypes.CLEAR_FLIGHT_DATA,
    payload: null,
  }
}

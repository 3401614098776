
export const flightData = [
    {
        flightName: 'African Flights',
        flightLocation: [
            'Flights to Lagos',
            'Flight to Nairobi',
            'Flight to cape town',
            'flight to johanessburg',
        ]
    },
    {
        flightName: 'European Flights',
        flightLocation: [
            'Flight to London',
            'Flight to Instanbul',
            'Flight to Manchester',
            'Flight to Paris',
        ]
    },
    {
        flightName: 'Asian Flights',
        flightLocation: [
            'Flights to Dubai',
            'Flight to Abu Dhabi',
            'Flight to Guangzhou',
            'Flight to Hong Kong',
        ]
    },
    {
        flightName: 'North American Flights',
        flightLocation: [
            ' Flights to Atlanta',
            'Flight to Toronto',
            'Flight to New York',
            'Flight to Houston',
        ]
    }
]

export const hotelData = [
    {
        hotelsName: 'African Hotels',
        hotelsLocation: [
            'Hotels in Lagos',
            'Hotels in Nairobi',
            'Hotels in cape town',
            'Hotels in johanessburg',
        ]
    },
    {
        hotelsName: 'European Hotels',
        hotelsLocation: [
            'Hotels in London',
            'Hotels in Instanbul',
            'Hotels in Manchester',
            'Hotels in Paris',
        ]
    },
    {
        hotelsName: 'Asian Hotels',
        hotelsLocation: [
            'Hotels in Dubai',
            'Hotels to Abu Dhabi',
            'Hotels in Guangzhou',
            'Hotels in Hong Kong',
        ]
    },
    {
        hotelsName: 'North American Hotels',
        hotelsLocation: [
            ' Hotels in Atlanta',
            'Hotels in Toronto',
            'Hotels in New York',
            'Hotels in Houston',
        ]
    }
]
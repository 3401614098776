

export const mainMenus = [
  { name: "Flight", link: "/flight" },
  // { name: "Hotel", link: "/hotel" },
  { name: "Packages", link: "/packages" },
  { name: "Visa", link: "/visa" },
  { name: "About Us", link: "/about-us" },
  // { name: "Support", link: "#" },
];

export const MenuItems = [
  // { link: "/our-services", name: "Our Services" },
  { link: "/about-us", name: "About us" },
  { link: "/contact-us", name: "Contact us" },
  { link: "/compliant-management-policy-and-procedure", name: "Complaints Management Policy and Procedure" },

  { link: "/privacy-policy", name: "Privacy and Policy" },

];

export const SocialItems = [
  { link: "https://m.facebook.com/travoofy/", name: "Facebook" },
  { link: "https://instagram.com/travoofy", name: "Instagram" },
  { link: "https://twitter.com/travoofy", name: "Twitter" },
  { link: "https://www.linkedin.com/company/travoofy/", name: "LinkedIn" },
  { link: "#", name: "Youtube" }

];

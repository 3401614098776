import { makeStyles, Theme } from "@material-ui/core";
export default makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3),
    position: 'sticky',
    top: theme.spacing(14),
  },
  info: {
    display: 'flex',
    alignItems: 'center',
  },
}));

import { makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) => ({
  root: {
    borderRadius: '0',
    padding: theme.spacing(4),
    marginBottom: theme.spacing(7)
  },
  text: {
    fontWeight: 700,
    fontSize: '18px',
  },
  box: {
    marginTop: theme.spacing(4)
  },
  container: {
    borderRadius: 10,
    padding: theme.spacing(2),
    border: '1px solid #5FC9FE',
    marginTop: theme.spacing(7)
  },
  button: {
    display: 'flex',
    cursor: 'pointer',
    justifyContent: 'center',
    padding: theme.spacing(1),
    borderRadius: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    '& > *': {
      color: theme.palette.common.white,
    }
  },
  total: {
    fontSize: '25px',
    fontWeight: 700,
    textAlign: 'center',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    color: theme.palette.primary.main,
  },
  amount: {
      fontWeight: 700,
      fontSize: theme.spacing(3),
      color: theme.palette.primary.main,
  }
}));

import { makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
    root: {

        justifyContent: 'center',
        alignItems: 'center',
        height: '270px',
        gap: '10%',

        [theme.breakpoints.down('xs')]: {

            height: ' 600px',
        },
    },
    dubaiBtn: {

        height: '64px',
        fontSize: '20px',
    },
    otherBtn: {

        height: '64px',
        color: '#fff',
        fontSize: '20px',
        backgroundColor: '#FEAB5F',
        '&:hover': {
            background: 'linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), #FEAB5F',


        }
    },
    visaButtons: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            gap: '10px',
        }
    },
    visaButtonsChild: {
        display: 'flex',
        alignItems: 'center',
        gap: '20px',
        width: '100%',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            gap: '10px',
        }
    },
    mt:{
        marginTop:'30px',
        [theme.breakpoints.down('xs')]: {
            marginTop:'0px'
        }
    }


}));
import {
  ActionTypes,
  FlightSearchProps,
  TicketClass,
} from "../../utilities/types";
import { FlightPropType } from "./actions";

const defaultState: FlightSearchProps = {
  FlightSearchType: "Return",
  Adults: "1",
  Children: "0",
  Infants: "0",
  Ticketclass: TicketClass.Economy,
  TargetCurrency: "NGN",
  Itineraries: [],
};

export function FlightPropReducer(
  state = defaultState,
  action: FlightPropType
) {
  switch (action.type) {
    case ActionTypes.SET_FLIGHT_PROPS:
      return { ...action.payload };
    case ActionTypes.CLEAR_FLIGHT_PROPS:
      return { ...defaultState };
    default:
      return { ...state };
  }
}

import {
  Avatar,
  Box,
  Divider,
  Icon,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { ElementType, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { AirportObject, AppStore } from "../../../utilities/types";
import FormSearchStyle from "./FormSearchStyle";

type Props = {
  label?: string;
  placeholder?: string;
  multiline?: boolean;
  icon?: ElementType<any>;
  color?: "white";
  value?: string;
  handleChange?: (value: string) => void;
  type?: string;
  error?: any;
  helperText?: any;
  id?: string;
  name?: string;
  hidden?: boolean;
  disable?: boolean;
};

export default function FormSearch({
  label,
  placeholder,
  icon,
  multiline,
  color,
  value,
  handleChange,
  type,
  error,
  helperText,
  id,
  name,
  hidden,
  disable,
}: Props) {
  const classes = FormSearchStyle(color)();
  const { airports }: AppStore = useSelector((store: AppStore) => store);
  const [searchResult, setSearchResult] = useState<AirportObject[]>([]);
  const [show, setShow] = useState<boolean>(false);
  const [data, setData] = useState<string>(value || "");
  const input = useRef<HTMLInputElement>();

  const searchFilter = (keyword: string) => {
    const text: string = keyword.toLowerCase();
    setSearchResult([
      ...text.length === 3 ? airports.filter(
        (airport) => airport.AirportCode.toLowerCase() === text
      ) : [],
      ...airports
        .filter((airport) => {
          if (text.length === 3 && airport.AirportCode.toLowerCase() === text) {
            return false;
          } else if (airport.AirportCode.toLowerCase().includes(text)) {
            return true;
          } else if (airport.AirportName.toLowerCase().includes(text)) {
            return true;
          } else if (airport.CityCountry.toLowerCase().includes(text)) {
            return true;
          } else {
            return false;
          }
        })
        .sort((a, b) => a.AirportName.localeCompare(b.AirportName)),
    ]);
  };

  const select = (item: AirportObject) => {
    setData(item.AirportName);
    handleClose(null);
    if (handleChange) {
      handleChange(item.AirportCode);
    }
  };

  const handleClose = (e: any) => {
    if (e) {
      setTimeout(() => setShow(false), 300);
    } else {
      setShow(false);
    }
  };

  const onChange = (event: any) => {
    const val: string = event.target.value;
    setData(val);

    if (val.length >= 3) {
      searchFilter(val);
      setShow(true);
    } else {
      handleClose(null);
    }
  };

  useEffect(() => {
    if (value && value.length >= 3) {
      const val: AirportObject = airports.filter((item) =>
        item.AirportCode.toLowerCase().includes(value.toLowerCase())
      )[0];
      if (val) {
        setData(val.AirportName);
        if (handleChange) {
          handleChange(val.AirportCode);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box className={classes.root}>
      <Typography className={classes.label} variant="body2">
        {label}
      </Typography>
      <TextField
        error={error}
        hidden={hidden}
        disabled={disable}
        type={type}
        id={id}
        inputRef={input}
        name={name}
        value={data}
        focused={show}
        autoComplete={"off"}
        onBlur={handleClose}
        autoCorrect={"off"}
        helperText={helperText}
        InputProps={{
          onChange: onChange,
          startAdornment: icon ? (
            <Box className={classes.adornment}>
              <Icon component={icon} color="disabled" />
              <Divider
                className={classes.divider}
                orientation="vertical"
                flexItem
              />
            </Box>
          ) : null,
        }}
        rows={3}
        variant="outlined"
        placeholder={placeholder || label}
        fullWidth
        multiline={multiline}
      />
      {show && searchResult.length > 0 ? (
        <Paper className={classes.popover}>
          {searchResult.map((item, index) => (
            <Box
              key={index}
              component={"div"}
              className={classes.searchItem}
              onClick={(e) => {
                e.preventDefault();
                select(item);
              }}
            >
              <Avatar className={classes.avatar}>{item.AirportCode}</Avatar>
              <Box component={"div"} className={classes.labelCon}>
                <Typography variant="body2">{item.AirportName}</Typography>
                <Typography variant="caption">{item.CityCountry}</Typography>
              </Box>
            </Box>
          ))}
        </Paper>
      ) : null}
    </Box>
  );
}

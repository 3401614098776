
import React from "react";
import { Grid, Box } from "@material-ui/core";
import { CalendarTodayOutlined } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import FormInput from "../../Form/FormInput/FormInput";
import FormDate from "../../Form/FormDate/FormDate";
import useVisaPassportInfoStyles from './VisaPassportInfo.style';
import FormUpload from "../../Form/FormUpload/FormUpload";
import { useFormikContext } from "formik";

type Props = {
    passportNumber: string;
    passportExpiryDate: Date;
    scannedCopyOfDataPage: File;
}

export const VisaPassportInfo = () => {
    const { values, handleChange, touched, errors } = useFormikContext<Props>();
    const classes = useVisaPassportInfoStyles();
    return (
        <Box >
            <Grid container className={classes.root} >
                <Grid item md={12} >
                    <Alert severity='warning' className={''}>
                        Use all given Information as they appear on your passport/ID to avoid complications.
                    </Alert>

                </Grid>
                <Grid item container className={classes.inputSpace} >
                    <Grid item xs={12} sm={5} md={5} >
                        <FormInput
                            label="Passport Number*"
                            placeholder="Passport Number" name="passportNumber"
                            type='text'
                            handleChange={handleChange('passportNumber')}
                            value={values.passportNumber}
                            error={touched.passportNumber && Boolean(errors.passportNumber)}
                            helperText={touched.passportNumber && errors.passportNumber}

                        />
                    </Grid>
                    <Grid item xs={12} sm={5} md={5}>
                        <FormDate
                            label="Expiry Date *"
                            icon={CalendarTodayOutlined}
                            placeholder="Expiry Date"
                            name="passportExpiryDate"
                            handleChange={handleChange('passportExpiryDate')}
                            error={touched.passportExpiryDate && Boolean(errors.passportExpiryDate)}
                            helperText={touched.passportExpiryDate && errors.passportExpiryDate}

                        />
                    </Grid>
                </Grid>
                <Grid item container className={classes.inputSpace} >
                    <Grid item xs={12} sm={5} md={5} >
                        <FormUpload
                            label="Scanned Copy Of Data Page *" name="scannedCopyOfDataPage"
                            handleChange={handleChange('scannedCopyOfDataPage')}
                            error={touched.scannedCopyOfDataPage && Boolean(errors.scannedCopyOfDataPage)}
                            helperText={touched.scannedCopyOfDataPage && errors.scannedCopyOfDataPage}

                        />

                    </Grid>

                </Grid>

            </Grid>
        </Box>
    );
}

import { makeStyles, Theme } from "@material-ui/core";

export default makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: '#002C43',
    paddingTop: `${theme.spacing(8)}px 0`,
  },
  header: {
    color: theme.palette.common.white,
    marginBottom: theme.spacing(4),
    fontSize: "25px",
  },
  text: {
    color: theme.palette.common.white,
    fontWeight: 400,
  },
  link: {
    textTransform: "capitalize",
    textDecoration: "none",
    padding: 0,
    color: theme.palette.common.white,
    display: "block",
    fontSize: "15px",
    lineHeight: "42px",
    "&:hover": {
      textDecoration: "none",
      textTransform: "capitalize",
      padding: 0,
      color: theme.palette.common.white,
      display: "block",
      fontSize: "15px",
      lineHeight: "42px",
    },
  },
  travoofy: {
    color: theme.palette.common.white,
    fontSize: "14px",
    marginTop: theme.spacing(2),
  },
  themeCon: {
    color: 'white',
    marginTop: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(5),
  },
  icon: {
    marginTop: '-2px',
    marginLeft: '-2px',
  },
  divider: {
    backgroundColor: "rgba(255, 255, 255, 0.21)",
    marginTop: theme.spacing(8),
  },
  star: {
    color: "#FFBF1C",
    cursor: "pointer",
  },
}));

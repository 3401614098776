import { Box, Paper, Typography } from "@material-ui/core";
import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { gtagEvent } from "../../utilities/helpers";
import pageRoutes from "../../utilities/pageRoutes";
import PaymentDetailStyle from "./PaymentDetailStyle";

type Props = {
  date: Date;
};

export default function PaymentDetail({ date }: Props) {
  const classes = PaymentDetailStyle();
  const [countDown, setCountDown] = useState({ min: 30, sec: 0 });
  const mounted = useRef(true);
  const history = useHistory();

  const handleCountDown = (date: Date) => {
    const bookingTime = new Date(date).getTime();
    const expireTime = bookingTime + 1000 * 60 * 30;
    const diff = Math.floor((expireTime - Date.now()) / 1000);
    if (diff <= 0) {
      gtagEvent("booking_timeout", { name: "Flight Booking Timeout" });
      return history.push(pageRoutes.SearchFlight);
    }
    const min = Math.floor(diff / 60);
    const sec = diff - min * 60;
    if (mounted.current) {
      setCountDown({ min, sec });
    }
  };

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (date) {
      setTimeout(() => handleCountDown(date), 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countDown]);

  return (
    <Paper elevation={0} className={classes.root}>
      <Typography variant={"h6"}>Payment Details</Typography>
      <Box>
        <Typography className={classes.response}>
          The quoted flight / price is not guaranteed until payment has been
          confirmed by Travoofy.
          <br />
          <br />
          This booking will expire in less than:{" "}
          <strong style={{ color: countDown.min > 1 ? "inherit" : "red" }}>
            {countDown.min}min : {countDown.sec}sec
          </strong>
          <br />
          <br />
          Please remember to use your Travoofy booking reference as the
          transaction remark or depositor's name when you pay. Then, allow for
          up to six (6) working hours to receive your e-ticket. If you still
          haven't received your ticket after this time, please email us at
          mail@travoofy.com.
          <br />
          <br />
        </Typography>
      </Box>
    </Paper>
  );
}

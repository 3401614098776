import { Button, Card, CircularProgress, Typography } from "@material-ui/core";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import * as yup from "yup";
import FormInput from "../../../components/Form/FormInput/FormInput";

import { Action_CloseAuthModal } from '../../../reduxStore/authModal/actions';
import { Action_SetUser, verifyOtp } from '../../../reduxStore/user/actions';
import { AppStore, UserObject } from '../../../utilities/types';

import VerifyOtpStyle from "./VerifyOtpStyle";

const VerifyOtp = () => {
  const classes = VerifyOtpStyle();
  const dispatch = useDispatch();
  const { token, user }: UserObject = useSelector(
    (store: AppStore) => store.user
  ) as any;

  const validationSchema = yup.object({
    otp: yup.string().min(6).max(6).required(),
  });

  const formik = useFormik({
    initialValues: {
      otp: "",
      token,
      client: user.email,
      type: "verification",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        const { status, message, data } = await verifyOtp(values);
        if (status) {
          formik.resetForm();
          formik.setSubmitting(false);

          dispatch(Action_SetUser({
            token: data.token,
            user,
          }));

          swal("Success!", message, "success");
          dispatch(Action_CloseAuthModal());
        } else {
          swal("Oops!", message, "error");
          formik.setSubmitting(false);
        }
      } catch (err) {
        swal("Oops!", "Something went wrong, please try again", "error");
        formik.setSubmitting(false);
      }
    },
  });

  return (
    <Card elevation={0} className={classes.root}>
      <Typography className={classes.heading} component={"h6"} variant={"h6"}>
        Verify Email
      </Typography>
      <Typography className={classes.heading} component={"p"} variant={"body1"}>
        Please Enter Otp Sent To Your email
      </Typography>
      <form className={classes.form} onSubmit={formik.handleSubmit}>
        <FormInput
          type="text"
          label="OTP"
          placeholder="OTP"
          id="otp"
          name="otp"
          value={formik.values.otp}
          handleChange={formik.handleChange}
          error={formik.touched.otp && Boolean(formik.errors.otp)}
          helperText={formik.touched.otp && formik.errors.otp}
        />
        <Button
          type="submit"
          variant={"contained"}
          color={"primary"}
          className={classes.btn}
        >
          {!formik.isSubmitting ? (
            "Verify Otp"
          ) : (
            <CircularProgress className={classes.spinner} />
          )}
        </Button>
      </form>
    </Card>
  );
};

export default VerifyOtp;

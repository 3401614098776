import { Box, Grid, Divider, Typography, Button} from '@material-ui/core';
import React from 'react';
import { ExpandMore, Wifi, Done } from '@material-ui/icons';
import image1 from '../../assets/images/boat.webp';
import image2 from '../../assets/images/city.webp';
import image3 from '../../assets/images/obudu.png';
import image4 from '../../assets/images/tropicana.webp';
import image5 from '../../assets/images/fabric-sales-8.png';
import image6 from '../../assets/images/savannah.webp';
import image7 from '../../assets/images/Rectangle-133.png';
import image8 from '../../assets/images/slide1.jpg';
import image9 from '../../assets/images/slide2.png';
import image10 from '../../assets/images/slide3.jpg';
import Gallery from '../../components/Gallery/Gallery';
import AppLink from '../AppLink/AppLink';
import HotelRoomStyle from './HotelRoomStyle';

const images = [image1, image2, image3, image4, image5, image6, image7, image8, image9, image10];

export default function HotelRoom() {
  const classes = HotelRoomStyle();

  return (
    <Box className={classes.root}>
      <Grid container >
        {/* gallery */}
        <Grid item xs={12} sm={12} md={5}>
          <Gallery images={images} autoPlay />
        </Grid>

        <Grid item xs={12} sm={12} md={7}>
          <>
          <Grid container spacing={0} className={classes.container}>
            <Grid item xs={12} sm={7}>
              <Box className={classes.boxContainer}>
                <Box>
                  <Typography className={classes.name}>Gevora suits Hotels</Typography>
                  <Typography className={classes.boldText}>Executive room</Typography>
                </Box>
                <Box>
                  <Box className={classes.check}>
                    <Done  className={classes.done} />
                    <Typography className={classes.bold}>Free Wifi</Typography>
                  </Box>
                  <Box className={classes.check}>
                    <Done  className={classes.done} />
                    <Typography className={classes.bold}>Non refundable</Typography>
                  </Box>
                </Box>
                <Box className={classes.checks}>
                  <Typography className={classes.small}>1 Adult</Typography>
                  <Typography className={classes.small}>| 1 Kid (under 12years)</Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={5}>
            <Box className={classes.contain}>
              <Box component={'div'} className={classes.reviewCon}>
                  <Box className={classes.review}>
                      <Box className={classes.button}>
                          <Typography>8.1</Typography>
                          <Typography>Excellence</Typography>
                      </Box>
                      <Typography className={classes.smallText}>From 3000 Reviews</Typography>
                  </Box>
                  <Typography className={classes.amount}>₦36,000</Typography>
              </Box>
              <AppLink to="hotel-detail">
                  <Box component={'div'}>
                      <Button className={classes.btn} variant="contained" color="primary" fullWidth>
                          Book Now
                      </Button>
                  </Box>
              </AppLink>
            </Box>
          </Grid>
          </Grid>
          </>
          <Divider />
        </Grid>
          <Box>
            <Box className={classes.flexBox}>
              <Typography>Room size: 30 m²/323 ft²</Typography>
              <Typography><Wifi /> Free Wifi</Typography>
              <Typography>1 bed</Typography>
              <Typography>Non-smoking </Typography>
              <Typography>Separate bathroom</Typography>
              <Typography>Pool</Typography>
              <ExpandMore className={classes.icon} />
            </Box>
          </Box>
      </Grid>
    </Box>
  );
}

import {
  Avatar,
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListSubheader,
  Paper,
} from "@material-ui/core";
import {
  CheckCircle,
  ConfirmationNumber,
  FlightLand,
  FlightTakeoff,
  Mail,
  People,
} from "@material-ui/icons";
import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toDateTime, toNumberFormat } from "../../utilities/helpers";
import pageRoutes from "../../utilities/pageRoutes";
import {
  AppStore,
  FlightCombination,
  FlightModel,
  PaidBookingData,
} from "../../utilities/types";
import PaymentSuccessStyle from "./PaymentSuccessStyle";

type Props = {
  booking: PaidBookingData;
};

export default function PaymentSuccess({ booking }: Props) {
  const classes = PaymentSuccessStyle();
  const {
    user: { user },
  }: AppStore = useSelector((store: AppStore) => store);
  const combination: FlightCombination =
    booking.meta.FlightBookingResult.FlightBookingSummaryModel.FlightSummaryModel.FlightCombination;
  const models: FlightModel[] = combination.FlightModels;
  const { Adults, Children, Infants } = combination;
  const history = useHistory();

  return (
    <Paper elevation={0} className={classes.root}>
      <Chip
        color="primary"
        variant="outlined"
        label={"Your payment has been completed."}
        icon={<CheckCircle />}
        className={classes.paid}
      />
      <br />
      <br />
      <Grid container spacing={0}>
        <Grid item xs={12} sm={6}>
          <ListItem dense>
            <ListItemAvatar>
              <ConfirmationNumber />
            </ListItemAvatar>
            <ListItemText
              primary={booking.bookingId}
              secondary={"Booking reference"}
              secondaryTypographyProps={{ className: classes.bold }}
            />
          </ListItem>
        </Grid>
        <Grid item xs={12} sm={6}>
          <ListItem dense>
            <ListItemAvatar>
              <Mail />
            </ListItemAvatar>
            <ListItemText
              primary={user.email}
              secondary={"Confirmation sent to"}
              secondaryTypographyProps={{ className: classes.bold }}
            />
          </ListItem>
        </Grid>
        {models.map((model, index) => (
          <Grid item xs={12} sm={models.length > 1 ? 6 : 12} key={index}>
            <List
              subheader={
                <ListSubheader>
                  {index === 0 ? "Departing Flight" : "Returning Flight"}
                </ListSubheader>
              }
              dense
            >
              <ListItem>
                <ListItemAvatar>
                  <FlightTakeoff />
                </ListItemAvatar>
                <ListItemText
                  primary={model.DepartureName}
                  secondary={`${toDateTime(
                    model.DepartureTime,
                    true
                  )} • ${toDateTime(model.DepartureTime)}`}
                  secondaryTypographyProps={{ className: classes.bold }}
                />
                <ListItemAvatar>
                  <Avatar
                    sizes=""
                    src={model.AirlineLogoUrl}
                    alt={model.AirlineName}
                  />
                </ListItemAvatar>
              </ListItem>
              <Divider variant="inset" />
              <ListItem>
                <ListItemAvatar>
                  <FlightLand />
                </ListItemAvatar>
                <ListItemText
                  primary={model.ArrivalName}
                  secondary={`${toDateTime(
                    model.ArrivalTime,
                    true
                  )} • ${toDateTime(model.ArrivalTime)}`}
                  secondaryTypographyProps={{ className: classes.bold }}
                />
                <ListItemAvatar>
                  <Avatar
                    sizes=""
                    src={model.AirlineLogoUrl}
                    alt={model.AirlineName}
                  />
                </ListItemAvatar>
              </ListItem>
            </List>
          </Grid>
        ))}
        <Grid item xs={12} sm={6}>
          <ListItem>
            <ListItemAvatar>
              <People />
            </ListItemAvatar>
            <ListItemText
              primary={`${Adults ? "Adult x" + Adults : ""} ${
                Children ? " • Children x" + Children : ""
              } ${Infants ? " • Infants x" + Infants : ""}`}
              secondary={`${Adults + Children + Infants} Passengers`}
              secondaryTypographyProps={{ className: classes.bold }}
            />
          </ListItem>
        </Grid>
        <Grid item xs={12} sm={6}>
          <ListItem dense>
            <ListItemText
              primary={toNumberFormat(booking.amount, booking.currency)}
              secondary={"Booking Fee"}
              secondaryTypographyProps={{ className: classes.bold }}
              primaryTypographyProps={{ color: "primary", variant: "h6" }}
            />
          </ListItem>
        </Grid>
      </Grid>
      <br />
      <Box className={classes.containerFlex}>
        <Box>
          <Button
            onClick={() =>
              history.push(`${pageRoutes.Booking}/${booking.bookingId}`)
            }
            variant="outlined"
            color="primary"
          >
            View Booking
          </Button>
        </Box>
      </Box>
    </Paper>
  );
}

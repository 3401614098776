import { Box, Grid, Typography } from "@material-ui/core";
import React from "react";
import InnerContainer from "../../components/InnerContainer/InnerContainer";
import Layout from "../../components/Layout/Layout";
import Seo from "../../components/Seo/Seo";
import compliantStyles from "./compliantStyles";

const ComplaintsManagement = () => {
  const classes = compliantStyles()
  return (
    <Layout>
      <Seo title="complaints management policy" />
      <InnerContainer>
        <Box className={classes.root}>
          {/* policy */}
          <Grid className={classes.childOne} >
            <h1>1. <span className={classes.headerTitle}>ABOUT THIS POLICY</span></h1>
            <Typography variant="body1">
              As a responsible travel agent GMART Alliance Tours and Travels Limited (Travoofy) seeks to maintain and enhance our reputation of
              providing you with high quality products and services. We value
              complaints as they assist us to improve our products, services and
              customer service.
            </Typography>
            <Typography variant="body1">
              We are committed to being responsive to the needs and concerns of
              our customers or potential customers and to resolving your
              complaint as quickly as possible.
            </Typography>
          </Grid>
          {/* Policy Details */}
          <Grid>
            <h1>2. <span className={classes.headerTitle}>POLICY DETAILS</span></h1>
            <Grid item>
              <h3>2.1Definition of a complaint</h3>
              <Typography>

                For the purpose of this policy, a complaint is defined as an
                expression of dissatisfaction made by a customer to us which is
                related to our services, employees, or the handling of a
                complaint, where a response or resolution is explicitly or
                implicitly expected or legally required.
              </Typography>
            </Grid>
            {/*  */}
            <Grid item>
              <h3>2.2 How a complaint can be made</h3>
              <Typography>

                If you are dissatisfied with a travel or related service provided
                by us, you should in the first instance consider speaking
                directly with the staff member you have been dealing with. If
                you are uncomfortable with this or consider the relevant staff
                member is unable to address your concerns you can lodge a
                complaint with us through the following platforms:
              </Typography>

              <ul>
                <li>By telephone, </li>
                <li>By physical mail, </li>
                <li>By electronic mail or </li>
                <li>By speaking with us in person</li>
              </ul>
              <Typography>Our contact details are located on our website at <a href="https://travoofy.com/contact-us">https://travoofy.com/contact-us</a>
              </Typography>
              <Typography>
                If we receive your complaint verbally and we consider it
                appropriate, we may ask you to put your complaint in writing.
              </Typography>
              <Typography>
                Our complaints handling process is free of charge.
              </Typography>
            </Grid>
            {/*  */}
            <Grid item>
              <h3>2.3 Help when making a complaint</h3>
              <Typography>
                The person receiving or managing your complaint should
                provide you with any assistance you may need to make your
                complaint. However, if you consider you need further
                assistance, please inform us of this at the time you are lodging
                your complaint.
              </Typography>
            </Grid>
            {/*  */}
            <Grid item className={classes.spacingEle}>
              <h3>2.4 Feedback to customers</h3>
              <Typography>
                We are committed to resolving your issues at the first point of
                contact however, this will not be possible in all circumstances,
                in which case a more formal complaints process will be
                followed.
              </Typography>
            </Grid>
            {/* child 6 */}
            <Grid item className={classes.spacingEle}>
              <h3>2.5 Our six-point complaint process</h3>
              <Typography>
                <b>We acknowledge –</b>  we will acknowledge receipt of your
                complaint within 1 business day.
              </Typography>
              <Typography>
                <b>We review –</b>  we undertake an initial review of your complaint
                and determine what if any additional information or
                documentation may be required to complete an investigation.
              </Typography>
              <div className={classes.spacingEle}>
                <Typography>
                  <b>We investigate –</b>  within 5 business days of receiving your
                  complaint we will investigate your complaint objectively and
                  impartially. We will consider the information you have provided
                  to us, our actions in relation to your dealings with us and any
                  other information that could assist us in investigating your
                  complaint.
                </Typography>
                <Typography>
                  During the investigation of your complaint, we may need to seek
                  further clarification or documentation from you to assist us in
                  resolving your complaint.
                </Typography>
                <Typography>Where a third-party travel supplier such as a tour operator was
                  involved in your travel services, we may be required to speak
                  with them to fully investigate your complaint. </Typography>
                <Typography>We may be unable to complete our investigation within the 5
                  business days, if we are waiting for a response from you or a
                  third party which we have told you is required. In such
                  circumstances upon receipt of your clarification or
                  documentation we will indicate to you when we expect to
                  finalize your complaint. </Typography>
                <Typography>The outcome will be provided to you no later than 14 calendar
                  days after receiving the complaint. </Typography>
                <Typography>
                  <b>We respond –</b>    Following our investigation we will notify you of
                  our findings and any actions we may have taken in regard to
                  your complaint.
                </Typography>
                <Typography>
                  <b>We take action –</b>  where appropriate we amend our business
                  practices or policies.</Typography>
                <Typography>
                  <b> We record -</b>   we will record your complaint for continuous
                  improvement process and monitoring through regular review,
                  your personal information will be recorded in accordance with
                  relevant privacy legislation.
                </Typography>
                <Typography>
                  You have the right to make enquiries about the current status
                  of your complaint at any time by contacting us.
                </Typography>

              </div>
            </Grid>
            {/* child 2.6 */}
            <Grid>
              <h3>2.6 When you complain about any of our employees</h3>
              <Typography>
                If you complain about a member of our staff member, we will
                treat your complaint confidentially, impartially, and equally
                (giving equal treatment to all people).
              </Typography>

            </Grid>
            {/* child 2.7 */}
            <Grid>
              <h3>2.7 Complaints under investigation by a regular or law
                enforcement agency</h3>
              <div className={classes.spacingEle}>

                <Typography>
                  If your complaint is currently being investigated by a relevant
                  federal, state or territory consumer protection regulator or law
                  enforcement agency we may cease to take further action in
                  relation to your complaint pending finalization of their
                  investigation.
                </Typography>
                <Typography>
                  We will assist any agency with their investigations.
                </Typography>
              </div>
            </Grid>
            {/* child 2.8 */}
            <Grid>
              <h3>2.8 Our complaint escalation</h3>
              <div className={classes.spacingEle}>
                <Typography>
                  Where possible, we will attempt to resolve your complaint
                  at the first point of contact. If we are unable to resolve your
                  complaint at the first point of contact, we will undertake an
                  investigation of your complaint and provide you with our
                  findings.
                </Typography>
                <Typography>
                  If you are satisfied with our proposed decision or actions, we
                  will close your complaint and record the findings for our
                  continuous improvement program.
                </Typography>
                <Typography>
                  However, if you are not satisfied with our proposed decision
                  or actions, we will record this, and provide you with
                  information on how to escalate your complaint, to the
                  National Association of Nigeria Travel Agencies
                  (NANTA), for
                  external review. You may also want to contact the Nigerian
                  Civil Aviation Authority (NCAA).
                </Typography>
                <div>

                  <Typography>
                    Should you wish to speak to NANTA about your complaint
                    you can contact them in the following ways:
                  </Typography>
                  <ul>
                    <li>By completing a ‘Make a Complaint’ form online at
                       <a href="http://www.nanta.org.ng/ComplaintReport#"> nanta.org</a> </li>
                    <li>By telephone on - +2348119041402 , </li>
                    <li>By writing to them at:  National Association of Nigeria Travel Agencies
                      84 Ikorodu Road, Fadeyi Bus Stop Lagos, Nigeria</li>
                    <li>Email: info@nanta.org.ng</li>
                  </ul>
                </div>
              </div>
            </Grid>

            {/* 2.9 child */}
            <Grid item>
              <h3>2.9 <span className={classes.headerTitle}> Your rights under Nigeria Consumer Protection Law</span></h3>
              <Typography>You reserve the right to refer your complaint to the relevant Federal Competition and Consumer Protection Commission
                at any time.</Typography>

            </Grid>


          </Grid>
        </Box>
      </InnerContainer>
    </Layout>
  );
};

export default ComplaintsManagement;

import { makeStyles, Theme } from "@material-ui/core";

export default makeStyles((theme: Theme) => ({
    root: {
        marginTop: "calc(min(50vw / 5, 50px) + 10px)",
        marginBottom: "calc(min(50vw / 5, 50px) + 10px)",
        display: "flex",
        flexDirection: "column",
        gap: "40px",
        background: "white",
        // padding:'30px 40px',
        [theme.breakpoints.down('sm')]: {
            padding: '10px 10px',
        },
        [theme.breakpoints.up('sm')]: {
            padding: '30px 50px'
        },
        '& li': {
            color: 'black',
            marginLeft: '-14px',
            fontWeight: 'bold',

        },
        '& li::marker': {
            color: 'black',

        },
        '& li ul li ': {
            color: 'black',
            fontWeight: 'bold',
            listStyleType: 'lower-alpha',
        },
        '& li ul li::marker': {
            color: 'black',
        }

    },
    liContainers: {
        marginBottom: '20px',
        marginTop: '20px',

    },
    liItem: {
        marginTop: '10px',
        marginBottom: '20px',
        display: 'flex',
        flexDirection: 'column',
        gap: '10px'

    },
    table: {
        width: '100%',
        borderCollapse: 'collapse',
        border: '1px solid black',
        '& th': {
            border: '1px solid black',
            padding: '10px',
            textAlign: 'left',
            background: '#f2f2f2',

        },
        '& td': {
            border: '1px solid black',
            padding: '10px',
            textAlign: 'left',


        }

    },
    tableItem: {
        gap: '10px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start'
    }


}));
import { makeStyles, Theme } from '@material-ui/core';
import { dark } from '../../../utilities/helpers';
import { ThemeMode } from '../../../utilities/types';

const styles = (mode: ThemeMode) => makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    minHeight: '200px',
    paddingTop: theme.spacing(5),
    height: 'calc(100vw / 70)',
    backgroundColor: dark(mode) ? theme.palette.background.default : '#057FC3',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '350px',
    },
  },
  searchButtonCon: {
    marginTop: theme.spacing(1.5),
  },
  texts: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(3),
  },
  text: {
    fontWeight: 700,
    fontSize: '25px',
    paddingBottom: theme.spacing(3),
  },
  paper: {
    marginTop: theme.spacing(3),
    display: 'flex',
    gap: '40px',
    flexWrap: 'wrap',
    fontSize: '16px',
    padding: theme.spacing(3),
  }
}));

export default styles;

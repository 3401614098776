import { Avatar, Box, Typography }from '@material-ui/core';
import React from 'react';
import ReviewsStyle from './ReviewsStyle';


const posts = [
  {
    id: 1, 
    text: '8.1', 
    smallText: 'Excellent', 
    title: '“Great Guest Service”', 
    content: 'I had a nice time, the room I was checked into was neat and all necessary things were in place.',
    timeline: 'Abhishek  | 12 February 2022'
  },
  {
    id: 2, 
    text: '6.9', 
    smallText: 'Very Good', 
    title: '“Great Guest Service”', 
    content: 'I had a nice time, the room I was checked into was neat and all necessary things were in place. I had a nice time, the room I was checked into was neat and all necessary things were in place.    ',
    timeline: 'Abhishek  | 12 February 2022'
  },
  {
    id: 3, 
    text: '8.9', 
    smallText: 'Very Good', 
    title: '“Great Guest Service”', 
    content: 'I had a nice time, the room I was checked into was neat and all necessary things were in place. I had a nice time, the room I was checked into was neat and all necessary things were in place.    ',
    timeline: 'Abhishek  | 12 February 2022'
  },
];

export default function Reviews() {
  const classes = ReviewsStyle();

  return (
    <Box className={classes.root}>
      {posts.map((item, i) => (
      <Box className={classes.context}>
              <>
              <Box className={classes.item}>
                <Box className={classes.items}>
                  <Avatar className={classes.avatar2} key={i}>
                  <Typography className={classes.text2}>{item.text}</Typography></Avatar>
                  <Typography className={classes.text}>{item.smallText}</Typography>
                </Box>
                <Box>
                  <Typography className={classes.excellent}>{item.title}</Typography>
                  <Typography className={classes.guest}>{item.content}</Typography>
                  <Typography className={classes.guest}>{item.timeline}</Typography>
                </Box>
              </Box>
              </>
      </Box>
      ))}
    </Box>
  );
}

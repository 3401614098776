import { ActionTypes, FlightData } from "../../utilities/types";
import { FlightDataActionType } from "./actions";

const defaultState: FlightData | null = null;

export function FlightDataReducer(
  state = defaultState,
  action: FlightDataActionType
) {
  switch (action.type) {
    case ActionTypes.SET_FLIGHT_DATA:
      return {...action.payload};
    case ActionTypes.CLEAR_FLIGHT_DATA:
      return null;
    default:
      return {...state};
  }
}

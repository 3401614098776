import { Box, Grid, Typography } from "@material-ui/core";
import { AirplanemodeInactive } from "@material-ui/icons";
import Pagination from "@material-ui/lab/Pagination";
import Skeleton from "@material-ui/lab/Skeleton";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import BookSearch from "../../components/BookSearch/BookSearch";
import BreadCrumb from "../../components/BreadCrumb/BreadCrumb";
import FlightSearch from "../../components/FlightSearch/FlightSearch";
import InnerContainer from "../../components/InnerContainer/InnerContainer";
import Layout from "../../components/Layout/Layout";
import Seo from "../../components/Seo/Seo";
import SideBar from "../../components/SideBar/SideBar";
import SortTab from "../../components/SortTab/SortTab";
import api from "../../utilities/api";
import {
  flightProcessing,
  getCrumb,
  getFlightData,
  gtagEvent,
  sorters,
} from "../../utilities/helpers";
import pageRoutes from "../../utilities/pageRoutes";
import {
  AnyObject,
  AppStore,
  FlightObject,
  FlightSearchProps,
} from "../../utilities/types";
import SearchFlightStyle from "./SearchFlightStyle";

export default function SearchFlight() {
  const { mode } = useSelector((store: AppStore) => store.themeSwitch);
  const classes = SearchFlightStyle(mode)();
  const { flightProps }: AppStore = useSelector((store: AppStore) => store);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [flightResult, setFlightResult] = useState<FlightObject[]>([]);
  const mounted = useRef(true);
  const [sort, setSort] = useState<string>(Object.keys(sorters)[0]);
  const [priceRange, setPriceRange] = useState<number[]>([0, 1]);
  const [stops, setStops] = useState<number[]>([0, 0, 0]);
  const [airlines, setAirlines] = useState<string[]>([]);
  const [departureTime, setDepartureTime] = useState<{ [key: string]: number }>(
    {}
  );
  const [arrivalTime, setArrivalTime] = useState<{ [key: string]: number }>({});
  const [page, setPage] = useState<number>(1);
  const [activePrices, setActivePrices] = useState<{
    min: number;
    max: number;
  }>();
  const [activeStops, setActiveStops] = useState<AnyObject<boolean>>();
  const [activeDeparture, setActiveDeparture] = useState<AnyObject<boolean>>();
  const [activeArrival, setActiveArrival] = useState<AnyObject<boolean>>();
  const [activeAirlines, setActiveAirlines] = useState<AnyObject<boolean>>();
  const pageLimit = 10;

  const search = (data: FlightSearchProps) => {
    setLoading(true);
    gtagEvent("level_start", { level_name: "Flight Search" });
    api
      .post("/flight/search", data)
      .then(({ status, data }) => {
        if (mounted.current && status) {
          setFlightResult(data);
          setPage(1);
          if ((data as any[]).length < 1) {
            gtagEvent("level_end", {
              level_name: "Flight Search",
              success: false,
            });
          } else {
            const gd = (data as FlightObject[]).map((i, j) => {
              return {
                item_id: j,
                item_name: `${i.FlightCombination.FlightModels[0].DepartureName} - ${i.FlightCombination.FlightModels[0].ArrivalName}`,
                item_brand: i.FlightCombination.FlightModels[0].AirlineName,
                currency: i.FlightCombination.Price.CurrencyCode,
                item_category: "Flight",
                price: i.FlightCombination.Price.Amount,
                item_variant:
                  i.FlightCombination.FlightModels.length > 1
                    ? "Return"
                    : "Oneway",
              };
            });
            gtagEvent("view_item_list", {
              item_list_id: "flight_search",
              item_list_name: "Flight Search",
              items: [...gd],
            });
          }
        }
      })
      .finally(() => (mounted.current ? setLoading(false) : null));
  };

  useEffect(() => {
    if (flightResult.length > 0) {
      const { _priceRange, _stops, _airlines, _departureTime, _arrivalTime } =
        getFlightData(flightResult);

      setPriceRange(_priceRange);
      setStops(_stops);
      setAirlines(_airlines);
      setDepartureTime(_departureTime);
      setArrivalTime(_arrivalTime);
    }
  }, [flightResult]);

  useEffect(() => {
    mounted.current = true;
    if (!flightProps.Itineraries[0]) {
      history.push(pageRoutes.Flight);
    } else {
      search(flightProps);
    }

    return () => {
      mounted.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { results, filterLength } = flightProcessing(
    flightResult,
    page,
    pageLimit,
    sort,
    activePrices,
    activeStops,
    activeDeparture,
    activeArrival,
    activeAirlines
  );

  return (
    <Layout>
      <Seo title={getCrumb(flightProps)} />
      <Box className={classes.root}>
        <Box className={classes.background}>
          <InnerContainer>
            <FlightSearch
              hideLabel
              textToggle
              action={search}
              loading={loading}
            />
          </InnerContainer>
        </Box>
      </Box>
      <InnerContainer className={classes.innerContainer}>
        {!loading && flightResult.length === 0 ? (
          <Box className={classes.emptyCon}>
            <AirplanemodeInactive className={classes.emptyIcon} />
            <Typography variant="h5">We couldn't find your trip.</Typography>
            <br />
            <Typography>
              We've searched more than 400 airlines that we sell, and couldn't
              find any flights
            </Typography>
            <Typography>Checkout other flights</Typography>
          </Box>
        ) : (
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={3}>
              {loading ? (
                <Skeleton className={classes.loadingText} animation="wave" />
              ) : (
                <BreadCrumb
                  paths={["Home", "Flight search", getCrumb(flightProps)]}
                />
              )}
              {loading ? (
                <Skeleton variant="rect" height={420} animation="wave" />
              ) : (
                <SideBar
                  priceRange={priceRange}
                  flightStops={stops}
                  airlines={airlines}
                  departureTime={departureTime}
                  arrivalTime={arrivalTime}
                  onPriceChange={setActivePrices}
                  onStopChange={setActiveStops}
                  onDepartureChange={setActiveDeparture}
                  onArrivalChange={setActiveArrival}
                  onAirlineChange={setActiveAirlines}
                  currency={flightProps.TargetCurrency}
                />
              )}
            </Grid>

            <Grid item xs={12} sm={12} md={9}>
              <Box>
                {loading ? (
                  <Skeleton
                    width={300}
                    className={classes.loadingText}
                    animation="wave"
                  />
                ) : flightResult.length === filterLength ? (
                  <Typography className={classes.firstText}>
                    {flightResult.length} Flight found searching for "
                    {getCrumb(flightProps)}"
                  </Typography>
                ) : (
                  <Typography className={classes.firstText}>
                    Showing: {filterLength} of {flightResult.length} Flight
                    found searching for "{getCrumb(flightProps)}"
                  </Typography>
                )}

                <Box className={classes.tabs}>
                  {loading ? (
                    <Skeleton height={50} variant="rect" animation="wave" />
                  ) : (
                    <SortTab
                      items={Object.keys(sorters)}
                      onChange={({ value }) => setSort(value)}
                    />
                  )}
                </Box>
                {loading
                  ? [1, 2, 3].map((i) => (
                      <Box component={"span"} key={i}>
                        <Skeleton
                          height={100}
                          variant={"rect"}
                          animation="wave"
                        />
                        <br />
                      </Box>
                    ))
                  : results.map((item, index) => (
                      <BookSearch key={index} flightDetails={item} />
                    ))}
                {loading ? (
                  <Skeleton
                    style={{ margin: "auto" }}
                    width={300}
                    animation="wave"
                  />
                ) : flightResult.length > pageLimit ? (
                  <Pagination
                    className={classes.pagination}
                    count={Math.ceil(filterLength / pageLimit)}
                    color="primary"
                    page={page}
                    onChange={(_, val) => setPage(val)}
                    showFirstButton
                    showLastButton
                  />
                ) : null}
              </Box>
            </Grid>
          </Grid>
        )}
      </InnerContainer>
    </Layout>
  );
}

import React, { ReactNode } from 'react';
import Footer from './Footer/Footer';
import MainHeader from './MainHeader/MainHeader';

type Props = {
  overlayHeader?: boolean;
  children: ReactNode;
};

export default function Layout({ overlayHeader, children }: Props) {
  return (
    <>
      <MainHeader overlay={overlayHeader} />
      {children}
      <Footer />
    </>
  );
}

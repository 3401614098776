import { makeStyles, Theme } from "@material-ui/core";

export default makeStyles((theme: Theme) => ({
  root: {
    borderRadius: 0,
    paddingBottom: theme.spacing(7),
  },
  paper: {
    padding: theme.spacing(3),
  },
  paper2: {
    padding: theme.spacing(3),
    marginTop: theme.spacing(3),
  },
  paper3: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  firstText: {
    fontSize: "14px",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(3),
  },
  container: {
    marginBottom: theme.spacing(3),
  },
  item: {
    display: "flex",
    gap: 70,
  },
  content: {
    display: "flex",
    marginTop: theme.spacing(2),
  },
  box: {
    margin: theme.spacing(3, 0, 0),
  },
  icon: {
    color: theme.palette.primary.main,
    marginRight: theme.spacing(1),
  },
  largeText: {
    paddingBottom: theme.spacing(2),
    fontSize: "25px",
    textTransform: 'capitalize',
  },
  amount: {
    color: theme.palette.primary.main,
    fontSize: "20px",
    display: "inline-block",
    marginBottom: "-10px",
  },
  oldPrice: {
    fontSize: "12px",
    lineHeight: "15.6px",
    textDecoration: "line-through",
  },
  unit: {
    fontSize: "12px",
    paddingBottom: theme.spacing(7),
    marginLeft: theme.spacing(1),
  },
  secondText: {
  },
  group: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  slideBox: {
    aspectRatio: "1.5",
    width: "calc(20% - 8px)",
    height: "auto",
  },
  gap: {
    paddingTop: theme.spacing(10),
  },
}));

import { Box, Button, Divider, Tab, Tabs } from "@material-ui/core";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Action_SetFlightData } from "../../reduxStore/flightData/actions";
import { gtagEvent, toNumberFormat } from "../../utilities/helpers";
import pageRoutes from "../../utilities/pageRoutes";
import { FlightObject } from "../../utilities/types";
import FlightCard from "../FlightCard/FlightCard";
import FlightToggleStyle from "./FlightToggleStyle";

type Props = {
  flight: FlightObject;
};

export default function FlightToggle({ flight }: Props) {
  const classes = FlightToggleStyle();
  const [tab, setTab] = useState<number>(0);
  const history = useHistory();
  const dispatch = useDispatch();

  return (
    <Box className={classes.root}>
      <Tabs
        className={classes.tabCon}
        value={tab}
        onChange={(_e, val) => setTab(val)}
      >
        <Tab className={classes.tab} label={"Flight"} />
        {/* <Tab className={classes.tab} label={'Rules'} /> */}
      </Tabs>
      <Divider />
      <Box component={"div"} className={classes.inner}>
        <Box>
          {
            tab === 0 ? <FlightCard details={flight} /> : null // <FlightOption rules={flight.FlightCombination.FareRules} />
          }
        </Box>
      </Box>
      <Button
        className={classes.button}
        variant="contained"
        onClick={() => {
          gtagEvent("select_item", {
            item_list_id: "flight_search",
            item_list_name: "Flight Search",
            items: [
              {
                item_name: `${flight.FlightCombination.FlightModels[0].DepartureName} - ${flight.FlightCombination.FlightModels[0].ArrivalName}`,
                item_brand:
                  flight.FlightCombination.FlightModels[0].AirlineName,
                currency: flight.FlightCombination.Price.CurrencyCode,
                item_category: "Flight",
                price: flight.FlightCombination.Price.Amount,
                item_variant:
                  flight.FlightCombination.FlightModels.length > 1
                    ? "Return"
                    : "Oneway",
              },
            ],
          });
          gtagEvent("level_end", {
            level_name: "Flight Search",
            success: true,
          });
          dispatch(Action_SetFlightData({ FlightObject: flight }));
          history.push(pageRoutes.Booking);
        }}
        color="primary"
        fullWidth
      >
        Book Now for{" "}
        {toNumberFormat(
          flight.FlightCombination.Price.Amount,
          flight.FlightCombination.Price.CurrencyCode
        )}
      </Button>
    </Box>
  );
}

import { makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) => ({
  root: {},
  text: {
    fontWeight: 700,
    marginBottom: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(4),
    color: theme.palette.text.primary,
  },
}));
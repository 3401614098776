import { makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
  },
  textBox: {
    padding: `${theme.spacing(3)}px ${theme.spacing(7)}px`,
  },
  text: {
    fontSize: '20px',
    fontWeight: 700,
  },
  services: {
    display: 'flex',
    gap: '5px',
    justifyContent: 'space-between',
    padding: `${theme.spacing(3)}px ${theme.spacing(5)}px`,
  },
  letter: {
    width: '100%',
    margin: theme.spacing(2)
  }
}));

import {
  Box,
  Button,
  Divider,
  Grid,
  Hidden,
  Paper,
  Typography,
} from "@material-ui/core";
import { ErrorOutline, Phone } from "@material-ui/icons";
import React from "react";
import { useHistory } from "react-router-dom";
import routes from "../../utilities/pageRoutes";
import InnerContainer from "../InnerContainer/InnerContainer";
import CustomerSupportStyle from "./CustomerSupportStyle";

export default function CustomerSupport() {
  const classes = CustomerSupportStyle();
  const history = useHistory();

  return (
    <InnerContainer>
      <Paper className={classes.root} elevation={0}>
        <Typography variant="h6">Customer Support</Typography>
        <Grid container spacing={3} className={classes.container}>
          <Grid item sm={12} md={5} className={classes.firstBox}>
            <Box className={classes.icon}>
              <Phone />
            </Box>
            <Box>
              <Typography className={classes.texts}>Manage booking</Typography>
              <Typography className={classes.small}>
                Get in touch for changes, cancellations and assistance
              </Typography>
              <Button
                onClick={() => {
                  history.push(routes.ContactUs);
                }}
                className={classes.link}
                variant="text"
                color="primary"
                size="small"
              >
                Contact us
              </Button>
            </Box>
          </Grid>
          <Grid item sm={"auto"} md={2} className={classes.divider}>
            <Hidden smDown>
              <Divider flexItem orientation="vertical" />
            </Hidden>
          </Grid>
          <Grid item sm={12} md={5} className={classes.firstBox}>
            <Box className={classes.icon}>
              <ErrorOutline />
            </Box>
            <Box>
              <Typography className={classes.texts}>
                Frequently asked questions
              </Typography>
              <Typography className={classes.small}>
                Get in touch for changes, cancellations and assistance
              </Typography>
              <Button
                className={classes.link}
                variant="text"
                color="primary"
                size="small"
              >
                FAQ
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </InnerContainer>
  );
}

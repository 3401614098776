import { makeStyles, Theme } from "@material-ui/core";

export default makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3, 0),
  },
  formContainer: {
    display: "flex",
    alignItems: "flex-end",
  },
  stepperControl: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
}));

import {
  Avatar,
  Box,
  Button,
  Divider,
  Grow,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Menu as Toggle,
  MenuItem,
  Typography,
} from "@material-ui/core";
import {
  AccountCircle,
  Menu,
  MenuOpen,
  PowerSettingsNew,
} from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Action_OpenAuthModal } from "../../../reduxStore/authModal/actions";
import { Action_RemoveUser } from "../../../reduxStore/user/actions";
import { mainMenus } from "../../../utilities/menuItems";
import { AppStore, ModalForms, ThemeMode } from "../../../utilities/types";
import AppLink from "../../AppLink/AppLink";
import InnerContainer from "../../InnerContainer/InnerContainer";
import SupportMenu from "../../SupportNavMenu/SupportMenu";
import MainHeaderStyle, { MobileMenu } from "./MainHeaderStyle";

const getLogo = (mode: ThemeMode, overScroll: boolean, overlay?: boolean) => {
  if (mode === "light") {
    return overlay && !overScroll
      ? require("../../../assets/images/logo-white.png")
      : require("../../../assets/images/logo.png");
  }
  return require("../../../assets/images/logo-white.png");
};

type Props = {
  overlay?: boolean;
};

const portalUrl = process.env.REACT_APP_PORTAL_URL || "";

export default function MainHeader({ overlay }: Props) {
  const {
    themeSwitch: { mode },
    user: { token, user },
  }: AppStore = useSelector((store: AppStore) => store);
  const [overScroll, setOverScroll] = useState(false);
  const classes = MainHeaderStyle(overlay, overScroll)();
  const styles = MobileMenu()();
  const logo = getLogo(mode, overScroll, overlay);
  const threshold = 100;
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openAccount = Boolean(anchorEl);

  const toggle = () => {
    setOpen((prev) => !prev);
  };

  const onScroll = () => {
    const scrollY = window.scrollY;
    if (scrollY > threshold) {
      setOverScroll(true);
    }
    if (scrollY < threshold) {
      setOverScroll(false);
    }
  };

  useEffect(() => {
    document.addEventListener("scroll", onScroll);
    return () => {
      document.removeEventListener("scroll", onScroll);
    };
  }, []);

  const getVariety = (color: ThemeMode): any => {
    if (color === "light") {
      return overlay && !overScroll ? "contained" : "outlined";
    }
    return "contained";
  };

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box component={"header"} className={classes.root}>
      <InnerContainer className={classes.innerCon}>
        <AppLink to={"/"}>
          <img className={classes.logo} height={"40px"} src={logo} alt="logo" />
        </AppLink>
        <Box
          sx={{ display: ["none", "none", "flex"] }}
          component={"div"}
          className={classes.navCon}
        >
          <Box className={classes.navContent} component={"nav"}>

            {mainMenus.map((item, index) => (
              <div key={index}>
                <Button
                  className={classes.menuItem}
                  key={index}
                  component={Link}

                  to={item.link}
                >
                  {item.name}
                </Button>
              </div>
            ))}
            <SupportMenu classStyle={classes.menuItem} type="navBtn" />
          </Box>
          {!token ? (
            <Box className={classes.navContent} component={"div"}>
              <Button
                size={"small"}
                className={`${classes.navButton} ${classes.signIn}`}
                onClick={() => {
                  dispatch(Action_OpenAuthModal(ModalForms.SignIn));
                }}
                variant={getVariety(mode)}
              >
                Sign In
              </Button>
              <Button
                size={"small"}
                className={classes.navButton}
                onClick={() => {
                  dispatch(Action_OpenAuthModal(ModalForms.SignUp));
                }}
                variant={"contained"}
                color={"primary"}
              >
                Sign Up
              </Button>
            </Box>
          ) : (
            <Box className={classes.navContent} component={"div"}>
              <div>
                <IconButton
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  color="inherit"
                  className={classes.but}
                >
                  <Avatar className={classes.avatar}>
                    {user.names
                      .split(" ", 2)
                      .map((n) => n[0].toUpperCase())
                      .join("")}
                  </Avatar>

                </IconButton>
              </div>
              <Toggle
                id="menu-appbar"
                className={classes.menu}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={openAccount}
                onClose={handleClose}
                TransitionComponent={Grow}
              >
                <MenuItem disabled>
                  <Typography variant="subtitle2">
                    Hello! {user.names.split(" ")[0]}
                  </Typography>
                </MenuItem>
                <Divider />
                <MenuItem
                  component={"a"}
                  target="_blank"
                  href={portalUrl}
                  onClick={handleClose}
                >
                  <ListItemIcon>
                    <AccountCircle />
                  </ListItemIcon>
                  <Typography variant="subtitle2">Account</Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    dispatch(Action_RemoveUser());
                    handleClose();
                  }}
                >
                  <ListItemIcon>
                    <PowerSettingsNew color={"error"} />
                  </ListItemIcon>
                  <Typography color={"error"} variant="subtitle2">
                    Sign out
                  </Typography>
                </MenuItem>
              </Toggle>
            </Box>
          )}
        </Box>
        <Box component={"div"} sx={{ display: ["block", "block", "none"] }}>
          <IconButton
            color={"secondary"}
            className={classes.toggleButton}
            onClick={toggle}
          >
            {open ? <MenuOpen children={mainMenus} /> : <Menu />}
          </IconButton>
          <Grow in={open}>
            <Box className={styles.root}>
              <InnerContainer>
                <List disablePadding>
                  {mainMenus.map((item, index) => (
                    <span key={index}>
                      <ListItem
                        component={Link}
                        to={item.link}
                        button
                      >
                        <ListItemText primary={item.name} />
                      </ListItem>
                      <Divider />
                    </span>
                  ))}
                  <SupportMenu type="list" />
                  {!token ? (
                    <>
                      <br />
                      <Box className={classes.navContent} component={"div"}>
                        <Button
                          className={`${classes.navButton} ${classes.signIn}`}
                          onClick={() => {
                            setOpen(false);
                            dispatch(Action_OpenAuthModal(ModalForms.SignIn));
                          }}
                          variant={getVariety(mode)}
                        >
                          Sign In
                        </Button>
                        <Button
                          className={classes.navButton}
                          onClick={() => {
                            setOpen(false);
                            dispatch(Action_OpenAuthModal(ModalForms.SignUp));
                          }}
                          variant={"contained"}
                          color={"primary"}
                        >
                          Sign Up
                        </Button>
                      </Box>
                      <br />
                    </>
                  ) : (
                    <>
                      <ListItem
                        button
                        component={"a"}
                        target="_blank"
                        href={portalUrl}
                        onClick={() => setOpen(false)}
                      >
                        <ListItemAvatar>
                          <Avatar className={classes.avatar}>
                            {user.names
                              .split(" ", 2)
                              .map((n) => n[0].toUpperCase())
                              .join("")}
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={`Hello! ${user.names.split(" ")[0]}`}
                        />
                        <ListItemSecondaryAction>
                          <IconButton
                            edge="end"
                            onClick={() => {
                              setOpen(false);
                              dispatch(Action_RemoveUser());
                            }}
                          >
                            <PowerSettingsNew color={"error"} />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                      <br />
                    </>
                  )}
                </List>
              </InnerContainer>
            </Box>
          </Grow>
        </Box>
      </InnerContainer >
    </Box >
  );
}

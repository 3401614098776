import { makeStyles, Theme } from '@material-ui/core/styles';


export const useVisaStyles = makeStyles((theme: Theme) => ({
    root: {
        padding: '20px',
        backgroundColor: 'white',
        fontSize: '14px',

    },
    formHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: '1px solid #002C43',
        paddingBottom: '10px',

    },
    main: {
        justifyContent: 'space-between',
        flexDirection: 'column',
        marginTop: '30px',
    },
    alert: {
        flexDirection: 'column',
        gridGap: theme.spacing(2)
    },
    paper: {
        padding: theme.spacing(1),
        textAlign: 'right',
        color: theme.palette.text.secondary,
        marginBottom: theme.spacing(1),
        flexWrap: 'nowrap',

    },
    titleOne: {
        flexDirection: 'row',

    },
    titleOneChild: {
        flexWrap: 'nowrap',

    },
    titleTwo: {
        flexDirection: 'row',

    },
    titleTwoChild: {
        flexWrap: 'nowrap',

    },
    titleThree: {
        flexDirection: 'row',
        alignItems: 'center',

    },
    titleFour: {
        flexDirection: 'row',
        alignItems: 'flex-end !important',
        justifyContent: 'center',
    },
    fileUpload: {
        marginBottom: theme.spacing(1.2),
    },
    contacts: {
        flexDirection: 'row',
        marginTop: theme.spacing(12),
        padding: theme.spacing(4, 0)
    },
    contactHeader: {
        marginBottom: theme.spacing(4),
    },
    contactChild: {

    },
    contactOne: {

    },
    contactTwo: {

    },
    contactThree: {

    },
    employment: {
        flexDirection: 'column',

    },
    radios: {
        flexDirection: 'row',
        display: 'flex',
        marginTop: theme.spacing(2),
        
         [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
         }
        
    },
    radioBtn: {
        flexDirection: 'column',
    }
    ,
    }))
import {makeStyles} from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';




 const styles= makeStyles((theme: Theme) => ({
    root: {
            gap: theme.spacing(4),
            padding:'20px',

    },
    formDetails:{
        flexDirection: 'column',
    
    },
    radioGroup:{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
       
    },
    title:{
        fontSize: '1.2rem',
    }
}));

export default styles
import Storage from '../../utilities/Storage';
import { ActionTypes, StorageKeys } from "../../utilities/types";
import { AirportObject } from "./../../utilities/types";

export type AirportActionType = {
  type: ActionTypes.SET_AIRPORT;
  payload: AirportObject[];
};

export function Action_SetAirport(payload: AirportObject[]): AirportActionType {
  Storage.setItem(StorageKeys.Airports, payload);
  return {
    type: ActionTypes.SET_AIRPORT,
    payload: [...payload],
  };
}

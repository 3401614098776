import {
  Grid,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
} from "@material-ui/core";
import {
  EmailOutlined,
  PersonPinCircleOutlined,
  Phone,
  WhatsApp,
} from "@material-ui/icons";
import InnerContainer from "../../components/InnerContainer/InnerContainer";
import Layout from "../../components/Layout/Layout";
import Seo from "../../components/Seo/Seo";
import { gtagEvent } from "../../utilities/helpers";
import ContactForm from "./ContactForm";
import ContactStyle from "./ContactStyle";

function Contact() {
  const classes = ContactStyle();
  return (
    <Layout>
      <Seo title={"Contact Us"} />
      <InnerContainer>
        <br />
        <Typography variant="h5">Contact Us</Typography>
        <br />
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={6} lg={8}>
            <Paper elevation={0} className={classes.root}>
              <Typography>
                Travoofy visa team is made up of seasoned, specialized and
                experienced experts in visa processing.
              </Typography>
              <br />
              <Typography>
                Our process includes profiling, helping you to complete your
                visa application forms, vetting documents, getting appointment
                dates, conducting pre-interview session where applicable and
                making sure you have 99% chances of getting the visa.
              </Typography>
              <br />
              <Typography>
                We do not encourage immigration defaults and kindly note that
                issuance of visas is at the discretion of the embassy.
              </Typography>
              <br />
            </Paper>

            <Paper elevation={0} className={classes.paper3}>
              <Typography className={classes.text} variant="subtitle2">
                Contact Details
              </Typography>
              <br />
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={12} lg={4}>
                  <ListItem
                    button
                    dense
                    onClick={() => {
                      gtagEvent("contact_us", { name: "Email Address" });
                      window.location.assign("mailto:bookings@travoofy.com");
                    }}
                  >
                    <ListItemIcon>
                      <EmailOutlined color="primary" fontSize="large" />
                    </ListItemIcon>
                    <ListItemText
                      primary="Email"
                      secondary="bookings@travoofy.com"
                      primaryTypographyProps={{
                        style: { fontWeight: "bold" },
                      }}
                    />
                  </ListItem>
                </Grid>
                <Grid item xs={12} sm={6} md={12} lg={4}>
                  <ListItem
                    button
                    dense
                    onClick={() => {
                      gtagEvent("contact_us", { name: "Phone Call" });
                      window.location.assign("tel:018880305");
                    }}
                  >
                    <ListItemIcon>
                      <Phone color="primary" fontSize="large" />
                    </ListItemIcon>
                    <ListItemText
                      primary="Phone"
                      secondary="01 888-0305"
                      primaryTypographyProps={{
                        style: { fontWeight: "bold" },
                      }}
                    />
                  </ListItem>
                </Grid>
                <Grid item xs={12} sm={6} md={12} lg={4}>

                  <ListItem
                    button
                    dense
                    onClick={() =>
                      window.location.assign("https://wa.me/2348120977653")
                    }
                  >
                    <ListItemIcon>
                      <WhatsApp color="primary" fontSize="large" />
                    </ListItemIcon>
                    <ListItemText
                      primary="WhatsApp"
                      secondary="+2348120977653"
                      primaryTypographyProps={{
                        style: { fontWeight: "bold" },
                      }}
                    />
                  </ListItem>
                </Grid>
                <Grid item xs={12} sm={6} md={12}>

                  <ListItem
                    button
                    dense
                    onClick={() => {
                      gtagEvent("contact_us", { name: "WhatsApp" });
                      window.location.assign("https://wa.me/2348120977653");
                    }}
                  >
                    <ListItemIcon>
                      <WhatsApp color="primary" fontSize="large" />
                    </ListItemIcon>
                    <ListItemText

                      primary="WhatsApp"
                      secondary="+2348120977653"

                      primaryTypographyProps={{
                        style: { fontWeight: "bold" },
                      }}
                    />
                  </ListItem>
                </Grid>

              </Grid>
            </Paper>
            <Grid item xs={12} sm={12} md={12} lg={12}>
            <Paper elevation={0} className={classes.paper3s}>
              <Grid item xs={12} sm={6} md={12}>
                <ListItem
                  button
                  dense
                  onClick={() => {
                    gtagEvent("contact_us", { name: "Google Map Address" });
                    window.location.assign(
                      "https://goo.gl/maps/f9HzqZeCrhVMzwmn8"
                    );
                  }}
                >
                  <ListItemIcon>
                    <PersonPinCircleOutlined
                      color="primary"
                      fontSize="large"
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary="Lagos Address"
                    secondary="40a Udeco Medical Road, Chevy View Estate, Lekki Lagos, Nigeria."
                    primaryTypographyProps={{
                      style: { fontWeight: "bold" },
                    }}
                  />
                </ListItem>
              </Grid>
              {/* contact address */}
              <Grid item xs={12} sm={6} md={12}>
                <ListItem
                  button
                  dense
                  onClick={() => {
                    gtagEvent("contact_us", { name: "Google Map Address" });
                    window.location.assign(
                      "https://goo.gl/maps/kpnjx26u9M5ZRHUX6"
                    );
                  }}
                >
                  <ListItemIcon>
                    <PersonPinCircleOutlined
                      color="primary"
                      fontSize="large"
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary="Abuja Address"
                    secondary="Plot 502, Dalaba Street, Novare mall, Wuse Zone 5, Abuja."
                    primaryTypographyProps={{
                      style: { fontWeight: "bold" },
                    }}
                  />
                </ListItem>
              </Grid>
              <Grid item xs={12} sm={6} md={12}>
                <ListItem
                  button
                  dense
                  onClick={() => {
                    gtagEvent("contact_us", { name: "Google Map Address" });
                    window.location.assign(
                      "https://goo.gl/maps/pt5ZCK2fG3LBHtw28"
                    );
                  }}
                >
                  <ListItemIcon>
                    <PersonPinCircleOutlined
                      color="primary"
                      fontSize="large"
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary="Port Harcourt Address"
                    secondary="Xorint Towers,
                      7, Okuru Road opposite Golf estate Gate,
                      Trans-amadi ,Port Harcourt"
                    primaryTypographyProps={{
                      style: { fontWeight: "bold" },
                    }}
                  />
                </ListItem>
              </Grid>
            </Paper>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <ContactForm />
          </Grid>
        </Grid>
      </InnerContainer>
      <br />
      <br />
      {/* <iframe
        title="Travoofy"
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1982.3066404031438!2d3.526388699336241!3d6.443675464648769!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103bf6f15243ffff%3A0xc0a6022cb86d23fe!2sGmart%20allance%20Ltd.!5e0!3m2!1sen!2sng!4v1656956811033!5m2!1sen!2sng"
        width="800"
        height="600"
        style={{ border: 0, width: "100%", height: "100vh" }}
        allowFullScreen
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe> */}
    </Layout>
  );
}

export default Contact;

import React from "react";
import { Box, Grid } from "@material-ui/core";
import FormInput from "../../../Form/FormInput/FormInput";
import useVisaEmployed from "./VisaEmployOrUnemployment.style";
import { useFormikContext } from "formik";

type Props = {
    employedOccupation: string;
    employersName: string;
    employersPhoneNumber: string;
    employersEmail: string;
}
export const VisaEmployed = () => {
    const { values, handleChange, touched, errors } = useFormikContext<Props>();
    const classes = useVisaEmployed();
    return (
        <Box >
            <Grid container className={classes.root} >
                <Grid item container className={classes.inputSpace} >
                    <Grid item xs={12} sm={5} md={5} >
                        <FormInput
                            label="Occupation *"
                            placeholder="Occupation"
                            name="employedOccupation"
                            type='text'
                            handleChange={handleChange}
                            value={values.employedOccupation}
                            error={touched.employedOccupation && Boolean(errors.employedOccupation)}
                            helperText={touched.employedOccupation && errors.employedOccupation}

                        />
                    </Grid>
                    <Grid item xs={12} sm={5} md={5}>
                        <FormInput
                            label="Employer's Name *"
                            placeholder="Employer's Name"
                            name="employersName"
                            type='text'
                            handleChange={handleChange}
                            value={values.employersName}
                            error={touched.employersName && Boolean(errors.employersName)}
                            helperText={touched.employersName && errors.employersName}

                        />
                    </Grid>
                </Grid>
                <Grid item container className={classes.inputSpace} >
                    <Grid item xs={12} sm={5} md={5} >
                        <FormInput
                            label="Employer's Phone Number  *"
                            placeholder="Employer's Phone Number "
                            name="employersPhoneNumber"
                            type='tel'
                            handleChange={handleChange}
                            value={values.employersPhoneNumber}
                            error={touched.employersPhoneNumber && Boolean(errors.employersPhoneNumber)}
                            helperText={touched.employersPhoneNumber && errors.employersPhoneNumber}

                        />
                    </Grid>
                    <Grid item xs={12} sm={5} md={5}>
                        <FormInput
                            label="Employer's Email *"
                            placeholder="Employer's Email"
                            name="employersEmail"
                            type='email'
                            handleChange={handleChange}
                            value={values.employersEmail}
                            error={touched.employersEmail && Boolean(errors.employersEmail)}
                            helperText={touched.employersEmail && errors.employersEmail}
                        />
                    </Grid>
                </Grid>

            </Grid>
        </Box>
    );
}


import { Box, ButtonBase, IconButton } from "@material-ui/core";
import { ArrowBackIos, ArrowForwardIos } from "@material-ui/icons";
import { useEffect, useRef, useState } from "react";
import GalleryStyle from "./GalleryStyle";

type Props = {
  height?: number;
  spacing?: number;
  images: any[];
  autoPlay?: boolean;
};

export default function Gallery({ spacing, images, height, autoPlay }: Props) {
  const classes = GalleryStyle(height || 188, spacing)();
  const [active, setActive] = useState(0);
  const playing = useRef(!!autoPlay);

  const next = () => {
    if (active < images.length - 1) {
      setActive((prev) => ++prev);
    } else {
      setActive(0);
    }
  };

  const prev = () => {
    if (active > 0) {
      setActive((prev) => --prev);
    } else {
      setActive(images.length - 1);
    }
  };

  const play = () => {
    if (playing.current) {
      setTimeout(() => {
        if (playing.current) {
          next();
        }
      }, 5000);
    }
  };

  const start = () => {
    if (autoPlay) {
      playing.current = true;
      play();
    }
  };

  const stop = () => {
    playing.current = false;
  };

  useEffect(() => {
    return stop;
  }, []);

  useEffect(() => {
    play();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active]);

  return (
    <Box
      className={classes.root}
      onMouseEnter={stop}
      onMouseLeave={start}
      component={"div"}
    >
      <Box className={classes.body}>
        <Box
          className={classes.display}
          style={{ backgroundImage: `url(${images[active]})` }}
        >
          <Box className={classes.nav}>
            <IconButton onClick={prev}>
              <ArrowBackIos />
            </IconButton>
            <IconButton onClick={next}>
              <ArrowForwardIos />
            </IconButton>
          </Box>
        </Box>
        <Box component={"div"} className={classes.thumbs}>
          <Box className={classes.scroll}>
            {images.map((image, index) =>
              typeof image === "string" ? (
                <Box
                  component={ButtonBase}
                  onClick={() => setActive(index)}
                  key={index}
                  className={classes.thumb}
                  style={{ backgroundImage: `url(${image})` }}
                />
              ) : null
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

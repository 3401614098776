const routes = {
    Flight: '/flight',
    Hotel: '/hotel',
    HotelDetail: '/hotel-detail',
    ContactUs: '/contact-us',
    SearchFlight: '/search-flight',
    SearchHotel: '/search-hotel',
    Packages: '/packages',
    Booking: '/booking',
    Payment: '/payment',
    PaymentSuccess: '/payment-success',
    Visa: '/visa',
    SearchVisa: '/search-visa',
    About:'/about-us',
    PrivacyPolicyPage:'/privacy-policy',
    CompliantManagement:'/compliant-management-policy-and-procedure',

};

export default routes;

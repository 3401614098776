import { makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    marginBottom: theme.spacing(15),
  },
  locationText: {
    fontWeight: 700,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  emptyCon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
  },
  emptyIcon: {
    fontSize: "6em",
  },
}));

import { makeStyles, Theme } from '@material-ui/core';

const styles = (color?: string) => makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
  },
  adornment: {
    paddingRight: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
  },
  divider: {
    marginLeft: theme.spacing(1),
  },
  label: {
    marginBottom: theme.spacing(1.5),
    color: color || theme.palette.text.primary,
    textTransform: 'capitalize',
  },
  picker: {
    width: 0,
    height: 0,
    overflow: 'hidden',
    marginTop: -theme.spacing(3),
  },
}));

export default styles;

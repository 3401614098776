import { Box, Button, Paper, Typography } from "@material-ui/core";
import { FlightOutlined } from "@material-ui/icons";
import React from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import FlightSearch from "../../../components/FlightSearch/FlightSearch";
import InnerContainer from "../../../components/InnerContainer/InnerContainer";
import pageRoutes from "../../../utilities/pageRoutes";
import { AppStore } from "../../../utilities/types";
import HeaderTabsStyle from "./HeaderTabsStyle";
import VisaSearch from "../../../components/VisaSearch/VisaSearch";
import { ReactComponent as VisaOutlined } from '../../../assets/images/visaIcon.svg';

export default function HeaderTabs() {
  const { mode } = useSelector((store: AppStore) => store.themeSwitch);
  const classes = HeaderTabsStyle(mode)();
  const { pathname } = useLocation();
  const history = useHistory();
  const tabs: { [key: string]: any } = {
    "/flight": {
      label: "Flight",
      component: (
        <FlightSearch
          action={() => {
            history.push(pageRoutes.SearchFlight);
          }}
        />
      ),
      icon: <FlightOutlined />,
      key: "/flight",
    },
    // '/hotel': {
    //   label: 'Hotel',
    //   component: <HotelSearch />,
    //   icon: <HotelOutlined />,
    //   key: '/hotel',
    // },
    "/visa": {
      label: "Visa",
      component: (
        <VisaSearch
        // action={() => {
        //   history.push(pageRoutes.SearchVisa);
        // }}
        />
      ),
      icon: <VisaOutlined />,
      key: "/visa",
    },
  }

  return (
    <InnerContainer className={classes.root}>
      <Box className={classes.headerText}>
        <Typography className={classes.title} variant="h1">
          Create your own unforgettable memories
        </Typography>
        <Typography className={classes.subtitle} variant="subtitle2">
          Book your next flight
        </Typography>
      </Box>
      <Box className={classes.topBar}>
        {Object.values(tabs).map((item, index) => (
          <span key={index}>
            {mode === "light" ? (
              <Button
                variant="text"
                color={pathname === item.key ? "primary" : "default"}
                startIcon={item.icon}
                className={
                  pathname === item.key ? classes.buttonActive : classes.button
                }
                onClick={() => {
                  history.push(item.key);
                }}
              >
                {item.label}
              </Button>
            ) : (
              <Button
                variant="text"
                color={pathname === item.key ? "default" : "default"}
                startIcon={item.icon}
                className={
                  pathname === item.key
                    ? classes.buttonActiveDark
                    : classes.buttonDark
                }
                onClick={() => {
                  history.push(item.key);
                }}
              >
                {item.label}
              </Button>
            )}
          </span>
        ))}
      </Box>
      <Box
        className={classes.cardCon}
        sx={{ borderRadius: "0 10px 10px 10px" }}
      >
        <Paper className={classes.card}>{tabs[pathname].component}</Paper>
      </Box>
    </InnerContainer>
  );
}

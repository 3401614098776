import React from 'react'
import { useVisaStyles } from '../VisaAdditionalInfo.styles'
import { Grid } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import InfoIcon from '@material-ui/icons/Info';
import FormUpload from '../../Form/FormUpload/FormUpload';
import { useFormikContext } from 'formik';

type Props = {
  hideLabel?: boolean;
  textToggle?: boolean;
  // action: (value: FlightSearchProps) => void;
  loading?: boolean;
};

type GroupFormProps = {
  groupTravels: {
    visaDataPage: File,
    passportPhotograph: File,
  }
}


export default function GroupForm({ hideLabel }: Props) {
  const classes = useVisaStyles();
  const { handleChange, errors, touched } = useFormikContext<GroupFormProps>();
  return (
    <Grid container>
      {/* passport upload and warning icon */}
      <Grid item container className={classes.titleFour} spacing={3}>
        <Grid item xs={12} sm={6} md={6} className={`${classes.paper} `} >
          <FormUpload
            label="Visa Data page *"
            name="groupTravels.visaDataPage"
            handleChange={handleChange('groupTravels.visaDataPage')}
            helperText={touched?.groupTravels?.visaDataPage && errors?.groupTravels?.visaDataPage}
            error={touched?.groupTravels?.visaDataPage && Boolean(errors?.groupTravels?.visaDataPage)}

          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} className={`${classes.paper} `} >
          <FormUpload
            label="Passport photograph photo *"
            name='groupTravels.passportPhotograph'
            handleChange={handleChange('groupTravels.passportPhotograph')}
            helperText={touched?.groupTravels?.passportPhotograph && errors?.groupTravels?.passportPhotograph}
            error={touched?.groupTravels?.passportPhotograph && Boolean(errors?.groupTravels?.passportPhotograph)}


          />
        </Grid>

        <Grid item xs={12} className={classes.fileUpload} >
          <Alert icon={<InfoIcon />} severity='warning' color='info'>All file uploads must be in .pdf formats</Alert>
        </Grid>

      </Grid>
    </Grid>
  )
}

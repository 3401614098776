import {
  Box,
  Grid,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
} from "@material-ui/core";
import { EmailOutlined, WhatsApp } from "@material-ui/icons";
import { Skeleton } from "@material-ui/lab";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import BreadCrumb from "../../../components/BreadCrumb/BreadCrumb";
import ContactDetail from "../../../components/ContactDetail/ContactDetail";
import CustomerSupport from "../../../components/CustomerSupport/CustomerSupport";
import DynamicFormView from "../../../components/Form/DynamicFormView/DynamicFormView";
import Gallery from "../../../components/Gallery/Gallery";
import Inclusion from "../../../components/Inclusion/Inclusion";
import InnerContainer from "../../../components/InnerContainer/InnerContainer";
import Layout from "../../../components/Layout/Layout";
import MakePayment from "../../../components/MakePayment/MakePayment";
import Seo from "../../../components/Seo/Seo";
import api from "../../../utilities/api";
import { gtagEvent, toNumberFormat } from "../../../utilities/helpers";
import pageRoutes from "../../../utilities/pageRoutes";
import {
  AppStore,
  PackageBookingData,
  PackageDetailObject,
} from "../../../utilities/types";
import PackageBookingStyle from "./PackageBookingStyle";

export default function PackageBooking() {
  const classes = PackageBookingStyle();
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<PackageDetailObject>();
  const { user, bookingData }: AppStore = useSelector(
    (store: AppStore) => store
  );
  const history = useHistory();
  const booking: PackageBookingData = bookingData as any;

  const getPackage = (packageId: number) => {
    setLoading(true);
    api
      .fetch(`/package/get-details/${packageId}`)
      .then(({ status, data }) => {
        if (status) {
          setData(data);
        } else {
          history.push(pageRoutes.Packages);
        }
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (booking.meta) {
      getPackage(booking.meta.package.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [booking]);

  return !booking.meta ? null : (
    <Layout>
      <Seo title={booking.meta.package.name} />
      <InnerContainer>
        <BreadCrumb paths={["Home", "Booking", booking.bookingId]} />
      </InnerContainer>

      <Box className={classes.root}>
        <InnerContainer>
          {loading || data ? (
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={7}>
                <Paper elevation={0} className={classes.paper}>
                  <Box className={classes.container}>
                    {loading ? (
                      <>
                        <Skeleton
                          variant="text"
                          animation="wave"
                          width={"70%"}
                          height={50}
                        />
                        <Skeleton
                          variant="text"
                          animation="wave"
                          width={"40%"}
                          height={30}
                        />
                        <Skeleton
                          variant="text"
                          animation="wave"
                          width={"20%"}
                          height={20}
                        />
                      </>
                    ) : (
                      <>
                        <Typography variant="h3" className={classes.largeText}>
                          {data?.name}
                        </Typography>
                        <Typography variant="h6" className={classes.amount}>
                          {toNumberFormat(
                            data?.prices.sellingPrice || "0",
                            data?.prices.currency
                          )}
                        </Typography>
                        <Typography variant="overline" className={classes.unit}>
                          {data?.prices.unit}
                        </Typography>
                        <Typography
                          className={classes.oldPrice}
                          variant="h6"
                          color="textSecondary"
                        >
                          {toNumberFormat(
                            data?.prices.actualPrice || "0",
                            data?.prices.currency
                          )}
                        </Typography>
                      </>
                    )}
                  </Box>

                  <Box>
                    {loading ? (
                      <>
                        <Skeleton
                          variant="rect"
                          animation="wave"
                          width={"100%"}
                          height={300}
                        />
                        <br />
                        <Box className={classes.group}>
                          {[1, 2, 3, 4, 5].map((i) => (
                            <Skeleton
                              key={i}
                              variant="rect"
                              animation="wave"
                              className={classes.slideBox}
                            />
                          ))}
                        </Box>
                      </>
                    ) : (
                      <Gallery
                        spacing={3}
                        images={[data?.featuredImage, ...(data?.images || [])]}
                        autoPlay
                        height={400}
                      />
                    )}
                  </Box>

                  <Box className={classes.box}>
                    <Typography className={classes.secondText}>
                      {data?.description}
                    </Typography>
                  </Box>
                </Paper>

                <Paper elevation={0} className={classes.paper2}>
                  <Box>
                    {loading ? (
                      <>
                        <Skeleton
                          animation="wave"
                          variant="text"
                          width={200}
                          height={40}
                          style={{ marginTop: "calc(8px * 8)" }}
                        />
                        {[1, 2, 3].map((i) => (
                          <Skeleton
                            key={i}
                            animation="wave"
                            variant="text"
                            width={"50%"}
                          />
                        ))}
                      </>
                    ) : (
                      <Inclusion items={data?.inclusions || []} />
                    )}
                  </Box>
                </Paper>
              </Grid>

              <Grid item xs={12} sm={12} md={5}>
                <Paper elevation={0} className={classes.paper3}>
                  <Typography variant="subtitle2">
                    Contact our vacation package consultants for all your
                    vacation package related questions.
                  </Typography>
                  <br />
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={12} lg={6}>
                      <ListItem
                        button
                        dense
                        onClick={() => {
                          gtagEvent("contact_us", { name: "Email Address" });
                          window.location.assign("mailto:mail@travoofy.com");
                        }}
                      >
                        <ListItemIcon>
                          <EmailOutlined color="primary" fontSize="large" />
                        </ListItemIcon>
                        <ListItemText
                          primary="Email"
                          secondary="mail@travoofy.com"
                          primaryTypographyProps={{
                            style: { fontWeight: "bold" },
                          }}
                        />
                      </ListItem>
                    </Grid>
                    <Grid item xs={12} sm={6} md={12} lg={6}>
                      <ListItem
                        button
                        dense
                        onClick={() => {
                          gtagEvent("contact_us", { name: "WhatsApp" });
                          window.location.assign("https://wa.me/2348120977653");
                        }}
                      >
                        <ListItemIcon>
                          <WhatsApp color="primary" fontSize="large" />
                        </ListItemIcon>
                        <ListItemText
                          primary="WhatsApp"
                          secondary="+2348120977653"
                          primaryTypographyProps={{
                            style: { fontWeight: "bold" },
                          }}
                        />
                      </ListItem>
                    </Grid>
                  </Grid>
                </Paper>
                {!user.token ? (
                  <>
                    <ContactDetail />
                    <br />
                  </>
                ) : null}
                {booking.status === "pending" ? (
                  <MakePayment bookingId={booking.bookingId} />
                ) : (
                  <DynamicFormView
                    booking={bookingData}
                    fields={booking.meta.document.data}
                  />
                )}
              </Grid>
            </Grid>
          ) : null}
        </InnerContainer>
        <Box className={classes.gap}>
          <CustomerSupport />
        </Box>
      </Box>
    </Layout>
  );
}

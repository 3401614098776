import { Box, Grid, Paper, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import Pagination from "@material-ui/lab/Pagination";
import { useEffect, useRef, useState } from "react";
import AppLink from "../../components/AppLink/AppLink";
import BreadCrumb from "../../components/BreadCrumb/BreadCrumb";
import CustomerSupport from "../../components/CustomerSupport/CustomerSupport";
import InnerContainer from "../../components/InnerContainer/InnerContainer";
import Layout from "../../components/Layout/Layout";
import PackageCard from "../../components/PackageCard/PackageCard";
import PackageSideBar from "../../components/PackageSideBar/PackageSideBar";
import Seo from "../../components/Seo/Seo";
import VacationCarousel from "../../components/VacationCarousel/VacationCarousel";
import api from "../../utilities/api";
import { filterPackages, getSlug, gtagEvent } from "../../utilities/helpers";
import {
  AnyObject,
  CategoryObject,
  PackageObject,
} from "../../utilities/types";
import VacationPackageStyle from "./VacationPackageStyle";

export default function VacationPackage() {
  const classes = VacationPackageStyle();
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState<PackageObject[]>([]);
  const mounted = useRef(true);
  const [page, setPage] = useState<number>(1);
  const pageLimit = 10;
  const [priceRange, setPriceRange] = useState<number[]>([0, 1]);
  const [categories, setCategories] = useState<CategoryObject[]>([]);
  const [activePrices, setActivePrices] = useState<{
    min: number;
    max: number;
  }>();
  const [activeCategories, setActiveCategories] =
    useState<AnyObject<boolean>>();
  const [gtagData, setGtagData] = useState<any[]>([]);

  const sendEvent = (val: any) => {
    gtagEvent("select_item", {
      item_list_id: "packages",
      item_list_name: "Packages",
      items: [val],
    });
  };

  const getCategories = () => {
    api.fetch("/categories").then(({ status, data }) => {
      if (status && data && mounted.current) {
        setCategories(data);
      }
    });
  };

  const getPackages = () => {
    if (mounted.current) {
      setLoading(true);
    }

    api
      .fetch("/packages")
      .then(({ status, data }) => {
        if (status && data && mounted.current) {
          const range = [0, 1];
          data.forEach((item: PackageObject) => {
            // Get minimum and maximum price
            const _amount = Number(item.prices.sellingPrice);
            if (!range[1]) range[1] = _amount;
            if (_amount < range[0]) range[0] = _amount;
            if (_amount > range[1]) range[1] = _amount;
          });
          setList(data);
          setPriceRange(range);
          setTimeout(() => {
            const gd = (data as PackageObject[]).map((i) => {
              const cat =
                categories.filter((j) => j.id === i.categoryId)[0] || {};
              return {
                item_id: i.id,
                item_name: i.name,
                currency: i.prices.currency,
                item_category: "Package",
                item_category2: cat.name || i.categoryId,
                price: i.prices.sellingPrice,
              };
            });
            setGtagData(gd);
            gtagEvent("view_item_list", {
              item_list_id: "packages",
              item_list_name: "Packages",
              items: [...gd],
            });
          }, 1000);
        }
      })
      .finally(() => (mounted.current ? setLoading(false) : null));
  };

  const { results, filterLength } = filterPackages(
    list,
    categories,
    page,
    pageLimit,
    activePrices,
    activeCategories
  );

  useEffect(() => {
    mounted.current = true;
    getCategories();
    getPackages();
    return () => {
      mounted.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout>
      <Seo title={"Packages"} />
      <VacationCarousel />
      <InnerContainer className={classes.innerContainer}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={3}>
            {loading ? (
              <Skeleton className={classes.loadingText} animation="wave" />
            ) : (
              <BreadCrumb paths={["Home", "Packages"]} />
            )}
            {loading ? (
              <Skeleton variant="rect" height={420} animation="wave" />
            ) : (
              <PackageSideBar
                priceRange={priceRange}
                onPriceChange={setActivePrices}
                onCategoryChange={setActiveCategories}
                categories={categories.map((c) => c.name)}
                currency={list[0] ? list[0].prices.currency : "NGN"}
              />
            )}
          </Grid>

          <Grid item xs={12} sm={12} md={9}>
            <Box>
              {loading ? (
                <Skeleton
                  width={300}
                  className={classes.loadingText}
                  animation="wave"
                />
              ) : list.length === filterLength ? (
                <Typography className={classes.firstText}>
                  {list.length} Available packages
                </Typography>
              ) : (
                <Typography className={classes.firstText}>
                  Showing: {filterLength} of {list.length} Available packages
                </Typography>
              )}

              {loading ? (
                <Grid container spacing={3}>
                  {[1, 2, 3, 4].map((i) => (
                    <Grid item sm={12} md={6} key={i}>
                      <Box component={"span"} key={i}>
                        <Skeleton
                          height={200}
                          variant={"rect"}
                          animation="wave"
                          style={{ borderRadius: "5px" }}
                        />
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <Grid container spacing={3} justifyContent="center">
                  {results.map((item, index) => (
                    <Grid item sm={12} md={6} key={index}>
                      <AppLink
                        onClick={() => sendEvent(gtagData[index])}
                        to={`/packages/${getSlug(item.name, item.id)}`}
                      >
                        <PackageCard {...item} />
                      </AppLink>
                    </Grid>
                  ))}
                </Grid>
              )}
              {loading ? (
                <Skeleton
                  style={{ margin: "calc(8px * 8) auto" }}
                  width={300}
                  height={50}
                  animation="wave"
                />
              ) : list.length > pageLimit ? (
                <Paper elevation={0} className={classes.pagination}>
                  <Pagination
                    count={Math.ceil(filterLength / pageLimit)}
                    color="primary"
                    shape="rounded"
                    page={page}
                    onChange={(_, val) => setPage(val)}
                    showFirstButton
                    showLastButton
                  />
                </Paper>
              ) : null}
            </Box>
          </Grid>
        </Grid>
      </InnerContainer>
      <Box className={classes.gap}>
        <CustomerSupport />
      </Box>
    </Layout>
  );
}

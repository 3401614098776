import { makeStyles, Theme } from '@material-ui/core';

const className = '& > .react-swipeable-view-container > div';

export default makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    minHeight: '350px',
    height: 'calc(100vw / 2.8)',
    overflow: 'hidden',
    [className]: {
      minHeight: '350px',
      height: 'calc(100vw / 2.8)',
      overflow: 'hidden !important',
     
    },
  },
  inner: {
    position: 'absolute',
    width: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    
  },
  buttonCon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 0,
    margin: 'auto 5%',
    width: '90%',
    position: 'absolute',
    top: '50%',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  swipeButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    borderRadius: '0',
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.primary.main,
    },
  },
}));

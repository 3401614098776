import {makeStyles,Theme} from '@material-ui/core/styles';



const styles = makeStyles((theme:Theme) => ({

    root: {
        gap: theme.spacing(4),
         width: '100%',
    },
    inputSpace:{
       justifyContent: 'space-between',

     
        
    }
}));
export default styles
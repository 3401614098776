import {
  Avatar,
  Box,
  Grid,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  useTheme,
} from "@material-ui/core";
import React from "react";
import { GiSchoolBag } from "react-icons/gi";
import { toDuration } from "../../utilities/helpers";
import { FlightModel, FlightObject } from "../../utilities/types";
import TimeLiner from "../TimeLiner/TimeLiner";
import FlightCardStyle from "./FlightCardStyle";

type Props = {
  details: FlightObject;
};
export default function FlightCard({ details }: Props) {
  const classes = FlightCardStyle();
  const theme = useTheme();

  const nonStopReturn = (model: FlightModel, index: number) => {
    if (!model.Stops && details.FlightCombination.FlightModels.length > 1) {
      if (index < details.FlightCombination.FlightModels.length - 1) {
        if (details.FlightCombination.FlightModels[index + 1].Stops > 0)
          return false;
        return true;
      }
      return true;
    }
    return false;
  };

  return (
    <Grid container spacing={3}>
      {details.FlightCombination.FlightModels.map((model, index) => (
        <Grid
          item
          xs={12}
          sm={nonStopReturn(model, index) ? 6 : 12}
          md={nonStopReturn(model, index) ? 6 : 12}
          className={classes.root}
          key={index}
        >
          <Box className={classes.boxThree}>
            <Typography component={"strong"} variant="inherit">
              {`${model.DepartureName} (${model.DepartureCode})`} &#10230;{" "}
              {`${model.ArrivalName} (${model.ArrivalCode})`}
            </Typography>
            <Typography component={"strong"} variant="inherit" align="right">
              {toDuration(model.TripDuration)}
            </Typography>
          </Box>
          <ListItem>
            <ListItemAvatar>
              <Avatar
                variant="square"
                alt={model.AirlineName}
                src={model.AirlineLogoUrl}
              />
            </ListItemAvatar>
            <Typography variant="caption">
              {`${model.AirlineName} (${model.Airline}) ${
                model.Stops === 0 ? "Non" : model.Stops
              } Stops ${model.FlightLegs[0].CabinClassName}`}
            </Typography>
          </ListItem>
          <Box className={classes.contain}>
            <TimeLiner timeline={model} />
            {model.FreeBaggage ? (
              <Box className={classes.baggage}>
                {model.FreeBaggage.BagCount ? (
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar className={classes.avatar}>
                        <GiSchoolBag color={theme.palette.primary.main} />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primaryTypographyProps={{
                        color: "textPrimary",
                        variant: "caption",
                        className: classes.baggageText,
                      }}
                      primary={"Baggage"}
                      secondaryTypographyProps={{
                        color: "textSecondary",
                        variant: "caption",
                      }}
                      secondary={`Allowed: ${
                        model.FreeBaggage.BagCount
                      }PCS, Weight: ${model.FreeBaggage.Weight}${
                        model.FreeBaggage.WeightUnit
                          ? model.FreeBaggage.WeightUnit
                          : "Kg"
                      }`}
                    />
                  </ListItem>
                ) : null}
              </Box>
            ) : null}
          </Box>
        </Grid>
      ))}
    </Grid>
  );
}

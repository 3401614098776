import { Box, Button, Grid, Typography } from "@material-ui/core";
import { AirplanemodeInactive } from "@material-ui/icons";
import { Skeleton } from "@material-ui/lab";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";
import BookFlight from "../../components/BookFlight/BookFlight";
import ContactDetail from "../../components/ContactDetail/ContactDetail";
import FlightSummary from "../../components/FlightSummary/FlightSummary";
import InnerContainer from "../../components/InnerContainer/InnerContainer";
import Layout from "../../components/Layout/Layout";
import Seo from "../../components/Seo/Seo";
import Steppers from "../../components/Stepper/Stepper";
import { Action_SetFlightData } from "../../reduxStore/flightData/actions";
import api from "../../utilities/api";
import { getFlightName } from "../../utilities/helpers";
import pageRoutes from "../../utilities/pageRoutes";
import { AppStore, FlightModel } from "../../utilities/types";
import BookingStyle from "./BookingStyle";

export default function Booking() {
  const classes = BookingStyle();
  const {
    flightData,
    user: { token },
  }: AppStore = useSelector((store: AppStore) => store);
  const history = useHistory();
  const mounted = useRef(true);
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();

  const selectFlight = () => {
    setLoading(true);
    api
      .post("/flight/select", {
        TargetCurrency: "NGN",
        SelectData: flightData.FlightObject?.SelectData,
      })
      .then(({ status, data, message }) => {
        if (mounted.current && status) {
          dispatch(Action_SetFlightData({ ...flightData, ...data }));
        } else {
          swal("Oops!", message, "error").then(() => {
            history.goBack();
          });
        }
      })
      .finally(() => (mounted.current ? setLoading(false) : null));
  };

  useEffect(() => {
    mounted.current = true;
    if (!flightData || !flightData.FlightObject) {
      history.push(pageRoutes.SearchFlight);
    } else {
      selectFlight();
    }

    return () => {
      mounted.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const flightModels: FlightModel[] = flightData.FlightSummaryModel
    ? flightData.FlightSummaryModel.FlightCombination.FlightModels
    : (flightData.FlightObject?.FlightCombination.FlightModels as any);

  return flightData.FlightObject ? (
    <Layout>
      <Seo title={getFlightName(flightModels[0])} />
      <InnerContainer>
        <Steppers activeStep={1} />
        <Box className={classes.root}>
          <Typography variant="h6" className={classes.locationText}>
            {loading ? (
              <Skeleton animation="wave" width={300} />
            ) : (
              getFlightName(flightModels[0])
            )}
          </Typography>
          {!loading && !flightData.BookingId ? (
            <Box className={classes.emptyCon}>
              <AirplanemodeInactive className={classes.emptyIcon} />
              <Typography variant="h5">Something went wrong.</Typography>
              <br />
              <Typography>
                Sorry we're unable to select your flight at this moment
              </Typography>
              <Button
                variant="text"
                color="primary"
                onClick={() => history.goBack()}
              >
                Try again or choose another flight option here
              </Button>
            </Box>
          ) : (
            <Grid container spacing={3}>
              <Grid item sm={12} md={8}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    {loading ? (
                      <Skeleton animation="wave" variant="rect" height={600} />
                    ) : (
                      <BookFlight />
                    )}
                  </Grid>
                  {token ? null : (
                    <Grid item xs={12}>
                      <ContactDetail />
                    </Grid>
                  )}
                </Grid>
              </Grid>

              <Grid item xs={12} md={4}>
                {!loading && flightData ? (
                  <FlightSummary
                    totalPrice={
                      flightData.FlightSummaryModel?.FlightCombination.Price
                        .Amount || 0
                    }
                    prices={
                      flightData.FlightSummaryModel?.FlightCombination
                        .PriceDetails || []
                    }
                    adults={
                      flightData.FlightSummaryModel?.FlightCombination.Adults ||
                      0
                    }
                    children={
                      flightData.FlightSummaryModel?.FlightCombination
                        .Children || 0
                    }
                    infants={
                      flightData.FlightSummaryModel?.FlightCombination
                        .Infants || 0
                    }
                    flightModels={
                      flightData.FlightSummaryModel?.FlightCombination
                        .FlightModels || []
                    }
                  />
                ) : (
                  <Skeleton animation="wave" variant="rect" height={600} />
                )}
              </Grid>
            </Grid>
          )}
        </Box>
      </InnerContainer>
    </Layout>
  ) : null;
}

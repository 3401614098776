import {makeStyles} from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    root: {
       
        margin:'20px 0'
    },
    aboutText:{
        padding:'20px',
    },
    textSpace:{
        margin:'20px 0'
    }
    
}))

export default useStyles;
import api from "../../utilities/api";
import { deleteUser, setUser } from "../../utilities/cookie";
import { ActionTypes, UserObject } from "../../utilities/types";

export type UserActionType = {
  type: ActionTypes.SET_USER | ActionTypes.REMOVE_USER;
  payload?: UserObject;
};

export function Action_SetUser(payload: UserObject): UserActionType {
  setUser(payload);
  return {
    type: ActionTypes.SET_USER,
    payload,
  };
}

export function Action_RemoveUser(): UserActionType {
  deleteUser();
  return {
    type: ActionTypes.REMOVE_USER,
  };
}

export const signUp = (user: any) => api.post(`/register`, user);

export const login = (user: any) => api.post(`/login`, user);

export const verifyOtp = (user: any) => api.post(`/verify-otp`, user);

export const changePassword = (user: any) => api.post(`/change-password`, user);

export const updateUser = (user: any) =>
  api.post(`/update-user-profile`, user, true);

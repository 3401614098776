import { ButtonBase, Divider, Grid, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import SortTabStyle from './SortTabStyle';

type SortItem = { label: string, value: string };

type Props = {
    items: (SortItem | string)[];
    onChange?: (value: SortItem) => void;
};

export default function SortTab({ items, onChange }: Props) {
    const classes = SortTabStyle();
    const [active, setActive] = useState(0);

    const handleChange = (index: number, data: SortItem | string) => {
        setActive(index);
        if (onChange) {
            const val: SortItem = typeof data === 'string'
                ? { label: data, value: data } : { ...data };
            onChange(val);
        }
    };

    return (<Grid container spacing={0} className={classes.root}>
        <Grid className={classes.item} item sm>
            <Typography align="center">Sort by:</Typography>
        </Grid>
        {items.map((item, index) => (
            <Grid key={index} className={classes.items} item xs={12} sm={6} md>
                <Divider orientation="vertical" />
                <ButtonBase
                    className={active === index ? classes.activeButton : classes.button}
                    onClick={() => handleChange(index, item)}
                >
                    <Typography align="center">{
                        typeof item === "string" ? item : item.label
                    }</Typography>
                </ButtonBase>
            </Grid>
        ))}
    </Grid>);
}

import { makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) => ({
  root: {
    display: 'block',
    padding: 0,
  },
  title: {
    padding: 0,
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: theme.spacing(1),
  },
  contain: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: theme.spacing(2),
  },
  text: {
    fontWeight: 700,
    fontSize: '16px',
  },
  textTotal: {
    fontWeight: 700,
    fontSize: '20px',
  },
  sumTotal: {
    fontWeight: 700,
    fontSize: '20px',
    color: theme.palette.primary.main,
  },
  smallText: {
    fontSize: '16px',
  },
}));
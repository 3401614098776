import { makeStyles, Theme } from '@material-ui/core';

const className = '& > .react-swipeable-view-container > div';

export default makeStyles((theme: Theme) => ({
  root: {
    width: 'calc(100% - 60px)',
    margin: 'auto',
    overflow: 'hidden',
    [className]: {
      overflow: 'hidden !important',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column-reverse',
    alignItems: 'center',
  },
  swipeButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    borderRadius: '0',
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.primary.main,
    },
  },
  buttonCon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 0,
    width: '100%',
    position: 'relative',
    top: '180px',
  },
}));

import { makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) => ({
  pagination: {
    margin: 'auto',
    marginBottom: theme.spacing(8),
    marginTop: theme.spacing(8),
    width: 'fit-content',
    padding: '5px',
  },
  innerContainer: {
    flexDirection: "row",
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(8),
    [theme.breakpoints.down("sm")]: {
      flexDirection: "row-reverse",
    },
  },
  firstText: {
    fontSize: "14px",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(3),
  },
  loadingText: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  gap: {
    paddingBottom: theme.spacing(10),
  },
  loading: {
    margin: theme.spacing(3, 'auto'),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

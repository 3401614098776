import { Divider, Box, Typography } from '@material-ui/core';
import React from 'react';
import HotelServiceStyle from './HotelServiceStyle';

export default function HotelService() {
    const classes = HotelServiceStyle();
    return (
        <Box className={classes.root}>
            <Box>
                <Box className={classes.textBox}>
                    <Typography className={classes.text}>Amenities</Typography>
                </Box>
                <Box><Divider /></Box>
                <Box className={classes.services}>
                    <Box>
                    <Typography className={classes.letter}>Front desk [24-hour]</Typography>
                    <Typography className={classes.letter}>Airport transfer</Typography>
                    <Typography className={classes.letter}>Bicycle rental</Typography>
                    <Typography className={classes.letter}>Valet parking</Typography>
                    </Box>

                    <Box>
                    <Typography className={classes.letter}>Fitness center</Typography>
                    <Typography className={classes.letter}>Swimming pool [outdoor]</Typography>
                    <Typography className={classes.letter}>Shuttle service</Typography>
                    <Typography className={classes.letter}>Free Wi-Fi in all rooms!</Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}
import { makeStyles, Theme } from '@material-ui/core';
export default makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
  },
  slider: {
    "& > .MuiSlider-markLabel": {
      fontSize: '12px',

      "&[data-index='0']": {
        left: '10% !important',
      },
      "&[data-index='1']": {
        left: '90% !important',
      }
    },
  },
  checkbox: {
    "& > .MuiFormControlLabel-label": {
      fontSize: '14px',
    },
  },
}));

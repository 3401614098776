import {makeStyles, Theme} from "@material-ui/core";
import image from '../../assets/images/travelimage2.jpg';

export default makeStyles((theme: Theme) => ({
    root: {},
    bgImage: {
        backgroundImage: `url(${image})`,
        // zIndex: 100,
    },
    text: {
        color: 'red',
        marginLeft: '40px',
        position: 'absolute',
        top: '100px'
    }
}));

import { Chip, Grid, Paper } from "@material-ui/core";
import { getFormView } from "../../../utilities/form";
import { getStatusIcon } from "../../../utilities/helpers";
import {
  BookingData,
  DocumentField,
  FormTypes,
} from "../../../utilities/types";
import BookingDetailsStyle from "../../BookingDetails/BookingDetailsStyle";
import DynamicFormViewStyle from "./DynamicFormViewStyle";

type Props = {
  fields: DocumentField[];
  booking: BookingData;
};

export default function DynamicFormView({ fields, booking }: Props) {
  const classes = DynamicFormViewStyle();
  const styles = BookingDetailsStyle();

  const items = fields.sort((x, y) => {
    const order = Object.values(FormTypes);
    if (order.indexOf(x.type) < order.indexOf(y.type)) return -1;
    if (order.indexOf(x.type) > order.indexOf(y.type)) return 1;
    return 0;
  });

  const BookingIcon = getStatusIcon(booking.bookingStatus);
  const PaymentIcon = getStatusIcon(booking.status);

  return (
    <Paper elevation={0} className={classes.root}>
      <Chip
        color="primary"
        variant="outlined"
        label={`Booking Status: ${booking.bookingStatus}`}
        icon={<BookingIcon />}
        className={styles[booking.bookingStatus as "success" | "pending"]}
      />
      <Chip
        color="primary"
        variant="outlined"
        label={`Payment Status: ${booking.status}`}
        icon={<PaymentIcon />}
        className={styles[booking.status as "success" | "pending"]}
      />
      <br />
      <br />
      <Grid container spacing={2}>
        {items.map(getFormView)}
      </Grid>
    </Paper>
  );
}

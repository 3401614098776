import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Button,
  CircularProgress,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Radio,
} from "@material-ui/core";
import { Cancel, PaymentRounded } from "@material-ui/icons";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import flutterLogo from "../../../assets/images/Flutterwave-Logo.png";
import { Action_SetBookingData } from "../../../reduxStore/bookingData/actions";
import api from "../../../utilities/api";
import { gtagEvent, toNumberFormat } from "../../../utilities/helpers";
import { AppStore } from "../../../utilities/types";
import FlutterPaymentStyle from "./FlutterPaymentStyle";

type Props = {
  active: boolean;
  onClick: any;
  bookingId: string;
};

export default function FlutterPayment({ active, onClick, bookingId }: Props) {
  const classes = FlutterPaymentStyle();
  const [flutterLoading, setFlutterLoading] = useState<boolean>(false);
  const [flutter, setFlutter] = useState({ frame: false, link: null });
  const { bookingData }: AppStore = useSelector((store: AppStore) => store);
  const dispatch = useDispatch();

  const getBooking = (id: string) => {
    setFlutterLoading(true);
    api
      .fetch(`/booking/${id}`, undefined, true)
      .then(({ status, data }) => {
        if (status) {
          dispatch(Action_SetBookingData(data));
        }
      })
      .finally(() => setFlutterLoading(false));
  };

  const closeFrame = () => {
    setFlutter((prev) => ({ ...prev, frame: false }));
    getBooking(bookingId);
    gtagEvent("purchase", {
      currency: bookingData.currency,
      transaction_id: bookingId,
      value: bookingData.amount,
      items: [{
        item_id: bookingId,
        item_category: bookingData.type,
        price: bookingData.amount,
        item_variant: "FlutterWave Payment",
      }],
    });
  };

  const flutterPay = (id: string) => {
    setFlutterLoading(true);
    api
      .post(
        "/payment/link",
        {
          bookingId: id,
          saveCard: true,
          currency: bookingData.meta.TargetCurrency,
          
        },
        true
      )
      .then(({ status, data }) => {
        if (status) {
          setFlutter({ frame: true, link: data.link });
        } else {
          setFlutterLoading(false);
        }
      });
  };

  return (
    <>
      {flutter.frame ? (
        <Box className={classes.flutterModal}>
          {flutter.link ? (
            <iframe
              className={classes.frame}
              src={flutter.link}
              title={"payment"}
            />
          ) : null}
          <IconButton className={classes.close} onClick={closeFrame}>
            <Cancel />
          </IconButton>
        </Box>
      ) : null}
      <Accordion elevation={0} expanded={active} onChange={onClick}>
        <AccordionSummary
          expandIcon={<Radio color="primary" checked={active} />}
        >
          <ListItem>
            <ListItemAvatar>
              <Avatar src={flutterLogo} alt="logo" className={classes.img} />
            </ListItemAvatar>
            <ListItemText
              primaryTypographyProps={{ className: classes.boldText }}
              primary="Flutterwave"
              secondary="Pay with your debit/credit card"
            />
          </ListItem>
        </AccordionSummary>
        <AccordionDetails>
          <Box className={classes.flutterCon}>
            <Button
              onClick={() => flutterPay(bookingId)}
              disabled={flutterLoading}
              startIcon={
                flutterLoading ? (
                  <CircularProgress color="secondary" />
                ) : (
                  <PaymentRounded fontSize="large" />
                )
              }
              size="large"
              className={classes.flutterButton}
            >
              Pay Now {toNumberFormat(bookingData.amount, bookingData.currency)}
            </Button>
          </Box>
        </AccordionDetails>
      </Accordion>
    </>
  );
}

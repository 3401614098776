import React from "react";
import { Grid, Box, Typography, RadioGroup, FormControlLabel } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import useVisaTravelHistory from './VisaTravelHistory.style';
import FormUpload from "../../Form/FormUpload/FormUpload";
import FormRadio from "../../Form/FormRadio/FormRadio";
import { useFormikContext } from 'formik';

export enum TravelHistory {
    UK = 'UK',
    USA = 'USA',
    Schengen = 'Schengen',
    Asia = 'Asia',
    Other = 'Others',
    None = 'None'
}

type History = {
    travelHistory: TravelHistory
}
type ReturnedProps = {
    travelHistoryScannedDataPage: File | null
}

const ReturnedTravel = ({ classes }: any) => {

    const {  handleChange, errors, touched } = useFormikContext<ReturnedProps>();

    return (
        < Grid item container className={classes.inputSpace} >
            <Grid item xs={12} sm={5} md={5} >
                <FormUpload
                    label="Scanned Copy Of Data Page *"
                    name="travelHistoryScannedDataPage "
                    handleChange={handleChange('travelHistoryScannedDataPage')}
                    error={touched.travelHistoryScannedDataPage && Boolean(errors.travelHistoryScannedDataPage)}
                    helperText={touched.travelHistoryScannedDataPage && errors.travelHistoryScannedDataPage}
                />

            </Grid>
        </ Grid>
    );
}
export const VisaTravelHistory = () => {
    const { values, handleChange} = useFormikContext<History>();
    const classes = useVisaTravelHistory();


    const TravelHistorySteps = (step: string, classes: any) => {
        if (step === TravelHistory.None) {
            return null;
        } else {
            return <ReturnedTravel classes={classes} />;
        }
    }

    return (
        <Box >

            <Grid container className={classes.root} >
                <Grid item md={12} >
                    <Alert severity='warning' className={''}>
                        Use all given Information as they appear on your passport/ID to avoid complications.
                    </Alert>

                </Grid>
                <Typography>Have you travelled to the following destinations in the last five years? *</Typography>
                <Grid item container >
                    <RadioGroup
                        onChange={handleChange('travelHistory')}
                        className={classes.formRadios}
                        defaultValue='None'
                        name="travelHistory"
                        value={values.travelHistory}
                    >
                        
                        <FormControlLabel
                            control={<FormRadio />}
                            label='UK'
                            value='UK'
                            name='travelHistory'
                        />
                        <FormControlLabel
                            control={<FormRadio />}
                            label='USA'
                            value='USA'
                            name='travelHistory'
                        />
                        <FormControlLabel
                            control={<FormRadio />}
                            label='Schengen'
                            value='Schengen'
                            name='travelHistory'
                        />
                        <FormControlLabel
                            control={<FormRadio />}
                            label='Asia'
                            value='Asia'
                            name='travelHistory'
                        />
                        <FormControlLabel
                            control={<FormRadio />}
                            label='Others'
                            value='Others'
                            name='travelHistory'
                        />
                        <FormControlLabel
                            control={<FormRadio />}
                            label='None'
                            value='None'
                            name='travelHistory'
                        />

                    </RadioGroup>

                </Grid>
                {TravelHistorySteps(values.travelHistory as string, classes)}

                {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
            </Grid>

        </Box>
    );
}

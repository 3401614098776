import { Box, Button, Grid, Paper } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import AppLink from "../../../components/AppLink/AppLink";
import PackageCard from "../../../components/PackageCard/PackageCard";
import SectionContainer from "../../../components/SectionContainer/SectionContainer";
import api from "../../../utilities/api";
import { getSlug, shuffle } from "../../../utilities/helpers";
import pageRoutes from "../../../utilities/pageRoutes";
import { PackageObject } from "../../../utilities/types";
import PackageStyle from "./PackageStyle";

export default function Packages() {
  const classes = PackageStyle();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState<PackageObject[]>([]);

  useEffect(() => {
    setLoading(true);
    api
      .fetch("/packages")
      .then(({ status, data }) => {
        if (status && data) {
          setList(data);
        }
      })
      .finally(() => setLoading(false));
  }, []);

  return loading || list.length > 0 ? (
    <SectionContainer
      title="Best Travel Packages"
      subtitle="At Travoofy, we have partnered with various airlines, global tour operating and value-added companies to provide top-notch travel services to our clientele."
    >
      {loading ? (
        <Grid container spacing={3} style={{ marginBottom: "16px" }}>
          {[1, 2, 3, 4].map((i) => (
            <Grid item sm={12} md={6} key={i}>
              <Box component={"span"} key={i}>
                <Skeleton
                  height={200}
                  variant={"rect"}
                  animation="wave"
                  style={{ borderRadius: "5px" }}
                />
              </Box>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Grid
          container
          spacing={3}
          className={classes.root}
          justifyContent="center"
        >
        {list.map((item, index) => (
            <Grid item sm={12} md={6} key={index}>
              <AppLink
                to={`${pageRoutes.Packages}/${getSlug(item.name, item.id)}`}
              >
                <PackageCard {...item} />
              </AppLink>
            </Grid>
          ))}
        </Grid>
      )}
      <Paper className={classes.paper} elevation={0}>
        <Button
          variant="text"
          onClick={() => {
            history.push(pageRoutes.Packages);
          }}
          color="primary"
          size="large"
          fullWidth
          className={classes.link}
        >
          View all travel packages
        </Button>
      </Paper>
    </SectionContainer>
  ) : null;
}

import { ElementType } from "react";
import { countryListData } from "../../../utilities/helpers";
import FormSelect from "../FormSelect/FormSelect";

type Props = {
  name?: string;
  label?: string;
  value?: any;
  handleChange?: (value: any) => void;
  error?: boolean;
  helperText?: any;
  color?: "white";
  hidden?: boolean;
  icon?: ElementType<any>;
  type?: string;
  disable?: boolean;
};

export default function FormCountryList({
  name,
  label,
  value,
  handleChange,
  error,
  helperText,
  color,
  hidden,
  icon,
  type,
  disable,
}: Props) {
  return (
    <FormSelect
      name={name || "CountryCode"}
      id={name || "CountryCode"}
      placeholder={`Select ${(label || "country").toLowerCase()}`}
      label={label || "Country"}
      options={countryListData.list}
      defaultValue={""}
      value={value || ""}
      handleChange={handleChange}
      error={error}
      helperText={helperText}
      color={color}
      hidden={hidden}
      icon={icon}
      type={type}
      disable={disable}
    />
  );
}

import { createTheme, responsiveFontSizes, ThemeOptions } from "@material-ui/core";
import { dark } from '../../utilities/helpers';
import { ThemeMode } from "../../utilities/types";

const theme = (type: ThemeMode): ThemeOptions => responsiveFontSizes(
    createTheme({
        palette: {
            type,
            primary: {
                main: '#5FC9FE',
                light: dark(type) ? 'rgb(127, 211, 254)' : '#FFFFFF',
                contrastText: dark(type) ? '#FFFFFF' : '#F8F8F8',
            },
            secondary: {
                main: dark(type) ? '#FFFFFF' : '#002C43',
                dark: dark(type) ? 'rgb(178, 178, 178)' : '#000000',
            },
            background: {
                default: dark(type) ? '#13394F' : '#f6f6f6',
                paper: dark(type) ? 'rgba(56, 100, 125, 1)' : '#FFFFFF',
            },
            text: {
                primary: dark(type) ? '#FFFFFF' : '#002C43',
                secondary: dark(type) ? 'rgba(255, 255, 255, 0.82)' : 'rgba(0, 44, 67, 0.46)',
            },
            divider: dark(type) ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 49, 74, 0.1)',
        },
        typography: {
            fontFamily: 'Inter',
            fontSize: 14,
            h1: {
                fontWeight: 700,
            },
            h2: {
                fontWeight: 700,
            },
            h3: {
                fontWeight: 700,
            },
            h4: {
                fontWeight: 700,
            },
            h5: {
                fontWeight: 700,
            },
            h6: {
                fontWeight: 700,
            },
            button: {
                fontWeight: 500,
                textTransform: 'none',
            },
        },
        shape: {
            borderRadius: 5,
        },
        props: {
            MuiAppBar: {
                color: 'inherit',
            },
            MuiTooltip: {
                arrow: true,
            },
        },
        overrides: {
            MuiIcon: {
                colorDisabled: {
                    color: dark(type) ? 'rgba(255, 255, 255, 0.66)' : 'rgba(193, 193, 193, 1)',
                }
            },
            MuiTextField: {
                root: {
                    '& .MuiOutlinedInput-input': {
                        paddingTop: '16px',
                        paddingBottom: '16px',
                    },
                    '& .MuiOutlinedInput-root': {
                        backgroundColor: dark(type) ? '#3C6B86' : '#F2F2F2',
                        '& fieldset': {
                            borderColor: dark(type) ? '#3C6B86' : '#F2F2F2',
                            borderWidth: 2,
                        },
                        '&:hover fieldset': {
                            borderColor: dark(type) ? '#3C6B86' : '#F2F2F2',
                            borderWidth: 2,
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: '#5FC9FE',
                        },
                    },
                    '& .MuiSelect-select:focus': {
                        backgroundColor: dark(type) ? '#3C6B86' : '#F2F2F2',
                        borderRadius: '0px 10px 10px 0px',
                    },
                },
            },
            MuiTabs: {
                root: {
                    backgroundColor: dark(type) ? '#38647D' : 'white',
                },
            },
            MuiTab: {
                root: {
                    backgroundColor: dark(type) ? '#38647D' : 'white',
                },
            },
            MuiButton: {
                root: {
                    boxShadow: 'none !important',
                },
                contained: {
                    backgroundColor: 'white',
                    color: dark(type) ? '#002C43' : 'rgba(0, 49, 74, 0.55)',
                },
                text: {
                    color: dark(type) ? '#FFFFFF' : 'rgba(0, 49, 74, 0.55)',
                },
                outlined: {
                    color: dark(type) ? '#FFFFFF' : 'rgba(0, 49, 74, 0.55)',
                },
                sizeLarge: {
                    padding: `12px 33px`,
                },
            },
            MuiSwitch: {
                root: {
                    width: 42,
                    height: 26,
                    padding: 0,
                    margin: 8,
                },
                switchBase: {
                    padding: 1,
                    '&$checked, &$colorPrimary$checked, &$colorSecondary$checked': {
                        transform: 'translateX(16px)',
                        color: '#fff',
                        '& + $track': {
                            opacity: 1,
                            border: 'none',
                        },
                    },
                },
                thumb: {
                    width: 24,
                    height: 24,
                },
                track: {
                    borderRadius: 13,
                    border: '1px solid #bdbdbd',
                    backgroundColor: '#fafafa',
                    opacity: 1,
                    transition:
                        'background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                },
            },
        },
    }));

export default theme;

import {
  CancelOutlined,
  CheckCircleOutline,
  WatchLaterOutlined,
} from "@material-ui/icons";
// @ts-ignore
import { customArray, customList } from "country-codes-list";
import { Dispatch } from "redux";
import { Action_SetTheme } from "../reduxStore/themeSwitch/actions";
import { Action_RemoveUser } from "../reduxStore/user/actions";
import {
  AnyObject,
  CategoryObject,
  DayTimes,
  FlightModel,
  FlightObject,
  FlightSearchProps,
  FlightSort,
  FlightStops,
  Gender,
  PackageObject,
  ThemeMode,
} from "./types";

export const toggleTheme = (dispatch: Dispatch<any>, mode: ThemeMode) => {
  dispatch(Action_SetTheme(mode === "light" ? "dark" : "light"));
};

export const logout = (dispatch: Dispatch<any>) => {
  dispatch(Action_RemoveUser());
};

export const dark = (color: ThemeMode) => color === "dark";

export const isProduction = () => process.env.NODE_ENV === "production";

export function toNumberFormat(
  amount: string | number,
  currency?: string,
  noDecimals?: boolean
): string {
  const values = parseFloat(amount.toString()).toFixed(2).split(".");
  const valLength = values[0].length;
  const a = valLength / 3;
  let b = Math.floor(valLength / 3);

  if ((a >= 1 && a > b) || (a >= 1 && a === b)) {
    if (a === b) {
      b -= 1;
    }
    let end = valLength;
    let start = 0;
    let str = "";
    for (let x = 0; x < b; x += 1) {
      start = end - 3;
      str = `,${values[0].substring(start, end)}${str}`;
      end -= 3;
    }
    if (b > 0) {
      values[0] = values[0].substring(0, start) + str;
    }
  }

  return `${currency ? (currency === "NGN" ? "₦" : "$") : ""}${
    !noDecimals ? values.join(".") : values[0]
  }`;
}

export function toDateTime(date: Date | string, time?: boolean): string {
  const data: Date = new Date(date);
  if (time) {
    const hour = (
      data.getHours() > 12 ? data.getHours() - 12 : data.getHours()
    ).toString();
    const min = data.getMinutes().toString();
    return `${hour.length < 2 ? "0" + hour : hour}:${
      min.length < 2 ? "0" + min : min
    } ${data.getHours() >= 12 ? "PM" : "AM"}`;
  }
  return data.toDateString();
}

export function toDuration(time: string) {
  const [hours, mins] = time.split(":", 2);
  if (hours.includes(".")) {
    const [day, hour] = hours.split(".", 2);
    return `${day}D ${hour}H ${mins}M`;
  } else {
    return `${hours}H ${mins}M`;
  }
}

export function getDayTime(time: string): DayTimes {
  const timeParts = time.split(" ");
  const hour = Number(timeParts[0].split(":")[0] || 0);
  const dayTime: string = timeParts[1];

  if (hour > 4 && hour < 12 && dayTime === "AM") {
    return DayTimes.MORNING;
  } else if ((hour === 12 || hour > 0) && hour < 4 && dayTime === "PM") {
    return DayTimes.AFTERNOON;
  } else if (hour > 3 && hour < 8 && dayTime === "PM") {
    return DayTimes.EVENING;
  } else {
    return DayTimes.NIGHT;
  }
}

export function sortByPrice(a: FlightObject, b: FlightObject) {
  const priceA = a.FlightCombination.Price.Amount;
  const priceB = b.FlightCombination.Price.Amount;
  if (priceA < priceB) return -1;
  if (priceA > priceB) return 1;
  return 0;
}

export function sortByDuration(a: FlightObject, b: FlightObject) {
  const mapDuration = (model: FlightModel) => {
    const times = model.TripDuration.split(":", 2).map(Number);
    const hours = times[0] * 60;
    return hours + times[1];
  };
  const durationA = a.FlightCombination.FlightModels.map(mapDuration).reduce(
    (c, d) => c + d
  );
  const durationB = b.FlightCombination.FlightModels.map(mapDuration).reduce(
    (c, d) => c + d
  );
  if (durationA < durationB) return -1;
  if (durationA > durationB) return 1;
  return 0;
}

export function sortByStops(a: FlightObject, b: FlightObject) {
  const stopA = a.FlightCombination.FlightModels[0].Stops;
  const stopB = b.FlightCombination.FlightModels[0].Stops;
  if (stopA < stopB) return -1;
  if (stopA > stopB) return 1;
  return 0;
}

export function sortByAirline(a: FlightObject, b: FlightObject) {
  const airlineA = a.FlightCombination.FlightModels[0].AirlineName;
  const airlineB = b.FlightCombination.FlightModels[0].AirlineName;
  return airlineA.localeCompare(airlineB);
}

export function filterStops(
  activeStops: AnyObject<boolean>,
  data: FlightObject[]
) {
  return data.filter((item) => {
    let found = false;
    const _flightStop = item.FlightCombination.FlightModels[0].Stops;
    Object.keys(activeStops).forEach((key) => {
      switch (key) {
        case FlightStops.NONSTOP:
          if (activeStops[key] && _flightStop === 0) found = true;
          break;
        case FlightStops.ONESTOP:
          if (activeStops[key] && _flightStop === 1) found = true;
          break;
        case FlightStops.MORESTOP:
        default:
          if (activeStops[key] && _flightStop > 1) found = true;
          break;
      }
    });
    return found;
  });
}

export function filterByTime(
  timeKey: "DepartureTime" | "ArrivalTime",
  activeTime: AnyObject<boolean>,
  data: FlightObject[]
) {
  return data.filter((item) => {
    let found = false;
    const _time = toDateTime(
      item.FlightCombination.FlightModels[0][timeKey],
      true
    );
    Object.keys(activeTime).forEach((key) => {
      switch (key) {
        case DayTimes.MORNING:
          if (activeTime[key] && key === getDayTime(_time)) found = true;
          break;
        case DayTimes.AFTERNOON:
          if (activeTime[key] && key === getDayTime(_time)) found = true;
          break;
        case DayTimes.EVENING:
          if (activeTime[key] && key === getDayTime(_time)) found = true;
          break;
        case DayTimes.NIGHT:
        default:
          if (activeTime[key] && key === getDayTime(_time)) found = true;
          break;
      }
    });
    return found;
  });
}

export function filterByAirlines(
  activeAirlines: AnyObject<boolean>,
  data: FlightObject[]
) {
  return data.filter((item) => {
    let found = false;
    const _name = item.FlightCombination.FlightModels[0].AirlineName;
    Object.keys(activeAirlines).forEach((key) => {
      if (activeAirlines[key] && key === _name) found = true;
    });
    return found;
  });
}

export const sorters: {
  [key: string]: (a: FlightObject, b: FlightObject) => number;
} = {
  [FlightSort.Cheapest]: sortByPrice,
  [FlightSort.Fastest]: sortByDuration,
  [FlightSort.Stops]: sortByStops,
  [FlightSort.Airlines]: sortByAirline,
};

export function getCrumb(flightProps: FlightSearchProps) {
  if (flightProps.Itineraries[0]) {
    const a = flightProps.Itineraries[0].Departure;
    const b = flightProps.Itineraries[0].Destination;
    return `${a}-${b}`;
  }
  return "";
}

export function getFlightName(flightModel: FlightModel) {
  return `${flightModel.DepartureName} (${flightModel.DepartureCode}) - ${flightModel.ArrivalName} (${flightModel.ArrivalCode})`;
}

export function getFlightData(flightResult: FlightObject[]) {
  const _priceRange = [0, 1];
  const _stops = [0, 0, 0];
  const _airlines: string[] = [];
  const _departureTime: { [key: string]: number } = {
    [DayTimes.MORNING]: 0,
    [DayTimes.AFTERNOON]: 0,
    [DayTimes.EVENING]: 0,
    [DayTimes.NIGHT]: 0,
  };
  const _arrivalTime: { [key: string]: number } = {
    [DayTimes.MORNING]: 0,
    [DayTimes.AFTERNOON]: 0,
    [DayTimes.EVENING]: 0,
    [DayTimes.NIGHT]: 0,
  };

  flightResult.forEach((flight) => {
    // Get departure time
    const _dTime = toDateTime(
      flight.FlightCombination.FlightModels[0].DepartureTime,
      true
    );
    _departureTime[getDayTime(_dTime)] += 1;

    // Get arrival time
    const _aTime = toDateTime(
      flight.FlightCombination.FlightModels[0].ArrivalTime,
      true
    );
    _arrivalTime[getDayTime(_aTime)] += 1;

    // Get minimum and maximum price
    const _amount = Number(flight.FlightCombination.Price.Amount);
    if (!_priceRange[1]) _priceRange[1] = _amount;
    if (_amount < _priceRange[0]) _priceRange[0] = _amount;
    if (_amount > _priceRange[1]) _priceRange[1] = _amount;

    // Get stops
    const _stop = flight.FlightCombination.FlightModels[0].Stops;
    if (_stop === 0) _stops[0] += 1;
    if (_stop === 1) _stops[1] += 1;
    if (_stop > 1) _stops[2] += 1;

    // Get Airlines
    const _airline = flight.FlightCombination.FlightModels[0].AirlineName;
    if (!_airlines.includes(_airline)) _airlines.push(_airline);
  });

  return {
    _priceRange,
    _stops,
    _airlines: _airlines.sort(),
    _departureTime,
    _arrivalTime,
  };
}

export function filterPackages(
  packageList: PackageObject[],
  categories: CategoryObject[],
  page: number,
  pageLimit: number,
  activePrices?: { max: number; min: number },
  activeCategories?: AnyObject<boolean>
) {
  let data = [...packageList];
  const offset = page * pageLimit - pageLimit;

  // data = [
  //   ...data.sort((a, b) => {
  //     const priceA = Number(a.prices.sellingPrice);
  //     const priceB = Number(b.prices.sellingPrice);
  //     if (priceA < priceB) return -1;
  //     if (priceA > priceB) return 1;
  //     return 0;
  //   }),
  // ];

  // Filter with price range
  if (activePrices) {
    data = data.filter((item) => {
      const _isMin = item.prices.sellingPrice >= activePrices.min;
      const _isMax = item.prices.sellingPrice <= activePrices.max;
      return _isMin && _isMax;
    });
  }

  // Filter with category names
  if (activeCategories && Object.values(activeCategories).includes(true)) {
    data = data.filter((item) => {
      let found = false;
      Object.keys(activeCategories).forEach((key) => {
        const { name } = categories.filter((c) => c.name === key)[0];
        if (activeCategories[key] && key === name) found = true;
      });
      return found;
    });
  }

  const filterLength = data.length;
  const results = data.splice(offset, pageLimit);

  return { results, filterLength };
}

export function flightProcessing(
  flightResult: FlightObject[],
  page: number,
  pageLimit: number,
  sort: string,
  activePrices: { max: number; min: number } | undefined,
  activeStops: AnyObject<boolean> | undefined,
  activeDeparture: AnyObject<boolean> | undefined,
  activeArrival: AnyObject<boolean> | undefined,
  activeAirlines: AnyObject<boolean> | undefined
) {
  let data = [...flightResult];
  const offset = page * pageLimit - pageLimit;
  data = [...data.sort(sorters[sort])];

  // Filter with price range
  if (activePrices) {
    data = data.filter((item) => {
      const _isMin = item.FlightCombination.Price.Amount >= activePrices.min;
      const _isMax = item.FlightCombination.Price.Amount <= activePrices.max;
      return _isMin && _isMax;
    });
  }

  // Filter with stops
  if (activeStops && Object.values(activeStops).includes(true)) {
    data = filterStops(activeStops, data);
  }

  // Filter with departure time
  if (activeDeparture && Object.values(activeDeparture).includes(true)) {
    data = filterByTime("DepartureTime", activeDeparture, data);
  }

  // Filter with arrival time
  if (activeArrival && Object.values(activeArrival).includes(true)) {
    data = filterByTime("ArrivalTime", activeArrival, data);
  }

  // Filter with airline names
  if (activeAirlines && Object.values(activeAirlines).includes(true)) {
    data = filterByAirlines(activeAirlines, data);
  }

  const filterLength = data.length;
  const results = data.splice(offset, pageLimit);

  return { results, filterLength };
}

export const genderListData: {
  list: { label: string; value: string }[];
  data: { [key: string]: string };
} = {
  list: [
    { label: Gender.MALE, value: Gender.MALE },
    { label: Gender.FEMALE, value: Gender.FEMALE },
  ],
  data: {
    [Gender.MALE]: Gender.MALE,
    [Gender.FEMALE]: Gender.FEMALE,
  },
};

export const countryListData: {
  list: { label: string; value: string }[];
  data: { [key: string]: string };
} = {
  list: customArray(
    {
      label: "{countryNameEn} {flag}",
      value: "{countryCode}",
    },
    { sortBy: "label" }
  ),
  data: customList("countryCode", "{countryNameEn}"),
};

export function chunkList<T>(list: T[], count: number): T[][] {
  const chunkCount = Math.ceil(list.length / count);
  const rows = Array.from(Array(chunkCount).keys());

  return rows.map((_, index) => {
    return [...list].splice(index * count, count);
  });
}

export function shuffle(): number {
  return 0.5 - Math.random();
}

export function getSlug(name: string, id: any): string {
  return `${name.toLowerCase().split(" ").join("-")}-${id.toString()}`;
}

export function getSlugId(slug: string): string {
  return slug.split("-").pop() as string;
}

export function getSlugName(slug: string): string {
  return slug
    .split("-")
    .slice(0, -1)
    .map((text) => {
      const chars = text.split("");
      chars[0] = chars[0] !== undefined ? chars[0].toUpperCase() : chars[0];
      return chars.join("");
    })
    .join(" ");
}

export function getStatusIcon(status: string) {
  const icons: AnyObject<any> = {
    success: CheckCircleOutline,
    pending: WatchLaterOutlined,
    failed: CancelOutlined,
    paid: CheckCircleOutline,
  };
  return icons[status];
}

export function gtagEvent(event: string, param: AnyObject<any>) {
  // @ts-ignore
  window.gtag('event', event, param);
}
import { makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) => ({
  text: {
    fontWeight: 700,
    paddingBottom: theme.spacing(2),
  },
  stepper: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  stepLabelComplete: {
    cursor: 'pointer',
  },
  stepLabelActive: {
    cursor: 'pointer',
    fontWeight: `700 !important` as any,
  },
  button: {
    marginTop: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(3),
  },
  alert: {
    marginTop: theme.spacing(1),
    fontSize: '12px',
  },
  privacy: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  terms: {
    fontSize: '14px',
  },
}));
import { makeStyles, Theme } from '@material-ui/core';
export default makeStyles((theme: Theme) => ({
  root: {
    padding: `${theme.spacing(3)}px ${theme.spacing(5)}px`,
  },
  container: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  firstBox: {
    display: 'flex',
    flexDirection: 'row'
  },
  icon: {
    marginRight: theme.spacing(2),
  },
  texts: {
    fontWeight: 700,
  },
  small: {
    fontSize: '14px',
    color: theme.palette.text.disabled,
  },
  link: {
    marginTop: theme.spacing(2),
  },
  divider: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

import { Box } from "@material-ui/core";
import React from "react";
import Layout from "../../components/Layout/Layout";
import Seo from "../../components/Seo/Seo";
import CustomerSupport from "../../components/CustomerSupport/CustomerSupport";
import Header from "./Header/Header";
import HeaderTabs from "./HeaderTabs/HeaderTabs";
import HomeStyle from "./HomeStyle";
import Packages from "./Packages/Package";
// import Partner from "./Partner/Partner";
import QuickBooking from "./QuickBooking/QuickBooking";
import { useLocation } from "react-router-dom";

import HeaderFlightFooter from "./HomeFlightFooter/HeaderFlightFooter";
export default function Home() {
  const classes = HomeStyle();
  const { pathname } = useLocation();
  const title = pathname.split("").slice(1);
  title[0] = title[0].toUpperCase();

  return (
    <Layout overlayHeader>
      <Seo title={title.join("")} />
      <Box component={"div"} className={classes.root}>
        <Header />
        <HeaderTabs />
        <QuickBooking />
        <Packages />
        <CustomerSupport />
        {/* <Partner /> */}
        <HeaderFlightFooter />
      </Box>
    </Layout>
  );
}

import React from 'react'
import Layout from '../../components/Layout/Layout'
import InnerContainer from '../../components/InnerContainer/InnerContainer'
import Seo from '../../components/Seo/Seo'
import { Box, Paper, Typography, Grid } from '@material-ui/core'
import useAboutStyles from './About.style'
import ContactForm from '../Contact/ContactForm'

export const About = () => {
    const classes = useAboutStyles()
    return (
        <Layout>
            <Seo title='About travoofy' />
            <InnerContainer>
                <Box className={classes.root}>
                    <Grid container spacing={3} >
                        <Grid item xs={12} sm={12} md={6} lg={4}>
                            <Paper elevation={0} className={classes.aboutText}>
                                <Typography variant='h5' className={classes.textSpace}>About Us</Typography>
                                <Typography>
                                    We are the top online travel company in Nigeria, and we genuinely want to make your tan unforgettable one.
                                </Typography>
                                <Typography className={classes.textSpace}>
                                    In order to create an unforgettable memory with your trip, Travoofy is a top online travel agency that provides flight and hotel bookings, visa assistance, tour packages, travel financing, protocol services, airport pickups, and any other travel necessities you could need.
                                </Typography>
                                <Typography className={classes.textSpace}>
                                    A group of enthusiastic professionals with experience in the tourism, business, marketing services, logistics, financial, and media sectors, as well as a shared love of travel, came up with the idea for Travoofy.
                                </Typography>
                            </Paper>
                        </Grid>

                        <Grid item xs={12} sm={12} md={6} lg={4}>
                            <ContactForm />
                        </Grid>
                    </Grid>
                </Box>

            </InnerContainer>
        </Layout>
    )
}
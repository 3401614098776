import { makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) => ({
    root: {
      height: '388px',
      marginBottom: theme.spacing(7),
      },
      container: {
        backgroundColor: theme.palette.background.paper,
      },
      ratingText: {
        fontWeight: 400,
        fontSize: '20px',
        color: theme.palette.common.black,
      },
      context: {
        display: 'flex',
        justifyContent: 'space-between',
      },
      cover: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center',
        flexDirection: 'column',
        backgroundColor: theme.palette.primary.main,
        [theme.breakpoints.down('sm')]: {
          width: '100%',
          display: 'none',
        },
      },
      avatar: {
        fontWeight: 700,
        padding: theme.spacing(5),
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.common.white,
        '& > *': {
          fontSize: '50px',
        }
      },
      avatar3: {
        fontWeight: 700,
        alignContent: 'flex-end',
        padding: theme.spacing(5),
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.common.white,
        '& > *': {
          fontSize: '50px',
        },
        [theme.breakpoints.up('md')]: {
          display: 'none',
        },
      },
      avatar2: {
        fontWeight: 700,
        padding: theme.spacing(5),
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        '& > *': {
          fontSize: '50px',
        }
      },
      text: {
        textDecoration: 'underline',
        fontSize: theme.spacing(2),
        marginTop: theme.spacing(2),
        color: theme.palette.common.white,
      },
      content: {
        padding: `${theme.spacing(3)}px`,
      },
      contain: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(5),
        padding: `${theme.spacing(1.5)}px ${theme.spacing(3)}px`,
      },
      item: {
        display: 'flex',
        gap: theme.spacing(3),
        alignItems: 'center',
        marginTop: theme.spacing(1),
      },
      guest: {
        fontSize: '16px',
        fontWeight: 400,
        color: theme.palette.text.secondary,
      },
      letter: {
        fontSize: '16px',
      },
      excellent: {
        fontSize: '24px',
        color: theme.palette.primary.main,
      },
      texts: {
        justifyContent: 'flex-end',
      },
      bar: {
        padding: '10px',
        margin: `${theme.spacing(2)}px ${theme.spacing(1)}px`,
      },
      num: {
        margin: `${theme.spacing(1)}px ${theme.spacing(1)}px`,
      },
      box: {
        flex: 1,
        width: '100%',
      },
      parent: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '50px',
        padding: theme.spacing(5),
      },
      number: {
        [theme.breakpoints.down('md')]: {
          display: 'none',
        },
      }
}));


import { makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    backgroundColor: theme.palette.background.paper,
  },
  container: {
    padding: theme.spacing(3),
  },
  boxContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  name: {
    fontSize: '14px',
  },
  check: {
    display: 'flex',
    paddingTop: theme.spacing(1)
  },
  checks: {
    display: 'flex',
    paddingTop: theme.spacing(10)
  },
  done: {
    color: theme.palette.primary.main,
  },
  boldText: {
    fontSize: '20px',
    fontWeight: 700,
  },
  small: {
    fontSize: '16px',
  },
  bold: {
    fontSize: '18px',
    fontWeight: 400,
  },
  flexBox: {
    display: "flex",
    gap: theme.spacing(3),
    padding: theme.spacing(2),
    '& > *' : {
      fontSize: "14px",
    }
  },
  icon: {
    fontSize: "30px",
  },
  contain: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    flexDirection: "column",
    height: "100%",
    gap: theme.spacing(4),
    "& > div": {
      width: "100%",
    },
  },
  reviewCon: {
    display: "flex",
    flexDirection: "column",
    textAlign: "right",
    height: "100%",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      marginTop: theme.spacing(4),
    },
  },
  review: {
    display: "flex",
    flexDirection: "column",
    alignItems: "right",
    textAlign: "right",
    [theme.breakpoints.down("xs")]: {
      alignItems: "left",
      textAlign: "left",
    },
  },
  smallText: {
    fontWeight: 400,
    fontSize: "12px",
    color: theme.palette.text.primary,
    marginTop: theme.spacing(1),
  },
  amount: {
    fontWeight: 700,
    fontSize: "24px",
    color: theme.palette.primary.main,
  },
  btn: {
    fontWeight: 700,
    fontSize: "20px",
    padding: theme.spacing(1),
  },
  button: {
    width: "fit-content",
    marginLeft: "auto",
    fontWeight: 700,
    color: "#ffffff",
    backgroundColor: "#FEAB5F",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 5,
    borderRadius: "5px",
    padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
  },
}));

import {
  Grid,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@material-ui/core";
import {
  CheckBoxOutlined,
  CloudUploadOutlined,
  DescriptionOutlined,
  EmailOutlined,
  PersonPinCircleOutlined,
  PhoneOutlined,
  PublicOutlined,
  TodayOutlined,
  WcOutlined,
} from "@material-ui/icons";
import { FormikErrors } from "formik";
import * as Yup from "yup";
import { ObjectShape } from "yup/lib/object";
import FormCountryList from "../components/Form/FormCountryList/FormCountryList";
import FormDate from "../components/Form/FormDate/FormDate";
import FormGenderList from "../components/Form/FormGenderList/FormGenderList";
import FormInput from "../components/Form/FormInput/FormInput";
import FormToggle from "../components/Form/FormToggle/FormToggle";
import FormUpload from "../components/Form/FormUpload/FormUpload";
import { countryListData } from "./helpers";
import { AnyObject, DocumentField, FormTypes, Gender } from "./types";

function getFieldValidation(field: DocumentField) {
  let valid;
  switch (field.type) {
    case FormTypes.Number:
      valid = Yup.number().label(field.label);
      if (field.required) valid = valid.required();
      return valid;
    case FormTypes.Email:
      valid = Yup.string().label(field.label).email();
      if (field.required) valid = valid.required();
      return valid;
    case FormTypes.Date:
      valid = Yup.date().label(field.label);
      if (field.required) valid = valid.required();
      return valid;
    case FormTypes.File:
      valid = Yup.string()
        .label(field.label)
        .test("file-check", (value: string | undefined, context) => {
          if (!value) return true;
          if (value.includes("data:")) return true;
          return context.createError({ message: value });
        });
      if (field.required) valid = valid.required();
      return valid;
    case FormTypes.County_Select:
      valid = Yup.string().label(field.label);
      if (field.required) valid = valid.required();
      return valid;
    case FormTypes.Gender_Select:
      valid = Yup.string().label(field.label).oneOf(Object.values(Gender));
      if (field.required) valid = valid.required();
      return valid;
    case FormTypes.Yes_No:
      valid = Yup.boolean().label(field.label);
      if (field.required) valid = valid.required();
      return valid;
    case FormTypes.Address:
    case FormTypes.Long_Text:
    case FormTypes.Text:
    case FormTypes.Phone_Number:
    default:
      valid = Yup.string().label(field.label);
      if (field.required) valid = valid.required();
      return valid;
  }
}

function getInitValues(field: DocumentField) {
  switch (field.type) {
    case FormTypes.Date:
      return field.value === undefined ? new Date() : field.value;
    case FormTypes.Yes_No:
      return field.value === undefined ? false : field.value;
    case FormTypes.File:
    case FormTypes.Number:
    case FormTypes.Email:
    case FormTypes.County_Select:
    case FormTypes.Gender_Select:
    case FormTypes.Address:
    case FormTypes.Long_Text:
    case FormTypes.Text:
    case FormTypes.Phone_Number:
    default:
      return field.value === undefined ? "" : field.value;
  }
}

export function getFormValidation(fields: DocumentField[]): ObjectShape {
  const data: ObjectShape = {};
  fields.forEach((field) => {
    data[field.name] = getFieldValidation(field);
  });
  return data;
}

export function getInitialValues(fields: DocumentField[], touched?: boolean) {
  const values: AnyObject<any> = {};
  if (!touched) {
    fields.forEach((field) => {
      values[field.name] = getInitValues(field);
    });
  } else {
    fields.forEach((field) => {
      values[field.name] = field.required;
    });
  }
  return values;
}

export function getFieldLabel(field: DocumentField) {
  return field.required ? `${field.label}*` : field.label;
}

export function getFormInput(
  field: DocumentField,
  values: AnyObject<any>,
  handleChange: (fieldName: string) => any,
  errors: FormikErrors<AnyObject<any>>
) {
  if (field.value !== undefined) handleChange(field.name)(field.value);

  switch (field.type) {
    case FormTypes.Number:
      return (
        <Grid key={field.name} item xs={12} sm={6} md={12} lg={6}>
          <FormInput
            name={field.name}
            type="number"
            label={getFieldLabel(field)}
            placeholder={field.label}
            helperText={errors[field.name] || field.description}
            value={values[field.name]}
            handleChange={handleChange(field.name)}
            error={Boolean(errors[field.name])}
          />
        </Grid>
      );

    case FormTypes.Email:
      return (
        <Grid key={field.name} item xs={12} sm={6} md={12} lg={6}>
          <FormInput
            name={field.name}
            type="email"
            label={getFieldLabel(field)}
            placeholder={field.label}
            helperText={errors[field.name] || field.description}
            value={values[field.name]}
            handleChange={handleChange(field.name)}
            error={Boolean(errors[field.name])}
            icon={EmailOutlined}
          />
        </Grid>
      );

    case FormTypes.Phone_Number:
      return (
        <Grid key={field.name} item xs={12} sm={6} md={12} lg={6}>
          <FormInput
            name={field.name}
            type="tel"
            label={getFieldLabel(field)}
            placeholder={field.label}
            helperText={errors[field.name] || field.description}
            value={values[field.name]}
            handleChange={handleChange(field.name)}
            error={Boolean(errors[field.name])}
            icon={PhoneOutlined}
          />
        </Grid>
      );

    case FormTypes.Date:
      return (
        <Grid key={field.name} item xs={12} sm={6} md={12} lg={6}>
          <FormDate
            name={field.name}
            label={getFieldLabel(field)}
            placeholder={field.label}
            helperText={errors[field.name] || field.description}
            value={values[field.name]}
            handleChange={handleChange(field.name)}
            error={Boolean(errors[field.name])}
            icon={TodayOutlined}
            showToolbar
          />
        </Grid>
      );

    case FormTypes.File:
      return (
        <Grid key={field.name} item xs={12} sm={6} md={12} lg={6}>
          <FormUpload
            name={field.name}
            label={getFieldLabel(field)}
            helperText={errors[field.name] || field.description}
            handleChange={handleChange(field.name)}
            error={Boolean(errors[field.name])}
            icon={CloudUploadOutlined}
          />
        </Grid>
      );

    case FormTypes.Gender_Select:
      return (
        <Grid key={field.name} item xs={12} sm={6} md={12} lg={6}>
          <FormGenderList
            helperText={errors[field.name] || field.description}
            value={values[field.name]}
            handleChange={handleChange(field.name)}
            error={Boolean(errors[field.name])}
            label={getFieldLabel(field)}
            name={field.name}
            icon={WcOutlined}
          />
        </Grid>
      );

    case FormTypes.County_Select:
      return (
        <Grid key={field.name} item xs={12} sm={6} md={12} lg={6}>
          <FormCountryList
            helperText={errors[field.name] || field.description}
            value={values[field.name]}
            handleChange={(value) => {
              handleChange(field.name)(countryListData.data[value]);
            }}
            error={Boolean(errors[field.name])}
            icon={PublicOutlined}
            label={getFieldLabel(field)}
            name={field.name}
          />
        </Grid>
      );

    case FormTypes.Yes_No:
      return (
        <Grid key={field.name} item xs={12} sm={6} md={12} lg={6}>
          <FormToggle
            name={field.name}
            label={getFieldLabel(field)}
            helperText={errors[field.name] || field.description}
            value={values[field.name]}
            handleChange={handleChange(field.name)}
            error={Boolean(errors[field.name])}
          />
        </Grid>
      );

    case FormTypes.Address:
      return (
        <Grid key={field.name} item xs={12}>
          <FormInput
            name={field.name}
            type="text"
            label={getFieldLabel(field)}
            placeholder={field.label}
            helperText={errors[field.name] || field.description}
            value={values[field.name]}
            handleChange={handleChange(field.name)}
            error={Boolean(errors[field.name])}
            icon={PersonPinCircleOutlined}
          />
        </Grid>
      );

    case FormTypes.Long_Text:
      return (
        <Grid key={field.name} item xs={12}>
          <FormInput
            name={field.name}
            type="text"
            label={getFieldLabel(field)}
            placeholder={`Type ${field.label} Here...`}
            helperText={errors[field.name] || field.description}
            value={values[field.name]}
            handleChange={handleChange(field.name)}
            error={Boolean(errors[field.name])}
            multiline
          />
        </Grid>
      );

    case FormTypes.Text:
    default:
      return (
        <Grid key={field.name} item xs={12} sm={6} md={12} lg={6}>
          <FormInput
            name={field.name}
            type="text"
            label={getFieldLabel(field)}
            placeholder={field.label}
            helperText={errors[field.name] || field.description}
            value={values[field.name]}
            handleChange={handleChange(field.name)}
            error={Boolean(errors[field.name])}
          />
        </Grid>
      );
  }
}

export function getFormView(field: DocumentField) {
  switch (field.type) {
    case FormTypes.Number:
      return (
        <Grid key={field.name} item xs={12} sm={6} md={12} lg={6}>
          <ListItem dense>
            <ListItemText
              primary={field.value}
              secondary={field.label}
              secondaryTypographyProps={{
                style: {
                  fontWeight: 700,
                  fontSize: "13px",
                  textTransform: "capitalize",
                },
              }}
            />
          </ListItem>
        </Grid>
      );

    case FormTypes.Email:
      return (
        <Grid key={field.name} item xs={12} sm={6} md={12} lg={6}>
          <ListItem dense>
            <ListItemAvatar>
              <EmailOutlined />
            </ListItemAvatar>
            <ListItemText
              primary={field.value}
              secondary={field.label}
              secondaryTypographyProps={{
                style: {
                  fontWeight: 700,
                  fontSize: "13px",
                  textTransform: "capitalize",
                },
              }}
            />
          </ListItem>
        </Grid>
      );

    case FormTypes.Phone_Number:
      return (
        <Grid key={field.name} item xs={12} sm={6} md={12} lg={6}>
          <ListItem dense>
            <ListItemAvatar>
              <PhoneOutlined />
            </ListItemAvatar>
            <ListItemText
              primary={field.value}
              secondary={field.label}
              secondaryTypographyProps={{
                style: {
                  fontWeight: 700,
                  fontSize: "13px",
                  textTransform: "capitalize",
                },
              }}
            />
          </ListItem>
        </Grid>
      );

    case FormTypes.Date:
      return (
        <Grid key={field.name} item xs={12} sm={6} md={12} lg={6}>
          <ListItem dense>
            <ListItemAvatar>
              <TodayOutlined />
            </ListItemAvatar>
            <ListItemText
              primary={field.value}
              secondary={field.label}
              secondaryTypographyProps={{
                style: {
                  fontWeight: 700,
                  fontSize: "13px",
                  textTransform: "capitalize",
                },
              }}
            />
          </ListItem>
        </Grid>
      );

    case FormTypes.File:
      return (
        <Grid key={field.name} item xs={12} sm={6} md={12} lg={6}>
          <ListItem dense>
            <ListItemAvatar>
              <DescriptionOutlined />
            </ListItemAvatar>
            <ListItemText
              primary={"File"}
              secondary={field.label}
              secondaryTypographyProps={{
                style: {
                  fontWeight: 700,
                  fontSize: "13px",
                  textTransform: "capitalize",
                },
              }}
            />
          </ListItem>
        </Grid>
      );

    case FormTypes.Gender_Select:
      return (
        <Grid key={field.name} item xs={12} sm={6} md={12} lg={6}>
          <ListItem dense>
            <ListItemAvatar>
              <WcOutlined />
            </ListItemAvatar>
            <ListItemText
              primary={field.value}
              secondary={field.label}
              secondaryTypographyProps={{
                style: {
                  fontWeight: 700,
                  fontSize: "13px",
                  textTransform: "capitalize",
                },
              }}
            />
          </ListItem>
        </Grid>
      );

    case FormTypes.County_Select:
      return (
        <Grid key={field.name} item xs={12} sm={6} md={12} lg={6}>
          <ListItem dense>
            <ListItemAvatar>
              <PublicOutlined />
            </ListItemAvatar>
            <ListItemText
              primary={field.value}
              secondary={field.label}
              secondaryTypographyProps={{
                style: {
                  fontWeight: 700,
                  fontSize: "13px",
                  textTransform: "capitalize",
                },
              }}
            />
          </ListItem>
        </Grid>
      );

    case FormTypes.Yes_No:
      return (
        <Grid key={field.name} item xs={12} sm={6} md={12} lg={6}>
          <ListItem dense>
            <ListItemAvatar>
              <CheckBoxOutlined />
            </ListItemAvatar>
            <ListItemText
              primary={field.value ? "YES" : "NO"}
              secondary={field.label}
              secondaryTypographyProps={{
                style: {
                  fontWeight: 700,
                  fontSize: "13px",
                  textTransform: "capitalize",
                },
              }}
            />
          </ListItem>
        </Grid>
      );

    case FormTypes.Address:
      return (
        <Grid key={field.name} item xs={12}>
          <ListItem dense>
            <ListItemAvatar>
              <PersonPinCircleOutlined />
            </ListItemAvatar>
            <ListItemText
              primary={field.value}
              secondary={field.label}
              secondaryTypographyProps={{
                style: {
                  fontWeight: 700,
                  fontSize: "13px",
                  textTransform: "capitalize",
                },
              }}
            />
          </ListItem>
        </Grid>
      );

    case FormTypes.Long_Text:
      return (
        <Grid key={field.name} item xs={12}>
          <ListItem dense>
            <ListItemText
              primary={field.label}
              primaryTypographyProps={{
                style: {
                  fontWeight: 700,
                  fontSize: "13px",
                  textTransform: "capitalize",
                },
              }}
              secondary={field.value}
            />
          </ListItem>
        </Grid>
      );

    case FormTypes.Text:
    default:
      return (
        <Grid key={field.name} item xs={12} sm={6} md={12} lg={6}>
          <ListItem dense>
            <ListItemText
              primary={field.value}
              secondary={field.label}
              secondaryTypographyProps={{
                style: {
                  fontWeight: 700,
                  fontSize: "13px",
                  textTransform: "capitalize",
                },
              }}
            />
          </ListItem>
        </Grid>
      );
  }
}

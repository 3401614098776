import { makeStyles, Theme } from '@material-ui/core';

const styles = (color?: string) => makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
  },
  adornment: {
    paddingRight: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
  },
  label: {
    marginBottom: theme.spacing(1.5),
    color: color || theme.palette.text.primary,
  },
  helperText: {
    fontSize: 'small',
  },
}));

export default styles;

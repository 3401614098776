import { makeStyles, Theme } from '@material-ui/core';
export default makeStyles((theme: Theme) => ({
  root: {
    fontSize: '14px',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(3),
  },
  link: {
    cursor: 'pointer',
    textDecoration: 'underline',
  },
}));

import {
  ActionTypes,
  AirportObject,
  StorageKeys,
} from "./../../utilities/types";
import { AirportActionType } from "./actions";
import Storage from "../../utilities/Storage";

const defaultState: AirportObject[] = Storage.getItem(StorageKeys.Airports, []);

export function AirportReducer(
  state = defaultState,
  action: AirportActionType
) {
  switch (action.type) {
    case ActionTypes.SET_AIRPORT:
      return [...action.payload];
    default:
      return [...state];
  }
}

import { makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  success: {
    color: theme.palette.success.main,
    border: 0,
  },
  paid: {
    color: theme.palette.success.main,
    border: 0,
  },
  pending: {
    color: theme.palette.warning.main,
    border: 0,
  },
  failed: {
    color: theme.palette.error.main,
    border: 0,
  },
  containerFlex: {
    gap: '30px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(2),
  },
  bold: {
    fontWeight: 700,
    fontSize: '13px',
  },
  amount: {
    fontWeight: 700,
    fontSize: '24px',
    color: theme.palette.primary.main,
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'underline !important',
  },
}));
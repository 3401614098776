import React from 'react';
import {Helmet} from "react-helmet";

type Props = {
    title: string;
    description?: string;
};

export default function Seo({title, description}: Props) {
    return (
        <Helmet>
            <title>{title} — Travoofy | Travel Smart!</title>
            <meta
                content={`${title} — Travoofy | Travel Smart!`}
                name="title"
            />
            <meta
                content={description || "Travoofy is a data driven OTA (Online Travel Agency) providing travel, tours, holiday and Logistics Company providing unparalleled and personalized travel solutions that have translated to high returns on our clients' corporate business and leisure travel & tour investments."}
                name="description"
            />
            <meta
                content={`${title} — Travoofy | Travel Smart!`}
                property="og:title"
            />
            <meta
                content={description || "Travoofy is a data driven OTA (Online Travel Agency) providing travel, tours, holiday and Logistics Company providing unparalleled and personalized travel solutions that have translated to high returns on our clients' corporate business and leisure travel & tour investments."}
                property="og:description"
            />
            <meta
                content={`${title} — Travoofy | Travel Smart!`}
                property="twitter:title"
            />
            <meta
                content={description || "Travoofy is a data driven OTA (Online Travel Agency) providing travel, tours, holiday and Logistics Company providing unparalleled and personalized travel solutions that have translated to high returns on our clients' corporate business and leisure travel & tour investments."}
                property="twitter:description"
            />

            <meta content="website" property="og:type"/>
            <meta content="https://travoofy.com/" property="og:url"/>
            <meta content="https://travoofy.com/logo512.png" property="og:image"/>
            <meta content="summary_large_image" property="twitter:card"/>
            <meta content="https://travoofy.com/" property="twitter:url"/>
            <meta content="https://travoofy.com/logo512.png" property="twitter:image"/>
        </Helmet>
    );
}

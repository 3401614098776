import { Box, IconButton } from '@material-ui/core';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import SliderStyle from './SliderStyle';

type Props = {
  children: ReactNode;
};

export default function Slider({ children }: Props) {
  const classes = SliderStyle();
  const [index, setIndex] = useState(0);
  const duration = 5 * 1000;
  let timer: any;
  const pause = useRef(false);
  const mounted = useRef(true);

  const getCount = (data: any): number => {
    if (data.hasOwnProperty('length')) return data.length;
    return 1;
  };

  const count = getCount(children);

  const left = () => {
    if (mounted.current) {
      if (index > 0) {
        setIndex(index - 1);
      } else {
        setIndex(count - 1);
      }
    }
  };

  const right = () => {
    if (mounted.current) {
      if (index < count - 1) {
        setIndex(index + 1);
      } else {
        setIndex(0);
      }
    }
  };

  const startAutoPlay = () => {
    pause.current = false;
    timer = setTimeout(() => {
      right();
    }, duration);
  };

  const stopAutoPlay = () => {
    pause.current = true;
    clearTimeout(timer);
  };

  useEffect(() => {
    if (pause.current === false) {
      startAutoPlay();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index]);

  useEffect(() => {
    return () => {
      mounted.current = false;
      stopAutoPlay();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box className={classes.root} onMouseLeave={startAutoPlay} onMouseEnter={stopAutoPlay}>
      <Box className={classes.inner}>
        <SwipeableViews index={index} axis='x' className={classes.root}>
          {children}
        </SwipeableViews>
        <Box className={classes.buttonCon}>
          <IconButton onClick={left} className={classes.swipeButton}>
            <ChevronLeft />
          </IconButton>
          <IconButton onClick={right} className={classes.swipeButton}>
            <ChevronRight />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
}

import { Grid, Box,  } from "@material-ui/core";
import FormInput from "../../Form/FormInput/FormInput";
import FormSelect from "../../Form/FormSelect/FormSelect";
import { Alert } from "@material-ui/lab";
import useVisaStyles from "./VisaApplicantTwo.style";
import { useFormikContext } from 'formik';



type Props = {
    hideLabel?: boolean;
    textToggle?: boolean;
    // action: (value: FlightSearchProps) => void;
    loading?: boolean;

};
type FormValues = {
    address: string;
    city: string;
    state: string;
    email: string;
    phoneNumber: string;
}

export const VisaApplicantTwo = ({ hideLabel }: Props) => {
    const { values, handleChange, touched, errors } = useFormikContext<FormValues>();
    const getLabel = (text: string) => (hideLabel ? "" : text);
    const classes = useVisaStyles();
    return (
        <Box>


            <Grid item container className={classes.root}>
                <Grid item md={12} >
                    <Alert severity='warning' className={classes.warning}>Use all given Information as they appear on your passport/ID to avoid complications.</Alert>

                </Grid>
                {/* email and phone number */}
                <Grid item container spacing={3}>
                    <Grid item className={classes.paper} container xs={12} sm={6} md={6}>
                        <FormInput
                            label="Email*"
                            placeholder="Email"
                            name="email"
                            type='email'
                            value={values.email}
                            handleChange={handleChange}
                            error={touched.email && Boolean(errors.email)}
                            helperText={touched.email && errors.email}

                        />
                    </Grid>

                    <Grid item className={classes.paper} xs={12} sm={6} md={6} >
                        <FormInput
                            label="Phone number*"
                            placeholder="Phone number"
                            name="phoneNumber"
                            type='tel'
                            value={values.phoneNumber}
                            handleChange={handleChange}
                            error={touched.phoneNumber && Boolean(errors.phoneNumber)}
                            helperText={touched.phoneNumber && errors.phoneNumber}

                        />
                    </Grid>

                </Grid>
                {/* Address */}
                <Grid item container spacing={3}>
                    <Grid item container xs={12} sm={12} md={12} >
                        <FormInput
                            label="Address *"
                            placeholder="Address"
                            name="address"
                            type="text"
                            value={values.address}
                            handleChange={handleChange}
                            error={touched.address && Boolean(errors.address)}
                            helperText={touched.address && errors.address}

                        />
                    </Grid>

                </Grid>
                {/* city and state */}
                <Grid item container spacing={2} >

                    <Grid item xs={12} sm={6} md={6} className={classes.paper} >
                        <FormInput
                            label="City *"
                            placeholder="Enter city e.g Abuja"
                            id="city"
                            type="text"
                            value={values.city}
                            handleChange={handleChange('city')}
                            error={touched.city && Boolean(errors.city)}
                            helperText={touched.city && errors.city}

                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={6} className={classes.paper} >
                        <FormInput
                            label={getLabel('state *')}
                            type='text'
                            name='state'
                            placeholder="Enter State e.g lagos"
                            id="state"
                            value={values.state}
                            handleChange={handleChange('state')}
                            error={touched.state && Boolean(errors.state)}
                            helperText={touched.state && errors.state}

                        />
                    </Grid>

                </Grid>

            </Grid>
            {/* Employment Information */}

        </Box >
    );
}

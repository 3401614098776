import React from 'react'
import InnerContainer from '../../components/InnerContainer/InnerContainer'
import Layout from '../../components/Layout/Layout'
import Seo from '../../components/Seo/Seo'
import { Typography, Grid, Paper, Box, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core'
import Policystyles from './PolicyStyles'
import { dataRetention, legalrightData, legalRights, rows, sharePersonalData } from './policyData'

const PolicyAndPrivacy = () => {
  const classes = Policystyles()
  return (
    <Layout>
      <Seo title={'privacy and policy'} />
      <InnerContainer>
        <Box className={classes.root}>

          <Typography variant='h4'>Our Privacy and Policy</Typography>

          <Grid >
            <Typography variant='h6'>Introduction</Typography>
            <Typography variant='body1'>
              At Travoofy, we respect your privacy and we are committed to protecting your personal data.
              This privacy policy will inform you as to how we look after your personal data when you visit
              our website (regardless of where you visit it from) and tell you about your privacy rights and
              how the law protects you.
            </Typography>
          </Grid>
          <Grid>
            <Typography variant='h6'>Purpose of this privacy policy</Typography>
            <Typography variant='body1'>
              This privacy policy aims to give you information on how Travoofy collects and processes
              your personal data through your use of this website, including any data you may provide
              through this website when you sign up to as a user of our website or sign up to receive our
              newsletter or purchase a product or service or take part in a competition or perform any
              other action that may require us to ask for any information from you.
              It is important that you read this privacy policy together with any other privacy policy or fair
              processing policy we may provide on specific occasions when we are collecting or
              processing personal data about you so that you are fully aware of how and why we are using
              your data. This privacy policy supplements other notices and privacy policies and is not
              intended to override them.
            </Typography>
          </Grid>
          <Grid>
            <Typography variant='h6'>Controller</Typography>
            <Typography variant='body1'>
              GMART ALLIANCE TOURS AND TRAVELS LIMITED “TRAVOOFY” is the controller and
              responsible for your personal data (collectively referred to as “TRAVOOFY”, "COMPANY",
              "we", "us" or "our" in this privacy policy).
              We have appointed a data protection officer (DPO) who is responsible for overseeing
              questions in relation to this privacy policy. If you have any questions about this privacy policy,
              including any requests to exercise <b>your legal rights</b>, please contact the DPO using the
              details set out below.
            </Typography>
          </Grid>
          <Grid>
            <Typography variant='h6'>Contact details</Typography>
            <div>
              <Typography variant='body1'>
                If you have any questions about this privacy policy or our privacy practices, please contact
                our DPO data privacy manager in the following ways:
              </Typography>
              <Typography>Your full name of legal entity:</Typography>
              <Typography>Your email address:</Typography>
              <Typography>Your postal address:</Typography >
              <Typography>Your Telephone number: </Typography>

            </div>
          </Grid>
          <Grid>
            <Typography variant='h6'>Changes to the privacy policy and your duty to inform us of changes</Typography>
            <Typography variant='body1'>
              We keep our privacy policy under regular review.
              It is important that the personal data we hold about you is accurate and current. Please keep
              us informed if your personal data changes during your relationship with us.
            </Typography>
          </Grid>
          <Grid>
            <Typography variant='h6'>Third-party links</Typography>
            <Typography variant='body1'>
              This website may include links to third-party websites, plug-ins and applications. Clicking on
              those links or enabling those connections may allow third parties to collect or share data about
              you. We do not control these third-party websites and are not responsible for their privacy
              statements. When you leave our website, we encourage you to read the privacy notice of every
              website you visit.
            </Typography>
          </Grid>
          {/* list item start 1 */}
          < Grid>
            <Grid >
              <div className={classes.liContainers}>
                <Typography variant='h6'>1. The Data we collect about you</Typography>
                <Typography variant='body1'>
                  Personal data, or personal information, means any information about an individual from
                  which that person can be identified. It does not include data where the identity has been
                  removed (anonymous data).
                  <br />
                  We may collect, use, store and transfer different kinds of personal data about you which we
                  have grouped together as follows:
                </Typography>
                <ul className={classes.liItem}>
                  <li>
                    <Typography variant='body1'>
                      <b>Identity Data</b> includes first name, maiden name, last name, username or similar
                      identifier, marital status, title, date of birth and gender. We may also ask
                      questions concerning your health.
                    </Typography>
                  </li>
                  <li>
                    <Typography variant='body1'>
                      <b>Contact Data</b> includes billing address, delivery address, email address and telephone numbers.
                    </Typography>
                  </li>
                  <li>
                    <Typography variant='body1'>
                      <b>Financial Data</b> includes bank account and payment card details.
                    </Typography>
                  </li>
                  <li>
                    <Typography variant='body1'>
                      <b>Transaction Data</b> includes details about payments to and from you and other details of
                      products and services you have purchased from us.
                    </Typography>
                  </li>
                  <li>
                    <Typography variant='body1'>
                      <b>Technical Data</b> includes internet protocol (IP) address, your login data, browser type
                      and version, time zone setting and location, browser plug-in types and versions,
                      operating system and platform and other technology on the devices you use to access
                      this website.
                    </Typography>
                  </li>
                  <li>
                    <Typography variant='body1'>
                      <b>Profile Data</b> includes your username and password, purchases or orders made by you,
                      your interests, preferences, feedback and survey responses.
                    </Typography>
                  </li>
                  <li>
                    <Typography variant='body1'>
                      <b>Usage Data</b> includes information about how you use our website, products and services.
                    </Typography>
                  </li>
                  <li>
                    <Typography variant='body1'>
                      <b>Marketing and Communications Data</b> includes your preferences in receiving marketing
                      from us and our third parties and your communication preferences.
                    </Typography>
                  </li>
                </ul>

              </div>
              <Grid>

                <Typography>
                  We also collect, use and share <b> Aggregated Data</b> such as statistical or demographic data for
                  any purpose. Aggregated Data could be derived from your personal data but is not
                  considered personal data in law as this data will not directly or indirectly reveal your identity.
                  For example, we may aggregate your Usage Data to calculate the percentage of users
                  accessing a specific website feature. However, if we combine or connect Aggregated Data
                  with your personal data so that it can directly or indirectly identify you, we treat the combined
                  data as personal data which will be used in accordance with this privacy policy.
                  We may request for some or all <b> Special Categories of Personal Data</b> about you this
                  includes details about your race or ethnicity, religious or philosophical beliefs, sex life, sexual
                  orientation, political opinions, trade union membership, information about your health, and
                  genetic and biometric data). Nor do we may if relevant, request for any information about
                  criminal convictions and offences to determine if you are a flight risk or other security
                  reasons
                </Typography>
                <h3> If you fail to provide personal data</h3>

                <Typography variant='body1'>
                  Where we need to collect personal data by law, or under the terms of a contract we have
                  with you, and you fail to provide that data when requested, we may not be able to perform
                  the contract we have or are trying to enter into with you (for example, to provide you with our
                  services). In this case, we may have to cancel a product or service you have with us but we
                  will notify you if this is the case at the time.
                </Typography>
              </Grid>
              {/* list item end */}
              <div className={classes.liContainers}>
                <Typography variant='h6'>2. How is your personal data collected?</Typography>

                <Typography variant='body1'>
                  We use different methods to collect data from and about you including through:
                </Typography>
                <ul className={classes.liItem}>
                  <li>
                    <Typography> <b>Direct interactions.</b> You may give us your identity, Contact and Financial Data
                      by filling in forms or by corresponding with us by post, phone, email or otherwise.
                      This includes personal data you provide when you:</Typography>
                    <ul>
                      <li>
                        <Typography variant='body1'>
                          apply for our products or services;
                        </Typography>
                      </li>
                      <li>
                        <Typography variant='body1'>
                          create an account on our website;
                        </Typography>

                      </li>
                      <li>
                        <Typography variant='body1'>
                          subscribe to our service or publications;
                        </Typography>
                      </li>
                      <li>
                        <Typography variant='body1'>
                          request marketing to be sent to you;
                        </Typography>
                      </li>
                    </ul>

                  </li>
                  <li>
                    <Typography variant='body1'>
                      <b>Automated technologies or interactions.</b> As you interact with our website, we may automatically
                      collect Technical Data about your equipment, browsing actions and patterns. We collect
                      this personal data by using cookies, server logs and other similar technologies. We may
                      also receive Technical Data about you if you visit other websites employing our cookies.
                      Please see our cookie policy for further details.
                    </Typography>
                  </li>
                  <li>
                    <Typography>
                      <b>Third parties or publicly available sources.</b> We will receive personal data
                      about you from various third parties and public sources as set out below:
                      Technical Data from the following parties:
                    </Typography>
                    <ol>
                      <li>
                        <Typography variant='body1'>
                          analytics providers such as Google, Facebook, Instagram and other media
                          platforms based within and or outside the geographical and legal jurisdiction
                          Federal Republic of Nigeria ;
                        </Typography>
                      </li>
                      <li>
                        <Typography variant='body1'>
                          advertising networks such as Google, Facebook, Instagram and other media
                          platforms based within and or outside the geographical and legal jurisdiction
                          Federal Republic of Nigeria ;
                        </Typography>
                      </li>
                      <li>
                        <Typography variant='body1'>
                          search information providers based within or outside the geographical and
                          legal jurisdiction of the Federal Republic of Nigeria.
                        </Typography>
                      </li>
                    </ol>

                  </li>
                  <li>
                    <Typography>
                      Contact, Financial and Transaction Data from providers of technical, payment
                      and delivery services such as Flutterwave based within or outside the
                      geographical and legal jurisdiction Federal Republic of Nigeria.
                    </Typography>
                  </li>
                  <li>
                    <Typography>
                      Identity and Contact Data from data brokers or aggregators based within or
                      outside the geographical and legal jurisdiction Federal Republic of Nigeria.
                    </Typography>
                  </li>
                  <li>
                    <Typography>
                      Identity and Contact Data from publicly available sources based within and
                      outside the geographical and legal jurisdiction Federal Republic of Nigeria.
                    </Typography>
                  </li>
                  <li>
                    <Typography>
                      Any other legal ways you collect personal data
                    </Typography>
                  </li>


                </ul>
              </div>
              {/* list item end */}
              <div className={classes.liContainers}>
                <Typography variant='h6'>3. How we use your personal data</Typography>
                <Typography>
                  We will only use your personal data when the law allows us to. Most commonly, we will use
                  your personal data in the following circumstances:
                </Typography>
                <ul className={classes.liItem}>
                  <li>
                    <Typography>
                      Where we need to perform the contract we are about to enter into or have
                      entered into with you.
                    </Typography>
                  </li>
                  <li>
                    <Typography>
                      Where it is necessary for our legitimate interests (or those of a third party) and
                      your interests and fundamental rights do not override those interests.
                    </Typography>
                  </li>
                  <li>
                    <Typography>
                      Where we need to comply with a legal obligation.
                    </Typography>
                  </li>
                </ul>
                <Grid className={classes.liItem}>

                  <Typography>
                    Kindly check the glossary below for list of lawfun bases, to find out more about the types of
                    lawful basis that we will rely on to process your personal data.
                  </Typography>

                  <Typography>
                    Generally, we do not rely on consent as a legal basis for processing your personal data
                    although we will get your consent before sending third party direct marketing
                    communications to you via email or text message. You have the right to withdraw consent to
                    marketing at any time by contacting us.
                  </Typography>
                </Grid>
                {/* table */}
                <Grid className={classes.liItem}>
                  <Typography variant='h6'>Purposes for which we will use your personal data</Typography>
                  <Typography>
                    We have set out below, in a table format, a description of all the ways we plan to use your
                    personal data, and which of the legal bases we rely on to do so. We have also identified
                    what our legitimate interests are where appropriate.
                  </Typography>
                  <Typography>
                    Note that we may process your personal data for more than one lawful ground depending on
                    the specific purpose for which we are using your data. Please contact us if you need details
                    about the specific legal ground we are relying on to process your personal data where more
                    than one ground has been set out in the table below
                  </Typography>
                  {/* table  */}
                  <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label='simple table'>
                      <TableHead>
                        <TableRow>
                          <TableCell>Purpose / Activity</TableCell>
                          <TableCell align='right'>Type of data</TableCell>
                          <TableCell align='right'>Lawful basis for processing
                            including basis of legitimate
                            interest</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows.map((row, index) => (
                          <TableRow key={index}>
                            <TableCell component='th' scope='row'>
                              <div className={classes.tableItem}>
                                {
                                  row.name.map((data, index) => (
                                    <Typography key={index}>{data}</Typography>
                                  ))
                                }
                              </div>
                            </TableCell>
                            <TableCell align='right' >
                              <div className={classes.tableItem}>
                                {
                                  row.DataType.map((data,index) => (
                                    <Typography key={index}>{data}</Typography>
                                  ))
                                }
                              </div>
                            </TableCell>
                            <TableCell align='right'> <div className={classes.tableItem}>
                              {
                                row.lawfulData.map((data,index) => (
                                  <Typography key={index}>{data}</Typography>
                                ))
                              }
                            </div></TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>

                </Grid>
                <Grid className={classes.liItem}>
                  <div>
                    <Typography variant='h6'>Marketing</Typography>
                    <Typography>
                      We strive to provide you with choices regarding certain personal data uses, particularly
                      around marketing and advertising.
                    </Typography>

                  </div>
                  <div>

                    <Typography variant='h6'>Promotional offer from us</Typography>

                    <Typography>
                      We may use your Identity, Contact, Technical, Usage and Profile Data to form a view on
                      what we think you may want or need, or what may be of interest to you. This is how we
                      decide which products, services and offers may be relevant for you (we call this marketing).
                    </Typography>
                    <Typography>
                      You will receive marketing communications from us if you have requested information from
                      us or purchased our services and you have not opted out of receiving that marketing.
                    </Typography>
                  </div>
                  <div>
                    <Typography variant='h6'>Third party marketing</Typography>
                    <Typography>
                      We will get your express opt-in consent before we share your personal data with any third
                      party for marketing purposes.
                    </Typography>
                  </div>
                  <div>
                    <Typography variant='h6'>Opting out</Typography>
                    <Typography>
                      You can ask us or third parties to stop sending you marketing messages at any time by
                      logging into the website and checking or unchecking relevant boxes to adjust your marketing
                      preferences or by following the opt-out links on any marketing message sent to you or by
                      contacting us at any time]. <b>(Inform the developers to have a section for opting in and
                        out of getting notifications)</b>
                    </Typography>
                    <Typography>
                      Where you opt out of receiving these marketing messages, this will not apply to personal
                      data provided to us as a result of a product/service purchase, warranty registration,
                      product/service experience or other transactions.

                    </Typography>

                  </div>
                  <div>
                    <Typography variant='h6'>Cookies</Typography>
                    <Typography>
                      You can set your browser to refuse all or some browser cookies, or to alert you when
                      websites set or access cookies. If you disable or refuse cookies, please note that some parts
                      of this website may become inaccessible or not function properly. For more information
                      about the cookies we use, please see.
                    </Typography>

                  </div>
                  <div>
                    <Typography variant='h6'>Change of purpose</Typography>
                    <Typography>
                      We will only use your personal data for the purposes for which we collected it, unless we reasonably consider that we need to use it for another reason and that reason is compatible with the original purpose. If you wish to get an explanation as to how the processing for the new purpose is compatible with the original purpose, please contact us.
                    </Typography>
                    <div>
                      <Typography>
                        If we need to use your personal data for an unrelated purpose, we will notify you and we will explain the legal basis which allows us to do so.
                      </Typography>
                    </div>
                    <div>
                      <Typography>
                        Please note that we may process your personal data without your knowledge or consent, in  compliance with the above rules, where this is required or permitted by law.
                      </Typography>
                    </div>
                  </div>
                </Grid>
              </div>
              {/* number 4 li */}
              <div className={classes.liContainers}>
                <Typography variant='h6'>4. With whom do we share personal data?</Typography>
                <div>
                  <Typography>
                    We process Personal Data in the countries in which we are established, and in other countries where third parties that we may use are based.
                  </Typography>

                  <Typography>
                    When processing your Personal Data, we may need to share it with other third
                    parties (including other entities within our Group of Companies), as set out below.
                    <br /> This list is non-exhaustive and there may be circumstances where we need to share Personal Data with other third parties:
                  </Typography>
                </div>
                <ul className={classes.liItem}>
                  {
                    sharePersonalData.map((data, index) =>
                    (<li key={index}>
                      <Typography>
                        {data}
                      </Typography>
                    </li>))
                  }
                </ul>
                <Typography>
                  Where necessary (such as when we transfer data to service providers) we put in place appropriate contractual arrangements and security mechanisms to protect the Personal Data shared and to comply with our data protection, confidentiality and security standards and obligations. Further details can be provided upon request
                </Typography>

              </div>
              {/* number 5 */}
              <div> <Typography variant='h6'>
                5.  International transfers
              </Typography>
                <div className={classes.liItem}>
                  <Typography>We may share your personal data within and outside the legal and geographical jurisdiction
                    of the Federal Republic of Nigeria where the service you are being offered may require the
                    involvement of an international company. An instance is where you book an international
                    flight with us. We may have to share your information with such international airline(s).</Typography>
                  <Typography>
                    We ensure your personal data is protected by requiring all companies we partner or work
                    with to follow the same rules when processing your personal data. These rules are called
                    "binding corporate rules”.

                  </Typography>
                  <Typography>
                    Many of our external third parties are based outside the legal and geographical jurisdiction of
                    the Federal Republic of Nigeria, so their processing of your personal data will involve a
                    transfer of data outside the Nigeria.
                  </Typography>
                  <div>
                    <Typography>
                      Whenever we transfer your personal data out of Nigeria, we ensure a similar degree of
                      protection is afforded to it by ensuring at least one of the following safeguards is
                      implemented:
                    </Typography>
                    <ul>
                      <li>
                        <Typography>We will only transfer your personal data to countries that have been deemed to
                          provide an adequate level of protection for personal data.</Typography>
                      </li>
                      <li>
                        <Typography>Where we use certain service providers, we may use specific contracts approved for use in Nigeria which give personal data the same protection it has in Nigeria.</Typography>
                      </li>

                    </ul>
                    <Typography>
                      Please contact us if you want further information on the specific mechanism used by us when transferring your personal data out of Nigeria.
                    </Typography>
                  </div>
                </div>

              </div>
              {/* number 6 */}
              <div className={classes.liItem} >
                <Typography variant='h6'>
                  6. Data security
                </Typography>
                <div>
                  <Typography>
                    We have put in place appropriate security measures to prevent your personal data from being accidentally lost, used or accessed in an unauthorised way, altered or disclosed. In addition, we limit access to your personal data to those employees, agents, contractors and other third parties who have a business need to know. They will only process your personal data on our instructions and they are subject to a duty of confidentiality.
                  </Typography>
                  <Typography>
                    We have put in place procedures to deal with any suspected personal data breach and will notify you and any applicable regulator of a breach where we are legally required to do so.
                  </Typography>
                </div>
              </div>
              {/* number 7 */}
              <div>
                <Typography variant='h6'>7. Data Retention</Typography>
                <Typography variant='h6'>
                  How long will you use my personal data for?
                </Typography>
                <div className={classes.liItem}>
                  {
                    dataRetention.map((data, index) => (
                      <Typography key={index}>
                        {data}
                      </Typography>
                    ))
                  }
                </div>
              </div>
              {/* number 8 */}
              <div>
                <Typography variant='h6'>
                  8. Your legal rights
                </Typography>
                <div>
                  <Typography>
                    Under certain circumstances, you have rights under data protection laws in relation to your personal data. Please click on the links below to find out more about these rights:
                  </Typography>
                  <ul className={classes.liItem}>
                    {
                      legalRights.map((data, index) => (
                        <li key={index}>
                          <Typography variant='body1'>{data}</Typography>
                        </li>
                      ))
                    }
                  </ul>
                  <Typography>If you wish to exercise any of the rights set out above, please contact us.</Typography>

                </div>
                {
                  legalrightData.map((data, index) => (
                    <div key={index} className={classes.liItem}>
                      <Typography variant='h6'>{data.title}</Typography>
                      {
                        data.data.map((data, index) => (
                          <Typography key={index}>{data}</Typography>
                        ))
                      }

                    </div>
                  ))

                }
              </div>
              {/* number 9 */}
              <div className={classes.liContainers}>
                <Typography variant='h6'>9. The data we collect from you</Typography>
                <div className={classes.liItem}>
                  <Typography >
                    Personal data, or personal information, means any information about an individual from which that person can be identified. It does not include data where the identity has been removed (anonymous data).
                  </Typography>
                  <Typography>
                    We may collect, use, store and transfer different kinds of personal data about you which we have grouped together as follows:
                  </Typography>

                  <ul>
                    <li>
                      <Typography>
                        <b>Identity Data</b>  includes first name, maiden name, last name, username or similar
                        identifier, marital status, title, date of birth and gender. We may also ask
                        questions concerning your health.
                      </Typography>
                    </li>
                  </ul>
                  <Typography>
                    <b>Identity Data</b> includes billing address , delivery address ,email address and telephone numbers.
                  </Typography>
                </div>
              </div>

            </Grid>

          </Grid>

        </Box>



      </InnerContainer >

    </Layout >
  )
}

export default PolicyAndPrivacy

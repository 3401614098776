import { Accordion, AccordionDetails, AccordionSummary, Box, Checkbox, Divider, FormControlLabel, FormGroup, Slider, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { Tune } from '@material-ui/icons';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { useEffect, useState } from 'react';
import { toNumberFormat } from '../../utilities/helpers';
import { AnyObject, DayTimes, FlightStops, StopsObject } from '../../utilities/types';
import SideBarStyle from './SideBarStyle';

type Props = {
  priceRange: number[];
  flightStops: number[];
  airlines: string[];
  departureTime: { [key: string]: number };
  arrivalTime: { [key: string]: number };
  currency: string;
  onPriceChange?: (value: { min: number, max: number }) => void;
  onStopChange?: (value: AnyObject<boolean>) => void;
  onDepartureChange?: (value: AnyObject<boolean>) => void;
  onArrivalChange?: (value: AnyObject<boolean>) => void;
  onAirlineChange?: (value: AnyObject<boolean>) => void;
}

export default function SideBar({
  onAirlineChange,
  onDepartureChange,
  onArrivalChange,
  onPriceChange,
  onStopChange,
  priceRange,
  flightStops,
  airlines,
  departureTime,
  arrivalTime,
  currency,
}: Props) {
  const classes = SideBarStyle();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const [filter, setFilter] = useState<boolean>(true);
  // Price
  const [showPrice, setShowPrice] = useState<boolean>(true);
  const [price, setPrice] = useState<number[]>(priceRange);
  // Stops
  const [showStops, setShowStops] = useState<boolean>(false);
  const [stops, setStops] = useState<AnyObject<StopsObject>>({
    [FlightStops.NONSTOP]: { label: 'Non-Stop', value: false },
    [FlightStops.ONESTOP]: { label: '1 Stop', value: false },
    [FlightStops.MORESTOP]: { label: '2 Stop +', value: false },
  });
  // Airlines
  const [showAirlines, setShowAirlines] = useState<boolean>(true);
  const [airlineNames, setAirlineNames] = useState<AnyObject<boolean>>({});
  // Departure Time
  const [showDeparture, setShowDeparture] = useState<boolean>(false);
  const [departures, setDepartures] = useState<AnyObject<boolean>>({
    [DayTimes.MORNING]: false,
    [DayTimes.AFTERNOON]: false,
    [DayTimes.EVENING]: false,
    [DayTimes.NIGHT]: false,
  });
  // Arrival Time
  const [showArrival, setShowArrival] = useState<boolean>(false);
  const [arrivals, setArrivals] = useState<AnyObject<boolean>>({
    [DayTimes.MORNING]: false,
    [DayTimes.AFTERNOON]: false,
    [DayTimes.EVENING]: false,
    [DayTimes.NIGHT]: false,
  });

  const priceMarks = ([a, b]: number[]) => [
    { label: toNumberFormat(a, currency), value: a },
    { label: toNumberFormat(b, currency), value: b },
  ];

  const handleStopChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStops({ ...stops, [event.target.name]: { ...stops[event.target.name], value: event.target.checked } });
  };

  const handleAirlineChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAirlineNames({ ...airlineNames, [event.target.name]: event.target.checked });
  };

  const handleDepartureChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDepartures({ ...departures, [event.target.name]: event.target.checked });
  };

  const handleArrivalChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setArrivals({ ...arrivals, [event.target.name]: event.target.checked });
  };

  useEffect(() => {
    if (onPriceChange) {
      onPriceChange({ min: priceRange[0], max: priceRange[1] });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [priceRange]);

  useEffect(() => {
    setFilter(!isSmall);
  }, [isSmall]);

  useEffect(() => {
    if (onStopChange) {
      const _val: AnyObject<boolean> = {} as any;
      Object.keys(stops).forEach((key) => {
        _val[key] = stops[key].value;
      });
      onStopChange(_val);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stops]);

  useEffect(() => {
    if (onDepartureChange) {
      onDepartureChange(departures);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [departures]);

  useEffect(() => {
    if (onArrivalChange) {
      onArrivalChange(arrivals);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [arrivals]);

  useEffect(() => {
    if (onAirlineChange) {
      onAirlineChange(airlineNames);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [airlineNames]);

  return (
    <Box className={classes.root}>
      <Accordion elevation={0} expanded={filter} onChange={(_, val) => setFilter(val)}>
        <AccordionSummary expandIcon={<Tune />}>
          <Typography variant='inherit' component={'strong'}>Filters</Typography>
        </AccordionSummary>
        <Divider />
        <AccordionDetails className={classes.body}>
          {/* Price Filter */}
          <Accordion elevation={0} expanded={showPrice} onChange={(_, val) => setShowPrice(val)}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant='inherit' component={'strong'}>Price</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Slider
                defaultValue={priceRange}
                value={price}
                min={priceRange[0]}
                max={priceRange[1]}
                marks={priceMarks(price)}
                valueLabelDisplay="off"
                onChange={(_, val) => setPrice(val as any)}
                onChangeCommitted={(_, val) => {
                  const _v: number[] = val as number[];
                  if (onPriceChange) {
                    onPriceChange({ min: _v[0], max: _v[1] });
                  }
                }}
                className={classes.slider}
              />
            </AccordionDetails>
          </Accordion>
          <Divider />
          {/* Price Filter */}

          {/* Stops Filter */}
          <Accordion elevation={0} expanded={showStops} onChange={(_, val) => setShowStops(val)}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant='inherit' component={'strong'}>Stops</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <FormGroup>
                {Object.keys(stops).map((stop, index) => (
                  <FormControlLabel
                    key={index}
                    className={classes.checkbox}
                    control={
                      <Checkbox
                        checked={stops[stop].value}
                        onChange={handleStopChange}
                        name={stop}
                        color='primary'
                      />
                    }
                    label={`${stops[stop].label} (${toNumberFormat(flightStops[index], undefined, true)})`}
                    disabled={flightStops[index] === 0}
                  />
                ))}
              </FormGroup>
            </AccordionDetails>
          </Accordion>
          <Divider />
          {/* Stops Filter */}

          {/* Departure Filter */}
          <Accordion elevation={0} expanded={showDeparture} onChange={(_, val) => setShowDeparture(val)}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant='inherit' component={'strong'}>Departure Time</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <FormGroup>
                {Object.keys(departures).map((departure, index) => (
                  <FormControlLabel
                    key={index}
                    className={classes.checkbox}
                    control={
                      <Checkbox
                        checked={departures[departure]}
                        onChange={handleDepartureChange}
                        name={departure}
                        color='primary'
                      />
                    }
                    label={`${departure} (${toNumberFormat(departureTime[departure], undefined, true)})`}
                    disabled={departureTime[departure] === 0}
                  />
                ))}
              </FormGroup>
            </AccordionDetails>
          </Accordion>
          <Divider />
          {/* Departure Filter */}

          {/* Arrival Filter */}
          <Accordion elevation={0} expanded={showArrival} onChange={(_, val) => setShowArrival(val)}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant='inherit' component={'strong'}>Arrival Time</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <FormGroup>
                {Object.keys(arrivals).map((arrival, index) => (
                  <FormControlLabel
                    key={index}
                    className={classes.checkbox}
                    control={
                      <Checkbox
                        checked={arrivals[arrival]}
                        onChange={handleArrivalChange}
                        name={arrival}
                        color='primary'
                      />
                    }
                    label={`${arrival} (${toNumberFormat(arrivalTime[arrival], undefined, true)})`}
                    disabled={arrivalTime[arrival] === 0}
                  />
                ))}
              </FormGroup>
            </AccordionDetails>
          </Accordion>
          <Divider />
          {/* Arrival Filter */}

          {/* Airlines Filter */}
          <Accordion elevation={0} expanded={showAirlines} onChange={(_, val) => setShowAirlines(val)}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant='inherit' component={'strong'}>Airlines</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <FormGroup>
                {airlines.map((name, index) => (
                  <FormControlLabel
                    key={index}
                    className={classes.checkbox}
                    control={
                      <Checkbox
                        checked={!!airlineNames[name]}
                        onChange={handleAirlineChange}
                        name={name}
                        color='primary'
                      />
                    }
                    label={name}
                  />
                ))}
              </FormGroup>
            </AccordionDetails>
          </Accordion>
          {/* Airlines Filter */}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}

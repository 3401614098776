import React from 'react'
import { useVisaStyles } from '../VisaAdditionalInfo.styles'
import { Grid} from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import InfoIcon from '@material-ui/icons/Info';

import { useFormikContext } from 'formik';
import FormUpload from '../../Form/FormUpload/FormUpload';

type Props = {
  hideLabel?: boolean;
  textToggle?: boolean;
  // action: (value: FlightSearchProps) => void;
  loading?: boolean;
};

type ChildFormProps = {
  childTravels: {
    visaDataPage: File,
    passportPhotograph: File,
    birthCertificate: File,
  }
}


export default function ChildForm({ hideLabel }: Props) {
  const classes = useVisaStyles();
  const { handleChange, errors, touched } = useFormikContext<ChildFormProps>();
  return (
    <Grid container>
      {/* passport upload and warning icon */}
      <Grid item container className={classes.titleFour} spacing={3}>
        <Grid item xs={12} sm={6} md={6} className={`${classes.paper} `} >
          <FormUpload
            label="Visa Data page *"
            name="childTravels.visaDataPage"
            handleChange={handleChange('childTravels.visaDataPage')}
            helperText={touched?.childTravels?.visaDataPage && errors?.childTravels?.visaDataPage}
            error={touched?.childTravels?.visaDataPage && Boolean(errors?.childTravels?.visaDataPage)}


          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} className={`${classes.paper} `} >
          <FormUpload
            label="Passport photograph photo *"
            name="childTravels.passportPhotograph"
            handleChange={handleChange('childTravels.passportPhotograph')}
            helperText={touched?.childTravels?.passportPhotograph && errors?.childTravels?.passportPhotograph}
            error={touched?.childTravels?.passportPhotograph && Boolean(errors?.childTravels?.passportPhotograph)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} className={`${classes.paper} `} >
          <FormUpload
            label="Birth Certificate (for child/children) *"
            name="childTravels.birthCertificate"
            handleChange={handleChange('childTravels.birthCertificate')}
            helperText={touched?.childTravels?.birthCertificate && errors?.childTravels?.birthCertificate}
            error={touched?.childTravels?.birthCertificate && Boolean(errors?.childTravels?.birthCertificate)}

          />
        </Grid>

        <Grid item xs={12} sm={6} md={6} className={classes.fileUpload} >
          <Alert icon={<InfoIcon />} severity='warning' color='info'>All file uploads must be in .pdf formats</Alert>
        </Grid>

      </Grid>
    </Grid>
  )
}

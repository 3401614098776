import { makeStyles, Theme } from '@material-ui/core';

const styles = (color?: string) => makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
  },
  adornment: {
    paddingRight: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
  },
  divider: {
    marginLeft: theme.spacing(1),
  },
  label: {
    marginBottom: theme.spacing(1.5),
    color: color || theme.palette.text.primary,
    textTransform: 'capitalize',
  },
  popover: {
    position: 'absolute',
    borderRadius: '5px',
    marginTop: theme.spacing(0.5),
    border: `1px solid ${theme.palette.primary.main}`,
    minWidth: '300px',
    maxHeight: `320px`,
    overflow: 'hidden',
    overflowY: 'auto',
    zIndex: 18000,
    [theme.breakpoints.down('xs')]: {
      width: '80%',
    },
  },
  searchItem: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    display: 'flex',
    flexDirection: 'row',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.background.default,
    },
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: `${theme.spacing(3)}px`,
    fontSize: `16px`,
    fontWeight: 'bold',
  },
  labelCon: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    marginLeft: theme.spacing(2),
  },
}));

export default styles;

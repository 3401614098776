import {
  Box,
  Divider,
  Icon,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { ChangeEvent, ElementType, useState } from "react";
import FormSelectStyle from "./FormSelectStyle";

type Props = {
  label?: string;
  placeholder?: string;
  icon?: ElementType<any>;
  options?: { label: string; value: any }[];
  color?: "white";
  value?: string;
  defaultValue?: any;
  type?: string;
  error?: any;
  helperText?: any;
  id?: string;
  name?: string;
  hidden?: boolean;
  disable?: boolean;
  handleChange?: (value: any) => void;
};

export default function FormSelect({
  label,
  disable,
  defaultValue,
  handleChange,
  placeholder,
  icon,
  options,
  value,
  color,
  type,
  error,
  helperText,
  id,
  name,
  hidden,
}: Props) {
  const classes = FormSelectStyle(color)();
  const [data, setData] = useState(value || "");

  const onChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const val = e.target.value;
    setData(val);
    if (handleChange) {
      handleChange(val);
    }
  };

  return (
    <Box className={classes.root}>
      <Typography className={classes.label} variant="body2">
        {label}
      </Typography>
      <TextField
        error={error}
        hidden={hidden}
        disabled={disable}
        helperText={helperText}
        InputProps={{
          startAdornment: icon ? (
            <Box className={classes.adornment}>
              <Icon component={icon} color="disabled" />
              <Divider
                className={classes.divider}
                orientation="vertical"
                flexItem
              />
            </Box>
          ) : null,
        }}
        variant="outlined"
        placeholder={placeholder || label}
        fullWidth
        select
        type={type}
        id={id}
        name={name}
        value={data}
        defaultValue={data}
        onChange={onChange}
      >
        <MenuItem selected value={defaultValue || ""}>
          {placeholder}
        </MenuItem>
        {options?.map((item, index) => (
          <MenuItem key={index} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </TextField>
    </Box>
  );
}

import { makeStyles,Theme } from '@material-ui/core/styles';




const styles = (makeStyles((theme: Theme) => ({
    root: {
            marginTop: theme.spacing(4),
            gap: theme.spacing(4),
            padding:'20px',
    },
    paper: {
        padding: theme.spacing(1),
        textAlign: 'right',
        color: theme.palette.text.secondary,
        marginBottom: theme.spacing(1),
        flexWrap: 'nowrap',

    },
    warning: {
       itemsAlign: 'center',
       justifyContent: 'center',
       wordSpacing: '2px',
    },
    radioGroup:{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
       
    },
})));
export default styles
import { makeStyles, Theme } from "@material-ui/core";
import { dark } from "../../../utilities/helpers";
import { ThemeMode } from "../../../utilities/types";

export default function styles(mode: ThemeMode) {
  return makeStyles((theme: Theme) => ({
    root: {
      marginTop: "calc(min(-100vw / 3, -350px) + 130px)",
    },
    topBar: {
      background: dark(mode) ? "rgba(56, 100, 125, 1)" : "white",
      borderRadius: "10px 10px 0 0",
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingRight: theme.spacing(4),
      width: "fit-content",
    },
    headerText: {
      marginBottom: theme.spacing(3),
    },
    title: {
      fontFamily: "Happy Monkey",
      fontSize: "32px",
      color: "yellow",
      fontWeight: 800,
      lineHeight: "50.61px",
    },
    subtitle: {
      fontSize: "20px",
      color: theme.palette.common.white,
    },
    button: {
      marginLeft: theme.spacing(4),
      borderRadius: "0",
      borderBottom: `2px solid transparent`,
    },
    buttonDark: {
      marginLeft: theme.spacing(4),
      borderRadius: "0",
      borderBottom: `2px solid transparent`,
      color: "rgba(255, 255, 255, 0.82)",
    },
    buttonActive: {
      marginLeft: theme.spacing(4),
      borderRadius: "0",
      borderBottom: `2px solid ${theme.palette.primary.main}`,
      fontWeight: 700,
    },
    buttonActiveDark: {
      marginLeft: theme.spacing(4),
      borderRadius: "0",
      borderBottom: `2px solid ${theme.palette.common.white}`,
      fontWeight: 700,
    },
    cardCon: {
      overflowY: "hidden",
      backgroundColor: "white",
    },
    card: {
      width: "100%",
      padding: theme.spacing(4),
      borderRadius: "0",
    },
    group: {
      display: "flex",
    },
    outlined: {
      border: "1px solid #cbd5d9",
    },
    contains: {
      color: theme.palette.primary.main,
    },
  }));
}

import { makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
  },
  stepper: {
    width: '100%',
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.default,
  },
  legCon: {
    marginTop: theme.spacing(2),
    width: '100%',
  },
  legTitle: {
    fontWeight: 'bold',
  },
  actionsContainer: {
    fontSize: '10px',
    marginTop: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    color: theme.palette.primary.main,
  },
  iconSmall: {
    color: theme.palette.primary.main,
    fontSize: '12px',
    marginLeft: theme.spacing(-3.3),
    marginRight: theme.spacing(1.7),
  },
  text: {
    fontSize: '11px',
    color: theme.palette.text.primary,
  },
  smallText: {
    display: 'flex',
    gap: '10px',
  },
}));

import { Box, Grid, Switch, Typography, useTheme } from "@material-ui/core";
import React from "react";
import { MdDarkMode, MdLightMode } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import logo from '../../../assets/images/logo-white.png';
import { gtagEvent, toggleTheme } from '../../../utilities/helpers';
import { MenuItems, SocialItems } from '../../../utilities/menuItems';
import { AppStore } from '../../../utilities/types';
import Link from "../../AppLink/AppLink";
import InnerContainer from "../../InnerContainer/InnerContainer";
import FooterStyle from './FooterStyle';

// IMPORT ICONS
import { Brightness1, Facebook, Instagram, LinkedIn, Twitter, YouTube } from '@material-ui/icons';

export default function Footer() {
  const theme = useTheme();
  const { mode } = useSelector((store: AppStore) => store.themeSwitch);
  const dispatch = useDispatch();
  const classes = FooterStyle(theme);
  const addressInfo = {
    address: ['40a Udeco Medical Road, Chevy View Estate, Lekki Lagos, Nigeria', "Plot 502 Dalaba Street, No are Mall, Wuse Zone 5, Abuja.", "Xorint Towers, 7 Okuru Road, Opposite Golf Estate Gate, Trans - Amadi, Port Harcourt."],
    phone: 'Phone: (01) 888-0305',
    mail: 'Mail: bookings@travoofy.com',
  };

  const toggle = () => {
    toggleTheme(dispatch, mode);
  };

  const socialIcons = [
    <Facebook fontSize="small" style={{ marginBottom: -4, marginRight: 5 }} />,
    <Instagram fontSize="small" style={{ marginBottom: -4, marginRight: 5 }} />,
    <Twitter fontSize="small" style={{ marginBottom: -4, marginRight: 5 }} />,
    <LinkedIn fontSize="small" style={{ marginBottom: -4, marginRight: 5 }} />,
    <YouTube fontSize="small" style={{ marginBottom: -4, marginRight: 5 }} />
  ]

  return (
    <Box className={classes.root} component={"footer"} pt={3} pb={5}>
      <InnerContainer>
        <Grid container spacing={3}>
          <Grid item sm={12} md={5}>
            <Typography variant={"h4"} className={classes.header}>
              <img src={logo} alt='Travoofy' height={'40px'} />
            </Typography>
            <Box className={classes.travoofy}>
              {
                addressInfo.address.map((item, index) => (
                  <Typography className={classes.travoofy} key={index}>{item}</Typography>
                ))
              }
              <Typography className={classes.travoofy}>{addressInfo.phone}</Typography>
              <Typography className={classes.travoofy}>{addressInfo.mail}</Typography>
            </Box>
            <Box className={classes.themeCon}>
              <MdLightMode size={25} />
              <Switch
                icon={<Brightness1 className={classes.icon} color='primary' />}
                checkedIcon={<Brightness1 className={classes.icon} color='secondary' />}
                color={'primary'} checked={mode === 'dark'}
                onChange={toggle}
              />
              <MdDarkMode size={25} />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant={"h4"} className={classes.header}>
              Join us on
            </Typography>
            {SocialItems.map((item, index) => (
              <Link
                key={index}
                to="#"
                className={classes.link}
                onClick={() => {
                  gtagEvent("social_link", { name: "Phone Call" });
                  window.location.assign(item.link);
                }}
              >
                {socialIcons[index]} {item.name}
              </Link>
            ))}
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant={"h4"} className={classes.header}>
              Quick links
            </Typography>
            {MenuItems.map((item, index) => (
              <Link key={index} className={classes.link} to={item.link}>{item.name}</Link>
            ))}
          </Grid>
        </Grid>
      </InnerContainer>
    </Box>
  )
}
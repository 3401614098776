import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Paper,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import { sub } from 'date-fns';
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";
import api from "../../utilities/api";
import { gtagEvent } from '../../utilities/helpers';
import pageRoutes from "../../utilities/pageRoutes";
import {
  AppStore,
  PassengerDetail,
  PassengerType,
  PriceDetail,
} from "../../utilities/types";
import PassengerForm from "../PassengerForm/PassengerForm";
import BookFlightStyle from "./BookFlightStyle";

export default function BookFlight() {
  const classes = BookFlightStyle();
  const history = useHistory();
  const [terms, setTerms] = useState<boolean>(false);
  const [activeStep, setActiveStep] = useState<number>(0);
  const {
    flightData,
    user: { token },
  }: AppStore = useSelector((store: AppStore) => store);
  const flight = flightData.FlightSummaryModel
    ? flightData.FlightSummaryModel.FlightCombination
    : flightData.FlightObject?.FlightCombination;
  const prices: PriceDetail[] = flight?.PriceDetails as any;
  const [passengerData, setPassengerData] = useState<PassengerDetail[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const getSteps = (data: PriceDetail[]) => {
    if (passengerData.length === 0) {
      const adults: PassengerDetail[] = [];
      const children: PassengerDetail[] = [];
      const infants: PassengerDetail[] = [];
      const base = {
        FirstName: "",
        MiddleName: "",
        LastName: "",
        DateOfBirth: "",
        Gender: "" as any,
        Email: "",
        PhoneNumber: "",
        PassportNumber: "",
        ExpiryDate: new Date().toDateString(),
        PassportIssuingAuthority: "",
        Country: "",
        Title: "",
        isPassportRequired: !!flightData.IsPassportRequired,
      };
      if (flight) {
        data.forEach((item) => {
          switch (item.PassengerType) {
            case "Adult":
              for (let i = 1; i <= flight.Adults; i++) {
                adults.push({
                  PassengerType: PassengerType.ADULT,
                  ...base,
                  DateOfBirth: sub(new Date(), { years: 12 }).toDateString(),
                });
              }
              break;
            case "Child":
              for (let i = 1; i <= flight.Children; i++) {
                children.push({
                  PassengerType: PassengerType.CHILD,
                  ...base,
                  DateOfBirth: sub(new Date(), { years: 2 }).toDateString(),
                });
              }
              break;
            case "Infant":
            default:
              for (let i = 1; i <= flight.Infants; i++) {
                infants.push({
                  PassengerType: PassengerType.INFANT,
                  ...base,
                  DateOfBirth: sub(new Date(), { years: 1 }).toDateString(),
                });
              }
              break;
          }
        });
      }
      setPassengerData([...adults, ...children, ...infants]);
    }
  };

  const handleNext = () => {
    setActiveStep((prev) => ++prev);
  };

  const handleBack = () => {
    setActiveStep((prev) => --prev);
  };

  const submit = () => {
    setLoading(true);
    const formData = {
      PassengerDetails: passengerData,
      BookingItemModels: [
        {
          ProductType: "Flight",
          BookingData: flightData.SelectData,
          BookingId: flightData.BookingId,
          TargetCurrency: "NGN",
        },
      ],
    };
    api
      .post("/book/flight", formData, true)
      .then(({ status, message, data }) => {
        setLoading(false);
        if (status) {
          gtagEvent("level_end", {
            level_name: "Passenger Details",
            success: true,
          });
          history.push(`${pageRoutes.Payment}/${data.BookingId}`);
        } else {
          gtagEvent("level_end", {
            level_name: "Passenger Details",
            success: false,
          });
          swal("Oops!", message, "error");
        }
      });
  };

  useEffect(() => {
    getSteps(prices);
    gtagEvent("level_start", { level_name: "Passenger Details" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flight]);

  const getSeverity = (SeverityLevel: "High" | "Medium" | "Low") => {
    switch (SeverityLevel) {
      case "High":
        return "error";
      case "Medium":
        return "warning";
      case "Low":
      default:
        return "info";
    }
  };

  return (
    <>
      <Paper elevation={0} className={classes.paper}>
        <Typography className={classes.text}>Passenger details</Typography>
        <Stepper
          className={classes.stepper}
          activeStep={activeStep}
          orientation="vertical"
        >
          {passengerData.map((step, index) => (
            <Step key={index}>
              <StepLabel
                classes={{
                  active: classes.stepLabelActive,
                  completed: classes.stepLabelComplete,
                }}
                onClick={() => {
                  if (activeStep > index) setActiveStep(index);
                }}
              >
                {index === 0 ? "Main" : ""}&nbsp;Passenger
                {index === 0 ? "" : " " + (index + 1)}&nbsp;(
                {step.PassengerType})
              </StepLabel>
              <StepContent>
                <PassengerForm
                  activeStep={activeStep}
                  handleBack={handleBack}
                  handleNext={handleNext}
                  passport={!!flightData.IsPassportRequired}
                  data={step}
                  saveData={setPassengerData}
                  date={flight?.FlightModels[flight.FlightModels.length - 1].ArrivalTime as string}
                />
              </StepContent>
            </Step>
          ))}
        </Stepper>
        {flightData.CustomMessages && flightData.CustomMessages.length > 0 ? (
          <>
            <br />
            {flightData.CustomMessages.map((message, index) => (
              <Alert
                className={classes.alert}
                variant="outlined"
                key={index}
                severity={getSeverity(message.SeverityLevel)}
              >
                <Typography component={AlertTitle} variant={"caption"}>
                  {message.Title}
                </Typography>
                {(() => {
                  if (message.Title.toLowerCase() === 'you require a transit visa') {
                    return (
                      <div dangerouslySetInnerHTML={{ __html: 'Get your transit visa with Travoofy.<br/> Send a request to visa@travoofy.com or WhatsApp message to 08120977653.<br/> Processing time: 5-7 working days after biometric appointment date.' }} />
                    )

                  } else {
                    return (
                      <div dangerouslySetInnerHTML={{ __html: message.Message.replace(/Wakanow/g, "Travoofy") }} />

                    )

                  }

                })()}

              </Alert>
            ))}
          </>
        ) : null}
      </Paper>

      {token ? (
        <>
          <Box className={classes.privacy}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={terms}
                  onChange={(e) => setTerms(e.target.checked)}
                  name="terms"
                  color="primary"
                />
              }
              labelPlacement={"end"}
              classes={{ label: classes.terms }}
              label={`By clicking "proceed" to reserve your flights, you agree and accept the Terms and Conditions of Travoofy and its travel partners. Note that the quoted fare / price is not guaranteed until payment has been confirmed.`}
            />
          </Box>
          <Button
            startIcon={loading ? <CircularProgress /> : undefined}
            onClick={submit}
            disabled={activeStep < passengerData.length || !terms || loading}
            color="primary"
            variant="contained"
            size="large"
          >
            Proceed
          </Button>
        </>
      ) : null}
    </>
  );
}

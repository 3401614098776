import { makeStyles, Theme } from '@material-ui/core/styles';


export default makeStyles((theme: Theme) => ({
    root: {
        margin: '50px auto',
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },


    },
    paragraph: {
        color: 'grey'
    },
    List: {
        flexDirection: 'column',
        gap: '16px',
        paddingTop: '20px',
    },
    rowList: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',

    },

    divider: {
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(8),
        width: '100%',
        border: '1px dotted grey'
    },
    last: {
       marginTop: '20px',
    },

    listItem: {
        padding: '0',
        color: 'grey'
    },
    headers: {

        fontSize: '1rem',
        fontWeight: 600,

        marginBottom: '10px',
    },
    headers1: {
        fontSize: '1.1rem',
        fontWeight: 600,
        marginBottom: theme.spacing(4),
    },
    columnList: {
      
        alignItems: 'flex-start',
        flexWrap: 'nowrap',
        gap: '16px',
        [theme.breakpoints.down('sm')]: {
            flexWrap: 'wrap',
        },
    },
    columnList1: {
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        flexWrap: 'nowrap',
        [theme.breakpoints.down('sm')]: {
            flexWrap: 'wrap',
        },
    },
    items3: {
        lineHeight: '1.5rem',
        color: 'grey',
    },
    items: {
        padding: '18px 20px'
    },
    socialIcons:{
        padding: '4px',
    }
}))
import {
  Box,
  Divider,
  Icon,
  InputBaseComponentProps,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { ElementType } from "react";
import FormInputStyle from "./FormInputStyle";

type Props = {
  label?: string;
  placeholder?: string;
  multiline?: boolean;
  icon?: ElementType<any>;
  color?: "white";
  value?: string;
  handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  type?: string;
  error?: any;
  helperText?: any;
  id?: string;
  name?: string;
  hidden?: boolean;
  disable?: boolean;
  smText?: boolean;
  inputProps?: InputBaseComponentProps;
};

export default function FormInput({
  label,
  placeholder,
  icon,
  multiline,
  color,
  value,
  handleChange,
  type,
  error,
  helperText,
  id,
  name,
  hidden,
  disable,
  inputProps,
}: Props) {
  const classes = FormInputStyle(color)();

  return (
    <Box className={classes.root}>
     
        <Typography className={classes.label} variant="body2">
          {label}
        </Typography>
      <TextField
        error={error}
        hidden={hidden}
        disabled={disable}
        type={type}
        id={id}
        name={name}
        value={value}
        helperText={helperText}
        inputProps={inputProps}
        InputProps={{
          onChange: handleChange,
          // classes: { input: classes.input },
          startAdornment: icon ? (
            <Box className={classes.adornment}>
              <Icon component={icon} color="disabled" />
              <Divider
                className={classes.divider}
                orientation="vertical"
                flexItem
              />
            </Box>
          ) : null,
        }}
        minRows={3}
        variant="outlined"
        placeholder={placeholder || label}
        fullWidth
        multiline={multiline}
      />
    </Box>
  );
}

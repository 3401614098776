import { ActionTypes, BookingData } from "../../utilities/types";

export type BookingDataActionType = {
  type: ActionTypes.SET_BOOKING_DATA | ActionTypes.CLEAR_BOOKING_DATA;
  payload: BookingData | null;
};

export function Action_SetBookingData(
  payload: BookingData
): BookingDataActionType {
  return {
    type: ActionTypes.SET_BOOKING_DATA,
    payload: { ...payload },
  };
}

export function Action_ClearBookingData(): BookingDataActionType {
  return {
    type: ActionTypes.CLEAR_BOOKING_DATA,
    payload: null,
  };
}

import { makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) => ({
  root: {
    borderRadius: 0,
    position: "sticky",
    top: "110px",
  },
  container: {
    padding: theme.spacing(3),
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
  },
  boldText: {
    fontWeight: 700,
    fontSize: '24px',
  },
  lightText: {
    fontSize: '16px',
  },
  content: {
    padding: theme.spacing(3),
  },
  top: {
    marginTop: theme.spacing(3)
  },
}));

import React from "react";
import { useVisaStyles } from './VisaApplicant.styles'
import { Grid, } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import InfoIcon from '@material-ui/icons/Info';
import { CalendarTodayOutlined, FileCopyOutlined } from "@material-ui/icons";
import FormInput from "../../Form/FormInput/FormInput";
import FormUpload from "../../Form/FormUpload/FormUpload";
import { useFormikContext } from "formik";
import FormSelect from "../../Form/FormSelect/FormSelect";
import FormDate from "../../Form/FormDate/FormDate";
import { Title } from "../../../utilities/types";
import FormCountryList from "../../Form/FormCountryList/FormCountryList";


type Props = {
    title: string;
    firstName: string;
    lastName: string;
    middleName: string;
    nationality: string;
    gender: string;
    dateOfBirth: string;
    maritalStatus: string;
    passportPhoto: File | null;

}
export const VisaApplicantOne = () => {
    const { values, handleChange, errors, touched } = useFormikContext<Props>();

    const classes = useVisaStyles();

    // const getLabel = (text: string) => (hideLabel ? "" : text);

    return (
        <Grid container className={classes.root} >

            <Grid item container className={classes.main}>
                <Grid item container className={classes.alert} >
                    <Alert severity='warning'>Use all given names and surname exactly as they appear on your passport/ID to avoid complications.</Alert>
                </Grid>
                <Grid container item >
                    {/* first name and last name  */}
                    <Grid item container className={classes.titleOne} spacing={3}>
                        <Grid item container xs={12} sm={6} md={6} className={classes.titleOneChild}>

                            <Grid item xs={5} sm={4} md={4} className={classes.paper} style={{ flex: '1' }}>
                                <FormSelect
                                    options={[{ label: Title.Mr, value: Title.Mr }, { label: Title.Mrs, value: Title.Mrs }, { label: Title.Ms, value: Title.Ms }, { label: Title.Hon, value: Title.Hon }, { label: Title.Dr, value: Title.Dr }]}
                                    name='title'
                                    defaultValue={'Title'}
                                    label='Title *'
                                    handleChange={handleChange('title')}
                                    error={touched.title && Boolean(errors.title)}
                                    helperText={touched.title && errors.title}
                                    value={values?.title}
                                />
                            </Grid>

                            <Grid item xs={7} sm={8} md={8} className={classes.paper} >

                                <FormInput
                                    label="First name *"
                                    placeholder="First name as on intl. passport"
                                    name="firstName"
                                    type='text'
                                    handleChange={handleChange('firstName')}
                                    error={touched.firstName && Boolean(errors.firstName)}
                                    helperText={touched.firstName && errors.firstName}
                                    value={values?.firstName}

                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} >
                            <Grid item className={classes.paper} >
                                <FormInput
                                    label="Last name *"
                                    placeholder="Last name as on intl. passport"
                                    name="lastName"
                                    type='text'
                                    handleChange={handleChange('lastName')}
                                    error={touched.lastName && Boolean(errors.lastName)}
                                    helperText={touched.lastName && errors.lastName}
                                    value={values?.lastName}

                                />
                            </Grid>
                        </Grid>

                    </Grid>
                    {/* middle name, nationality and gender */}
                    <Grid item container className={classes.titleTwo} spacing={3}>

                        <Grid item xs={12} sm={6} md={6} >
                            <Grid item className={classes.paper}>
                                <FormInput
                                    label="Middle name *"
                                    placeholder="Middle name as on intl. passport"
                                    name="middleName"
                                    type='text'
                                    handleChange={handleChange('middleName')}
                                    error={touched.middleName && Boolean(errors.middleName)}
                                    helperText={touched.middleName && errors.middleName}
                                    value={values?.middleName}

                                />
                            </Grid>
                        </Grid>
                        <Grid item container xs={12} sm={6} md={6} className={classes.titleTwoChild}>

                            <Grid item xs={12} sm={8} md={6} className={classes.paper} style={{ flex: '1' }}>
                                <FormCountryList
                                    label={'Nationality *'}
                                    name='nationality'
                                    handleChange={handleChange('nationality')}
                                    error={touched.nationality && Boolean(errors.nationality)}
                                    helperText={touched.nationality && errors.nationality}
                                    value={values?.nationality}


                                />
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} className={classes.paper} >
                                <FormSelect
                                    label={'Gender *'}
                                    options={[{ label: 'Male', value: 'Male' }, { label: 'Female', value: 'Female' },]}
                                    name='gender'
                                    defaultValue={'Gender'}
                                    handleChange={handleChange('gender')}
                                    error={touched.gender && Boolean(errors.gender)}
                                    helperText={touched.gender && errors.gender}
                                    value={values?.gender}

                                />
                            </Grid>
                        </Grid>

                    </Grid>

                    {/* marital status and date of birth */}
                    <Grid item container className={classes.titleThree} spacing={3}>
                        <Grid item xs={12} sm={6} md={6} className={classes.paper} >

                            <FormDate
                            label="Date of birth *"
                                placeholder="e.g 1/11/1994"
                                name="dateOfBirth"
                                
                                icon={CalendarTodayOutlined}
                                handleChange={handleChange('dateOfBirth')}
                                error={touched.dateOfBirth && Boolean(errors.dateOfBirth)}
                                helperText={touched.dateOfBirth && errors.dateOfBirth}
                                value={values?.dateOfBirth}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} md={6} >
                            <Grid item className={classes.paper} xs={12} sm={12} md={12} >

                                <FormSelect
                                    label={'Marital Status *'}
                                    options={[{ label: 'Single', value: 'Single' }, { label: 'Married', value: 'Married' }, { label: 'Divorce', value: 'Divorce' }]}
                                    name='maritalStatus'
                                    defaultValue={'Marital Status'}
                                    handleChange={handleChange('maritalStatus')}
                                    error={touched.maritalStatus && Boolean(errors.maritalStatus)}
                                    helperText={touched.maritalStatus && errors.maritalStatus}
                                    value={values?.maritalStatus}

                                />


                            </Grid>

                        </Grid>

                    </Grid>
                    {/* passport upload and warning icon */}
                    <Grid item container className={classes.titleFour} spacing={3}>
                        <Grid item xs={12} sm={6} md={6} className={`${classes.paper} `} >

                            <FormUpload
                                label="Upload Passport photo *"
                                name="passportPhoto"
                                handleChange={handleChange('passportPhoto')}
                                error={touched.passportPhoto && Boolean(errors.passportPhoto)}
                                helperText={touched.passportPhoto && errors.passportPhoto}
                                icon={FileCopyOutlined}
                
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} md={6} className={classes.fileUpload} >

                            <Alert icon={<InfoIcon />} severity='warning' color='info'>All file upload must not exceed 7MB</Alert>

                        </Grid>

                    </Grid>

                </Grid>
            </Grid>
        </Grid >
    );
}

import { makeStyles, Theme } from "@material-ui/core";
import mobileBg from "../../../assets/images/travoofy-header.jpeg";
import headerBg from "../../../assets/images/travelImage1.jpg";
import { dark } from "../../../utilities/helpers";
import { ThemeMode } from "../../../utilities/types";

export default function styles(mode: ThemeMode) {
  return makeStyles((theme: Theme) => ({
    root: {
      width: "100%",
      minHeight: "350px",
      height: "calc(100vw / 3)",
      backgroundColor: dark(mode)
        ? theme.palette.background.default
        : "#057FC3",
 
    },
    background: {
      height: "100%",
      width: "100%",
      marginLeft: "auto",
     
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPositionX: "center",
      backgroundPositionY: "center",
      
      [theme.breakpoints.down("xs")]: {
        width: "100%",
        backgroundImage: `url(${mobileBg})`,
      },
      [theme.breakpoints.up("sm")]: {
        width: "100%",
        backgroundImage: `url(${headerBg})`,

    },
  },
    overlay: {
      height: "100%",
      width: "100%",
      background: "rgba(0, 0, 0, .10)",
    },
  }));
}

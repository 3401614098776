import { makeStyles, Theme } from '@material-ui/core';
export default makeStyles((theme: Theme) => ({
  root: {
    padding: `${theme.spacing(10)}px 0`,
  },
  sectionHead: {
    marginBottom: theme.spacing(5),
  },
  sectionTitle: {
    marginBottom: theme.spacing(2),
  },
  subtitle: {
    width: '70%',
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '90%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
}));

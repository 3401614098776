import { makeStyles, Theme } from '@material-ui/core';



const styles =(Photo:any) => makeStyles((theme: Theme) => ({
root: {
     width: '90%',
    margin: 'auto',
    display: 'flex ',
    flexDirection: 'column',
    marginTop: theme.spacing(-24),
    marginBottom: theme.spacing(8),
    padding: theme.spacing(8),
    alignItems: 'center',
    backgroundColor: 'white',

    [theme.breakpoints.down('sm')]: {    
        padding: theme.spacing(2),
     },
   
    [theme.breakpoints.down('xs')]: {
       width: '100%', 
       alignItems: 'center',
       padding: theme.spacing(0),
       justifyContent: 'center',
    },
},
background:{
    width: '100%',
    minHeight: "430px",
    height: "calc(120vw / 3)",
    backgroundImage: `url(${Photo})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
},
visaFormContainer:{
    flexDirection: 'column',
  
},
forms:{
    display: 'flex ',
    flexDirection: 'column',
    alignItems: 'center',
}

}));
export default styles

import React from 'react'
import { useVisaStyles } from '../VisaAdditionalInfo.styles'
import { Grid } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import FormInput from "../../Form/FormInput/FormInput";
import InfoIcon from '@material-ui/icons/Info';

import FormUpload from '../../Form/FormUpload/FormUpload';
import { useFormikContext } from 'formik';

type Props = {
  hideLabel?: boolean;
  textToggle?: boolean;
  // action: (value: FlightSearchProps) => void;
  loading?: boolean;
};

type StaffFormProps = {
  staffTravels: {
    passportPhotograph: File,
    visaDataPage: File,
    swornAffidavit: File,
  }
}

export default function StaffForm({ hideLabel }: Props) {
  const classes = useVisaStyles();
  const { handleChange, errors, touched } = useFormikContext<StaffFormProps>();

  return (
    <Grid container>
      {/* passport upload and warning icon */}
      <Grid item container className={classes.titleFour} spacing={3}>
        <Grid item xs={12} sm={6} md={6} className={`${classes.paper} `} >
          <FormUpload
            label="Visa Data page *"
            name='staffTravels.visaDataPage'
            handleChange={handleChange('staffTravels.visaDataPage')}
            helperText={touched?.staffTravels?.visaDataPage && errors?.staffTravels?.visaDataPage}
            error={touched?.staffTravels?.visaDataPage && Boolean(errors?.staffTravels?.visaDataPage)}

          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} className={`${classes.paper} `} >
          <FormUpload
            label="Passport photograph photo *"
            name='stafftravels.passportPhotograph'
            handleChange={handleChange('staffTravels.passportPhotograph')}
            helperText={touched?.staffTravels?.passportPhotograph && errors?.staffTravels?.passportPhotograph}
            error={touched?.staffTravels?.passportPhotograph && Boolean(errors?.staffTravels?.passportPhotograph)}

          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} className={`${classes.paper} `} >
          <FormInput
            label="Sworn Affidavits (for staff) *"
            name='staffTravels.swornAffidavit'
            handleChange={handleChange('staffTravels.swornAffidavit')}
            helperText={touched?.staffTravels?.swornAffidavit && errors?.staffTravels?.swornAffidavit}
            error={touched?.staffTravels?.swornAffidavit && Boolean(errors?.staffTravels?.swornAffidavit)}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={6} className={classes.fileUpload} >
          <Alert icon={<InfoIcon />} severity='warning' color='info'>All file uploads must be in .pdf formats</Alert>
        </Grid>

      </Grid>
    </Grid>
  )
}

import { makeStyles, Theme } from '@material-ui/core';
export default makeStyles((theme: Theme) => ({
  root: {
    marginBottom: theme.spacing(15),
  },
  bookingText: {
    fontWeight: 700,
    fontSize: "25px",
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
}));

import { applyMiddleware, combineReducers, createStore } from "redux";
import thunk from "redux-thunk";
import { AirportReducer } from "./airports/reducer";
import { AuthModalReducer } from "./authModal/reducer";
import { BookingDataReducer } from './bookingData/reducer';
import { FlightDataReducer } from "./flightData/reducer";
import { FlightPropReducer } from "./flightProps/reducer";
import { ThemeReducer } from "./themeSwitch/reducer";
import { UserReducer } from "./user/reducer";

export default createStore(
  combineReducers({
    themeSwitch: ThemeReducer,
    user: UserReducer,
    authModal: AuthModalReducer,
    airports: AirportReducer,
    flightData: FlightDataReducer,
    flightProps: FlightPropReducer,
    bookingData: BookingDataReducer,
  }),
  applyMiddleware(thunk)
);

import { Button, Grid, Paper, Typography } from '@material-ui/core';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Action_OpenAuthModal } from '../../reduxStore/authModal/actions';
import ContactDetailStyle from './ContactDetailStyle';
import { ModalForms } from '../../utilities/types';

export default function ContactDetail() {
  const classes = ContactDetailStyle();
  const dispatch = useDispatch();

  return (
    <Paper elevation={0} className={classes.paper}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Typography component={'p'} align='center' variant='caption'>
            Already have account?
          </Typography>
          <Button onClick={() => {
            dispatch(Action_OpenAuthModal(ModalForms.SignIn));
          }} fullWidth size='large' variant='contained'>
            Sign In
          </Button>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography component={'p'} align='center' variant='caption'>
            Don't have an account yet?
          </Typography>
          <Button onClick={() => {
            dispatch(Action_OpenAuthModal(ModalForms.SignUp));
          }} fullWidth size='large' variant='contained' color='primary'>
            Sign Up
          </Button>
        </Grid>
      </Grid>
    </Paper>
  )
}
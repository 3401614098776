import { makeStyles, Theme } from "@material-ui/core";

export default makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    cursor: "pointer",
    backgroundColor: theme.palette.background.paper,
    borderRadius: "5px",
    overflow: "hidden",
  },
  con: {
    marginBottom: theme.spacing(3),
  },
  logo: {
    height: "50px",
  },
  boxOne: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    justifyItems: "center",
    alignItems: "center",
    alignSelf: "center",
  },
  boxTwo: {
    display: "flex",
    alignItems: "center",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  boxThree: {
    display: "flex",
  },
  box: {
    marginRight: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  boxed: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: `0 ${theme.spacing(4)}px`,
  },
  boxes: {
    display: "flex",
    flexDirection: "column",
    borderLeft: `1px solid ${theme.palette.divider}`,
  },
  boxContainer: {
    padding: theme.spacing(3),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: 700,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    textAlign: "center",
  },
  divider: {
    width: "150px",
    marginTop: theme.spacing(0.3),
    marginBottom: theme.spacing(0.3),
  },
  blockBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  price: {
    fontWeight: "bold",
    // textShadow: '1px 1px 1px rgba(0,0,0,0.3)',
  },
}));

import { makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) => ({
  root: {
    marginBottom: theme.spacing(7),
  },
  context: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  item: {
    display: 'flex',
    gap: theme.spacing(3),
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(1),
    padding: `${theme.spacing(3)}px`,
    backgroundColor: theme.palette.background.paper,
  },
  guest: {
    fontSize: '16px',
    fontWeight: 400,
    marginTop: theme.spacing(2),
    color: theme.palette.primary.dark,
  },
  text: {
    fontSize: '16px',
    color: theme.palette.primary.main,
  },
  items: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  excellent: {
    fontSize: '18px',
    fontWeight: 700,
  },
  avatar2: {
    padding: theme.spacing(6),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  text2: {
    fontSize: '28px',
    fontWeight: 700,
  }
}));

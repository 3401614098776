import { Box, Typography } from '@material-ui/core';
import React, { ReactNode } from 'react';
import InnerContainer from '../InnerContainer/InnerContainer';
import SectionContainerStyle from './SectionContainerStyle';

type Props = {
  className?: string;
  contentClassName?: string;
  title: string;
  subtitle?: string;
  sx?: any;
  children: ReactNode;
};

export default function SectionContainer({ className, title, subtitle, sx, children, contentClassName }: Props) {
  const classes = SectionContainerStyle();

  return (
    <Box sx={sx} className={`${classes.root} ${className}`}>
      <InnerContainer>
        <Box className={classes.sectionHead}>
          <Typography variant='h5' align='center' className={classes.sectionTitle}>{title}</Typography>
          {subtitle ? (
            <Typography className={classes.subtitle} variant='subtitle1' align='center'>{subtitle}</Typography>
          ) : null}
        </Box>
        <Box className={contentClassName}>{children}</Box>
      </InnerContainer>
    </Box>
  );
}

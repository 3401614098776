import {
  Box,
  Grid,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
} from "@material-ui/core";
import { EmailOutlined, Phone } from "@material-ui/icons";
import { Skeleton } from "@material-ui/lab";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import swal from "sweetalert";
import * as Yup from "yup";
import BreadCrumb from "../../components/BreadCrumb/BreadCrumb";
import ContactDetail from "../../components/ContactDetail/ContactDetail";
import CustomerSupport from "../../components/CustomerSupport/CustomerSupport";
import DynamicForm from "../../components/Form/DynamicForm/DynamicForm";
import Gallery from "../../components/Gallery/Gallery";
import Inclusion from "../../components/Inclusion/Inclusion";
import InnerContainer from "../../components/InnerContainer/InnerContainer";
import Layout from "../../components/Layout/Layout";
import Seo from "../../components/Seo/Seo";
import { Action_OpenAuthModal } from "../../reduxStore/authModal/actions";
import api from "../../utilities/api";
import { getFormValidation, getInitialValues } from "../../utilities/form";
import {
  getSlugId,
  getSlugName,
  gtagEvent,
  toNumberFormat,
} from "../../utilities/helpers";
import pageRoutes from "../../utilities/pageRoutes";
import {
  AnyObject,
  AppStore,
  ModalForms,
  PackageDetailObject,
} from "../../utilities/types";
import PackageDetailStyle from "./PackageDetailStyle";

export default function PackageDetail() {
  const params = useParams<AnyObject<string>>();
  const classes = PackageDetailStyle();
  const [id, setId] = useState<string>(getSlugId(params.slug || ""));
  const [name, setName] = useState<string>(getSlugName(params.slug || ""));
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<PackageDetailObject>();
  const { user }: AppStore = useSelector((store: AppStore) => store);
  const dispatch = useDispatch();
  const history = useHistory();

  const formik = useFormik({
    initialValues: getInitialValues(data ? data.documentModel.fields : []),
    onSubmit: (value: AnyObject<any>, { setSubmitting }) => {
      if (user.token) {
        gtagEvent("level_start", { level_name: "Package Booking" });
        api
          .post(
            "/document/create",
            {
              documentModelId: data?.documentModelId,
              data: data?.documentModel.fields.map((field) => ({
                ...field,
                value: value[field.name],
              })),
            },
            true
          )
          .then((res) => {
            if (res.status) {
              const documentId = res.data.documentId;
              api
                .post(
                  "/book/package",
                  { packageId: data?.id, documentId },
                  true
                )
                .then((res2) => {
                  setSubmitting(false);
                  if (res2.status) {
                    gtagEvent("level_end", {
                      level_name: "Package Booking",
                      success: true,
                    });
                    history.push(
                      `${pageRoutes.Booking}/${res2.data.bookingId}`
                    );
                  } else {
                    gtagEvent("level_end", {
                      level_name: "Package Booking",
                      success: false,
                    });
                    swal("Oops!", res2.message, "error");
                  }
                });
            } else {
              gtagEvent("level_end", {
                level_name: "Package Booking",
                success: false,
              });
              swal("Oops!", res.message, "error");
              setSubmitting(false);
            }
          });
      } else {
        dispatch(Action_OpenAuthModal(ModalForms.SignIn, true));
        setSubmitting(false);
      }
    },
    validationSchema: Yup.object(
      getFormValidation(data ? data.documentModel.fields : [])
    ),
  });

  const getPackage = (packageId: string) => {
    setLoading(true);
    api
      .fetch(`/package/get-details/${packageId}`)
      .then(({ status, data }) => {
        if (status) {
          setData(data);
          const obj: PackageDetailObject = data;
          const gd = {
            item_id: obj.id,
            item_name: obj.name,
            item_category: "Package",
            item_category2: obj.category.name,
            item_list_id: "packages",
            item_list_name: "Packages",
          };
          gtagEvent("view_item", {
            currency: obj.prices.currency,
            value: obj.prices.sellingPrice,
            items: [gd],
          });
        }
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getPackage(id);
  }, [id]);

  useEffect(() => {
    setId(getSlugId(params.slug));
    setName(getSlugName(params.slug));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  return (
    <Layout>
      <Seo title={name} />
      <InnerContainer>
        <BreadCrumb paths={["Home", "Packages", name]} />
      </InnerContainer>

      <Box className={classes.root}>
        <InnerContainer>
          {loading || data ? (
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={7}>
                <Paper elevation={0} className={classes.paper}>
                  <Box className={classes.container}>
                    {loading ? (
                      <>
                        <Skeleton
                          variant="text"
                          animation="wave"
                          width={"70%"}
                          height={50}
                        />
                        <Skeleton
                          variant="text"
                          animation="wave"
                          width={"40%"}
                          height={30}
                        />
                        <Skeleton
                          variant="text"
                          animation="wave"
                          width={"20%"}
                          height={20}
                        />
                      </>
                    ) : (
                      <>
                        <Typography variant="h3" className={classes.largeText}>
                          {data?.name}
                        </Typography>
                        <Typography variant="h6" className={classes.amount}>
                          {toNumberFormat(
                            data?.prices.sellingPrice || "0",
                            data?.prices.currency
                          )}
                        </Typography>
                        <Typography variant="overline" className={classes.unit}>
                          {data?.prices.unit}
                        </Typography>
                        <Typography
                          className={classes.oldPrice}
                          variant="h6"
                          color="textSecondary"
                        >
                          {toNumberFormat(
                            data?.prices.actualPrice || "0",
                            data?.prices.currency
                          )}
                        </Typography>
                      </>
                    )}
                  </Box>

                  <Box>
                    {loading ? (
                      <>
                        <Skeleton
                          variant="rect"
                          animation="wave"
                          width={"100%"}
                          height={300}
                        />
                        <br />
                        <Box className={classes.group}>
                          {[1, 2, 3, 4, 5].map((i) => (
                            <Skeleton
                              key={i}
                              variant="rect"
                              animation="wave"
                              className={classes.slideBox}
                            />
                          ))}
                        </Box>
                      </>
                    ) : (
                      <Gallery
                        spacing={3}
                        images={[data?.featuredImage, ...(data?.images || [])]}
                        autoPlay
                        height={400}
                      />
                    )}
                  </Box>

                  <Box className={classes.box}>
                    <Typography className={classes.secondText}>
                      {data?.description}
                    </Typography>
                  </Box>
                </Paper>

                <Paper elevation={0} className={classes.paper2}>
                  <Box>
                    {loading ? (
                      <>
                        <Skeleton
                          animation="wave"
                          variant="text"
                          width={200}
                          height={40}
                          style={{ marginTop: "calc(8px * 8)" }}
                        />
                        {[1, 2, 3].map((i) => (
                          <Skeleton
                            key={i}
                            animation="wave"
                            variant="text"
                            width={"50%"}
                          />
                        ))}
                      </>
                    ) : (
                      <Inclusion items={data?.inclusions || []} />
                    )}
                  </Box>
                </Paper>
              </Grid>

              <Grid item xs={12} sm={12} md={5}>
                <Paper elevation={0} className={classes.paper3}>
                  <Typography variant="subtitle2">
                    Contact our vacation package consultants for all your
                    vacation package related questions.
                  </Typography>
                  <br />
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={12} lg={6}>
                      <ListItem
                        button
                        dense
                        onClick={() =>
                          window.location.assign("mailto:mail@travoofy.com")
                        }
                      >
                        <ListItemIcon>
                          <EmailOutlined color="primary" fontSize="large" />
                        </ListItemIcon>
                        <ListItemText
                          primary="Email:"
                          secondary="mail@travoofy.com"
                          primaryTypographyProps={{
                            style: { fontWeight: "bold" },
                          }}
                        />
                      </ListItem>
                    </Grid>
                    <Grid item xs={12} sm={6} md={12} lg={6}>
                      <ListItem
                        button
                        dense
                        onClick={() =>
                          window.location.assign("tel:+23470087286639")
                        }
                      >
                        <ListItemIcon>
                          <Phone color="primary" fontSize="large" />
                        </ListItemIcon>
                        <ListItemText
                          primary="Phone:"
                          secondary="+2347087286639"
                          primaryTypographyProps={{
                            style: { fontWeight: "bold" },
                          }}
                        />
                      </ListItem>
                    </Grid>
                  </Grid>
                </Paper>
                {!user.token ? (
                  <>
                    <ContactDetail />
                    <br />
                  </>
                ) : null}
                {data ? (
                  <DynamicForm
                    note={data?.documentModel.description}
                    fields={data?.documentModel.fields || []}
                    actionLabel="Continue"
                    form={formik}
                  />
                ) : null}
              </Grid>
            </Grid>
          ) : null}
        </InnerContainer>
        <Box className={classes.gap}>
          <CustomerSupport />
        </Box>
      </Box>
    </Layout>
  );
}

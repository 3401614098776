import { makeStyles, Theme } from "@material-ui/core";

const styles = (height: number, spacing?: number) =>
  makeStyles((theme: Theme) => ({
    root: {
      width: "100%",
      backgroundColor: "transparent",
      display: "flex",
    },
    body: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      overflow: "hidden",
      gap: theme.spacing(spacing || 0),
    },
    display: {
      height: `${height}px`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center center",
      backgroundSize: "cover",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    nav: {
      width: "100%",
      margin: `0 ${theme.spacing(2)}px`,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    thumbs: {
      width: "100%",
      display: "flex",
      overflow: "hidden",
      overflowX: "auto",
    },
    scroll: {
      display: "flex",
      flexDirection: "row",
      gap: theme.spacing(spacing ? spacing / 2 : 0),
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
    },
    thumb: {
      height: "75px",
      width: `calc(75px * 1.3)`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center center",
      backgroundSize: "cover",
    },
  }));

export default styles;

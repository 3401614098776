import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Avatar,
    Box,
    Button,
    CircularProgress,
    IconButton,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Radio,
  } from "@material-ui/core";
  import { Cancel, PaymentRounded } from "@material-ui/icons";
  import React, { useState } from "react";
  import { useDispatch, useSelector } from "react-redux";
  import kalabashLogo from "../../../assets/images/Kalabash.png";
  import { Action_SetBookingData } from "../../../reduxStore/bookingData/actions";
  import api from "../../../utilities/api";
  import { gtagEvent, toNumberFormat } from "../../../utilities/helpers";
  import { AppStore } from "../../../utilities/types";
  import KalabashPaymentStyle from "./KalabashPaymentStyle";

  type Props = {
    active: boolean;
    onClick: any;
    bookingId: string;
  };


  export default function KalabashPayment({ active, onClick, bookingId }: Props) {


    const classes = KalabashPaymentStyle();
    const [kalabashLoading, setKalabashLoading] = useState<boolean>(false);
    const [kalabash, setKalabash] = useState({ frame: false, link: null });
    const { bookingData }: AppStore = useSelector((store: AppStore) => store);
    const dispatch = useDispatch();
    const [comment, setComment] = useState('')

    const getBooking = (id: string) => {
      setKalabashLoading(true);
      api
        .fetch(`/booking/${id}`, undefined, true)
        .then(({ status, data }) => {
          if (status) {
            dispatch(Action_SetBookingData(data));
          }
        })
        .finally(() => setKalabashLoading(false));
    };

    const closeFrame = () => {
      setKalabash((prev) => ({ ...prev, frame: false }));
      getBooking(bookingId);
      gtagEvent("purchase", {
        currency: bookingData.currency,
        transaction_id: bookingId,
        value: bookingData.amount,
        items: [{
          item_id: bookingId,
          item_category: bookingData.type,
          price: bookingData.amount,
          item_variant: "KalabashWave Payment",
        }],
      });
      window.location.href="/flight"
    };

    const kalabashPay = (id: string) => {
      setKalabashLoading(true);
      setKalabashLoading(true);
         let dayx =    new Date(bookingData.meta.FlightBookingResult.FlightBookingSummaryModel.FlightSummaryModel.FlightCombination.FlightModels[0].DepartureTime);
         let minusDate = dayx.getTime() - Date.now();
       let numberofDay = minusDate/86400000;
        if(numberofDay > 10){
      api
        .post(
          "/payment/link",
          {
            bookingId: id,
            saveCard: true,
            currency: bookingData.meta.TargetCurrency,
            paymentType: "kalabash"
          },
          true
        )
        .then(({ status, data }) => {
          if (status) {
            setKalabash({ frame: true, link: data.link });

          } else {
            setKalabashLoading(false);
          }
        })
      }else {
         setComment("To use Kalabash payment method depature should be at least 10 days from booking date.");
         setKalabashLoading(false)
      }
    };

    return (
      <>
        {kalabash.frame ? (
          <Box className={classes.kalabashModal}>
            {kalabash.link ? (
              <iframe
                className={classes.frame}
                src={kalabash.link}
                title={"payment"}
              />
            ) : null}
            <IconButton className={classes.close} onClick={closeFrame}>
              <Cancel />
            </IconButton>
          </Box>
        ) : null}
        <Accordion elevation={0} expanded={active} onChange={onClick}>
          <AccordionSummary
            expandIcon={<Radio color="primary" checked={active} />}
          >
            <ListItem>
              <ListItemAvatar>
                <Avatar src={kalabashLogo} alt="logo" className={classes.img} />
              </ListItemAvatar>
              <ListItemText
                primaryTypographyProps={{ className: classes.boldText }}
                primary="Kalabash"
                secondary="I Want To Pay Small Small"
              />
            </ListItem>
          </AccordionSummary>
          <AccordionDetails>

            <Box className={classes.kalabashCon}>
              <Button
                onClick={() => kalabashPay(bookingId)}
                disabled={kalabashLoading}
                startIcon={
                  kalabashLoading ? (
                    <CircularProgress color="secondary" />
                  ) : (
                    <PaymentRounded fontSize="large" />
                  )
                }
                size="large"
                className={classes.kalabashButton}
              >
                Pay Now
              </Button>

            </Box>

          </AccordionDetails>
          <p style={
                {color:'red',
                textAlign: 'center',
                margin: 5
              }

              }>{comment}</p>
        </Accordion>
      </>
    );
  }

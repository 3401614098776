import { makeStyles,Theme } from "@material-ui/core";

const useStyles = makeStyles((theme:Theme) => ({
    root: {
        gap: theme.spacing(4),
        width: '100%',
        padding:'20px',
    },
    inputSpace: {
        justifyContent: 'space-between',

    },
    formRadios: {
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
        }

    },

}))

export default useStyles
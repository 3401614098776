import { Box, Paper, Typography } from "@material-ui/core";
import { useFormik } from "formik";
import swal from "sweetalert";
import * as Yup from "yup";
import DynamicForm from "../../components/Form/DynamicForm/DynamicForm";
import api from "../../utilities/api";
import { getFormValidation, getInitialValues } from "../../utilities/form";
import { gtagEvent } from "../../utilities/helpers";
import { DocumentField, FormTypes } from "../../utilities/types";
import ContactFormStyle from "./ContactFormStyle";

export default function ContactForm() {
  const classes = ContactFormStyle();
  const fields: DocumentField[] = [
    {
      label: "Subject",
      name: "subject",
      required: true,
      type: FormTypes.Text,
      description: "",
    },
    {
      label: "Title",
      name: "title",
      required: true,
      type: FormTypes.Text,
      description: "",
    },
    {
      label: "Full Name",
      name: "fullName",
      required: true,
      type: FormTypes.Text,
      description: "",
    },
    {
      label: "Email Address",
      name: "email",
      required: true,
      type: FormTypes.Email,
      description: "",
    },
    {
      label: "Message",
      name: "msg",
      required: true,
      type: FormTypes.Long_Text,
      description: "",
    },
  ];
  const formik = useFormik({
    initialValues: getInitialValues(fields),
    onSubmit: (value: any, { setSubmitting, resetForm }) => {
      api.post("/contacts/create", value).then((res) => {
        setSubmitting(false);
        if (res.status) {
          gtagEvent("contact_us", { name: "Contact Form" });
          swal("Success", "Message sent successfully", "success");
          resetForm();
        } else {
          swal("Oops!", res.message, "error");
        }
      });
    },
    validationSchema: Yup.object(getFormValidation(fields)),
  });

  return (
    <Paper elevation={0} className={classes.root}>
      <Box className={classes.container}>
        <Typography className={classes.boldText}>
          Get in touch with us
        </Typography>
      </Box>
      <DynamicForm form={formik} fields={fields} actionLabel="Send Message" />
    </Paper>
  );
}

import React from 'react'
import { Divider, Grid, Typography, List, ListItem, ListItemText, Box, Paper } from '@material-ui/core';
import { Facebook, Instagram, YouTube, Twitter, LinkedIn } from '@material-ui/icons';
import useHeaderFlightFooterStyle from './HeaderFlightFooter.style';
import { hotelData, flightData } from './homeFlightPackages'
import InnerContainer from '../../../components/InnerContainer/InnerContainer';
import { SocialItems } from '../../../utilities/menuItems';

const socialIcons = [
    <Facebook fontSize="small" />,
    <Instagram fontSize="small" />,
    <Twitter fontSize="small" />,
    <LinkedIn fontSize="small" />,
    <YouTube fontSize="small" />
]

const HeaderFlightFooter = () => {
    const classes = useHeaderFlightFooterStyle();
    return (
        <InnerContainer>
            <Grid container className={classes.root} justifyContent='center' >
                <Grid item md={12}  >
                    <Paper elevation={0} className={classes.items}>
                        <Typography variant='h6' className={classes.headers1}>Search Flights, Hotels, Bus and Holiday Packages</Typography>
                        <Typography paragraph className={classes.paragraph}>
                            {texts}
                        </Typography>

                    </Paper>
                </Grid>
                {/* cheap flights in naigeria */}
                <Grid item container className={classes.List}>
                    <Paper elevation={0} className={classes.items}>
                        <Typography className={classes.headers}>Cheap Flights from Nigeria</Typography>
                        <Grid className={classes.rowList} item container >
                            {
                                flightData.map((item, index) => (
                                    <Grid key={index} item xs={12} sm={3} md={3} >

                                        <Typography variant='h6' className={classes.headers}>{item.flightName}</Typography>
                                        <List disablePadding >
                                            {
                                                item.flightLocation.map((item, index) => (
                                                    <ListItem key={index} disableGutters className={classes.listItem}>
                                                        <ListItemText> {item}</ListItemText>
                                                    </ListItem>
                                                ))
                                            }
                                        </List>
                                    </Grid>
                                ))
                            }
                        </Grid>
                    </Paper>
                </Grid>
                {/* cheap hotels in popular destinations */}
                <Grid item container className={classes.List}>
                    <Paper elevation={0} className={classes.items}>
                        <Typography className={classes.headers}>Cheap Hotels in popular destinations </Typography>
                        <Grid className={classes.rowList} item container >
                            {
                                hotelData.map((item, index) => (
                                    <Grid key={index} item xs={12} sm={3} md={3} >

                                        <Typography variant='h6' className={classes.headers}>{item.hotelsName}</Typography>
                                        <List disablePadding >
                                            {
                                                item.hotelsLocation.map((item, index) => (
                                                    <ListItem key={index} disableGutters className={classes.listItem}>
                                                        <ListItemText> {item}</ListItemText>
                                                    </ListItem>
                                                ))
                                            }
                                        </List>
                                    </Grid>
                                ))
                            }
                        </Grid>
                    </Paper>
                </Grid>
                {/* other deals */}
                <Divider className={classes.divider} />
                <Paper elevation={0} className={classes.items}>
                    <Grid item container className={classes.columnList} xs={12} sm={12} md={12}>

                        <Grid item xs={4} sm={6} md={4}>
                            <Typography className={classes.headers}>Get Exclusive deals on our products</Typography>
                            <List disablePadding>
                                <ListItem className={classes.listItem} disableGutters>
                                    <ListItemText>
                                        Book Flight, Flight status,Search Hotels
                                    </ListItemText>

                                </ListItem>
                                <ListItem disableGutters className={classes.listItem}>

                                    <ListItemText  >
                                        Domestic Flights ,International Flight
                                    </ListItemText>
                                </ListItem >
                                <ListItem className={classes.listItem} disableGutters>
                                    <ListItemText>
                                        Holiday Packages, International Flight
                                    </ListItemText>
                                </ListItem>
                                <ListItem className={classes.listItem} disableGutters>

                                    <ListItemText>
                                        Card ,Travel slim ,Pay Small Small
                                    </ListItemText>
                                </ListItem>
                            </List>

                        </Grid>

                        <Grid item sm={5} md={4} >

                            <Typography className={classes.headers}>Follow Us!</Typography>
                            <Typography paragraph className={classes.items3}>Stay tuned and access the latest deals and discounts with:</Typography>
                            <Box component='div'>
                                {
                                    SocialItems.map((item, index) => (
                                        <Box key={index} component='span' className={classes.socialIcons}>
                                            {socialIcons[index]}
                                        </Box>
                                    ))
                                }
                            </Box>

                        </Grid>

                        <Grid item sm={6} md={4} className={classes.last} >
                            <Typography className={classes.headers}>Get Inspired for your next trip!</Typography>
                            <Typography className={classes.items3}>
                                Find information about the best travel destinations and events in our Dreamguides. Discover our travel guide. </Typography>
                        </Grid>

                    </Grid>
                </Paper>

            </Grid >
        </InnerContainer>
    );
}

export default HeaderFlightFooter;



const texts = ' Travoofy is Africa’s leading, full-service online travel company. At Travoofy, we understand that everyone likes easy and affordable travel services, so we provide our customers with a one-stop booking portal for Affordable Flights, Hotels, Visa Assistance, Holiday Packages, Airport Transfers, a Loyalty program, and much more. Our portal gives customers the power of choice from a broad selection of top travel deals, and the ability to research, plan and book their domestic and international travel from the comfort of their homes and everywhere they are. We also have an interactive Call Centre and physical travel centres numbering 32 and counting across Nigeria, with a dedicated travel centre strategically located at international airports for customers’ convenience. We have offices in Ghana, Dubai, UK and Kenya with aggressive plans to expand into several African countries and the rest of the world. Our strategic relationships with Travel Partners ensure we offer the best travel deals to our customers, every time.'
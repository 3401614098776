import { Paper, Typography, Box } from '@material-ui/core';
import { CalendarTodayOutlined } from '@material-ui/icons';
import React from 'react';
import { BsDoorClosed } from 'react-icons/bs';
import AppLink from '../AppLink/AppLink';
import FormDate from '../Form/FormDate/FormDate';
import FormSelect from '../Form/FormSelect/FormSelect';
import HotelDetailCardStyle from './HotelDetailCardStyle';

type Props = {
    hideLabel?: boolean;
    textToggle?: boolean;
  };

  const options = [
    { label: 'Economy', value: 'economy' },
    { label: 'Business Class', value: 'business-class' },
    { label: 'First Class', value: 'first-class' },
  ];

export default function HotelDetailCard({hideLabel, textToggle}: Props) {
    const getLabel = (text: string) => hideLabel ? '' : text;

    const classes = HotelDetailCardStyle();
    return (
        <Paper elevation={0} className={classes.root}>
            <Typography className={classes.text}>Genova Hotels</Typography>

            <Box className={classes.box}><FormDate label={getLabel('Check in')} icon={CalendarTodayOutlined} disablePast /></Box>
            <Box className={classes.box}><FormDate label={getLabel('Check out')} icon={CalendarTodayOutlined} disablePast /></Box>

            <Box className={classes.container}>
                <Typography className={classes.amount}>₦36,000</Typography>
                <Typography>For Standard room</Typography>
                <Box className={classes.box}><FormSelect options={options} label={getLabel('Rooms')} placeholder='1' icon={BsDoorClosed} /></Box>
            </Box>

            <Box><Typography className={classes.total}>Total - ₦36,000</Typography></Box>
            <Box className={classes.button}><AppLink to='visa'>BOOK NOW</AppLink></Box>
        </Paper>
    )
}
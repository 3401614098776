import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Button,
  CircularProgress,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Radio,
} from "@material-ui/core";
import { PaymentRounded } from "@material-ui/icons";
import React, { useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
import { useSelector } from "react-redux";
import CredPalLogo from "../../../assets/images/Credpal-logo.png";
// import { Action_SetBookingData } from "../../../reduxStore/bookingData/actions";
// import api from "../../../utilities/api";
import { toNumberFormat } from "../../../utilities/helpers";
import { AppStore } from "../../../utilities/types";
import CredPalPaymentStyle from "./CredPalPaymentStyle";

type Props = {
  active: boolean;
  onClick: any;
  bookingId: string;
};

declare global {
  interface Window {
    Checkout?: any;
  }
}

export default function CredPalPayment({ active, onClick, bookingId }: Props) {
  const classes = CredPalPaymentStyle();
  const [credpalLoading, setCredpalLoading] = useState<boolean>(false);
  const { bookingData }: AppStore = useSelector((store: AppStore) => store);
  // const dispatch = useDispatch();

  // const getBooking = (id: string) => {
  //   setCredpalLoading(true);
  //   api
  //     .fetch(`/booking/${id}`, undefined, true)
  //     .then(({ status, data }) => {
  //       if (status) {
  //         dispatch(Action_SetBookingData(data));
  //       }
  //     })
  //     .finally(() => setCredpalLoading(false));
  // };

  const credpalPay = (id: string) => {
    setCredpalLoading(true);
    if (window.Checkout) {
      const checkout: any = new window.Checkout({
        key: "97213d9d-7ff9-4536-ab95-25809cf2cc53",
        amount: bookingData.amount,
        product: `Travoofy booking payment for ${id}`,
        onClose: () => setCredpalLoading(false),
        onLoad: () => setCredpalLoading(false),
        onError: (error: any) => setCredpalLoading(false),
        onSuccess: (data: any) => {
          // console.log("eeeeeeeeeee", data);
          checkout.close();
        },
      })!;
      checkout.setup();

      return checkout.open();
    }
  };

  return (
    <>
      <Accordion elevation={0} expanded={active} onChange={onClick}>
        <AccordionSummary
          expandIcon={<Radio color="primary" checked={active} />}
        >
          <ListItem>
            <ListItemAvatar>
              <Avatar src={CredPalLogo} alt="logo" className={classes.img} />
            </ListItemAvatar>
            <ListItemText
              primaryTypographyProps={{ className: classes.boldText }}
              primary="Credpal"
              secondary="Pay with your credpal account"
            />
          </ListItem>
        </AccordionSummary>
        <AccordionDetails>
          <Box className={classes.credpalCon}>
            <Button
              onClick={() => credpalPay(bookingId)}
              disabled={credpalLoading}
              startIcon={
                credpalLoading ? (
                  <CircularProgress color="secondary" />
                ) : (
                  <PaymentRounded fontSize="large" />
                )
              }
              size="large"
              className={classes.credpalButton}
            >
              Pay Now {toNumberFormat(bookingData.amount, bookingData.currency)}
            </Button>
          </Box>
        </AccordionDetails>
      </Accordion>
    </>
  );
}

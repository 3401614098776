import DateFnsUtils from "@date-io/date-fns";
import { Box, Divider, Icon, TextField, Typography } from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import "date-fns";
import React, { ElementType, useState } from "react";
import FormDateStyle from "./FormDateStyle";

type Props = {
  label?: string;
  placeholder?: string;
  icon: ElementType<any>;
  defaultDate?: Date;
  showToolbar?: boolean;
  disablePast?: boolean;
  disableFuture?: boolean;
  minDate?: Date;
  maxDate?: Date;
  color?: "white";
  value?: string;
  handleChange?: (value: string) => void;
  error?: any;
  helperText?: any;
  id?: string;
  name?: string;
  hidden?: boolean;
  disable?: boolean;
};

export default function FormDate({
  label,
  disable,
  disableFuture,
  maxDate,
  placeholder,
  icon,
  defaultDate,
  showToolbar,
  disablePast,
  minDate,
  color,
  handleChange,
  helperText,
  id,
  name,
  hidden,
  error,
  value,
}: Props) {
  const classes = FormDateStyle(color)();
  const data = value ? new Date(value) : new Date();
  const [selectedDate, setSelectedDate] = useState<Date>(defaultDate || data);
  const [dateValue, setDateValue] = useState(data.toDateString());
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleDateChange = (date: Date) => {
    setSelectedDate(date as Date);
    setDateValue(date.toDateString());
    if (handleChange) {
      handleChange(
        `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
      );
    }
    if (open) handleClose();
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Box className={classes.root}>
        <Typography className={classes.label} variant="body2">
          {label}
        </Typography>
        <TextField
          InputProps={{
            startAdornment: (
              <Box className={classes.adornment}>
                <Icon component={icon} color="disabled" />
                <Divider
                  className={classes.divider}
                  orientation="vertical"
                  flexItem
                />
              </Box>
            ),
          }}
          error={error}
          hidden={hidden}
          disabled={disable}
          id={id}
          name={name}
          value={dateValue}
          variant="outlined"
          placeholder={placeholder || label}
          fullWidth
          onClick={handleOpen}
          helperText={helperText}
        />
        <DatePicker
          disablePast={disablePast}
          disableFuture={disableFuture}
          
          format="MM/dd/yyyy"
          variant={"inline"}
          value={selectedDate}
          onChange={handleDateChange as any}
          minDate={minDate}
          maxDate={maxDate}
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          className={classes.picker}
        />
      </Box>
    </MuiPickersUtilsProvider>
  );
}

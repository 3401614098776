import React from "react";
import { useVisaStyles } from './VisaAdditionalInfo.styles'
import VisaAdditionalInfoBlock from './VisaAdditionalInfoBlock'
import { Box, Grid, Divider, RadioGroup, FormControlLabel } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import FormRadio from '../Form/FormRadio/FormRadio';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import { useFormikContext } from 'formik';

export type InfoProps = {
    termsAndConditions: boolean,
    travelingWithSomeone: string,

}

export enum TravelingWithSomeone {
    Yes = "Yes",
    No = "No"
}

const VisaAdditionalInfo = () => {
    const classes = useVisaStyles();
    const ctx = useFormikContext<InfoProps>();
  

    // OTHER OPTIONS STATE
    function additionalBlock(options: string): JSX.Element | null {
        switch (options) {
            case TravelingWithSomeone.No:
                return null;
            case TravelingWithSomeone.Yes:
                return <VisaAdditionalInfoBlock />;
            default:
                return null
        }

    };



    return (
        <Grid container className={classes.root} >
            <Grid item md={12} >
                <Alert severity='warning' className={''}>Use all given Information as they appear on your passport/ID to avoid complications.</Alert>

            </Grid>
            {/* Travel data Information */}
            <Grid container item className={classes.main}>
                {/* Travel status and occupation */}
                <Grid item container component='div' className={classes.radioBtn}>
                    {/* <Typography variant="body2" component="p">Are you traveling with someone?</Typography> */}
                    <FormControl component="fieldset" >
                        <FormLabel id="">Are you traveling with someone?</FormLabel>
                        <RadioGroup
                            className={classes.radios}
                            onChange={ctx.handleChange}
                            defaultValue={TravelingWithSomeone.No}
                            name="travelingWithSomeone"
                            value={ctx.values.travelingWithSomeone}
                        >
                            <FormControlLabel value={TravelingWithSomeone.No} label='No' control={<FormRadio />} />
                            <FormControlLabel value={TravelingWithSomeone.Yes} label='Yes' control={<FormRadio />} />
                        </RadioGroup>

                    </FormControl>

                </Grid>


            </Grid>

            <Grid item container >
                <>
                    {additionalBlock(ctx.values.travelingWithSomeone as string)}
                </>
            </Grid>

            <Divider />
            <Box my={3}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={<Checkbox color="primary" />}
                            label="I agree to the terms and conditions"
                            name='termsAndConditions'
                            onChange={ctx.handleChange}
                            value={ctx.values.termsAndConditions}


                        />
                    </Grid>
                    {ctx.touched.termsAndConditions && ctx.errors.termsAndConditions ? (
                        <Grid item xs={12}>
                            <Alert severity='error'>{ctx.errors.termsAndConditions}</Alert>
                        </Grid>

                    ) : null}
                </Grid>
            </Box>
            {/* <pre>{JSON.stringify(ctx.values, null, 3)}</pre> */}
        </Grid >
    );
}
export default VisaAdditionalInfo
import {
  Box,
  Grid,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from "@material-ui/core";
import {
  Build,
  FiberManualRecord,
  FlightLand,
  FlightTakeoff,
} from "@material-ui/icons";
import React from "react";
import { toDateTime, toDuration } from "../../utilities/helpers";
import { FlightModel } from "../../utilities/types";
import TimeLineStyle from "./TimeLineStyle";

type Props = {
  timeline: FlightModel;
};

export default function TimeLiner({ timeline }: Props) {
  const classes = TimeLineStyle();

  return (
    <Grid container spacing={3} className={classes.root}>
      {timeline.FlightLegs.map((leg, index) => (
        <Grid item xs={12} sm={6} md key={index} className={classes.legCon}>
          <Typography className={classes.legTitle} variant="caption">
            {toDateTime(leg.StartTime)} &nbsp; {leg.DepartureCode} &#10230;{" "}
            {leg.DestinationCode} ({leg.CabinClassName})
          </Typography>
          <br />
          <Typography className={classes.text} variant="caption">
            Airplane: {leg.Aircraft} &bull; Cabin Class: {leg.CabinClass} &bull;
            Booking Class: {leg.BookingClass}
          </Typography>
          <Stepper className={classes.stepper} orientation="vertical">
            <Step>
              <StepLabel
                StepIconComponent={() => (
                  <FlightTakeoff className={classes.icon} />
                )}
              >
                <Box>
                  <Typography
                    component={"strong"}
                    variant="inherit"
                    className={classes.text}
                  >
                    {leg.DepartureName}
                  </Typography>
                  <Box className={classes.smallText}>
                    <Typography className={classes.text}>
                      {toDateTime(leg.StartTime)}
                    </Typography>
                    <Typography className={classes.text}>
                      {toDateTime(leg.StartTime, true)}
                    </Typography>
                  </Box>
                </Box>
              </StepLabel>
              <StepContent>
                <Box className={classes.actionsContainer}>
                  <FiberManualRecord className={classes.iconSmall} />
                  Flight Duration: {toDuration(leg.Duration)}
                </Box>
              </StepContent>
            </Step>
            {leg.TechnicalStops.map((stop, key) => (
              <Step key={key}>
                <StepLabel
                  StepIconComponent={() => <Build className={classes.icon} />}
                >
                  <Box>
                    <Typography
                      component={"strong"}
                      variant="inherit"
                      className={classes.text}
                    >
                      {stop.AirportName}
                    </Typography>
                    <Box className={classes.smallText}>
                      <Typography className={classes.text}>
                        {toDateTime(stop.ArrivalDate)}
                      </Typography>
                      <Typography className={classes.text}>
                        {toDateTime(stop.ArrivalDate, true)}
                      </Typography>
                    </Box>
                    <Typography className={classes.text}>
                      Technical Stop Duration: {toDuration(stop.Duration)}
                    </Typography>
                  </Box>
                </StepLabel>
              </Step>
            ))}
            <Step>
              <StepLabel
                StepIconComponent={() =>
                  leg.Layover ? (
                    <FiberManualRecord className={classes.icon} />
                  ) : (
                    <FlightLand className={classes.icon} />
                  )
                }
              >
                <Box>
                  <Typography
                    component={"strong"}
                    variant="inherit"
                    className={classes.text}
                  >
                    {leg.DestinationName}
                  </Typography>
                  <Box className={classes.smallText}>
                    <Typography className={classes.text}>
                      {toDateTime(leg.EndTime)}
                    </Typography>
                    <Typography className={classes.text}>
                      {toDateTime(leg.EndTime, true)}
                    </Typography>
                  </Box>
                  {leg.Layover ? (
                    <Typography className={classes.text}>
                      Layover Duration: {toDuration(leg.LayoverDuration)}
                    </Typography>
                  ) : null}
                </Box>
              </StepLabel>
            </Step>
          </Stepper>
        </Grid>
      ))}
    </Grid>
  );
}

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Radio,
} from "@material-ui/core";
import { AccountBalanceWallet, MonetizationOn } from "@material-ui/icons";
import { Skeleton } from "@material-ui/lab";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import { Action_SetBookingData } from "../../../reduxStore/bookingData/actions";
import api from "../../../utilities/api";
import { gtagEvent, toNumberFormat } from "../../../utilities/helpers";
import { AppStore, WalletObject } from "../../../utilities/types";
import WalletPaymentStyle from "./WalletPaymentStyle";

type Props = {
  active: boolean;
  onClick: any;
  bookingId: string;
  amount: number;
};

export default function WalletPayment({
  active,
  onClick,
  bookingId,
  amount,
}: Props) {
  const classes = WalletPaymentStyle();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [confirm, setConfirm] = useState<boolean>(false);
  const [disable, setDisable] = useState<boolean>(false);
  const [wallet, setWallet] = useState<WalletObject>();
  const { bookingData }: AppStore = useSelector((store: AppStore) => store);

  const getBooking = (id: string) => {
    setConfirm(true);
    api
      .fetch(`/booking/${id}`, undefined, true)
      .then(({ status, data }) => {
        if (status) {
          dispatch(Action_SetBookingData(data));
        }
      })
      .finally(() => setConfirm(false));
  };

  const pay = () => {
    setLoading(true);
    api
      .post("/payment/baines", { bookingId }, true)
      .then(({ status, message }) => {
        if (status) {
          gtagEvent("purchase", {
            currency: bookingData.currency,
            transaction_id: bookingId,
            value: bookingData.amount,
            items: [{
              item_id: bookingId,
              item_category: bookingData.type,
              price: bookingData.amount,
              item_variant: "Wallet Payment",
            }],
          });
          swal("Congratulation!", message, "success");
          getBooking(bookingId);
        } else {
          swal("Warning", message, "warning");
          getBooking(bookingId);
        }
      })
      .finally(() => setLoading(false));
  };

  const getWallet = () => {
    setLoading(true);
    api
      .fetch("/get-user-wallet", {}, true)
      .then(({ status, data }) => {
        if (status) {
          setWallet(data);
          setDisable(false);
        } else {
          setDisable(true);
        }
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getWallet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const insuficientFund = (wallet?.balance || 0) < amount;

  return (
    <Accordion
      disabled={disable}
      elevation={0}
      expanded={active}
      onChange={onClick}
    >
      <AccordionSummary expandIcon={<Radio color="primary" checked={active} />}>
        <ListItem>
          <ListItemAvatar>
            <AccountBalanceWallet fontSize="large" />
          </ListItemAvatar>
          <ListItemText
            primaryTypographyProps={{ className: classes.boldText }}
            primary="E-wallet"
            secondary="Pay via your E-wallet"
          />
        </ListItem>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={3} justifyContent={"center"}>
          <Grid item md={6} sm={9} xs={12} className={classes.root}>
            {!loading && wallet ? (
              <>
                <List dense className={classes.list}>
                  <ListItem button dense onClick={() => {}}>
                    <ListItemIcon>
                      <MonetizationOn />
                    </ListItemIcon>
                    <ListItemText
                      primary={toNumberFormat(wallet.balance, "NGN")}
                      secondary={"Wallet Balance"}
                      primaryTypographyProps={{ className: classes.boldText }}
                      secondaryTypographyProps={{
                        className: classes.smallText,
                      }}
                    />
                  </ListItem>
                </List>
              </>
            ) : (
              <Box className={classes.list}>
                <Skeleton animation="wave" variant="rect" height={30} />
                <br />
                <Skeleton animation="wave" variant="rect" height={30} />
                <br />
                <Skeleton animation="wave" variant="rect" height={30} />
                <br />
                <Skeleton
                  animation="wave"
                  variant="rect"
                  height={50}
                  width={200}
                  style={{ margin: "auto" }}
                />
                <br />
              </Box>
            )}
          </Grid>
          {!loading ? (
            <Grid item xs={12} className={classes.button}>
              {!insuficientFund ? (
                <Button
                  onClick={pay}
                  variant="contained"
                  color="primary"
                  size="large"
                  disabled={confirm}
                  startIcon={confirm ? <CircularProgress /> : null}
                >
                  Pay Now{" "}
                  {toNumberFormat(bookingData.amount, bookingData.currency)}
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  color="default"
                  size="large"
                  disabled
                >
                  Insufficient Fund
                </Button>
              )}
              <br />
            </Grid>
          ) : null}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

import { makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  topRow: {
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: theme.spacing(3),
  },
  bottomRow: {
    flex: 1,
  },
  buttons: {
    marginRight: theme.spacing(2),
  },
  buttons2: {
    marginRight: theme.spacing(2),
    color: theme.palette.common.white,
  },
  buttonsActive: {
    marginRight: theme.spacing(2),
    color: theme.palette.common.white,
    borderBottom: `1px solid ${theme.palette.common.white}`,
    borderRadius: '0',
    outline: '0',
  },
  gap: {
    height: theme.spacing(3),
  },
  searchButtonCon: {
    display: 'flex',
    alignItems: 'flex-start',
    paddingTop: `${theme.spacing(6)}px !important`,
  },
  searchButtonConNoLabel: {
    display: 'flex',
    alignItems: 'flex-start',
    paddingTop: `${theme.spacing(3)}px !important`,
  },
  spinner: {
    color: "#ffffff"
  },
  errorText: {
    marginLeft: theme.spacing(3),
    marginTop: '-5px',
  },
}));

import { makeStyles, Theme } from '@material-ui/core';
export default makeStyles((theme: Theme) => ({
  root: {},
  boldText: {
    fontWeight: 700,
    fontSize: "16px",
  },
  credpalCon: {
    padding: theme.spacing(4),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
  },
  credpalButton: {
    backgroundColor: "#01C5A5",
    padding: theme.spacing(3, 6),
    fontWeight: "bold",
    color: theme.palette.type === "dark" ? "#fff" : "#000",
    "&:hover": {
      backgroundColor: "#0a917a",
    },
  },
  img: {
    width: "50px",
    borderRadius: "0",
  },
}));

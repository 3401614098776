import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Button,
  CircularProgress,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Radio,
} from "@material-ui/core";
import { Cancel, PaymentRounded } from "@material-ui/icons";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import binanceLogo from "../../../assets/images/Binance-logo.png";
import { Action_SetBookingData } from "../../../reduxStore/bookingData/actions";
import api from "../../../utilities/api";
import { gtagEvent, toNumberFormat } from "../../../utilities/helpers";
import { AppStore } from "../../../utilities/types";
import BinancePaymentStyle from "./BinancePaymentStyle";

type Props = {
  active: boolean;
  onClick: any;
  bookingId: string;
};

export default function BinancePayment({ active, onClick, bookingId }: Props) {
  const classes = BinancePaymentStyle();
  const [binanceLoading, setBinanceLoading] = useState<boolean>(false);
  const [binance, setBinance] = useState({ frame: false, link: null });
  const { bookingData }: AppStore = useSelector((store: AppStore) => store);
  const dispatch = useDispatch();
  const [comment, setComment] = useState("");

  const getBooking = (id: string) => {
    setBinanceLoading(true);
    api
      .fetch(`/booking/${id}`, undefined, true)
      .then(({ status, data }) => {
        if (status) {
          dispatch(Action_SetBookingData(data));
        }
      })
      .finally(() => setBinanceLoading(false));
  };

  const closeFrame = () => {
    setBinance((prev) => ({ ...prev, frame: false }));
    getBooking(bookingId);
    gtagEvent("purchase", {
      currency: bookingData.currency,
      transaction_id: bookingId,
      value: bookingData.amount,
      items: [
        {
          item_id: bookingId,
          item_category: bookingData.type,
          price: bookingData.amount,
          item_variant: "Binance Payment",
        },
      ],
    });
    window.location.href = "/flight";
  };

  const binancePay = (id: string) => {
    setBinanceLoading(true);
    setBinanceLoading(true);
    api
      .post(
        "/payment/link",
        {
          bookingId: id,
          saveCard: true,
          currency: bookingData.meta.TargetCurrency,
          paymentType: "binance",
        },
        true
      )
      .then(({ status, data }) => {
        if (status) {
          window.open(data.link, "_self");
          // setBinanceLoading(false);
          // setBinance({ frame: true, link: data.link });
        } else {
          setBinanceLoading(false);
        }
      });
  };

  return (
    <>
      {binance.frame ? (
        <Box className={classes.binanceModal}>
          {binance.link ? (
            <iframe
              className={classes.frame}
              src={binance.link}
              title={"payment"}
            />
          ) : null}
          <IconButton className={classes.close} onClick={closeFrame}>
            <Cancel />
          </IconButton>
        </Box>
      ) : null}
      <Accordion elevation={0} expanded={active} onChange={onClick}>
        <AccordionSummary
          expandIcon={<Radio color="primary" checked={active} />}
        >
          <ListItem>
            <ListItemAvatar>
              <Avatar src={binanceLogo} alt="logo" className={classes.img} />
            </ListItemAvatar>
            <ListItemText
              primaryTypographyProps={{ className: classes.boldText }}
              primary="Binance Pay"
              secondary="Pay with Binance Pay"
            />
          </ListItem>
        </AccordionSummary>
        <AccordionDetails>
          <Box className={classes.binanceCon}>
            <Button
              onClick={() => binancePay(bookingId)}
              disabled={binanceLoading}
              startIcon={
                binanceLoading ? (
                  <CircularProgress color="secondary" />
                ) : (
                  <PaymentRounded fontSize="large" />
                )
              }
              size="large"
              className={classes.binanceButton}
            >
              Pay Now
            </Button>
          </Box>
        </AccordionDetails>
        <p style={{ color: "red", textAlign: "center", margin: 5 }}>
          {comment}
        </p>
      </Accordion>
    </>
  );
}

import { Box } from '@material-ui/core';
import React from 'react';
import HeaderStyle from './HeaderStyle';
import { useSelector } from 'react-redux';
import { AppStore } from '../../../utilities/types';

export default function Header() {
  const {mode} = useSelector((store: AppStore) => store.themeSwitch);
  const classes = HeaderStyle(mode)();

  return (
    <Box className={classes.root}>
      <Box className={classes.background} >
        <Box className={classes.overlay} />
      </Box>
    </Box>
  );
}

import { makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  firstText: {
    fontSize: '18px',
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
  },
  blockBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: theme.spacing(2),
  },
  modelCon: {
    margin: `${theme.spacing(1)}px 0`,
    width: '100%',
  },
  modelTitle: {
    fontWeight: 'bold',
  },
  boxTwo: {
    display: "flex",
    alignItems: "center",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    justifyContent: 'space-between',
  },
  left: {
    marginRight: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  center: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    flex: 1,
  },
  right: {
    marginLeft: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
  divider: {
    width: "max(100px, 50%)",
    marginTop: theme.spacing(0.3),
    marginBottom: theme.spacing(0.3),
  },
}));
import React from 'react';
import { Avatar, Grid, Box, Typography, LinearProgress, Divider }from '@material-ui/core';
import HotelReviewStyle from './HotelReviewStyle';
import { Person } from '@material-ui/icons';

export default function MediaControlCard() {
  const classes = HotelReviewStyle();

  return (
    <Grid container spacing={0} className={classes.root}>
        <Grid item xs={12} md={9} sm={12} className={classes.container}>
            <Box className={classes.content}>
                <Typography className={classes.ratingText}>Overall rating</Typography>
                <Box className={classes.context}>
                    <Box className={classes.item}>
                    <Avatar className={classes.avatar2}>8.1</Avatar>
                    <Box>
                        <Typography className={classes.excellent}>Excellent</Typography>
                        <Typography className={classes.guest}>Based on 147 Guest Reviews</Typography>
                    </Box>
                    </Box>
                    <Avatar className={classes.avatar3}><Person /></Avatar>
                </Box>
            </Box>
            <Divider />
            <Box className={classes.parent}>
                <Box>
                    <Typography className={classes.num}>Cleanliness</Typography>
                    <Typography className={classes.num}>Room/Comfort</Typography>
                    <Typography className={classes.num}>Service Quality</Typography>
                    <Typography className={classes.num}>Facilities</Typography>
                    <Typography className={classes.num}>Location</Typography>
                </Box>
                <Box className={classes.box}>
                    <LinearProgress value={75} variant="determinate" className={classes.bar} />
                    <LinearProgress value={65} variant="determinate" className={classes.bar} />
                    <LinearProgress value={75} variant="determinate" className={classes.bar} />
                    <LinearProgress value={60} variant="determinate" className={classes.bar} />
                    <LinearProgress value={55} variant="determinate" className={classes.bar} />
                </Box>
                <Box className={classes.number}>
                    <Typography className={classes.num}>75</Typography>
                    <Typography className={classes.num}>65</Typography>
                    <Typography className={classes.num}>75</Typography>
                    <Typography className={classes.num}>65</Typography>
                    <Typography className={classes.num}>75</Typography>
                </Box>
            </Box>
        </Grid>


        <Grid item xs={12} md={3} sm={12} className={classes.cover}>
            <Avatar className={classes.avatar}>
                <Person />
            </Avatar>
            <Typography className={classes.text}>Write a guest review</Typography>
        </Grid>
  </Grid>
  );
}
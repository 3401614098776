import { getUser } from "../../utilities/cookie";
import { ActionTypes, UserObject } from "../../utilities/types";
import { UserActionType } from "./actions";

const defaultState: UserObject = getUser();

export function UserReducer(state = defaultState, action: UserActionType) {
  switch (action.type) {
    case ActionTypes.SET_USER:
      return { ...action.payload };
    case ActionTypes.REMOVE_USER:
      return { token: "", user: {} };
    default:
      return { ...state };
  }
}

import { makeStyles, Theme } from '@material-ui/core';
export default makeStyles((theme: Theme) => ({
  root: {},
  boldText: {
    fontWeight: 700,
    fontSize: "16px",
  },
  flutterCon: {
    padding: theme.spacing(4),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
  },
  flutterButton: {
    backgroundColor: "#ff9b00",
    padding: theme.spacing(3, 6),
    fontWeight: "bold",
    color: theme.palette.type === "dark" ? "#fff" : "#000",
    "&:hover": {
      backgroundColor: "#ff9b00",
    },
  },
  img: {
    width: "50px",
    borderRadius: "0",
  },
  flutterModal: {
    position: 'fixed',
    backgroundColor: 'white',
    top: theme.spacing(4),
    bottom: theme.spacing(4),
    left: theme.spacing(4),
    right: theme.spacing(4),
    zIndex: 20000,
  },
  frame: {
    border: '0',
    backgroundColor: '#fff',
    width: '100%',
    height: '100%',
  },
  close: {
    color: 'white',
    backgroundColor: 'red',
    position: 'absolute',
    right: -10,
    top: -20,
    "&:hover": {
      backgroundColor: 'red',
    },
  },
}));

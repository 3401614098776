import { CircularProgress } from "@material-ui/core";
import axios from "axios";
import React, { ElementType, useState } from "react";
import { BASE_URL } from "../../../utilities/api";
import { APIResponse } from "../../../utilities/types";
import FormInput from "../FormInput/FormInput";

type Props = {
  label?: string;
  color?: "white";
  handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  error?: any;
  helperText?: any;
  name: string;
  hidden?: boolean;
  disable?: boolean;
  icon?: ElementType<any>;
};

export default function FormUpload({
  label,
  color,
  handleChange,
  error,
  helperText,
  name,
  hidden,
  disable,
  icon,
}: Props) {
  const [loading, setLoading] = useState<boolean>(false);

  const handleEvent = (value: any) => {
    if (handleChange) {
      handleChange({
        target: {
          type: "file",
          value,
        },
      } as any);
    }
  };

  const handleUpload = (file: File) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("file", file, file.name);
    axios
      .post<APIResponse>(`${BASE_URL}/upload-files?dir=document`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(({ data: { status, data, message } }) => {
        if (status) {
          handleEvent(data.url);
        } else {
          handleEvent(message);
        }
      })
      .catch((err: Error) => handleEvent(err.message))
      .finally(() => setLoading(false));
  };

  return (
    <FormInput
      name={name}
      type="file"
      label={label}
      placeholder={label}
      helperText={helperText}
      handleChange={(event) => {
        const file = event.target.files?.item(0) as File;
        if (!file) return handleEvent("");
        const valid = file.type.includes("image") || file.type.includes("pdf");
        if (!valid)
          return handleEvent(
            `File type (.${file.type.split("/")[1]}) is not allowed`
          );
        if (file.size > 2000000) return handleEvent(`File size must be < 2MB`);
        return handleUpload(file);
      }}
      error={error}
      icon={loading ? CircularProgress : icon}
      color={color}
      id={name}
      hidden={hidden}
      disable={disable}
      inputProps={{ accept: "image/*,.pdf" }}
    />
  );
}

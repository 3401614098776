import { Box, Grid } from "@material-ui/core";
import FormInput from "../../../Form/FormInput/FormInput";
import useVisaSelfEmployed from "./VisaEmployOrUnemployment.style";
import { useFormikContext } from "formik";

type Props = {
    selfEmployedOccupation: string;
    companysName: string;
    companysPhoneNumber: string;
    companysEmail: string;
}
export const VisaSelfEmployed = () => {
    const classes = useVisaSelfEmployed();
    const { values, handleChange, touched, errors } = useFormikContext<Props>();
    return (
        <Box >
            <Grid container className={classes.root} >

                <Grid item container className={classes.inputSpace} >
                    <Grid item xs={12} sm={5} md={5} >
                        <FormInput
                            label="Occupation *"
                            placeholder="Occupation"
                            name="selfEmployedOccupation"
                            type='text'
                            handleChange={handleChange}
                            value={values.selfEmployedOccupation}
                            error={touched.selfEmployedOccupation && Boolean(errors.selfEmployedOccupation)}
                            helperText={touched.selfEmployedOccupation && errors.selfEmployedOccupation}

                        />
                    </Grid>
                    <Grid item xs={12} sm={5} md={5}>
                        <FormInput
                            label="Company Name *"
                            placeholder="Company Name"
                            name="companysName"
                            type='text'
                            handleChange={handleChange}
                            value={values.companysName}
                            error={touched.companysName && Boolean(errors.companysName)}
                            helperText={touched.companysName && errors.companysName}

                        />
                    </Grid>
                </Grid>
                <Grid item container className={classes.inputSpace} >
                    <Grid item xs={12} sm={5} md={5} >
                        <FormInput
                            label="Company's Phone Number  *"
                            placeholder="Company's Phone Numeber "
                            name="companysPhoneNumber "
                            type='tel'
                            handleChange={handleChange('companysPhoneNumber')}
                            value={values.companysPhoneNumber}
                            error={touched.companysPhoneNumber && Boolean(errors.companysPhoneNumber)}
                            helperText={touched.companysPhoneNumber && errors.companysPhoneNumber}

                        />
                    </Grid>
                    <Grid item xs={12} sm={5} md={5}>
                        <FormInput
                            label="Company's Email *"
                            placeholder="Company's Email"
                            name="companysEmail"
                            type='email'
                            handleChange={handleChange}
                            value={values.companysEmail}
                            error={touched.companysEmail && Boolean(errors.companysEmail)}
                            helperText={touched.companysEmail && errors.companysEmail}

                        />
                    </Grid>
                </Grid>

            </Grid>
        </Box>
    );
}


import { Box, Fade, Modal, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import SignInForm from "../pages/Auth/SignInForm/SignInForm";
import SignUpForm from "../pages/Auth/SignUpForm/SignUpForm";
import VerifyOtp from "../pages/Auth/VerifyOtp/VerifyOtp";
import Booking from "../pages/Booking/Booking";
import ViewBooking from "../pages/Booking/ViewBooking/ViewBooking";
import Contact from "../pages/Contact/Contact";
import Home from "../pages/Home/Home";
import SearchFlight from "../pages/SearchFlight/SearchFlight";
import HotelDetail from "../pages/Hotel/HotelDetail/HotelDetail";
import PackageDetail from "../pages/PackageDetail/PackageDetail";
import Payment from "../pages/Payment/Payment";
import VacationPackage from "../pages/VacationPackage/VacationPackage";
import { Action_CloseAuthModal } from "../reduxStore/authModal/actions";
import pageRoutes from "../utilities/pageRoutes";
import { AppStore, AuthModalType, ModalForms } from "../utilities/types";
import Visa from "../pages/Visa/Visa";
import { About } from "../pages/About/About";
import PolicyAndPrivacy from "../pages/PolicyAndTerms/PolicyAndPrivacy";
import ComplaintsManagement from "../pages/PolicyAndTerms/ComplaintsManagement";



export default function MarkUp() {
  const { pathname } = useLocation();
  const classes = styles();
  const { open, form, dismissible }: AuthModalType = useSelector(
    (store: AppStore) => store.authModal
  );
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(Action_CloseAuthModal());
  };

  const handleDismiss = (e: any) => {
    const id = e.target.id;
    if (dismissible && id === "modalRoot") {
      dispatch(Action_CloseAuthModal());
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Box className={classes.root}>
      <Switch>
        <Route path={pageRoutes.Flight} exact component={Home} />
        <Route path={pageRoutes.Hotel} exact component={Home} />
        <Route path={pageRoutes.ContactUs} component={Contact} />
        <Route path={pageRoutes.SearchFlight} component={SearchFlight} />
        {/* <Route path={pageRoutes.SearchHotel} component={Hotel} /> */}
        <Route
          path={`${pageRoutes.Packages}/:slug`}
          component={PackageDetail}
        />
        <Route path={pageRoutes.Packages} component={VacationPackage} />
        <Route
          path={`${pageRoutes.Booking}/:bookingId`}
          component={ViewBooking}
        />
        <Route path={pageRoutes.Booking} component={Booking} />
        <Route path={`${pageRoutes.Payment}/:bookingId`} component={Payment} />
        <Route path={pageRoutes.HotelDetail} component={HotelDetail} />
        <Route path={pageRoutes.Visa} exact component={Home} />
        <Route path={pageRoutes.SearchVisa} component={Visa} />
        <Route path={pageRoutes.About} component={About} />
        <Route path={pageRoutes.PrivacyPolicyPage} component={PolicyAndPrivacy } />
        <Route path={pageRoutes.CompliantManagement} component={ComplaintsManagement}/>
        <Route path={"*"}>
          <Redirect to={pageRoutes.Flight} />
        </Route>
      </Switch>
      <Modal
        open={open}
        onClose={handleClose}
        className={classes.modal}
        BackdropProps={{ onClick: handleDismiss }}
        disableEscapeKeyDown
      >
        <Fade in={open}>
          <Box
            id="modalRoot"
            onClick={handleDismiss}
            className={classes.innerCon}
          >
            {form === ModalForms.SignIn ? <SignInForm /> : null}
            {form === ModalForms.SignUp ? <SignUpForm /> : null}
            {form === ModalForms.EmailVerify ? <VerifyOtp /> : null}
          </Box>
        </Fade>
      </Modal>
    </Box>
  );
}

const styles = makeStyles((theme: Theme) => ({
  root: {
    "& *": {
      outline: "none !important",
    },
  },
  modal: {
    outline: "none !important",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "auto",
    paddingTop: theme.spacing(8),
  },
  innerCon: {
    width: "100%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
}));

import { makeStyles, Theme } from "@material-ui/core";

export default makeStyles((theme: Theme) => ({
  root: {
    marginBottom: theme.spacing(15),
  },
  paymentText: {
    fontWeight: 700,
    fontSize: "25px",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  privacyText: {
    fontSize: "14px",
  },
}));

import { makeStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        minHeight: '55px',
        width: '100%',
        borderRadius: '5px',
        overflow: 'hidden',
    },
    item: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '55px',
        flex: 1,
        '& > p': {
            fontWeight: 500,
        },
    },
    items: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    button: {
        justifySelf: 'center',
        flex: 1,
        height: '55px',
        '& > p': {
            fontSize: '14px',
            color: theme.palette.text.primary,
        },
    },
    activeButton: {
        justifySelf: 'center',
        flex: 1,
        height: '55px',
        backgroundColor: theme.palette.primary.main,
        '& > p': {
            fontSize: '14px',
            color: theme.palette.common.white,
        },
    },
}));

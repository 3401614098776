import {
  Box,
  Grid,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import { Skeleton } from "@material-ui/lab";
import { useEffect, useState } from "react";
import SwipeableViews from "react-swipeable-views";
import BookingCard from "../../../components/BookingCard/BookingCard";
import SectionContainer from "../../../components/SectionContainer/SectionContainer";
import api from "../../../utilities/api";
import { chunkList, gtagEvent } from '../../../utilities/helpers';
import { QuickBookingObject } from "../../../utilities/types";
import QuickBookingStyle from "./QuickBookingStyle";

export default function QuickBooking() {
  const classes = QuickBookingStyle();
  const [index, setIndex] = useState(0);
  const theme = useTheme();
  const lg = useMediaQuery(theme.breakpoints.only("lg"));
  const md = useMediaQuery(theme.breakpoints.only("md"));
  const sm = useMediaQuery(theme.breakpoints.only("sm"));
  const xs = useMediaQuery(theme.breakpoints.only("xs"));
  const [list, setList] = useState<QuickBookingObject[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [gtagData, setGtagData] = useState<any[]>([]);

  const updateGtagData = (val: any) => {
    setGtagData((prev) => [...prev, val]);
  };

  const sendEvent = (val: any) => {
    gtagEvent('view_item_list', {
      item_list_id: 'quick_booking',
      item_list_name: 'Quick Booking',
      items: [...gtagData],
    });
    gtagEvent('select_item', {
      item_list_id: 'quick_booking',
      item_list_name: 'Quick Booking',
      items: [val],
    });
  };

  const getCount = () => {
    if (lg) return 4;
    else if (md) return 3;
    else if (sm) return 2;
    else if (xs) return 1;
    else return 4;
  };

  const data = list.length > 0 ? chunkList(list.reverse(), getCount()) : [];
  const max = data.length - 1;

  const left = () => {
    if (index > 0) {
      setIndex(index - 1);
    }
  };

  const right = () => {
    if (index < max) {
      setIndex(index + 1);
    }
  };

  useEffect(() => {
    setLoading(true);
    api
      .fetch("/quick-flights")
      .then(({ status, data }) => {
        if (status && data) {
          setList(data);
        }
      })
      .finally(() => setLoading(false));
  }, []);

  return loading || data.length > 0 ? (
    <SectionContainer
      contentClassName={classes.content}
      title="Quick Flight Booking"
      subtitle="Weekend deals"
    >
      {loading ? (
        <Grid container spacing={3} justifyContent="space-evenly">
          {Array.from(Array(getCount())).map((_, i) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={i}>
              <Skeleton variant="rect" height={300} animation="wave" />
            </Grid>
          ))}
        </Grid>
      ) : (
        <>
          {data && data.length > 0 ? (
            <SwipeableViews index={index} axis="x" className={classes.root}>
              {data.map((items, listIndex) => (
                <Grid
                  container
                  spacing={3}
                  key={listIndex}
                  justifyContent="space-evenly"
                >
                  {items.map((item, itemIndex) => (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={itemIndex}>
                      <BookingCard
                        item={item}
                        update={updateGtagData}
                        send={sendEvent}
                      />
                    </Grid>
                  ))}
                </Grid>
              ))}
            </SwipeableViews>
          ) : null}
          <Box className={classes.buttonCon}>
            <IconButton onClick={left} className={classes.swipeButton}>
              <ChevronLeft />
            </IconButton>
            <IconButton onClick={right} className={classes.swipeButton}>
              <ChevronRight />
            </IconButton>
          </Box>
        </>
      )}
    </SectionContainer>
  ) : null;
}

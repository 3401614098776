import { Step, StepLabel, Stepper, Theme, Typography, useMediaQuery } from '@material-ui/core';
import React from "react";
import StepperStyle from "./StepperStyle";

function getSteps() {
  return [
    "Search",
    "Passenger details",
    "Payment",
  ];
}

type Props = {
  activeStep: number;
};

export default function Steppers({ activeStep }: Props) {
  const classes = StepperStyle();
  const steps = getSteps();
  const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));

  return (
    <Stepper className={classes.root} alternativeLabel={isSmall} activeStep={activeStep}>
      {steps.map((label, index) => (
        <Step key={index}>
          <StepLabel>
            <Typography className={classes.text}>{label}</Typography>
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
}
